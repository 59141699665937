<template>
    <a v-if="product" :href="'/produs/'+ product.productid"  class="app-prod-el el" :class="{'carousel-m':carousel,disabled:disabled}"  > <!-- @mouseover="expandStats" @mouseleave="expand=false" -->
        <div class="figure">
            <img :src="product.picture" style="position:relative;" oncontextmenu="return false" alt="product_picture">
            <img class="image-hover" oncontextmenu="return false" :src="product.picture_hover" alt="hover-picture">
        </div>
        <div class="app-prod-el__fav" @click="addWishlist"  @click.prevent="goProduct" >
            <i class='bx bxs-heart' style="color:#d62121;" v-if="showFilled||product.showFilled"></i>
            <i class='bx bx-heart' v-else ></i>
            <!-- <ion-icon name="heart" style="pointer-events :none;" title="xxxxxx" v-if="showFilled" ></ion-icon>
            <ion-icon name="heart-outline"  title="xxxxxx" v-else ></ion-icon> -->
        </div>
        
        <!-- <div v-else class="app-prod-el__fav" @click="openSizes"  @click.prevent="goProduct" @mouseover="showFilled=true"  @mouseleave="showFilled=false" @mouseout="showFilled=false">
            <ion-icon name="heart" v-if="showFilled" ></ion-icon>
            <ion-icon name="heart-outline" v-else ></ion-icon>
        </div> -->
        <div style="margin-top:5px;">  
               <div :class="{'w-height':$route.name=='wishlist'}">
                    <span class="app-prod-el__stats-title-brand" style="padding-top:2px;">{{product.product_brand}}</span>
                    <span class="app-prod-el__stats-new-title">{{product.product_name}}</span>
                    <!-- <span class="app-prod-el__stats-title" >{{product.product_name}}</span> -->
                    <span class="app-prod-el__stats-price" :class="{discount:product.discount_price!=product.original_price}">{{product.original_price}}</span>
                    <span class="app-prod-el__stats-price app-prod-el__stats-price--d " v-if="product.discount_price!=product.original_price">{{product.discount_price}}</span>
                    <span class="app-prod-el__stats-price app-prod-el__stats-price--d " v-if="product.discount_price==product.original_price">&nbsp;</span>
               </div>
                <button @click.prevent="goProduct" v-if="wishlist&&product.stock" @click="openSizesW"  class="app-prod-el-to-cart">Adauga in cos</button>
                <button @click.prevent="goProduct" v-if="wishlist&&product.stock==false"  class="app-prod-el-to-cart app-prod-el-to-cart--out">Stoc epuizat</button>
        </div>
        <!-- <div class="app-prod-el__stats" :class="{'w-stats':wishlist}" :style="{background:bg}">
            <div class="app-prod-el__stats-1" >
                <span class="app-prod-el__stats-title" style="padding-top:2px;">{{product.product_brand}}</span>
                <span class="app-prod-el__stats-title">{{product.product_name}}</span>
                <span class="app-prod-el__stats-price" :class="{discount:product.discount_price!=product.original_price}">{{product.original_price}}</span>
                <span class="app-prod-el__stats-price app-prod-el__stats-price--d " v-if="product.discount_price!=product.original_price">{{product.discount_price}}</span>
                <span class="app-prod-el__stats-price app-prod-el__stats-price--d " v-if="product.discount_price==product.original_price">&nbsp;</span>

            </div>
            <div class="app-prod-el__stats-2" :class="{expand:expand}">
                <div class="app-prod-el__sizes">
                    <span v-for="(s,index) in product.product_size" class="app-prod-el__sizes-el" :key="s.size">{{s.size}}<span v-if="index!=product.product_size.length-1">&#124;</span></span>
                </div>
            </div>
            <button @click.prevent="goProduct" v-if="wishlist&&product.stock" @click="openSizesW"  class="app-prod-el-to-cart">Adauga in cos</button>
            <button @click.prevent="goProduct" v-if="wishlist&&product.stock==false"  class="app-prod-el-to-cart app-prod-el-to-cart--out">Stoc epuizat</button>
        </div>  -->
        <!-- <notifications position="top center" width="100%" class="notification-custom"/>    -->
    </a>
</template>


<script>
import $ from "jquery"
export default {
    data(){
        return {
            expand:false,
            disabled:false,
            showFilled:false,
            heart:"heart-outline",
            addedW:false
        }
    },
    props:{
        product:{
            type:Object
        },
        bg:{
            type:String,
            default:"white"
        },
        wishlist:{
            type:Boolean,
            default:false
        },
        carousel:{
            type:Boolean,
            default:false
        }
    },
    methods:{
        expandStats(){
            this.expand = true;
        },
        goProduct(){
            this.disabled = true;
            // this.disabled = false;
        },
        openSizesW(){
            this.$store.dispatch("updateLive",this.product.productid);
            console.log(this.product)
            let data = {
                id:this.product.productid,
                price:this.product.discount_price,
                sizes:[]
            }
            for(let i = 0; i < this.product.product_size.sizes.length;i++){
                if(this.product.product_size.sizes[i].stock){
                    data.sizes.push(
                        {
                            selected:false,
                            value:this.product.product_size.sizes[i]
                        }
                    );
                }
            }
            $('html, body').css({
                overflow: 'hidden',
                height: '100%'
            });
            if(data.sizes[0].value.size == "One Size") {
                // this.$notify({ type: 'success', text: 'Produsul a fost adaugat cu succes in cos' })
                this.$store.commit('SET_WSIZES',data);
            }
            else{
                this.$store.commit('SET_WSIZES',data);
            }
        },
        openSizes(){
            const data = {
                sizes:[],
                id:2    
            }
            this.$store.commit('SET_SIZES',data);
            this.$store.dispatch("updateLive",this.product.productid);
        },
        async addWishlist(){
            this.$store.dispatch("updateLive",this.product.productid);
            console.log(this.$cookies.get("wishlist"));
            if(!this.addedW){
                const product_id = this.product.productid;
                let wishlist = this.$cookies.get('wishlist');
                wishlist = JSON.parse(wishlist);
                if(!wishlist) { wishlist = []; }
                const found = wishlist.find(product => product === product_id);
                if(found === undefined) {
                    wishlist.push(product_id);
                    this.$cookies.set('wishlist', JSON.stringify(wishlist));
                    wishlist = this.$cookies.get('wishlist');
                    this.addedW = this.showFilled = true;
                    this.$notify({ type: 'success', text: '<i class="bx bx-x close-notif"></i>Produsul a fost adaugat cu succes la favorite.' })
                }
                if(this.$cookies.get("token")&&this.$cookies.get("useridsession")) {
                    this.$store.dispatch("loadWishlist",this.$route);
                }      
            }

            else {
                if(this.$cookies.get("token")&&this.$cookies.get("useridsession")){
                    const product_id = this.product.productid;
                    let response = await this.axios.get(`/wishlist?domain=${this.$store.state.domain}&language=${this.$store.state.language}&token=${this.$store.state.auth.token}&useridsession=${this.$store.state.auth.useridsession}&deletewishlistid=${product_id}`);
                    this.$store.commit("SET_WISHLIST", response.data.favorites);
                    this.$store.dispatch("loadMain",this.$route)
                    if(this.$route.fullPath!= "/wishlist"){
                        this.showFilled = false;this.heart = "heart-outline";this.addedW = false;
                    }
                    this.product.showFilled = false;
                    this.$notify({ type: 'success', text: '<i class="bx bx-x close-notif"></i>Produsul a fost șters din wishlist' })
                    this.addedW =  this.product.showFilled;

                }
                else {
                    const product_id = this.product.productid;
                    console.log(product_id)
                    let wishlist = this.$cookies.get('wishlist');
                    wishlist = JSON.parse(wishlist)
                    let x = wishlist.filter(product => product != product_id)
                    // const index = wishlist.findIndex(product => product === product_id);
                    // wishlist.splice(index, 1);
                    wishlist = x;
                    if(wishlist.length == 0) this.$cookies.remove('wishlist')
                    else this.$cookies.set('wishlist', JSON.stringify(wishlist));
                    console.log(wishlist)
                    // const productsGet = await this.$axios.$get(`/checkout?domain=${this.$store.state.domain}&products=${JSON.stringify(products)}&token=${this.$store.state.auth.token}&useridsession=${this.$store.state.auth.useridsession}&detelefromcart=${this.product.product_ID}&deletefromcartthissize=${this.product.product_size}`);
                    // this.$store.commit('SET_CART_PRODS', productsGet);
                    // this.$store.commit('SET_CARTL', products.length);
                    this.$notify({ type: 'success', text: '<i class="bx bx-x close-notif"></i>Produsul a fost șters din wishlist' })
                    this.$store.dispatch("loadWishlist");
                    if(this.wishlist!=true)
                    this.addedW = this.showFilled =  false;
                }
            } 
            console.log(this.$cookies.get("wishlist"));
            if(this.$cookies.get('wishlist')) this.$store.commit("SET_WLENGTH",JSON.parse(this.$cookies.get('wishlist')).length)
            else this.$store.commit("SET_WLENGTH",0)
            
    }
    },
    beforeMount(){
        let wishlist = this.$cookies.get('wishlist');
        wishlist = JSON.parse(wishlist)
        let found;
        if(wishlist) found = wishlist.find(element => element == this.product.productid);
        if(found||this.product.wishlist_added==true||this.product.showFilled) {this.showFilled = true;this.heart = "heart";this.addedW = true;}
    },
    watch:{
        product(){
            if(this.$cookies.get("token")&&this.$cookies.get("useridsession"))
                this.addedW = true
        }
    }
}
</script>


<style lang="scss" scoped>
@import "../assets/styles/_variables.scss";
@import "../assets/styles/main.scss";
.app-prod-el{
    width: 100%;
    position: relative;
    cursor: pointer;
    display: block;
    text-decoration: none;
    color: $color-black;


    img{
        // position: relative;
        display:block;
        width: 100%;
    }

    &__fav {
        position: absolute;
        top: 1rem;
        right: 1rem;
        font-size: 2.5rem;

        ion-icon:hover,
        i:hover{
            animation: bounce 1s infinite;
        }
    }

    &-to-cart {
        width: 100%;
        background: $color-white;
        color: $color-black;
        padding:1rem 0;
        font-family: $medium-main;
        font-size: 1.2rem;
        border-radius: 5px;
        margin-top: 1rem;
        border: 1px solid $color-black;
        transition: all .5s;

        &:hover {
            color: $color-white;
            background: $color-black;
        }
    }

    &__stats {
        position: absolute;
        transition: all 5s;
        bottom: 0;
        width: 100%;
        max-width: 100%;
        padding-left: 1rem;
        z-index: 0;

        
        &-title {
            font-family: $semibold-main;
            display: block;
            font-size: 1.1rem;
            max-width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            &-brand {
                font-family: $bold-main;
                letter-spacing: 1px;
                text-transform: uppercase;
                margin-top: 2px; 
            }
        }

        &-new-title {
            font-family: $medium-main;
            display: block;
            font-size: 1.16rem;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &-price {
            font-family: $semibold-main;
            text-transform: uppercase;
            font-size: 1.3rem;

            &--d {
                color: red;
                margin-left: 1rem;
            }
        }

        &-2 {
            height: 0;
            overflow: hidden;
            transition: all .5s;
            position: relative;
        }
    }

    &__sizes {
        display: flex;
        margin-top: .5rem;



        &-el {
            display: block;
            margin-left: 1rem;
            font-family: $semibold-main;
            cursor: pointer;
            font-size: 1.1rem;
            display: flex;

            span {
                padding-left: 1rem;
                color:rgb(161, 161, 161);
                font-weight: 100;
                transform: translateY(-1px);

            }

            &:first-child{
                margin-left: 0;
            }
        }
    }
}

.expand {
    height:2rem;
    padding-bottom: 1rem;
    cursor:default
}

.discount {
    text-decoration: line-through;
    color: gray;
}

@keyframes bounce {
  0%, 100% {
    transform: scale(1)
  }
  50% {
    transform: scale(1.2)
}
}

.w-stats {
    bottom: -30%;
}

.app-prod-el-to-cart--out {
    background: #868686 !important;
    color: #fff;
    border: #111111;
    outline: none;
    border: 1px solid #868686!important;
}
.app-prod-el__stats-new-title {
    height: 34px;
}

@media only screen and (max-width: 400px) {
    .app-prod-el__stats-new-title {
        height: 34px;
    }
    .app-prod-el__stats-price {
        display: block;
        margin-left: 0!important;
    }
    // .app-prod-el {
    //     padding-bottom: 60px;;
    // }
    .app-prod-el__stats-1 {
        margin-bottom: -10px;
    }

    .carousel-m {
        height: 330px;
    }
    .app-prod-el-to-cart {
        margin-top: 3rem;
    }
}

.figure {
    position:relative;
}
.image-hover {
     position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      object-fit: contain;
      opacity: 0;
      transition: opacity .2s;
      max-height: 100%;
}

 .figure:hover img.image-hover {
      opacity: 1;
    }

    .w-height{
        height:65px;
    }
</style>