<template>
  <div class="app-brands" v-if="$store.state.brands">
    <div class="wrapper" style="background:white;margin-top:4rem;">
      <h2>{{ $store.state.brands.branduri.title }}</h2>
    </div>
    <div class="app-brands__cnt">
      <div class="wrapper">
        <h3>{{ $store.state.brands.branduri.branduripremium.text }}</h3>
        <div class="app-brands__all">
          <div
            class="app-brands__template"
            v-for="(b, index) in $store.state.brands.branduri.branduripremium
              .list"
            :key="index"
          >
            <a :href="b.link" class="app-brands__template-link">
              <img :src="b.image" alt="brand" />
              <span>{{ b.brand }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="wrapper">
      <div class="app-brands__texts">
        <h4>{{ $store.state.brands.branduri.toatebrandurile.title }}</h4>
        <div class="app-brands__letters">
          <span
            v-for="(l, index) in $store.state.brands.branduri.toatebrandurile
              .content.letters"
            :key="index"
            @click="selectLetter(l.value)"
            :style="{ color: l.color + '!important' }"
          >
            {{ l.display }}
          </span>
        </div>
        <div
          class="app-brands__texts-template"
          v-for="(b, index) in $store.state.brands.branduri.toatebrandurile
            .content.lettergroups"
          :key="index"
        >
          <div class="app-brands__texts-template-letter">
            {{ b.symbol }}
          </div>
          <div class="app-brands__texts-template-brands">
            <a
              class="app-brands__texts-template-brands-el"
              v-for="(el, index) in b.brands"
              :href="el.link"
              :key="index"
              >{{ el.brand }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    async selectLetter(letter) {
      const response = await this.axios.get(
        `/branduri?domain=${this.$store.state.domain}&language=${this.$store.state.language}&${this.$route.params.id}&symbol=${letter}`
      );
      this.$store.commit("SET_BRANDS", response.data);
    },
  },
  beforeMount() {
    this.$store.dispatch("loadBrands", this.$route.params.id);
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/_variables.scss";
@import "../assets/styles/main.scss";
.app-brands {
  h2 {
    color: $color-black;
    font-family: $medium-main;
    font-size: 2.4rem;
  }

  &__cnt {
    background-color: $color-gray-light;
    margin-top: 3rem;
    padding: 3rem 0;

    h3 {
      color: $color-black;
      font-family: $medium-main;
      font-size: 2rem;
    }
  }

  &__all {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    column-gap: 5rem;
  }

  &__template {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 7rem;

    &-link {
      width: 10rem;
      cursor: pointer;
      color: $color-gray;
      text-decoration: none;

      &:hover {
        color: $color-black;
      }

      img {
        width: inherit;
        margin: 0 auto;
      }

      span {
        display: block;
        text-align: center;
        margin-top: auto;
        color: inherit;
        font-family: $medium-main;
      }
    }
  }

  &__texts {
    margin-top: 4rem;

    h4 {
      color: $color-black;
      font-family: $medium-main;
      font-size: 2rem;
    }

    &-template {
      display: flex;
      flex-direction: row;
      margin-top: 3rem;

      &-letter {
        color: $color-black;
        font-family: $bold-main;
        font-size: 2rem;
        display: block;
        width: 85px;
      }

      &-brands {
        margin-left: 30rem;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 20rem;
        row-gap: 1.2rem;
        width: 150rem;

        &-el {
          text-decoration: none;
          display: block;
          font-family: $medium-main;
          color: $color-gray;
          font-size: 1.2rem;
          transition: all 0.5s;

          &:hover {
            cursor: pointer;
            color: $color-black;
          }
        }
      }
    }
  }

  &__letters {
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
    flex-wrap: wrap;

    span {
      display: block;
      margin-left: 1.8rem;
      font-family: $bold-main;
      color: $color-gray;
      font-size: 2.4rem;
      cursor: pointer;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}

@media only screen and (max-width: 1300px) {
  .app-brands__all {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-top: 3rem;
  }
  .app-brands__texts-template {
    flex-direction: column;
  }
  .app-brands__texts-template-brands {
    grid-template-columns: 1fr;
    margin-left: 0;
    margin-top: 2rem;
  }
  .app-brands__template {
    margin: auto;
    margin-top: 2rem;
  }
  .app-brands__letters {
    margin-left: -2rem;
    span {
      margin-left: 2rem !important;
    }
  }
}
@media only screen and (max-width: 700px) {
  .app-brands__all {
    grid-template-columns: 1fr 1fr;
  }
  .app-brands__template {
    margin-top: 5rem;

    div {
      margin: auto;
      width: 15rem;

      span {
        font-size: 1.4rem;
      }
    }
  }
  .app-brands__letters {
    padding: 0 1.5rem;
  }
  .app-brands__texts-template {
    padding: 0 1.5rem;
  }
  .app-brands__texts {
    h4 {
      padding-left: 1.5rem;
    }
  }
}
</style>
