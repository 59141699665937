import Vue from 'vue'
import VueRouter from 'vue-router'
import cookies from 'js-cookie'
import axios from 'axios'
import store from "../store"

Vue.use(VueRouter)

import Homepage from "../views/Homepage.vue"
import Retur from '../views/retur.vue'
import Reclamatii from '../views/reclamatii.vue'
import Termeni from '../views/termeni-si-conditii.vue'
import Faq from '../views/faq.vue'
import Pl from '../views/plata-la-livrare.vue'
import Po from '../views/plata-online.vue'
import Newsletter from '../views/newsletter.vue'
import Livrare from '../views/livrare.vue'
import Contact from '../views/contact.vue'
import Club from '../views/club.vue'
import Politica from '../views/politica-de-confidentialitate.vue'
import Produse from '../views/produse.vue'
import Cont from '../views/contul-meu.vue'
import Branduri from '../views/branduri.vue'
import Pay from '../views/pay.vue'
import test from '../views/test.vue'
import cart from '../views/cart.vue'
import produs from "../views/produs.vue"
import checkout from "../views/checkout.vue"
import pr_originale from "../views/produse-originale.vue"
import wishlist from "../views/wishlist.vue"
import actualizare_parola from "../views/actualizare-parola.vue";
import Auth from "../views/auth.vue"
import PageNotFound from '../views/404.vue'
import Reclama from "../views/reclama.vue";


const  routes =  [
  {
    path: '/',
    name: 'Homepage',
    component: Homepage
  },
  {
    path: '/retur',
    name: 'Retur',
    component: Retur
  },
  {
    path: '/reclamatii',
    name: 'Reclamatii',
    component: Reclamatii
  },
  {
    path: '/termeni-si-conditii/:type?',
    name: 'Termeni',
    component: Termeni
  },
  {
    path: '/faq',
    name: 'Faq',
    component: Faq
  },
  {
    path: '/plata-la-livrare',
    name: 'plata-la-livrare',
    component: Pl
  },
  {
    path: '/plata-online',
    name: 'plata-online',
    component: Po
  },
  {
    path: '/newsletter',
    name: 'Newsletter',
    component: Newsletter
  },
  {
    path: '/livrare',
    name: 'Livrare',
    component: Livrare
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/club',
    name: 'Club',
    component: Club
  },
  {
    path: '/politica-de-confidentialitate',
    name: 'Politica',
    component: Politica
  },
  {
    path: '/produse/:type',
    name: 'Produse',
    component: Produse
  },
  {
    path: '/contul-meu/:type?/:orderid?',
    name: 'Contul Meu',
    component: Cont,
    beforeEnter: (to, from, next) => {
      if((!cookies.get('token')||!cookies.get("useridsession"))&&to.params.type!='login'){
        next('/contul-meu/login')
      }
      else 
        next()
      // if(!cookies.get('token')||!cookies.get("useridsession")) next("/contul-meu/login");else next();
    }
  },
  {
    path: '/branduri/:id?',
    name: 'Branduri',
    component: Branduri
  },
  {
    path: '/pay/:pi?',
    name: 'Pay',
    component: Pay
  },
  {
    path: '/test/:id?',
    name: 'test',
    component: test
  },
  {
    path: '/cart',
    name: 'cart',
    component: cart
  },
  {
    path: '/produs/:id',
    name: 'produs',
    component: produs
  },
  {
    path: '/checkout/:step',
    name: 'checkout',
    component: checkout
  },
  {
    path: '/produse-originale',
    name: 'checkout',
    component: pr_originale
  },
  {
    path: '/wishlist',
    name: 'wishlist',
    component: wishlist
  },
  {
    path: '/auth/:userid?/:token?/:route?',
    name: 'Auth',
    component: Auth
  },
  {
    path: '/campaign/:id/:redirect?/:prodid?',
    name: 'Reclama',
    component: Reclama
  },
  {
    path: '/actualizare-parola',
    name: 'actualizare-parola',
    component: actualizare_parola,
    beforeEnter: (to, from, next) => {
      if(!cookies.get('token')||!cookies.get("useridsession")) next("/");else next();
    }
  },
  { path: '*', beforeEnter:  (to, from, next) => {
    console.log("error",to.path);
    let x = to.path;
    axios.get(`/404?domain=${store.state.domain}&token=${store.state.auth.token}&useridsession=${store.state.auth.useridsession}&from=${x}`).then(function(response) {
      // handle success
      console.log(response);
    })
    next('/404') } 
  
  },
  { path: "/404",  component:PageNotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  // scrollBehavior () {
  //   return { x: 0, y: 0 };
  // },
  base: process.env.BASE_URL,
  routes
})

export default router
