<template>
    <div class="app-modal" v-if="opened">
        <div class="app-modal__main">
            <div class="app-modal__main-content">
                <div class="app-modal__main-close">
                    <ion-icon name="close-outline" @click="$emit('closeModal')"></ion-icon>
                </div>
                <div style="margin-top:2rem;">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
export default {
    props:{
        opened:{
            type:Boolean,
            default:false
        }
    },
    watch:{
        opened(){
            if(this.opened == true) 
                $('html, body').css({
                    overflow: 'hidden',
                    height: 'auto'
                });
            else 
                $('html, body').css({
                    overflow: 'auto',
                    height: 'auto'
                });
        }
    }
}
</script>


<style lang="scss" scoped>
.app-modal {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background: rgba(8, 8, 8, 0.561);
    top: 0;
    left: 0;
    z-index: 1000000000;

    &__main {
        width: inherit;
        height: inherit;
        position: relative;

        &-close {   
            position: relative;

            ion-icon {
                position: absolute;
                right: -20px;
                display: block;  
                margin-bottom: auto;
                font-size: 3rem;
                font-weight: bold;
                cursor: pointer;
            }
        }

        &-content {
            background: white;
            width: 720px;
            height: 500px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            box-sizing: border-box;
            padding: 1.5rem 3rem; 
            max-width: 100vw;
            overflow: auto;
            z-index: 1000000000;
        }
    }
}

@media only screen and (max-width: 720px) {
    .app-modal__main-content {
        height: 100vh;
    }
}

::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	background-color: #000000;
	border: 2px solid #555555;
}

</style>