<template>
  <div id="app" >
  <vue-headful
    v-if="$store.state.main&&$route.matched[0].path!='/produse/:type'&&$route.matched[0].path!='/produs/:id'"
    :title="$store.state.main.pagetitle"
    description="Description from vue-headful"
  />
  <vue-headful
    v-else-if="$store.state.browse&&$route.matched[0].path=='/produse/:type'"
    :title="$store.state.browse.titlu"
    description="Description from vue-headful"
  />
  <vue-headful
    v-if="$store.state.product&&$route.matched[0].path=='/produs/:id'"
    :title="$store.state.product.titlu"
    description="Description from vue-headful"
  />
  <AppCookies/>
  <div v-if="$route.name!='Pay'">
    <AppSaleTop/>
    <AppInfoBar/>
    <!-- <div style="height:180px;" class="replace-nav-d" v-if="$store.state.hiddenNav==false"></div> -->
      <AppNavbar  style="z-index:99999999;" /> 
     
          <!-- <div style="height:180px;" class="replace-nav-d" v-if="$store.state.hiddenNav==false"></div> -->

    <router-view />
    <div class="wrapper" style="margin-top:5rem">
      <AppNewsLetter/>
    </div>
    <AppAnswearBar/>
    <div class="wrapper"> 
      <!-- <span >{{$store.state.main}}</span> -->
      <AppFooter/>
    </div>
  </div>
  <div v-else>
    <router-view/>
  </div>
  </div>
</template>

<script>
import AppCookies from "./components/AppCookies.vue";
import VueStickyDirective from "@renatodeleao/vue-sticky-directive";
export default {
  components:{
    AppCookies,
  },
  directives: {
    VueStickyDirective
  },
  methods:{ 
    trackUser() {
      let resolution = window.screen.width + 'x' + window.screen.height;
       this.axios.get(`/activesession?domain=${this.$store.state.domain}&cookie=${this.$cookies.get("tracker_id")}&route=${this.$route.fullPath}&token=${this.$store.state.auth.token}&useridsession=${this.$store.state.auth.useridsession}&resolution=${resolution}`).then((response)=> {
        // handle success
        this.$cookies.set("tracker_id", response.data.cookie)
        console.log("iiiiiiiiii",response)
      })
    }
    },
  beforeMount(){
    this.trackUser();
    // if(!this.$cookies.get('domain')) this.$cookies.set("dom",window.location.hostname)
    this.$cookies.set("dom",'designmall.ro')
    this.$store.dispatch('loadMain',this.$route);
    if(this.$route.name!='Homepage'){
      this.$store.dispatch('loadHomepage','off',this.$route);
    }
    console.log(this.$route)
    if(this.$route.fullPath.split('?token=')[1]) {
      this.$cookies.set('invitedFrom', this.$route.fullPath.split('?token=')[1])
    }
    if(this.$cookies.get("token")&&this.$cookies.get("useridsession")) {
      this.$store.dispatch("loadWishlist", this.$route);
      this.$store.dispatch("loadCart", this.$route);
    }
  },
  watch:{
    '$route.fullPath':function() {
      this.trackUser();
    }
  }
}
</script>



<style lang="scss">
* {
    -webkit-tap-highlight-color: transparent;
}

html{
    font-size: 10px!important;
    overflow-x: hidden;
}
body{
    overflow-x: hidden;
    padding: 0!important;
    margin: 0!important;
}

.VueCarousel {
  display: flex;
  justify-content: center!important;
}

.vue-notification {
  // styling
  margin: 0 5px 5px;
  padding: 10px;
  font-size: 12px;
  color: #ffffff;
  
  // default (blue)
  background: #44A4FC;
  border-left: 5px solid #187FE7;

  // types (green, amber, red)
  &.success {
    background: #68CD86;
    border-left-color: #42A85F;
    text-align: center;
    font-family: $medium-main;
    font-size: 1.3rem;
  }

  &.warn {
    background: #ffb648;
    border-left-color: #f48a06;
  }

  &.error {
    background: #E54D42;
    border-left-color: #B82E24;
    text-align: center;
    font-family: $medium-main;
    font-size: 1.3rem;
  }
}
.linktext {
    color:gray!important;
    text-decoration: none;
}
.app-viewer-m__close {
  z-index: 999999999!important;
}

.vue-slider-dot-handle {
  background: rgb(255, 255, 255)!important;
  border: 1px solid #ddd!important;
  background: #fff;
  box-shadow: 0 2px 15px rgb(207, 206, 206), 0 1px 3px black;
}

.vue-slider-ltr {
  height: 20px!important;
}

.vue-slider-process {
  background: black!important;
}

.vue-slider  {
  width: 95%!important;
  margin-left:-5px!important
}

.vue-slider-rail {
  height: 10%!important;
}



.vue-ads-bg-gray-200 {
  display: none!important;
}

.vue-ads-bg-teal-500 {
  font-family: $bold-main!important;;
  text-decoration: underline;
  font-size: 1.3rem!important;
  transform: translateY(-1.6px);
}

.vueperslides__bullet .default {
  background-color: rgba(7, 7, 7, 0.376);
  border: none;
  box-shadow: none;
  transition: 0.3s;
  width: 10px;
  height: 10px;
  outline: none!important;
}

.vueperslides__bullet--active .default {background-color: #ffffff;outline: none!important;padding: 0;}
.default:focus {
  outline: none!important;
}
.limited-width {
  max-width: 100%;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
}

.vueperslides__bullet{
  outline: none!important;
}

.vueperslide,.vueperslides__inner,.vueperslides__track-inner,.vueperslides__track,
.vueperslides__parallax-wrapper {
  height: 140vw!important;
  box-sizing: border-box;
}
.vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:after, .vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:before {

    box-shadow: none!important;

}

.notif-position{
  position: fixed;
  left:0;top:140px!important;width:100%;
  z-index: 10000000000;
}
  
.no-nav {
  margin-top:140px;
}
  @media only screen and (max-width: 800px) {
  .vue-slider {
    width: 90%!important;
    margin-left:18px!important
  }

  .vue-slider-dot {
    width: 24px!important;
    height: 24px!important;
  }
}

.close-notif {
  position: absolute;
  right: 15px;
  font-size: 2rem;
  cursor: pointer;
}

  @media only screen and (max-width: 850px) {
  .notif-position{
    left:0;top:77px!important;width:100%;
  }
  .replace-nav-d {
    display: none;
  }
  
  }
</style>