<template>
    <div class="app-sizesw" v-if="opened">
        <div class="app-sizesw__main">
            <div class="app-sizesw__main-top">
                <div class="app-sizesw__main-title">
                    Sorteaza
                </div>
                <ion-icon name="close-outline" @click="closeSizes"></ion-icon>
            </div>
            <div class="app-sizesw__main-templates">
                <div class="app-sizesw__main-template" v-for="(x,index) in localCategories" :key="index" @click="setActive(x.ID)">
                    <!-- <div class="app-sizesw__main-template-check">
                        <div v-if="x.active"></div>
                    </div> -->
                     <span class="filter-icon" :class="{'filter-icon--active':x.active}">&nbsp;</span>
                    <span class="app-sizesw__main-template-size">{{x.name}}</span>
                    <!-- <span class="app-sizesw__main-template-price">{{$store.state.w_opened.price}}</span> -->
                </div>
            </div>
            <button @click="sortBy">Salveaza</button>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
export default {
    data(){
        return {
            sizes:null,
            localCategories:JSON.parse(JSON.stringify(this.categories)),
            copyCat:[],
            activeID:false
        }
    },
    props:{
        opened:{
            type:Boolean,
            default:false
        },
        categories:{
            type:Array,
            default:()=>{[]}
        }
    },
    methods:{
        closeSizes(){
            this.$emit("closeSort");
            $('html, body').css({
                overflow: 'auto',
                height: 'auto'
            });
        },
        setActive(id) {
            console.log(this.categories)
            if(this.copyCat.length==0) this.copyCat = [...this.categories]
            let cp = JSON.parse(JSON.stringify(this.localCategories));
            for(let i =0;i < cp.length;i++) {
                if(cp[i].ID == id) cp[i].active = true;
                else cp[i].active = false;
            }
            this.localCategories = JSON.parse(JSON.stringify(cp));
            this.activeID = id;
        },
        sortBy(){
            let v = this.activeID;
            let r = '';
            let index = this.$route.params.type.indexOf("&sortby");
            if(index!=-1){
                let str = this.$route.params.type.split('&');
                str.forEach(item => {
                    let cpy = item.split('=');
                    if(cpy[0]=='sortby') {
                        item = "sortby=" + v; 
                    }
                    if(cpy[0]=='pagina') {
                        item = "";
                    }
                    if(item!="")
                    r = r + item + '&';
                });
                this.$store.commit("SET_CURR_PAGE", 0);
                r = r.slice(0, -1)
                this.$router.push(r);
            }
            else {
                this.$router.push(this.$route.params.type+"&sortby="+this.activeID);
            }
            this.closeSizes()
        }
    }
}
</script>

<style lang="scss" scoped>
.app-sizesw{
    z-index: 99999999999999;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.459);
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;

    &__main {
        margin: auto;
        width: 600px;
        max-width: 600px;
        background: $color-white;
        padding-bottom: 2rem;
        height: 80vh;
        max-height: 80vh;
        position: relative;
        // overflow: auto;
        
        &-top {
            -webkit-box-shadow: 0px 11px 16px -11px rgba(0,0,0,0.75);
            -moz-box-shadow: 0px 11px 16px -11px rgba(0,0,0,0.75);
            box-shadow: 0px 11px 16px -11px rgba(0,0,0,0.75);
            display: flex;
            padding: 2rem;

            ion-icon {
                display: block;
                margin-left: auto;
                margin-top: auto;
                margin-bottom: auto;
                font-size: 3rem;
                font-weight: bold;
                cursor: pointer;
            }
        }

        &-title {
            font-family: $semibold-main;
            font-size: 2rem;
            
        }

        &-templates {
            height:calc(100% - 220px);
            overflow: auto;
            box-sizing: border-box;
            padding-bottom: 100px;
        }

        &-template {
            display: flex;
            padding: 1.5rem 2rem;
            border-bottom: 1px solid rgb(141, 141, 141);
            cursor: pointer;

            span {
                display: block;
                font-size: 1.7rem;
                margin-top: auto;
                margin-bottom: auto;
            }

            &-check {
                display: block;
                width: 15px;
                height: 15px;
                border: 1px solid black;
                box-sizing: border-box;
                margin-top: auto;
                margin-bottom: auto;
                position: relative;

                div {
                    position: absolute;
                    background: black;
                    width: 9px;
                    height: 9px;
                    margin: auto;
                    top:6px;
                    left: 7px;;
                    transform:translateY(-50%) translateX(-50%);
                }
            }

            &-price {
                margin-left: auto;
            }

            &-size {
                margin-left: 1rem;
            }
        }

        button {
            
            display: block;
            width: calc(100% - 4rem);
            margin-left: auto;
            margin-right: auto;
            margin-top: 2rem;
            background: #000000;
            color: $color-white;
            padding: 1.5rem;
            font-family: $semibold-main;
            font-size: 1.5rem;
            // bottom: 10px;
            // left: 50%;
            // transform: translateX(-50%);


        }
    }
}

@media only screen and (max-width: 650px){
    .app-sizesw__main {
        max-width: 99vw;
        max-height: 99vh;
        width: 100vw;
        height: 100vh;
    }

    button {
        position: fixed;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
    }
}


.filter-icon {
    width: 9px;
    height: 9px;
    overflow: hidden;
    float: left;
    display: block;
    margin-top: 4px;
    white-space: nowrap;
    outline: 1px solid #000;
    outline-offset: 1px;

    &--active {
        background: black;
    }
}
</style>