<template>
    <div class="app-my-account">
        <div
            class="app-my-account__logged"
            v-if="$store.state.auth.success && $store.state.myacc"
        >
            <div
                class="wrapper app-my-account__page"
                :class="{
                    'grid-2':
                        $route.params.type == 'comenzile-mele' ||
                        $route.params.type == 'retururi' ||
                        $route.params.type == 'recomanda-unui-prieten' ||
                        $route.params.type == 'personalizeaza',
                }"
            >
                <div class="app-my-account__sections">
                    <div
                        :class="{
                            'active-section':
                                !$route.params.type ||
                                $route.params.type == 'activare',
                        }"
                    >
                        <ion-icon name="person-outline"></ion-icon>
                        <router-link to="/contul-meu">Contul meu</router-link>
                    </div>
                    <div
                        :class="{
                            'active-section':
                                $route.params.type == 'comenzile-mele',
                        }"
                    >
                        <ion-icon name="shirt-outline"></ion-icon>
                        <router-link to="/contul-meu/comenzile-mele"
                            >Comenzile mele</router-link
                        >
                    </div>
                    <div
                        :class="{
                            'active-section': $route.params.type == 'retururi',
                        }"
                    >
                        <ion-icon name="arrow-back-circle-outline"></ion-icon>
                        <router-link to="/contul-meu/retururi"
                            >Retururi</router-link
                        >
                    </div>
                    <div
                        :class="{
                            'active-section':
                                $route.params.type == 'recomanda-unui-prieten',
                        }"
                    >
                        <ion-icon name="person-add-outline"></ion-icon>
                        <router-link to="/contul-meu/recomanda-unui-prieten"
                            >Recomanda unui prieten</router-link
                        >
                    </div>
                    <div
                        :class="{
                            'active-section':
                                $route.params.type == 'personalizeaza',
                        }"
                    >
                        <ion-icon name="sparkles-outline"></ion-icon>
                        <router-link to="/contul-meu/personalizeaza"
                            >Personalizeaza</router-link
                        >
                    </div>
                    <div>
                        <ion-icon name="mail-outline"></ion-icon>
                        <a href="/contact">Contact</a>
                    </div>
                    <div
                        style="width:30rem;height:1px;background-color:rgb(207, 207, 207);"
                    ></div>
                    <div>
                        <ion-icon name="log-out-outline"></ion-icon>
                        <a @click="logoutUser">Deconectare</a>
                    </div>
                </div>
                <div
                    v-if="
                        !$route.params.type || $route.params.type == 'activare'
                    "
                    class="app-logged-1"
                >
                    <span class="app-logged-1__title">
                        {{ $store.state.myacc.datelemele.title }}
                    </span>
                    <span class="app-logged-1__template" style="height:23rem;">
                        <span
                            v-for="(c, index) in $store.state.myacc.datelemele
                                .content"
                            :key="index"
                            class="app-logged-1__template-txt"
                            >{{ c.text }}</span
                        >
                    </span>
                </div>
                <div v-if="!$route.params.type" class="app-logged-2">
                    <span class="app-logged-2__title">
                        Ultima metodă de livrare selectată
                    </span>
                    <span class="app-logged-1__template">
                        {{
                            $store.state.myacc.datelemele.ultimametodadelivrare
                        }}
                    </span>
                    <span class="app-logged-2__title" style="margin-top:4rem;">
                        Ultima metodă de plată selectată
                    </span>
                    <span class="app-logged-1__template">
                        {{ $store.state.myacc.datelemele.ultimametodadeplata }}
                    </span>
                </div>
                <div class="adresses-grid" v-if="!$route.params.type">
                    <div></div>
                    <div class="mobile-w">
                        <span
                            class="app-logged-1__title"
                            style="margin-top:4rem;"
                        >
                            Adresele mele de livrare
                        </span>
                        <div class="adresses-grid__cnt padd-r-0">
                            <div
                                v-for="(address, index) in $store.state.myacc
                                    .adreselemele"
                                :key="index"
                                class="app-edit-block"
                            >
                                <div
                                    class="add-edit-address"
                                    :class="{
                                        'add-edit-address--open': addressEdit,
                                    }"
                                    v-if="addressEdit == address.addressid"
                                >
                                    <div
                                        class="add-edit-address__template"
                                        v-for="(a, index) in forEdit"
                                        :key="index"
                                    >
                                        <label :for="a.label"
                                            >{{ a.text }}
                                        </label>
                                        <input
                                            type="text"
                                            :name="a.label"
                                            v-if="a.dropdown == false"
                                            v-model="a.predefinied"
                                        />
                                        <select
                                            name="xxx"
                                            v-else
                                            v-model="a.predefinied"
                                        >
                                            <option
                                                :value="i"
                                                v-for="(i,
                                                index) in a.valuesdropdown"
                                                :key="index + 1223"
                                            >
                                                {{ i }}
                                            </option>
                                        </select>
                                        <p
                                            style="color:red"
                                            v-if="errors[index]"
                                        >
                                            {{ errors[index] }}
                                        </p>
                                    </div>
                                    <div></div>

                                    <div class="add-edit-address__btns">
                                        <button
                                            class="black-color"
                                            @click="closeEdit(index)"
                                        >
                                            Anulează
                                        </button>
                                        <button @click="saveEdit(index)">
                                            Salvează
                                        </button>
                                    </div>
                                </div>
                                <div v-else>
                                    <span
                                        style="padding-bottom:1rem;font-weight:bold;"
                                        >{{ address.title }}</span
                                    >
                                    <span style="font-weight:bold;">{{
                                        address.fullname
                                    }}</span>
                                    <span class="limited-width">{{
                                        address.email
                                    }}</span>
                                    <span>{{ address.telefon }}</span>
                                    <span style="margin-top:1.5rem;"
                                        >{{ address.strada }}
                                        {{ address.nrcasa }}</span
                                    >
                                    <span>{{ address.codpostal }}</span>
                                    <span>{{ address.judet }}</span>
                                    <span>{{ address.oras }}</span>
                                    <div
                                        class="edit-btn"
                                        @click="editAdress(address.addressid)"
                                    >
                                        <ion-icon
                                            name="create-outline"
                                        ></ion-icon>
                                    </div>
                                </div>
                                <div
                                    class="delete-btn"
                                    @click="deleteAddress(address.addressid)"
                                    v-if="
                                        address.deletepossible &&
                                            addressEdit != address.addressid
                                    "
                                >
                                    <ion-icon name="trash-outline"></ion-icon>
                                </div>
                            </div>
                        </div>
                        <div class="add-edit-address" v-if="addAd">
                            <div
                                class="add-edit-address__template"
                                v-for="(input, index) in addTemplate"
                                :key="index + 1234"
                            >
                                <label for="firstname">{{ input.text }}</label>
                                <input
                                    type="text"
                                    v-if="input.dropdown == false"
                                    name="firstname"
                                    v-model="input.predefinied"
                                />

                                <select
                                    name="xxx"
                                    v-else
                                    v-model="input.predefinied"
                                >
                                    <option value="" disabled selected
                                        >Selecteaza judetul</option
                                    >
                                    <option
                                        :value="i"
                                        v-for="(i,
                                        index) in input.valuesdropdown"
                                        :key="index + 1223"
                                    >
                                        {{ i }}
                                    </option>
                                </select>
                                <!-- <p style="color:red" v-if="errors[index]">{{errors[index]}}</p> -->
                                <span class="form-error" v-if="errors[index]">{{
                                    errors[index]
                                }}</span>
                            </div>
                            <div></div>
                            <!-- <div class="add-edit-address__template">
                            <label for="secondname">Nume*</label>
                            <input type="text" name="secondname" v-model="address.second_name">
                            <span class="form-error"></span>
                        </div>
                        <div></div>
                        <div class="add-edit-address__template">
                            <label for="street">Strada si numarul*</label>
                            <input type="text" name="street" v-model="address.street">
                            <span class="form-error"></span>
                        </div>
                        <div></div>
                        <div class="add-edit-address__template">
                            <label for="housenumber">Informații adiționale la adresă (bloc, scară, etaj etc.)*</label>
                            <input type="text" name="housenumber" v-model="address.housenr">
                            <span class="form-error"></span>
                        </div>
                        <div></div> -->
                            <!-- <div class="add-edit-address__template">
                            <label for="flatnumber">Numărul apartamentului*</label>
                            <input type="text" name="flatnumber" v-model="address.apartnr">
                            <span class="form-error"></span>
                        </div>
                        <div></div> -->
                            <!-- <div class="add-edit-address__template">
                            <label for="postalcode">Codul postal (optional)</label>
                            <input type="text" name="postalcode" v-model="address.postal_code">
                            <span class="form-error"></span>
                        </div>
                        <div></div>
                        <div class="add-edit-address__template">
                            <label for="region">Județ*</label>
                            <input type="text" name="region" v-model="address.city1">
                            <span class="form-error"></span>
                        </div>
                        <div></div>
                        <div class="add-edit-address__template">
                            <label for="city">Orașul*</label>
                            <input type="text" name="city" v-model="address.city2">
                            <span class="form-error"></span>
                        </div>
                        <div></div>
                        <div class="add-edit-address__template">
                            <label for="phone">Numărul de telefon*</label>
                            <input type="text" name="phone" v-model="address.phone">
                            <span class="form-error"></span>
                        </div>
                        <div></div>
                        <div class="add-edit-address__template">
                            <label for="email">Adresa e-mail*</label>
                            <input type="text" name="email" v-model="address.email">
                            <span class="form-error"></span>
                        </div>
                        <div></div> -->
                            <div class="add-edit-address__btns">
                                <button class="black-color" @click="closeAdd">
                                    Anulează
                                </button>
                                <button @click="addAddress">Salvează</button>
                            </div>
                        </div>
                        <button
                            class="app-logged-1__address-btn black-color"
                            @click="openAddressAdd"
                            v-if="addAd == false"
                        >
                            Adaugă adresa noua de livrare
                        </button>
                        <div class="adresses-grid__cnt padd-r-0">
                            <div class="change-password">
                                <span class="change-password__title"
                                    >Schimbă parola</span
                                >
                                <div
                                    class="change-password__hidden"
                                    v-if="showChange == false"
                                >
                                    <p>**********</p>
                                    <span @click="showChange = true">
                                        <ion-icon
                                            name="create-outline"
                                        ></ion-icon>
                                    </span>
                                </div>
                                <div
                                    v-else
                                    style="margin-top:2rem;"
                                    class="padd-m-0"
                                >
                                    <div
                                        class="add-edit-address__template"
                                        style="padding-left:0;padding-right:0;"
                                    >
                                        <label for="email">Parola veche</label>
                                        <input
                                            :type="passwordType5"
                                            @input="error.old = false"
                                            class="password-input"
                                            :class="{ error_input: error.old }"
                                            name="password"
                                            v-model="changeold"
                                        />
                                        <img
                                            v-if="passwordType5 == 'password'"
                                            @click="passwordType5 = 'text'"
                                            class="pass-image"
                                            src="../assets/images/visibility.png"
                                            alt="visibility"
                                        />
                                        <img
                                            v-else
                                            @click="passwordType5 = 'password'"
                                            class="pass-image"
                                            src="../assets/images/visibility2.png"
                                            alt="visibility"
                                        />
                                        <span
                                            class="form-error"
                                            v-if="error.old"
                                            >{{ error.old }}</span
                                        >
                                    </div>
                                    <div
                                        class="add-edit-address__template"
                                        style="padding-left:0;padding-right:0;"
                                    >
                                        <label for="email">Parola nouă</label>
                                        <input
                                            :type="passwordType4"
                                            @input="error.new = false"
                                            class="password-input"
                                            name="password"
                                            :class="{ error_input: error.new }"
                                            v-model="changenew"
                                        />
                                        <img
                                            v-if="passwordType4 == 'password'"
                                            @click="passwordType4 = 'text'"
                                            class="pass-image"
                                            src="../assets/images/visibility.png"
                                            alt="visibility"
                                        />
                                        <img
                                            v-else
                                            @click="passwordType4 = 'password'"
                                            class="pass-image"
                                            src="../assets/images/visibility2.png"
                                            alt="visibility"
                                        />
                                        <span
                                            class="form-error"
                                            v-if="error.new"
                                            >{{ error.new }}</span
                                        >
                                    </div>
                                    <div
                                        class="add-edit-address__template"
                                        style="padding-left:0;padding-right:0;"
                                    >
                                        <label for="email"
                                            >Repetă noua parolă</label
                                        >
                                        <input
                                            :type="passwordType3"
                                            @input="error.repeat = false"
                                            class="password-input"
                                            :class="{
                                                error_input: error.repeat,
                                            }"
                                            name="password"
                                            v-model="changerepeat"
                                        />
                                        <img
                                            v-if="passwordType3 == 'password'"
                                            @click="passwordType3 = 'text'"
                                            class="pass-image"
                                            src="../assets/images/visibility.png"
                                            alt="visibility"
                                        />
                                        <img
                                            v-else
                                            @click="passwordType3 = 'password'"
                                            class="pass-image"
                                            src="../assets/images/visibility2.png"
                                            alt="visibility"
                                        />
                                        <span
                                            class="form-error"
                                            v-if="error.repeat"
                                            >{{ error.repeat }}</span
                                        >
                                    </div>
                                    <div
                                        class="add-edit-address__btns change-pass-btns"
                                    >
                                        <button
                                            class="black-color"
                                            @click="closeChange"
                                        >
                                            Anulează
                                        </button>
                                        <button @click="changePassword">
                                            Salvează
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <input type="text" placeholder="parola veche" v-model="changeold">
                    <input type="text" placeholder="parola noua" v-model="changenew">
                    <input type="text" placeholder="repeta parola" v-model="changerepeat">
                    <button @click="changePassword">Schimba</button> -->
                    </div>
                </div>
                <div
                    v-if="
                        $route.params.type == 'personalizeaza' &&
                            $store.state.personalizeaza
                    "
                    class="app-my-account__comenzi"
                >
                    <h3>Personalizeaza</h3>
                    <div
                        style="display:flex;border-bottom:1px solid rgb(133, 133, 133);padding-bottom:1.5rem;margin-top:1.5rem;"
                        v-for="(p, index) in $store.state.personalizeaza
                            .personalizeaza"
                        :key="index"
                    >
                        <div class="personalizeaza-txt">
                            <p style="font-weight:bold;">{{ p.title }}</p>
                            <p>{{ p.text }}</p>
                        </div>
                        <div class="check__container">
                            <input
                                type="checkbox"
                                @click="
                                    sendPersonalizeaza(p.parameter, p.value)
                                "
                                v-model="p.value"
                                class="switch"
                            />
                        </div>
                        <!-- <div @click="sendPersonalizeaza(index)" >{{p.value}}</div> -->
                        <!-- <div @click="sendPersonalizeaza(p.parameter, p.value),p.value=0" v-else>{{p.value}}</div> -->
                    </div>
                </div>
                <div
                    v-if="$route.params.type == 'comenzile-mele'"
                    class="app-my-account__comenzi"
                >
                    <h3 v-if="$route.params.orderid && $store.state.history">
                        {{ $store.state.history.comanda }}
                    </h3>
                    <h3 v-else>Istoric comenzi</h3>
                    <!-- {{$store.state.myacc.comenzilemele}} -->
                    <span
                        v-if="$store.state.myacc.comenzilemele.noorders == true"
                        >Nicio comanda</span
                    >

                    <div
                        class="history-purchases"
                        v-if="
                            !$store.state.myacc.comenzilemele.noorders &&
                                !$route.params.orderid
                        "
                    >
                        <AppHistoryPurchase
                            v-for="order in $store.state.myacc.comenzilemele
                                .orders"
                            :order="order"
                            :key="order.idcomanda"
                            class="history-purchases__el"
                        ></AppHistoryPurchase>
                    </div>
                    <div class="purchase_cnt" v-else>
                        <AppPurchase
                            v-if="!$store.state.myacc.comenzilemele.noorders"
                        ></AppPurchase>
                    </div>
                </div>
                <div
                    v-if="$route.params.type == 'retururi'"
                    class="app-my-account__retururi"
                >
                    <div class="app-my-account__retururi-types">
                        <span
                            :class="{ 'retur-n-act': retur == 2 }"
                            @click="retur = 1"
                            >Creează retur</span
                        >
                        <span
                            :class="{ 'retur-n-act': retur == 1 }"
                            @click="retur = 2"
                            >Istoric retur</span
                        >
                    </div>
                    <div class="app-my-account__retururi-prod">
                        <span
                            v-if="
                                retur == 1 &&
                                    $store.state.retururi.cereriretur == null
                            "
                            >Momentan nu ai comenzi de retur inregistrate.</span
                        >
                        <span
                            v-if="
                                retur == 2 &&
                                    $store.state.retururi.istoricretururi ==
                                        null
                            "
                            >Niciun retur</span
                        >
                        <div
                            class="app-retur__history-boxes"
                            v-if="
                                retur == 2 &&
                                    $store.state.retururi.istoricretururi
                            "
                        >
                            <AppReturBox
                                :retur="ir"
                                v-for="ir in $store.state.retururi
                                    .istoricretururi"
                                :key="ir.returnumber"
                                style="margin-top:2rem;"
                            ></AppReturBox>
                        </div>
                        <div
                            class="app-retur__history-adding"
                            style="width:99%;"
                            v-if="
                                retur == 1 &&
                                    $store.state.retururi.cereriretur != null
                            "
                        >
                            <!-- {{$store.state.retururi.cereriretur}} -->
                            <AppCartProd
                                style="margin-top:2rem;"
                                v-for="(product, index) in $store.state.retururi
                                    .cereriretur"
                                :key="index + 657"
                                :product="product"
                                :retur="true"
                                :simple="true"
                                @addRetur="addRetur"
                            >
                            </AppCartProd>
                            <button
                                class="app-history-retur-btn"
                                v-if="forReturn.length > 0"
                                @click="openReturT"
                            >
                                Continua
                            </button>
                            <AppModal
                                :opened="returSend.opened"
                                @closeModal="closeReturn"
                            >
                                <div class="app-modal-retur">
                                    <span class="app-modal-retur__title"
                                        >Cerere retur - Sumar</span
                                    >
                                    <div style="margin-top:1rem;">
                                        <div
                                            class="add-edit-address__template"
                                            v-for="(input,
                                            index) in returTemplate"
                                            :key="index + 1234"
                                        >
                                            <label for="firstname">{{
                                                input.text
                                            }}</label>
                                            <input
                                                type="text"
                                                v-if="input.dropdown == false"
                                                name="firstname"
                                                v-model="input.predefinied"
                                            />
                                            <select
                                                name="xxx"
                                                v-else
                                                v-model="input.predefinied"
                                            >
                                                <option
                                                    :value="i"
                                                    v-for="(i,
                                                    index) in input.valuesdropdown"
                                                    :key="index + 1223"
                                                >
                                                    {{ i }}
                                                </option>
                                            </select>
                                            <!-- <p style="color:red" v-if="errors[index]">{{errors[index]}}</p> -->
                                            <span
                                                class="form-error"
                                                style="bottom:13px;font-size:1rem;"
                                                v-if="errorsRetur[index]"
                                                >{{ errorsRetur[index] }}</span
                                            >
                                        </div>
                                    </div>
                                    <!-- {{$store.state.retururi.returformtemplate}} -->
                                    <!-- <div class="app-modal-retur__top-inputs">
                                        
                                        <div class="add-edit-address__template app-modal-retur__input" >
                                            <label for="firstname">Nume</label>
                                            <input type="text" name="firstname" v-model="returSend.first_name" placeholder="Pietricica">
                                            <span class="form-error" style="font-size:1rem;" v-if="returSend.errors.first_name">{{returSend.errors.first_name}}</span>
                                        </div>
                                        <div class="add-edit-address__template app-modal-retur__input" >
                                            <label for="secondname">Prenume</label>
                                            <input type="text" name="secondname" v-model="returSend.second_name" placeholder="Ionel">
                                            <span class="form-error" style="font-size:1rem;" v-if="returSend.errors.second_name">{{returSend.errors.second_name}}</span>
                                        </div>
                                    </div>
                                    <div class="add-edit-address__template app-modal-retur__input" style="width:100%;">
                                        <label for="iban">IBAN</label>
                                        <input type="text" name="firstname" v-model="returSend.iban" placeholder="xxxxxxxxxxxxxxx">
                                        <span class="form-error" style="font-size:1rem;" v-if="returSend.errors.iban">{{returSend.errors.iban}}</span>
                                    </div> -->
                                    <!-- <span>Sumar</span>
                                    <div class="app-history-retur__summary">

                                    </div> -->
                                    <div class="rado-btns">
                                        <div
                                            class="radio-btns__template"
                                            @click="returRadio1 = !returRadio1"
                                        >
                                            <span
                                                class="form__radio-button"
                                                v-if="!returRadio1"
                                            ></span>
                                            <span
                                                class="form__radio-button-active"
                                                v-else
                                            ></span>
                                            <span
                                                class="radio-btns__template-text"
                                                :class="{ errReturR: errorR1 }"
                                                >Produsul se afla in aceeasi
                                                stare in care a fost
                                                livrat</span
                                            >
                                        </div>
                                        <div
                                            class="radio-btns__template"
                                            @click="returRadio2 = !returRadio2"
                                        >
                                            <span
                                                class="form__radio-button"
                                                v-if="!returRadio2"
                                            ></span>
                                            <span
                                                class="form__radio-button-active"
                                                v-else
                                            ></span>
                                            <span
                                                class="radio-btns__template-text"
                                                :class="{ errReturR: errorR2 }"
                                                >Produsul a fost probat, dar
                                                nepurtat, fara pete, fara urme
                                                de uzura sau rupturi</span
                                            >
                                        </div>
                                        <div
                                            class="radio-btns__template"
                                            @click="returRadio3 = !returRadio3"
                                        >
                                            <span
                                                class="form__radio-button"
                                                v-if="!returRadio3"
                                            ></span>
                                            <span
                                                class="form__radio-button-active"
                                                v-else
                                            ></span>
                                            <span
                                                class="radio-btns__template-text"
                                                :class="{ errReturR: errorR3 }"
                                                >Produsul are accesoriile sau
                                                cutia cu eticheta inca
                                                atasata</span
                                            >
                                        </div>
                                    </div>
                                    <button
                                        class="app-history-retur-btn"
                                        @click="sendRetur"
                                    >
                                        Trimite cerere
                                    </button>
                                </div>
                            </AppModal>
                            <!-- <input type="text" style="display:block;margin-top:2rem;" v-model="returSend.fullname" placeholder="Nume Prenume">
                            {{returSend.errors.fullname}}
                            <input type="text" style="display:block;margin-top:2rem;"  v-model="returSend.iban" placeholder="IBAN">
                            {{returSend.errors.iban}}
                            <button style="margin-top:2rem;" @click="sendRetur">Trimite cerere</button> -->
                        </div>
                        <!-- <div class="app-retur__history-products" v-if="retur==1">
                        <div class="app-retur__history-products__template" v-for="n in 7" :key="n">
                            <span>Data retur:&nbsp;</span>
                            <span>18-08-2021</span>
                        </div>
                        <div class="app-retur__history-products-border"></div>
                        <span class="app-retur__history-products-subtitle">Produse returnate</span>
                    </div> -->
                    </div>
                </div>
                <div
                    v-if="$route.params.type == 'recomanda-unui-prieten'"
                    class="app-my-account__friend"
                >
                    <h4 v-if="!$store.state.auth.success">Ai cont?</h4>
                    <h5>{{ $store.state.myacc.recomandaunuiprieten.title }}</h5>
                    <span class="app-my-account__friend-description">{{
                        $store.state.myacc.recomandaunuiprieten.subtitle
                    }}</span>
                    <div class="app-my-account__friend-copy">
                        <!-- <div class="app-my-account__friend-copy-icon">
                        <ion-icon name="link-outline"></ion-icon>
                    </div> -->
                        <div class="app-my-account__friend-copy-link">
                            <span
                                >Tot ce trebuie să faci e să-i trimiți linkul de
                                mai jos</span
                            >
                            <div class="app-my-account__friend-copy-link-cnt">
                                <span
                                    class="app-my-account__friend-copy-link-cnt-txt"
                                >
                                    <span>{{
                                        $store.state.myacc.recomandaunuiprieten
                                            .invitelink
                                    }}</span>
                                    <span>...</span>
                                </span>
                                <button
                                    @click="
                                        copyLink(
                                            $store.state.myacc
                                                .recomandaunuiprieten.invitelink
                                        )
                                    "
                                >
                                    {{ textBtn }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div
                        class="app-my-account__friend-copy-how-works"
                        style="margin-top:0;"
                    >
                        <div class="app-my-account__friend-copy-how-works-left">
                            <span>Cum funcționează?</span>
                            <div><ion-icon name="link-outline"></ion-icon></div>
                            <span
                                class="app-my-account__friend-copy-how-works-left-r-text"
                                >Copiază linkul și trimite-i-l amicului
                                tău</span
                            >
                        </div>
                        <div
                            class="app-my-account__friend-copy-how-works-right"
                        >
                            <span>Ce obții?</span>
                            <span>{{
                                $store.state.myacc.recomandaunuiprieten.getting
                                    .text_1
                            }}</span>
                            <div>
                                <div>
                                    <svg
                                        class="SVGInline-svg"
                                        width="40px"
                                        height="40px"
                                        viewBox="0 0 40 40"
                                        version="1.1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                    >
                                        <g
                                            id="Design"
                                            stroke="none"
                                            stroke-width="1"
                                            fill="none"
                                            fill-rule="evenodd"
                                        >
                                            <g
                                                id="Moje-konto-polec"
                                                transform="translate(-981.000000, -696.000000)"
                                            >
                                                <rect
                                                    fill="#FFFFFF"
                                                    x="0"
                                                    y="0"
                                                    width="1657"
                                                    height="1409"
                                                ></rect>
                                                <rect
                                                    id="White"
                                                    fill="#FFFFFF"
                                                    x="543"
                                                    y="177"
                                                    width="877"
                                                    height="979"
                                                ></rect>
                                                <circle
                                                    id="Oval-Copy"
                                                    fill="#F5F5F5"
                                                    cx="1001"
                                                    cy="716"
                                                    r="28"
                                                ></circle>
                                                <g
                                                    id="Icon/Share-Copy"
                                                    transform="translate(981.000000, 696.000000)"
                                                    fill-rule="nonzero"
                                                >
                                                    <g
                                                        id="save-money-(1)"
                                                        transform="translate(5.026855, 1.666667)"
                                                    >
                                                        <path
                                                            d="M29.6961328,13.8169596 C28.3063086,12.6588086 26.398138,12.5619141 24.9344076,13.4535156 C25.0544336,12.7911523 25.1173828,12.1092383 25.1173828,11.4128581 C25.1173828,5.11979818 19.9975846,0 13.7044531,0 C7.41139323,0 2.29166667,5.11979818 2.29166667,11.4128581 C2.29166667,14.3044401 3.3731901,16.9477344 5.15216797,18.9610352 C2.15574219,20.0075391 0,22.8620964 0,26.2110091 L0,36.6666667 L13.3595573,36.6666667 L13.3595573,29.8163021 L21.9304622,29.8163021 L30.2552279,19.5704753 C31.6677539,17.8319596 31.4169596,15.2510417 29.6961328,13.8169596 Z M11.2111198,27.6678646 L11.2111198,29.9513672 L2.1484375,29.9513672 L2.1484375,26.2110807 C2.1484375,23.1625911 4.62852214,20.6825065 7.67701172,20.6825065 L18.3746549,20.6825065 C19.0413867,20.6825065 19.5838672,21.224987 19.5838672,21.8917904 C19.5838672,22.5585938 19.0413867,23.1010026 18.3746549,23.1010026 L13.8076497,23.1010026 L13.8076497,25.2494401 L18.3745833,25.2494401 C20.2260352,25.2494401 21.7322331,23.7431706 21.7322331,21.8917904 C21.7322331,21.3590495 21.6069076,20.8550977 21.3851172,20.4072201 L25.5946224,15.6716341 C26.3035352,14.8740625 27.5010742,14.7844727 28.3207031,15.4676042 C29.1426953,16.152526 29.2625065,17.3853711 28.5877539,18.2157422 L20.907806,27.6679362 L11.2111198,27.6679362 L11.2111198,27.6678646 Z M11.2111198,34.5182292 L2.1484375,34.5182292 L2.1484375,32.0997331 L11.2111198,32.0997331 L11.2111198,34.5182292 Z M13.7044531,2.1484375 C18.8128646,2.1484375 22.9688737,6.30444661 22.9688737,11.4128581 C22.9688737,14.2724284 21.6663477,16.8332227 19.6237565,18.5339974 L7.78522135,18.5339974 C5.74263021,16.8332227 4.44010417,14.2724284 4.44010417,11.4128581 C4.44010417,6.30444661 8.59604167,2.1484375 13.7044531,2.1484375 Z"
                                                            id="Shape"
                                                            fill="#000000"
                                                        ></path>
                                                        <path
                                                            d="M10.1614648,11.8356706 C11.6552018,11.8356706 12.7825586,10.8491797 12.7825586,9.08767578 C12.7825586,7.34027995 11.6552018,6.3397526 10.1614648,6.3397526 C8.66772786,6.3397526 7.54029948,7.34027995 7.54029948,9.08767578 C7.54037109,10.8491797 8.66772786,11.8356706 10.1614648,11.8356706 Z M10.1614648,7.86163411 C10.4714844,7.86163411 10.7815039,8.14350911 10.7815039,9.08760417 C10.7815039,10.0318424 10.4714844,10.3136458 10.1614648,10.3136458 C9.85144531,10.3136458 9.54142578,10.0317708 9.54142578,9.08760417 C9.54142578,8.14350911 9.85144531,7.86163411 10.1614648,7.86163411 Z"
                                                            id="Shape"
                                                            fill="#FFCD00"
                                                        ></path>
                                                        <path
                                                            d="M14.7696484,13.7380404 C14.7696484,15.4996159 15.8970052,16.4860352 17.3907422,16.4860352 C18.8844792,16.4860352 20.0119076,15.4996159 20.0119076,13.7380404 C20.0119076,11.9906445 18.8845508,10.9901172 17.3907422,10.9901172 C15.8969336,10.9901172 14.7696484,11.9906445 14.7696484,13.7380404 Z M18.0107812,13.7380404 C18.0107812,14.6822786 17.7007617,14.964082 17.3907422,14.964082 C17.0807227,14.964082 16.7707031,14.682207 16.7707031,13.7380404 C16.7707031,12.7938737 17.0807227,12.5120703 17.3907422,12.5120703 C17.7007617,12.5120703 18.0107812,12.7938737 18.0107812,13.7380404 Z"
                                                            id="Shape"
                                                            fill="#FFCD00"
                                                        ></path>
                                                        <polygon
                                                            id="Path"
                                                            fill="#FFCD00"
                                                            points="11.5989128 16.3450977 18.3349089 6.48061849 15.9533659 6.48061849 9.21729818 16.3450977"
                                                        ></polygon>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <span>{{
                                    $store.state.myacc.recomandaunuiprieten
                                        .getting.text_2
                                }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="app-my-account__friends">
                        <span
                            class="app-my-account__friends--1"
                            v-html="
                                $store.state.myacc.recomandaunuiprieten.info_1
                            "
                        >
                            Lorem ipsum dolor sit amet consectetur, adipisicing
                            elit. Id, qui recusandae. Odio delectus itaque ipsa
                            quas est ipsam, deleniti animi numquam dolor qui
                            placeat, molestiae blanditiis, nulla sunt ducimus
                            impedit? Lorem ipsum dolor sit amet consectetur,
                            adipisicing elit. Id, qui recusandae. Odio delectus
                            itaque ipsa quas est ipsam, deleniti animi numquam
                            dolor qui placeat, molestiae blanditiis, nulla sunt
                            ducimus impedit?
                        </span>
                        <span
                            class="app-my-account__friends--2"
                            v-html="
                                $store.state.myacc.recomandaunuiprieten.info_2
                            "
                        ></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="app-my-account__unlogged" v-else>
            <div
                v-if="
                    $route.params.type == 'login' ||
                        $route.params.type == 'login-checkout'
                "
            >
                <div class="wrapper">
                    <div class="app-my-account__lg">
                        <div class="app-my-account__sections">
                            <div class="active-section">
                                <ion-icon name="person-outline"></ion-icon>
                                <a href="/contul-meu"
                                    >Conectare / Înregistrare</a
                                >
                            </div>
                            <div>
                                <ion-icon name="mail-outline"></ion-icon>
                                <a href="/contact">Contact</a>
                            </div>
                        </div>
                        <div class="app-my-account__choose">
                            <span
                                @click="changeType(1)"
                                :class="{
                                    'app-my-account__choose-active-el':
                                        typeMobile == 1,
                                }"
                                >Am cont</span
                            >
                            <span
                                @click="changeType(2)"
                                :class="{
                                    'app-my-account__choose-active-el':
                                        typeMobile == 2,
                                }"
                                >Nu am cont</span
                            >
                        </div>
                        <div
                            v-if="centered == 'login'"
                            class="app-my-account__login-form"
                            :class="{ 'unshow-el': typeMobile == 2 }"
                        >
                            <h6 class="app-my-account__login-form-title">
                                Am deja cont
                            </h6>
                            <form class="app-my-account__login-form-cnt">
                                <div>
                                    <label for="email">Adresa e-mail*</label>
                                    <input
                                        type="text"
                                        @input="loginErr.login = true"
                                        v-model="login"
                                        name="email"
                                        :class="{
                                            'inp-err': loginErr.login != true,
                                        }"
                                    />
                                    <p
                                        class="form-error"
                                        v-if="loginErr.login != true"
                                    >
                                        {{ loginErr.login }}
                                    </p>
                                </div>
                                <div>
                                    <label for="password">Parola*</label>
                                    <input
                                        :type="passwordType1"
                                        @input="loginErr.pass = true"
                                        class="password-input"
                                        name="password"
                                        v-model="password"
                                        :class="{
                                            'inp-err': loginErr.pass != true,
                                        }"
                                    />
                                    <img
                                        v-if="passwordType1 == 'password'"
                                        @click="passwordType1 = 'text'"
                                        class="pass-image"
                                        src="../assets/images/visibility.png"
                                        alt="visibility"
                                    />
                                    <img
                                        v-else
                                        @click="passwordType1 = 'password'"
                                        class="pass-image"
                                        src="../assets/images/visibility2.png"
                                        alt="visibility"
                                    />
                                    <p
                                        class="form-error"
                                        v-if="loginErr.pass != true"
                                    >
                                        {{ loginErr.pass }}
                                    </p>
                                </div>
                                <span @click="centered = 'forgot'"
                                    >Ți-ai uitat parola?</span
                                >
                                <button type="button" @click="loginUser">
                                    Conectează-te
                                </button>
                            </form>
                        </div>
                        <div
                            v-else
                            class="app-my-account__login-form"
                            :class="{ 'unshow-el': typeMobile == 2 }"
                        >
                            <h6 class="app-my-account__login-form-title">
                                Ți-ai uitat parola?
                            </h6>
                            <form class="app-my-account__login-form-cnt">
                                <div>
                                    <p class="forgot-txt">
                                        Introdu adresa de e-mail furnizata cand
                                        te-ai inregistrat si noi iti vom trimite
                                        un link de resetare a parolei. Daca nu
                                        primesti acest email, te rugam sa
                                        verifici si folderul Spam sau sa
                                        contactezi departamentul relatii
                                        clienti.
                                    </p>
                                    <label for="email"
                                        >Introdu adresa de e-mail*</label
                                    >
                                    <input
                                        type="text"
                                        @input="forgotEmailErr = true"
                                        v-model="forgotEmail"
                                        name="email"
                                        :class="{
                                            'inp-err': forgotEmailErr != true,
                                        }"
                                    />
                                    <p
                                        class="form-error"
                                        v-if="forgotEmailErr != true"
                                    >
                                        {{ forgotEmailErr }}
                                    </p>
                                </div>
                                <button type="button" @click="sendForgot">
                                    Trimite
                                </button>
                            </form>
                        </div>
                        <div
                            class="app-my-account__register-form"
                            :class="{ 'unshow-el': typeMobile == 1 }"
                        >
                            <h5 class="app-my-account__login-form-title">
                                Nu am cont
                            </h5>
                            <div class="app-my-account__login-form-cnt">
                                <div v-if="register == false">
                                    <button
                                        type="button"
                                        @click="register = true"
                                    >
                                        Creează Cont
                                    </button>
                                    <div
                                        class="app-my-account__login-form-description"
                                    >
                                        <span
                                            >De ce merită să-ți faci cont?</span
                                        >
                                        <ul v-if="$store.state.myacc">
                                            <li
                                                v-for="(t, index) in $store
                                                    .state.myacc.stringtexts"
                                                :key="index + 99"
                                            >
                                                {{ t }}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div v-else>
                                    <form
                                        class="app-my-account__login-form-cnt"
                                        style="margin-top:-1px;"
                                    >
                                        <div
                                            style="display:flex;flex-direction:row;margin-bottom:-20px;"
                                        >
                                            <div
                                                style="display:flex;flex-direction:row;"
                                                @click="registerForm.gender = 0"
                                            >
                                                <div class="bullet">
                                                    <div
                                                        v-if="
                                                            registerForm.gender ===
                                                                0
                                                        "
                                                    ></div>
                                                </div>
                                                <span class="bullet__text"
                                                    >Femeie</span
                                                >
                                            </div>
                                            <div
                                                style="display:flex;flex-direction:row;margin-left:5rem;"
                                                @click="registerForm.gender = 1"
                                            >
                                                <div class="bullet">
                                                    <div
                                                        v-if="
                                                            registerForm.gender ===
                                                                1
                                                        "
                                                    ></div>
                                                </div>
                                                <span class="bullet__text"
                                                    >Barbat</span
                                                >
                                            </div>
                                            <p
                                                class="form-error"
                                                style="display:block;margin-bottom:20px;"
                                                v-if="registerErr.gender"
                                            >
                                                {{ registerErr.gender }}
                                            </p>
                                        </div>
                                        <!-- <p class="form-error" v-if="registerErr.gender">{{registerErr.gender}}</p>    -->
                                        <div>
                                            <label for="secondname"
                                                >Prenume*</label
                                            >
                                            <input
                                                type="text"
                                                @input="
                                                    registerErr.first_name = false
                                                "
                                                v-model="
                                                    registerForm.first_name
                                                "
                                                name="secondname"
                                                :class="{
                                                    'inp-err':
                                                        registerErr.first_name,
                                                }"
                                            />
                                            <p
                                                class="form-error"
                                                v-if="registerErr.first_name"
                                            >
                                                {{ registerErr.first_name }}
                                            </p>
                                        </div>
                                        <div>
                                            <label for="firstname">Nume*</label>
                                            <input
                                                type="text"
                                                @input="
                                                    registerErr.second_name = false
                                                "
                                                v-model="
                                                    registerForm.second_name
                                                "
                                                name="firstname"
                                                :class="{
                                                    'inp-err':
                                                        registerErr.second_name,
                                                }"
                                            />
                                            <p
                                                class="form-error"
                                                v-if="registerErr.second_name"
                                            >
                                                {{ registerErr.second_name }}
                                            </p>
                                        </div>
                                        <div>
                                            <label for="email"
                                                >Adresa e-mail*</label
                                            >
                                            <input
                                                type="text"
                                                @input="
                                                    registerErr.email = false
                                                "
                                                v-model="registerForm.email"
                                                name="email"
                                                :class="{
                                                    'inp-err':
                                                        registerErr.email,
                                                }"
                                            />
                                            <p
                                                class="form-error"
                                                v-if="registerErr.email"
                                            >
                                                {{ registerErr.email }}
                                            </p>
                                        </div>
                                        <div>
                                            <label for="phone"
                                                >Număr mobil*</label
                                            >
                                            <input
                                                type="number"
                                                @input="
                                                    registerErr.phone = false
                                                "
                                                v-model="registerForm.phone"
                                                name="phone"
                                                :class="{
                                                    'inp-err':
                                                        registerErr.phone,
                                                }"
                                            />
                                            <p
                                                class="form-error"
                                                v-if="registerErr.phone"
                                            >
                                                {{ registerErr.phone }}
                                            </p>
                                        </div>
                                        <div>
                                            <label for="password"
                                                >Parola*</label
                                            >
                                            <input
                                                :type="passwordType2"
                                                @input="
                                                    registerErr.password = false
                                                "
                                                class="password-input"
                                                name="password"
                                                v-model="registerForm.password"
                                                :class="{
                                                    'inp-err':
                                                        registerErr.password,
                                                }"
                                            />
                                            <img
                                                v-if="
                                                    passwordType2 == 'password'
                                                "
                                                @click="passwordType2 = 'text'"
                                                class="pass-image"
                                                src="../assets/images/visibility.png"
                                                alt="visibility"
                                            />
                                            <img
                                                v-else
                                                @click="
                                                    passwordType2 = 'password'
                                                "
                                                class="pass-image"
                                                src="../assets/images/visibility2.png"
                                                alt="visibility"
                                            />
                                            <p
                                                class="form-error"
                                                v-if="registerErr.password"
                                            >
                                                {{ registerErr.password }}
                                            </p>
                                        </div>
                                        <div
                                            class="terms-conditions"
                                            @click="squares.t1 = !squares.t1"
                                        >
                                            <div class="square">
                                                <div v-if="squares.t1"></div>
                                            </div>
                                            <span
                                                :class="{
                                                    'err-terms':
                                                        validTerms != true,
                                                }"
                                                >Am citit și accept Termenii și
                                                Condițiile.</span
                                            >
                                        </div>
                                        <div
                                            class="terms-conditions"
                                            @click="squares.t2 = !squares.t2"
                                        >
                                            <div class="square">
                                                <div v-if="squares.t2"></div>
                                            </div>
                                            <span
                                                >Vreau să primesc informații
                                                comerciale despre oferta la
                                                adresa de e-mail
                                                furnizată.</span
                                            >
                                        </div>
                                        <div
                                            class="terms-conditions"
                                            @click="squares.t3 = !squares.t3"
                                        >
                                            <div class="square">
                                                <div v-if="squares.t3"></div>
                                            </div>
                                            <span
                                                >Vreau să primesc oferte pe
                                                numărul de telefon dat mai
                                                sus.</span
                                            >
                                        </div>
                                        <span
                                            class="register-txt"
                                            v-html="
                                                $store.state.main.registertext
                                            "
                                        ></span>
                                        <!-- <div>
                                            <label for="pass">Te rugăm să repeți parola*</label>
                                            <input type="text" name="pass" v-model="registerForm.repeatpassword" :class="{'inp-err':loginErr.pass}">
                                            <p class="form-error" v-if="loginErr.pass">{{loginErr.pass}}</p>
                                        </div> -->
                                        <!-- <span>Ți-ai uitat parola?</span> -->
                                        <p
                                            class="all-error"
                                            v-if="registerErr.all != false"
                                        >
                                            Te rugam sa verifici toate campurile
                                        </p>
                                        <button
                                            type="button"
                                            @click="registerUser"
                                        >
                                            Creează Cont
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <h2>Login</h2> -->
            <!-- <input type="text" placeholder="Login" v-model="login">
                <input type="text" placeholder="Password" v-model="password">
                <button @click="loginUser">Login</button> -->
        </div>
        <!-- <span @click="goRegister" v-if="$route.params.type=='login'" style="font-size:2rem;cursor:pointer;">Nu ai cont?</span> -->
        <!-- <div v-if="$route.params.type=='register'">
                <h2>Register</h2>
                <input type="text" placeholder="Login" v-model="login">
                <input type="text" placeholder="Password" v-model="password">
                <button @click="registerUser">Login</button>
            </div> -->
        <notifications
            position="top center"
            width="100%"
            class="notification-custom"
        />
    </div>
</template>

<script>
import AppHistoryPurchase from "../components/AppHistoryPurchase.vue";
import AppPurchase from "../components/AppPurchase.vue";
import AppReturBox from "../components/AppReturBox.vue";

import $ from "jquery";
export default {
    components: {
        AppHistoryPurchase,
        AppPurchase,
        AppReturBox,
    },
    data() {
        return {
            showChange: false,
            addTemplate: [],
            login: "",
            forgotEmail: "",
            centered: "login",
            password: "",
            loginErr: {
                login: true,
                pass: true,
            },
            register: false,
            registerForm: {
                first_name: "",
                second_name: "",
                email: "",
                phone: "",
                password: "",
                repeatpassword: "",
                gender: "empty",
            },
            registerErr: {
                first_name: "",
                second_name: "",
                email: "",
                phone: "",
                password: "",
                repeatpassword: "",
                gender: "",
                all: false,
            },
            squares: {
                t1: true,
                t2: true,
                t3: true,
            },
            address: {
                first_name: "",
                second_name: "",
                street: "",
                housenr: "",
                apartnr: "",
                postal_code: "",
                city1: "",
                city2: "",
                phone: "",
                email: "",
            },
            forgotEmailErr: true,
            retur: 1,
            textBtn: "Copiază linkul",
            addAd: false,
            typeMobile: 1,
            addressEdit: null,
            forEdit: [],
            validTerms: true,
            changenew: "",
            changerepeat: "",
            changeold: "",
            error: {
                old: false,
                new: false,
                repeat: false,
            },
            errors: [],
            forReturn: [],
            returSend: {
                opened: false,
                first_name: "",
                second_name: "",
                iban: "",
                errors: {
                    first_name: false,
                    second_name: false,
                    iban: false,
                },
            },
            returTemplate: null,
            errorsRetur: [],
            returRadio1: false,
            returRadio2: false,
            returRadio3: false,
            errorR1: false,
            errorR2: false,
            errorR3: false,
            passwordType1: "password",
            passwordType2: "password",
            passwordType3: "password",
            passwordType4: "password",
            passwordType5: "password",
            passwordType6: "password",
            passwordType7: "password",
        };
    },
    methods: {
        async loginUser() {
            try {
                const products = this.$cookies.get("cart");
                let response = await this.axios.get(
                    `/login?domain=${this.$store.state.domain}&login=${
                        this.login
                    }&password=${this.password}&products=${JSON.stringify(
                        products
                    )}`
                );
                response = response.data;
                if (response.login.loginsuccess == true) {
                    // this.$cookies.set('gender', response.login.genderlink);
                    this.$cookies.set("token", response.login.token);
                    this.$cookies.set(
                        "useridsession",
                        response.login.useridsession
                    );
                    if (this.$route.params.type == "login-checkout")
                        this.$router.push("/cart");
                    else window.location = "/contul-meu";
                }
                console.log(response);
                this.loginErr.login = response.login.validemail;
                this.loginErr.pass = response.login.validpassword;
                console.log(this.loginErr);
            } catch (e) {
                console.log(e);
            }
        },
        testare(b) {
            console.log(b);
        },
        openReturT() {
            this.returSend.opened = true;
            this.returTemplate = this.$store.state.retururi.returformtemplate;
        },
        closeReturn() {
            this.returSend.opened = false;
        },
        addRetur(t, id, quantity, reason, product_size, comanda_product) {
            let template = {
                productid: id,
                quantity,
                reason,
                product_size,
                comanda_product,
            };
            if (t == "add") this.forReturn.push(template);
            else {
                this.forReturn.splice(
                    this.forReturn.findIndex((v) => v.id === id),
                    1
                );
            }
            console.log(template);
        },
        async sendRetur() {
            if (!this.returRadio3) this.errorR3 = true;
            else this.errorR3 = false;
            if (!this.returRadio2) this.errorR2 = true;
            else this.errorR2 = false;
            if (!this.returRadio1) this.errorR1 = true;
            else this.errorR1 = false;
            // console.log(this.returSend)
            // if(this.returSend.first_name.length < 3) this.returSend.errors.first_name = "Va rugam sa introduceti un nume valid";else this.returSend.errors.first_name = false
            // if(this.returSend.second_name.length < 3) this.returSend.errors.second_name = "Va rugam sa introduceti un prenume valid";else this.returSend.errors.second_name = false
            // if(this.returSend.iban.length < 5) this.returSend.errors.iban = "Va rugam sa introduceti un IBAN valid";else this.returSend.errors.iban = false;
            // if(this.returSend.errors.second_name == false&&this.returSend.errors.first_name == false&&this.returSend.errors.iban==false) {
            let data = "";
            let validate = "";
            const address = this.returTemplate;
            for (let i = 0; i < address.length; i++) {
                validate =
                    validate +
                    "&" +
                    address[i].validatelabel +
                    "=" +
                    address[i].predefinied;
                data =
                    data +
                    "&" +
                    address[i].paramtere +
                    "=" +
                    address[i].predefinied;
            }
            const validare = await this.axios.get(
                `/validatefieldsretur?domain=${this.$store.state.domain}&useridsession=${this.$store.state.auth.useridsession}&token=${this.$store.state.auth.token}${validate}`
            );
            this.errorsRetur = validare.data.errors;
            if (
                validare.data.success &&
                this.returRadio1 &&
                this.returRadio2 &&
                this.returRadio3
            ) {
                await this.axios.get(
                    `/retururi?domain=${
                        this.$store.state.domain
                    }&useridsession=${
                        this.$store.state.auth.useridsession
                    }&token=${
                        this.$store.state.auth.token
                    }&returns=${JSON.stringify(this.forReturn)}${data}`
                );
                // console.log("xxxxxxxxxxxxxxxxxxxxxxxx",xxx)
                // console.log(this.returSend)
                // this.forReturn = []
                this.$store.dispatch("loadRetururi");
                this.returSend.opened = false;
                this.returRadio1 = this.returRadio2 = this.returRadio3 = false;
                // this.returSend.first_name = "";
                // this.returSend.second_name = "";
                // this.returSend.iban = "";
            }
            // }
        },
        closeAdd() {
            this.errors = [];
            this.addAd = false;
            for (const property in this.address) {
                this.address[property] = "";
            }
            let targetOffset = $(".mobile-w").offset().top;
            $("html, body").animate({ scrollTop: targetOffset }, 500);
        },
        changeType(i) {
            this.typeMobile = i;
            this.login = "";
            this.password = "";
            for (const property in this.registerForm) {
                this.registerForm[property] = "";
                console.log(`${property}: ${this.registerForm[property]}`);
            }
        },
        goRegister() {
            this.$router.push("/contul-meu/register");
        },
        async sendForgot() {
            let mail = this.forgotEmail;
            if (mail == "") mail = "empty";
            let response = await this.axios.get(
                `/resetareparola?domain=${this.$store.state.domain}&emailorphone=${mail}`
            );
            response = response.data;

            if (response.success && this.validateEmail(mail)) {
                this.$notify({
                    type: "success",
                    text: '<i class="bx bx-x close-notif"></i>' + response.text,
                    duration: 5000,
                });
                this.forgotEmail = "";
                this.centered = "login";
                this.login = "";
                this.password = "";
                this.forgotEmailErr = "";
            } else {
                if (this.validateEmail(mail) == false)
                    this.forgotEmailErr =
                        "Va rugam sa introduceti o adresa de email valida.";
                else {
                    this.forgotEmailErr = response.text;
                    this.forgotEmailErr = true;
                }
                //    this.$notify({ type: 'error', text: response.text })
            }
        },
        openAddressAdd() {
            this.addTemplate = this.$store.state.myacc.formtemplateblank;
            this.addAd = true;
        },
        closeChange() {
            this.showChange = false;
            this.changenew = "";
            this.changeold = "";
            this.changerepeat = "";
        },
        async sendPersonalizeaza(parameter, value) {
            // if(this.$store.state.personalizeaza.personalizeaza[index].value == true)
            //     this.$store.state.personalizeaza.personalizeaza[index].value = 0;
            // else    this.$store.state.personalizeaza.personalizeaza[index].value = 1
            if (value == false) value = 1;
            else value = 0;
            await this.axios.get(
                `/personalizeaza?domain=${this.$store.state.domain}&useridsession=${this.$store.state.auth.useridsession}&token=${this.$store.state.auth.token}&${parameter}=${value}`
            );
            // console.log(parameter,value)
            // this.$store.dispatch('loadPersonalizeaza');
        },
        async editAdress(id) {
            this.addressEdit = id;
            let response = await this.axios.get(
                `/updateadresa?domain=${this.$store.state.domain}&useridsession=${this.$store.state.auth.useridsession}&token=${this.$store.state.auth.token}&afisareadresa=${id}`
            );
            response = response.data;
            this.forEdit = response.afisareadresa.detaliiadresa;
            console.log(response);
        },
        async changePassword() {
            let changenew = this.changenew;
            let changeold = this.changeold;
            let changerepeat = this.changerepeat;
            if (changerepeat == "") changerepeat = "empty";
            if (changenew == "") changenew = "empty";
            if (changeold == "") changeold = "empty";

            let response = await this.axios.get(
                `/schimba-parola?domain=${this.$store.state.domain}&useridsession=${this.$store.state.auth.useridsession}&token=${this.$store.state.auth.token}&parolaveche=${changeold}&parolanoua1=${changenew}&parolanoua2=${changerepeat}`
            );
            response = response.data;

            if (!response.success) {
                // this.$notify({ type: 'error', text: response.text })
                this.error.old = response.parolaveche;
                this.error.new = response.parolanoua1;
                this.error.repeat = response.parolanoua2;
                console.log(this.error);
            } else {
                this.$notify({
                    type: "success",
                    text:
                        '<i class="bx bx-x close-notif"></i>' +
                        response.success,
                });
                this.changenew = "";
                this.changeold = "";
                this.changerepeat = "";
                this.showChange = false;
            }
        },
        closeEdit(index) {
            this.addressEdit = null;
            let targetOffset = $(".app-edit-block")
                .eq(index)
                .offset().top;
            $("html, body").animate({ scrollTop: targetOffset }, 500);
        },
        async saveEdit(index) {
            let data = "";
            let validate = "";
            const address = this.forEdit;
            for (let i = 0; i < address.length; i++) {
                if (address[i].predefinied) {
                    data =
                        data +
                        "&" +
                        address[i].paramtere +
                        "=" +
                        address[i].predefinied;
                    validate =
                        validate +
                        "&" +
                        address[i].validatelabel +
                        "=" +
                        address[i].predefinied;
                } else {
                    data = data + "&" + address[i].paramtere + "=" + "";
                    validate =
                        validate + "&" + address[i].validatelabel + "=" + "";
                }
            }
            const validare = await this.axios.get(
                `/validatefields?domain=${this.$store.state.domain}&useridsession=${this.$store.state.auth.useridsession}&token=${this.$store.state.auth.token}&updateaddressid=${this.addressEdit}${validate}`
            );
            console.log(validare);
            this.errors = validare.data.errors;
            console.log(data);
            if (validare.data.success) {
                await this.axios.get(
                    `/updateadresa?domain=${this.$store.state.domain}&useridsession=${this.$store.state.auth.useridsession}&token=${this.$store.state.auth.token}&updateaddressid=${this.addressEdit}${data}`
                );
                this.addressEdit = null;
                let response = await this.axios.get(
                    `/contul-meu?domain=${this.$store.state.domain}&language=${this.$store.state.language}&token=${this.$store.state.auth.token}&useridsession=${this.$store.state.auth.useridsession}`
                );
                response = response.data;
                this.$store.commit("SET_MYACC", response.contulmeu.contulmeu);
                let targetOffset = $(".app-edit-block")
                    .eq(index)
                    .offset().top;
                $("html, body").animate({ scrollTop: targetOffset }, 500);
                this.$notify({
                    type: "success",
                    text:
                        '<i class="bx bx-x close-notif"></i>Adresa a fost actualizata cu succes',
                });
            }
        },
        async deleteAddress(id) {
            let response = await this.axios.get(
                `/edit-address?domain=${this.$store.state.domain}&useridsession=${this.$store.state.auth.useridsession}&token=${this.$store.state.auth.token}&deleteaddressid=${id}`
            );
            response = response.data;
            const myacc = await this.axios.get(
                `/contul-meu?domain=${this.$store.state.domain}&language=${this.$store.state.language}&token=${this.$store.state.auth.token}&useridsession=${this.$store.state.auth.useridsession}`
            );
            this.$store.commit("SET_MYACC", myacc.data.contulmeu.contulmeu);
            console.log(response);
        },
        async addAddress() {
            let data = "";
            let validate = "";
            const address = this.addTemplate;
            for (let i = 0; i < address.length; i++) {
                validate =
                    validate +
                    "&" +
                    address[i].validatelabel +
                    "=" +
                    address[i].predefinied;
                data =
                    data +
                    "&" +
                    address[i].paramtere +
                    "=" +
                    address[i].predefinied;
            }
            const validare = await this.axios.get(
                `/validatefields?domain=${this.$store.state.domain}&useridsession=${this.$store.state.auth.useridsession}&token=${this.$store.state.auth.token}&updateaddressid=${this.addressEdit}${validate}`
            );
            console.log(validare);
            this.errors = validare.data.errors;
            if (validare.data.success) {
                await this.axios.get(
                    `/edit-address?domain=${this.$store.state.domain}&useridsession=${this.$store.state.auth.useridsession}&token=${this.$store.state.auth.token}${data}&addnew=1`
                );
                const myacc = await this.axios.get(
                    `/contul-meu?domain=${this.$store.state.domain}&language=${this.$store.state.language}&token=${this.$store.state.auth.token}&useridsession=${this.$store.state.auth.useridsession}`
                );
                this.$store.commit("SET_MYACC", myacc.data.contulmeu.contulmeu);
                this.addAd = false;

                let targetOffset = $(".app-edit-block")
                    .last()
                    .offset().top;
                $("html, body").animate({ scrollTop: targetOffset }, 500);
                this.$notify({
                    type: "success",
                    text:
                        '<i class="bx bx-x close-notif"></i>Adresa a fost adaugata cu succes',
                });
            }
            // let validate = "";
            // let response = await this.axios.get(`/edit-address?domain=${this.$store.state.domain}&useridsession=${this.$store.state.auth.useridsession}&token=${this.$store.state.auth.token}&addnew=1&updateprenume=${this.address.first_name}&updatenume=${this.address.second_name}&updatestradasinr=${this.address.street}&updateaditional=${this.address.housenr}&updatecodpostal=${this.address.postal_code}&updatejudet=${this.address.city1}&updateoras=${this.address.city2}&updatetelefon=${this.address.phone}&updateemail=${this.address.email}`);
            // response = response.data
            // console.log(response)
            // const myacc = await this.axios.get(`/contul-meu?domain=${this.$store.state.domain}&language=${this.$store.state.language}&token=${this.$store.state.auth.token}&useridsession=${this.$store.state.auth.useridsession}`);
            // this.$store.commit("SET_MYACC", myacc.data.contulmeu.contulmeu);
            // this.addAd = false;
            // let address = {
            //     first_name:"",
            //     second_name:"",
            //     street:"",
            //     housenr:"",
            //     apartnr:"",
            //     postal_code:"",
            //     city1:"",
            //     city2:"",
            //     phone:"",
            //     email:""
            // };
            // this.address = Object.assign({}, address);
        },
        copyLink(link) {
            let dummy = document.createElement("textarea");
            document.body.appendChild(dummy);
            dummy.value = link;
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);
            this.textBtn = "Copiat";
        },
        async registerUser() {
            if (this.registerForm.gender !== 0 && this.registerForm.gender != 1)
                this.registerForm.gender = "empty";
            const products = this.$cookies.get("cart");
            let registerForm = this.registerForm;
            // alert(registerForm.gender)
            // if(!registerForm.gender) registerForm.gender = 0;
            // alert(this.registerForm.gender)
            console.log(this.squares);
            if (this.squares.t1 == true) this.squares.t1 = 1;
            else this.squares.t1 = 0;
            if (this.squares.t2 == true) this.squares.t2 = 1;
            else this.squares.t2 = 0;
            if (this.squares.t3 == true) this.squares.t3 = 1;
            else this.squares.t3 = 0;
            let invited = 0;
            if (this.$cookies.get("invitedFrom"))
                invited = this.$cookies.get("invitedFrom");
            let response = await this.axios.get(
                `/register?domain=${this.$store.state.domain}&email=${
                    registerForm.email
                }&password=${registerForm.password}&nume=${
                    registerForm.second_name
                }&prenume=${registerForm.first_name}&gender=${
                    registerForm.gender
                }&phonenumber=${registerForm.phone}&products=${JSON.stringify(
                    products
                )}&terms=${this.squares.t1}&oferta=${
                    this.squares.t2
                }&oferta_telefon=${
                    this.squares.t3
                }&friendinvite=${invited}&campainid=${
                    this.$store.state.campaign
                }`
            );
            response = response.data;

            // alert(response)
            // alert(this.$store.state.domain)
            // alert(registerForm.gender)
            // alert(registerForm.first_name)
            // alert(registerForm.second_name)
            // alert(registerForm.email)
            // alert(registerForm.phone)
            // alert(registerForm.password)
            // alert(response.register.success)

            if (response.register.success == true) {
                // this.$cookies.set('gender', response.register.genderlink);
                this.$cookies.set("token", response.register.token);
                this.$cookies.set(
                    "useridsession",
                    response.register.useridsession
                );
                if (this.$route.params.type == "login-checkout")
                    window.location = "https://designmall.ro/cart";
                // this.$router.push('/cart');
                else window.location.reload();
            } else {
                if (response.register.validterms != true) {
                    this.validTerms = response.register.validterms;
                    this.$notify({
                        type: "error",
                        text:
                            '<i class="bx bx-x close-notif"></i>' +
                            response.register.validterms,
                    });
                }
                this.registerErr.email = response.register.validemail;
                this.registerErr.second_name = response.register.validnume;
                this.registerErr.password = response.register.validpassword;
                this.registerErr.phone = response.register.validphone;
                this.registerErr.first_name = response.register.validprenume;
                this.registerErr.gender = response.register.validgender;
                console.log(
                    "🚀 ~ file: contul-meu.vue ~ line 983 ~ registerUser ~ response",
                    response
                );
                for (const property in this.registerErr) {
                    if (this.registerErr[property] == true)
                        this.registerErr[property] = "";
                }
                this.registerErr.all = true;
            }
        },
        validateEmail(email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },
        logoutUser() {
            this.$cookies.remove("token");
            this.$cookies.remove("useridsession");
            // this.$cookies.set('gender',1);
            this.$cookies.remove("cart");
            this.$router.push("/");
            this.$store.commit("SET_CARTL", 0);
        },
    },
    beforeMount() {
        this.$store.dispatch("loadCont");
        this.$store.dispatch("loadRetururi");
        this.$store.dispatch("loadPersonalizeaza");
        if (!this.$cookies.get("token") && !this.$cookies.get("useridsession"))
            this.$router.push(
                "/contul-meu/login" &&
                    this.$route.params.type != "login-checkout"
            );
        else {
            if (
                this.$route.params.type != "activare" &&
                this.$route.params.type != "comenzile-mele" &&
                this.$route.params.type != "retururi" &&
                this.$route.params.type != "recomanda-unui-prieten" &&
                this.$route.params.type != "personalizeaza" &&
                this.$route.params.type != "login-checkout"
            )
                this.$router.push("/contul-meu");
        }
    },
    mounted() {
        if (this.$route.params.type == "activare") {
            this.$notify({
                type: "success",
                text:
                    '<i class="bx bx-x close-notif"></i>Contul tau a fost activat cu succes',
            });
        }
        if (this.$cookies.get("token") && this.$cookies.get("useridsession")) {
            //    window.fbq('track', 'CompleteRegistration');
            // gtag.event('conversion', {
            //     "send_to":"AW-10800167573/pDa-CMK6koMDEJX19Z0o"
            // })
            gtag("event", "conversion", {
                send_to: "AW-10800167573/TDbICL-Pn5EDEJX19Z0o",
            });
        }
        //    this.registerForm.gender = "empty";
    },
    watch: {
        $route() {
            setTimeout(() => {
                if (
                    this.$route.fullPath == "/contul-meu/personalizeaza" &&
                    window.innerWidth <= 1200
                ) {
                    // document.querySelector('.app-my-account__comenzi').scrollIntoView({ behavior: 'smooth' })
                    let targetOffset = $(".app-my-account__comenzi").offset()
                        .top;
                    $("html, body").animate({ scrollTop: targetOffset }, 1000);
                }
                // if(this.$route.fullPath=="/contul-meu" && window.innerWidth <= 1200) {
                //     // document.querySelector('.app-my-account__comenzi').scrollIntoView({ behavior: 'smooth' })
                //     let targetOffset = $('.app-logged-1').offset().top;
                //     $('html, body').animate({scrollTop: targetOffset}, 1000);
                // }
                if (
                    this.$route.fullPath == "/contul-meu/comenzile-mele" &&
                    window.innerWidth <= 1200
                ) {
                    // document.querySelector('.app-my-account__comenzi').scrollIntoView({ behavior: 'smooth' })
                    let targetOffset = $(".app-my-account__comenzi").offset()
                        .top;
                    $("html, body").animate({ scrollTop: targetOffset }, 1000);
                }
                if (
                    this.$route.fullPath == "/contul-meu/retururi" &&
                    window.innerWidth <= 1200
                ) {
                    // document.querySelector('.app-my-account__comenzi').scrollIntoView({ behavior: 'smooth' })
                    let targetOffset = $(".app-my-account__retururi").offset()
                        .top;
                    $("html, body").animate({ scrollTop: targetOffset }, 1000);
                }
                if (
                    this.$route.fullPath ==
                        "/contul-meu/recomanda-unui-prieten" &&
                    window.innerWidth <= 1200
                ) {
                    // document.querySelector('.app-my-account__comenzi').scrollIntoView({ behavior: 'smooth' })
                    let targetOffset = $(".app-my-account__friend").offset()
                        .top;
                    $("html, body").animate({ scrollTop: targetOffset }, 1000);
                }
            }, 100);
        },
    },
    //     mounted() {
    //         this.$fb.enable()
    //     if (process.browser && this.$store.state.auth.success) {
    //         fbq('track', "CompleteRegistration");
    //         this.$gtag('event', 'conversion', {
    //             'send_to': 'AW-302540489/BH81COSO-PUCEMnNoZAB'
    //         })
    //     }
    //   }
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/_variables.scss";
@import "../assets/styles/main.scss";
.app-my-account {
    &__lg,
    &__page {
        display: grid;
        grid-template-columns: 1fr 1.5fr 1fr;
        column-gap: 2rem;
    }

    &__choose {
        display: flex;
        flex-direction: row;
        display: none;

        span {
            display: block;
            width: 50%;
            text-align: center;
            font-family: $bold-main;
            margin-top: 3rem;
            font-size: 1.5rem;
            padding: 2rem;
        }

        &-active-el {
            background: $color-gray-light;
        }
    }

    &__comenzi {
        background: $color-gray-light;
        padding: 3rem;

        h3 {
            font-family: $bold-main;
            font-size: 1.8rem;
        }

        span {
            display: block;
            margin-top: 2rem;
            font-family: $light-main;
            font-size: 1.3rem;
        }
    }

    &__retururi {
        &-types {
            display: flex;

            span {
                display: block;
                padding: 3rem;
                background: $color-gray-light;
                font-family: $bold-main;
                font-size: 1.6rem;
                cursor: pointer;
            }
        }

        &-prod {
            display: flex;
            background: $color-gray-light;
            min-height: 78%;
            padding: 0 2rem;
            padding-bottom: 2rem;

            span {
                font-family: $medium-main;
                display: block;
                margin: 0 auto;
                margin-top: 3rem;
                font-size: 1.3rem;
            }
        }
    }

    &__friend {
        padding-left: 2rem;
        padding-right: 2rem;

        h4 {
            font-family: $bold-main;
            font-size: 2rem;
        }

        h5 {
            font-family: $bold-main;
            font-size: 3.5rem;
        }

        &-description {
            display: block;
            margin-top: 1.5rem;
            font-family: $medium-main;
            font-size: 1.4rem;
        }

        &-copy {
            display: flex;
            margin-top: 3rem;

            &-how-works {
                display: flex;
                flex-direction: row;
                margin-top: 5rem;

                &-left {
                    width: 50%;
                    border-right: 2px solid $color-gray-light;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;

                    span {
                        font-size: 2rem;
                        display: block;
                        font-family: $semibold-main;
                    }

                    div {
                        padding: 2rem 0;

                        ion-icon {
                            background-color: rgb(233, 233, 233);
                            padding: 1.5rem;
                            font-size: 4.5rem;
                            border-radius: 50%;
                            transform: rotate(-45deg);
                        }
                    }
                }
                &-right {
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                    // padding-left: 2rem;

                    span {
                        font-family: $bold-main;
                        font-size: 2rem;

                        &:nth-child(2),
                        &:nth-child(4) {
                            display: block;
                            margin-top: 2.5rem;
                            font-family: $light-main;
                        }
                    }

                    div {
                        display: flex;
                        margin-top: 1rem;

                        span {
                            font-family: $light-main;
                            display: block;
                            font-size: 1.4rem;
                            margin: auto 0;
                            margin-left: 2rem;
                        }
                    }
                }
            }

            &-icon {
                background: $color-yellow;
                display: inline-block;
                padding: 0.7rem;
                border-radius: 50%;
                height: 3.5rem;
                width: 3.5rem;
                margin: auto 0;

                ion-icon {
                    font-size: 3.5rem;
                    display: block;
                    transform: rotate(-45deg);
                }
            }

            &-link {
                display: flex;
                flex-direction: column;
                // margin-left: 2rem;
                // box-shadow: 0 8px 24px -5px rgb(0 0 0 / 10%);
                width: 100%;
                padding: 3rem 2rem;
                box-sizing: border-box;
                padding-left: 0;

                span {
                    display: block;
                    font-family: $semibold-main;
                    font-size: 1.5rem;
                }

                &-cnt {
                    margin-top: 2rem;
                    display: flex;

                    button {
                        width: calc(100% - 57rem);
                        margin-left: auto;
                        font-family: $medium-main;
                        color: $color-white;
                        background-color: $color-black;
                        border: none;
                        outline: none;
                        cursor: pointer;
                    }

                    &-txt {
                        display: flex;
                        font-family: $medium-main;
                        width: 55rem;
                        border: 2px solid $color-gray-light;
                        padding: 1rem;
                        cursor: pointer;
                        position: relative;

                        padding-right: 5rem;
                        box-sizing: border-box;

                        span {
                            display: block;
                            overflow: hidden;
                            white-space: nowrap;

                            &:nth-child(2) {
                                display: block;
                                position: absolute;
                                right: 0;
                                top: 50%;
                                transform: translate(-4rem, -50%);
                            }
                        }
                    }
                }
            }
        }
    }

    &__sections {
        display: flex;
        flex-direction: column;

        div {
            font-family: $bold-main;
            display: flex;
            margin-top: 2rem;
            color: $color-black;
            font-size: 1.4rem;

            ion-icon {
                font-size: 2.4rem;
                color: gray;
                cursor: pointer;
            }

            a {
                text-decoration: none;
                display: block;
                margin: auto 0;
                margin-left: 2rem;
                padding-bottom: 0.3rem;
                cursor: pointer;
                color: gray;
                // border-bottom: 1px solid black;
                // color: $color-black;
            }

            &:hover {
                a {
                    border-bottom: 1px solid black;
                    color: $color-black;
                }
                ion-icon {
                    color: black;
                }
            }
        }
    }

    &__login {
        &-form {
            padding: 0 6rem;

            &-title {
                font-family: $semibold-main;
                font-size: 2rem;
            }

            &-cnt {
                margin-top: 3rem;
                display: flex;
                flex-direction: column;

                div {
                    display: flex;
                    flex-direction: column;
                    padding-bottom: 3rem;
                    position: relative;

                    label {
                        font-family: $semibold-main;
                        letter-spacing: 0.1rem;
                    }

                    input {
                        border: 1.5px solid rgb(207, 207, 207);
                        background: transparent;
                        outline: none;
                        font-family: $medium-main;
                        padding: 1rem;
                        margin-top: 1rem;
                        -webkit-appearance: none;

                        &:focus {
                            border: 2px solid $color-black;
                        }
                    }
                }
                span {
                    font-family: $medium-main;
                    cursor: pointer;
                    display: block;
                    margin-top: -1.5rem;
                    margin-left: auto;
                    z-index: 2000;
                }

                button {
                    background: $color-black;
                    outline: none;
                    color: $color-white;
                    padding: 1rem 0;
                    font-family: $medium-main;
                    font-size: 1.5rem;
                    border: none;
                    cursor: pointer;
                    margin-top: 2rem;
                    transition: all 0.5s;

                    &:hover {
                        background-color: rgb(46, 46, 46);
                    }
                }
            }

            &-description {
                margin-top: 2rem;

                span {
                    font-family: $bold-main;
                    margin: 0;
                    font-size: 1.5rem;
                }

                ul {
                    font-family: $medium-main;
                    margin-left: 1.8rem;
                    font-size: 1.1rem;
                    margin-top: 1rem;

                    li {
                        margin-top: 1rem;
                    }
                }
            }
        }
    }
}

.wrapper {
    margin: 0 auto !important;
    margin-top: 2.3rem !important;
}

.form-error {
    position: absolute;
    bottom: 1.5rem;
    color: red;
    font-family: $medium-main;
}
.inp-err {
    border: 1px solid red !important;
}

.bullet {
    width: 2.2rem;
    height: 2.2rem;
    border: 2px solid rgb(104, 104, 104);
    box-sizing: border-box;
    padding: 0 !important;
    border-radius: 50%;
    display: flex;
    flex-direction: row !important;
    cursor: pointer;

    div {
        width: 1.2rem !important;
        height: 1.2rem !important;
        background: $color-black;
        border-radius: 50%;
        padding: 0 !important;
        margin: auto !important;
    }

    &__text {
        display: block;
        margin: auto 0 auto 1rem !important;
        font-family: $semibold-main !important;
        font-size: 1.3rem;
        cursor: pointer;
    }
}

.terms-conditions {
    margin: 0 !important;
    display: flex;
    flex-direction: row !important;
    padding: 0 !important;
    margin-top: 2rem !important;

    &:first-child {
        margin-top: 0 !important;
    }

    span {
        margin: auto 0 auto 1rem !important;
        display: block;
        width: 90%;
        cursor: pointer;
    }
}

.square {
    height: 1.8rem;
    width: 1.8rem;
    padding: 0 !important;
    box-shadow: 0 0 0 1px $color-black;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    justify-content: center;

    div {
        display: flex;
        width: 1rem;
        height: 1rem;
        background: $color-black;
        padding: 0 !important;
        margin: auto !important;
        border: none !important;
    }
}

.register-txt {
    margin: 0 !important;
    margin-top: 1.5rem !important;
    cursor: default !important;
}

.registertextclass {
    cursor: default !important;

    a {
        text-decoration: none;
        color: gray !important;
    }
}

input,
input[type="text"],
input[type="button"],
input[type="submit"] {
    -webkit-appearance: none !important;
    border-radius: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
    -webkit-appearance: none !important;
}

.grid-2 {
    display: grid;
    column-gap: 2rem;
    grid-template-columns: 1fr 3fr !important;
}

.retur-n-act {
    background: #fff !important;
    color: gray;
    transition: all 0.5s;

    &:hover {
        background-color: rgb(0, 0, 0) !important;
        color: white;
    }
}

.app-logged-1 {
    display: flex;
    flex-direction: column;

    &__title {
        font-family: $bold-main;
        font-size: 1.8rem;
    }

    &__template {
        font-family: $semibold-main;
        margin-top: 2rem;
        padding: 2.4rem;
        background-color: $color-gray-light;
        font-size: 1.5rem;
        border-radius: 3px;

        &-txt {
            display: block;
            margin-top: 1rem;
            font-family: $light-main;

            &:nth-child(1) {
                margin-top: 0 !important;
                font-family: $bold-main;
            }
        }
    }

    &__address {
        &-btn {
            display: block;
            width: 30rem;
            font-family: $medium-main;
            padding: 1.3rem 0;
            margin-top: 2rem;
            background: $color-white;
            outline: none;
            border: 1px solid black;
            cursor: pointer;
        }
    }
}

.app-logged-2 {
    display: flex;
    flex-direction: column;

    &__title {
        font-family: $bold-main;
        font-size: 1.8rem;
    }
}
.active-section {
    a {
        border-bottom: 1px solid black;
        color: $color-black !important;
    }
    ion-icon {
        color: black !important;
    }
}

.adresses-grid {
    grid-column: span 3;
    display: grid;
    grid-template-columns: 1fr 2.5fr !important;
    column-gap: 2rem;

    &__cnt {
        display: grid;
        grid-template-columns: 1fr 1fr !important;
        column-gap: 2rem;
        row-gap: 2rem;
        margin-top: 2rem;

        div {
            background: $color-gray-light;
            height: auto;
            padding: 2rem;
            border-radius: 2px;
            padding-bottom: 5rem;
            position: relative;

            .edit-btn {
                position: absolute;
                top: 0;
                right: 0;

                font-size: 2rem;

                ion-icon {
                    background: $color-white;
                    padding: 1rem;
                    border-radius: 50%;
                    cursor: pointer;
                }
            }

            .delete-btn {
                position: absolute;
                bottom: -4rem;
                right: 0;
                font-size: 1.4rem;
                background: transparent;

                ion-icon {
                    background: $color-white;
                    padding: 1rem;
                    border-radius: 50%;
                    cursor: pointer;
                }
            }

            span {
                display: block;
                font-size: 1.3rem;
                font-family: $medium-main;
                margin-top: 0.5rem;
            }
        }
    }
}

.add-edit-address {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
    column-gap: 2rem;
    margin-top: 5rem;

    &__template {
        display: flex;
        flex-direction: column;
        padding-bottom: 3rem;
        position: relative;

        label {
            display: block;
            font-family: $semibold-main;
            letter-spacing: 0.1rem;
        }

        input {
            display: block;
            border: 1.5px solid rgb(207, 207, 207);
            background: transparent;
            outline: none;
            font-family: $medium-main;
            padding: 1rem;
            margin-top: 1rem;

            &:focus {
                border: 2px solid $color-black;
            }
        }
    }

    &__btns {
        display: flex;
        justify-content: space-between;

        button {
            background: none;
            border: 1px solid $color-black;
            width: 15rem;
            font-family: $medium-main;
            padding: 1rem 0;
            cursor: pointer;

            &:nth-child(2) {
                background: $color-black;
                color: $color-white;
            }
        }
    }
}

@media only screen and (max-width: 1600px) {
    .change-pass-btns {
        flex-direction: column-reverse;
        margin-top: -50px;
        padding-left: 0px !important;
        padding-right: 0px !important;

        button {
            width: 100%;
            margin-top: 20px;
        }
    }
}

@media only screen and (max-width: 1200px) {
    .app-my-account__page {
        grid-template-columns: 50% 50%;
    }
    .app-my-account__sections {
        grid-column: 1;
        grid-row: 1;
    }
    .app-logged-1 {
        grid-column: 1 / 2;
        margin-top: 4rem;
    }
    .app-logged-2 {
        grid-column: 2 / 2;
        box-sizing: border-box;
        padding-right: 2rem;
        margin-top: 4rem;
    }
    .adresses-grid {
        grid-template-columns: 100% !important;
        margin-top: 4rem;
    }
    .grid-2 {
        grid-template-columns: 100% !important;
    }
    .app-my-account__comenzi,
    .app-my-account__retururi,
    .app-my-account__friend {
        margin-top: 4rem;
        margin-bottom: 4rem;
    }

    .add-edit-address {
        max-width: calc(100vw - 4rem);
    }
}

@media only screen and (max-width: 800px) {
    .app-my-account__lg {
        grid-template-columns: 1fr;
    }
    .app-my-account__login-form-title {
        display: none;
    }
    .app-my-account__sections {
        margin-left: 2rem;
    }
    .app-my-account__login-form,
    .app-my-account__login-form-cnt {
        padding: 0;
        background: $color-gray-light;
        padding: 2rem;
    }
    .app-my-account__choose {
        display: flex;
    }
    .show-el {
        display: flex;
    }
    .unshow-el {
        display: none;
    }
    .app-my-account__login-form-cnt {
        margin-top: 0;
    }
    input,
    input[type="text"],
    input[type="button"],
    input[type="submit"] {
        -webkit-appearance: none !important;
        border-radius: 0;
        font-size: 16px !important;
    }
    .app-my-account__friend-copy-how-works {
        flex-direction: column;
    }
    .app-my-account__friend-copy-how-works-right {
        margin-top: 2rem;
    }
    .app-my-account__friend-copy-how-works-left {
        span,
        div {
            margin-left: 2rem;
        }

        &-r-text {
            margin-top: 4rem;
        }
    }

    .app-my-account__friend-copy-link-cnt-txt {
        width: 50%;
    }
    .app-my-account__friend-copy-link-cnt > button {
        display: block;
        width: 40%;
        box-sizing: border-box !important;
        margin-right: auto;
        margin-left: 0;
    }
}

@media only screen and (max-width: 600px) {
    .app-my-account__friend-copy-how-works-left,
    .app-my-account__friend-copy-how-works-right {
        width: 100%;
    }
    .app-my-account__page,
    .adresses-grid__cnt,
    .add-edit-address {
        grid-template-columns: 100% !important;
    }
    .adresses-grid__cnt,
    .add-edit-address {
        box-sizing: border-box;
        padding-right: 6rem;
    }
    .app-logged-1 {
        grid-column: 1;
        padding-right: 0 !important;
    }
    .app-logged-2 {
        grid-column: 1;
        padding-right: 0 !important;
    }
    .app-logged-1 {
        padding-right: 0 !important;
    }
    .adresses-grid__cnt {
        padding-right: 4rem !important;
    }
    .app-my-account__friend-copy-link-cnt {
        flex-direction: column;
    }
    .app-my-account__friend-copy-link-cnt-txt {
        width: 100%;
    }
    .app-my-account__friend-copy-link-cnt {
        button {
            padding-top: 1rem;
            padding-bottom: 1rem;
            margin-top: 1rem;
            width: 100%;
        }
    }
    .add-edit-address {
        max-width: calc(100vw - 1.8rem);
        padding-right: 0;
    }

    .app-edit-block {
        padding: 0 !important;
    }
    .add-edit-address--open {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.forgot-txt {
    font-family: $medium-main;
    font-size: 1.2rem;
    padding-bottom: 2rem;
    color: rgba(112, 112, 112, 0.933);
}

.add-edit-address--open {
    display: block;
    padding: 0;
    margin-top: 0;

    .add-edit-address__template {
        padding-bottom: 0 !important;
        padding-top: 1rem;
    }

    div {
        padding: 1rem;
        padding-top: 0;
    }
}

.change-password {
    margin-top: 3rem;
    padding-bottom: 0 !important;

    &__title {
        font-family: $bold-main !important;
        font-size: 1.8rem;
    }

    &__hidden {
        display: flex;
        flex-direction: row;
        margin-top: 2rem;
        padding: 2rem;
        background: #f4f4f4;
        border-radius: 5px;

        p {
            margin-top: auto;
            margin-bottom: auto;
        }

        span {
            display: block;
            margin-left: auto;
            background: $color-white;
            font-size: 1.5rem;
            border-radius: 50%;
            padding: 0 2px;
            margin-bottom: auto;
            margin-top: auto;

            ion-icon {
                font-size: 2rem;
            }
        }
    }
}

.err-terms {
    color: red !important;
}

.error_input {
    border-color: red !important;
}

@media only screen and (max-width: 400px) {
    .app-logged-1__address-btn {
        display: block;
        width: calc(100% - 1.2rem);
    }
    .mobile-w {
        max-width: 100vw;
    }
    .padd-r-0 {
        padding-right: 1.2rem !important;
    }
    .add-edit-address {
        max-width: calc(100vw - 1rem);
    }

    .padd-m-0 {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .change-password {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }
    .app-modal-retur__top-inputs {
        display: flex !important;
        flex-direction: column !important;
    }
    .add-edit-address__template {
        width: 100% !important;
    }
}

.history-purchases {
    &__el {
        margin-top: 2rem;
    }
}

.app-retur__history-boxes {
    display: flex;
    width: 100%;
    flex-direction: column;
}
.app-retur__history-products {
    display: flex;
    flex-direction: column;
    background: $color-white;
    width: 100%;
    margin-top: 2rem;
    padding: 2rem;
    border: 1px solid rgb(161, 161, 161);

    &__template {
        display: flex;
        flex-direction: row;
        justify-content: flex-start !important;

        span {
            margin: 0;
            margin-top: 0.5rem;

            &:first-child {
                font-family: $semibold-main;
            }
        }
    }
}
.app-retur__history-products-border {
    width: 100%;
    height: 1px;
    background-color: rgb(161, 161, 161);
    margin-top: 2rem;
}
.app-retur__history-products-subtitle {
    font-family: $bold-main !important;
    margin: 0 !important;
    margin-top: 2rem !important;
    display: block;
    text-align: start;
}

// Checkboxes
.check__container {
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
    input[type="checkbox"],
    input[type="radio"] {
        --active: rgb(0, 0, 0);
        --active-inner: #fff;
        --focus: 2px rgba(0, 0, 0, 0.027);
        --border: rgb(133, 133, 133);
        --border-hover: rgb(0, 0, 0);
        --background: #fff;
        --disabled: #f6f8ff;
        --disabled-inner: #e1e6f9;
        -webkit-appearance: none;
        -moz-appearance: none;
        height: 21px;
        outline: none;
        display: inline-block;
        vertical-align: top;
        position: relative;
        margin: 0;
        cursor: pointer;
        border: 1px solid var(--bc, var(--border));
        background: var(--b, var(--background));
        transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
        &:after {
            content: "";
            display: block;
            left: 0;
            top: 0;
            position: absolute;
            transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
                opacity var(--d-o, 0.2s);
        }
        &:checked {
            --b: var(--active);
            --bc: var(--active);
            --d-o: 0.3s;
            --d-t: 0.6s;
            --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
        }
        &:disabled {
            --b: var(--disabled);
            cursor: not-allowed;
            opacity: 0.9;
            &:checked {
                --b: var(--disabled-inner);
                --bc: var(--border);
            }
            & + label {
                cursor: not-allowed;
            }
        }
        &:hover {
            &:not(:checked) {
                &:not(:disabled) {
                    --bc: var(--border-hover);
                }
            }
        }
        &:focus {
            box-shadow: 0 0 0 var(--focus);
        }
        &:not(.switch) {
            width: 21px;
            &:after {
                opacity: var(--o, 0);
            }
            &:checked {
                --o: 1;
            }
        }
        & + label {
            font-size: 14px;
            line-height: 21px;
            display: inline-block;
            vertical-align: top;
            cursor: pointer;
            margin-left: 4px;
        }
    }
    input[type="checkbox"] {
        &:not(.switch) {
            border-radius: 7px;
            &:after {
                width: 5px;
                height: 9px;
                border: 2px solid var(--active-inner);
                border-top: 0;
                border-left: 0;
                left: 7px;
                top: 4px;
                transform: rotate(var(--r, 20deg));
            }
            &:checked {
                --r: 43deg;
            }
        }
        &.switch {
            width: 38px;
            border-radius: 11px;
            &:after {
                left: 2px;
                top: 2px;
                border-radius: 50%;
                width: 15px;
                height: 15px;
                background: var(--ab, var(--border));
                transform: translateX(var(--x, 0));
            }
            &:checked {
                --ab: var(--active-inner);
                --x: 17px;
            }
            &:disabled {
                &:not(:checked) {
                    &:after {
                        opacity: 0.6;
                    }
                }
            }
        }
    }
    input[type="radio"] {
        border-radius: 50%;
        &:after {
            width: 19px;
            height: 19px;
            border-radius: 50%;
            background: var(--active-inner);
            opacity: 0;
            transform: scale(var(--s, 0.7));
        }
        &:checked {
            --s: 0.5;
        }
    }
}

.personalizeaza-txt {
    width: 80%;

    p {
        &:first-child {
            font-family: $bold-main;
            font-size: 1.5rem;
        }

        &:nth-child(2) {
            font-family: $light-main;
            font-size: 1.3rem !important;
        }
    }
}

.app-my-account__friend-copy-icon {
    margin: 0;
    padding: 0;
}

.app-history-retur-btn {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    background: black;
    color: $color-white;
    font-family: $medium-main;
    padding: 1rem 5rem;
    font-size: 1.5rem;
    max-width: 100%;
    box-sizing: border-box;
    outline: none;
    margin-bottom: 2rem;
}

.app-modal-retur {
    display: flex;
    flex-direction: column;

    &__title {
        font-family: $bold-main !important;
        font-size: 2rem !important;
        text-transform: uppercase;
    }

    &__top-inputs {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 2rem;
    }

    &__input {
        width: 48%;

        input {
            border: 1px solid rgb(141, 140, 140);
        }
    }
}

.add-edit-address__template {
    select {
        border: 1.5px solid rgb(207, 207, 207);
        background: transparent;
        outline: none;
        font-family: $medium-main;
        padding: 1rem;
        margin-top: 1rem;
        -webkit-appearance: none;
        padding-top: 15px;
        padding-bottom: 15px;

        &:focus {
            border: 2px solid $color-black;
        }
    }
}

.radio-btns {
    position: relative;

    &__template {
        display: flex;
        position: relative;

        &-text {
            display: block;
            margin-left: 3rem !important;
            transform: translateY(-3px);
            font-family: $medium-main;
            cursor: pointer;
            user-select: none;
        }
    }
}

.form__radio-button {
    height: 2rem;
    width: 2rem;
    border: 2px solid #000000;
    display: inline-block;
    position: absolute;
    left: 0;
    top: -0.4rem;
    cursor: pointer;

    &::after {
        content: "";
        display: block;
        height: 1rem;
        width: 1rem;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        -webkit-transition: opacity 0.2s;
        transition: opacity 0.2s;
    }
}

.form__radio-button-active {
    height: 2rem;
    width: 2rem;
    border: 2px solid #000000;
    display: inline-block;
    position: absolute;
    left: 0;
    top: -0.4rem;
    cursor: pointer;

    &::after {
        content: "";
        display: block;
        height: 1rem;
        width: 1rem;
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: black;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        -webkit-transition: opacity 0.2s;
        transition: opacity 0.2s;
    }
}

.errReturR {
    color: red;
}

.password-input {
    padding-right: 4rem !important;
    box-sizing: border-box;
    max-width: 100%;
    position: relative;
}

.pass-image {
    width: 20px;
    position: absolute;
    right: 10px;
    top: 40%;
    cursor: pointer;
}

.app-logged-1__template-txt {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.app-my-account__friends {
    margin-top: 2.5rem;

    span {
        display: block;
    }

    &--1 {
        font-family: $medium-main;
        font-size: 1.2rem;
    }

    &--2 {
        font-family: $light-main;
        margin-top: 4rem;
    }
}

.all-error {
    color: red;
    font-family: $medium-main;
}
</style>
