<template>
    <div class="app-navbar" v-if="$store.state.main" :class="{'sticky-el':stickyHeader,'hide-nav':navShow=='bottom'}">
        <div class="app-navbar-cnt">
            <div class="app-navbar__genders">
                <a class="app-navbar__link" @click="selectGender(category.link)" :class="{'active-gender':category.link.substring(12,13)==$cookies.get('gender')||category.link.substring(5,6)==$cookies.get('gender')}" v-for="category in navbar.categories" :href="category.link" :key="category.ID">{{category.text}}</a>
            </div>
            <div class="app-navbar__burger" @click="openNavbar">
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div class="app-navbar__opened-m" :class="{'app-navbar__opened-m--t':navbar_m==true}">
                <ul>
                    <li v-for="(category) in navbar.categories" @click="selectGender(category.link)" :key="category.ID" >
                        <router-link class="app-navbar__opened-m-link" :class="{'app-navbar__opened-m-link--active':category.link.substring(12,13)==$cookies.get('gender')||category.link.substring(5,6)==$cookies.get('gender')}" :to="category.link">{{category.text}}</router-link>
                    </li>
                </ul>
                <div>
                    <AppTypes :closeMobile="typeClose" version="m"/>
                </div>
                <div class="app-navbar__opened-m-close" @click="closeNavbar">
                    <span></span>
                    <!-- <span></span> -->
                    <!-- <span></span>
                    <span></span> -->
                </div>
            </div>
            <div class="app-navbar__logo">
                <img @click="goLink('/')" :src="navbarLogo" alt="logo">
            </div>
            <div class="app-navbar__icons" >
                   <!-- <div class="app-navbar__basket search-mobile"  > 
                   <svg class="SVGInline-svg Header__iconsSearch__12Ih2-svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Icon/Search" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path d="M13.2739746,2.625 C8.80708008,2.625 5.17294922,6.25911666 5.17294922,10.7259937 C5.17294922,12.658225 5.85333252,14.4343411 6.98660889,15.8281711 L3.21757925,19.5972493 C2.89030634,19.9245264 2.89030857,20.4551437 3.21758423,20.7824181 C3.54485937,21.1096919 4.07547617,21.1096909 4.40275002,20.7824158 C4.40275008,20.7824157 4.40275014,20.7824157 4.4027502,20.7824156 L8.17177734,17.0133715 L8.17177734,17.0133715 C9.56561279,18.1466068 11.3417358,18.8269875 13.2739746,18.8269875 C17.7408691,18.8269875 21.375,15.1928708 21.375,10.7259937 C21.375,6.25911666 17.7409424,2.625 13.2739746,2.625 Z M13.2739746,17.1509198 C9.73125,17.1509198 6.84902344,14.2687045 6.84902344,10.7259937 C6.84902344,7.18328298 9.73125,4.30106767 13.2739746,4.30106767 C16.8166992,4.30106767 19.6989258,7.18328298 19.6989258,10.7259937 C19.6989258,14.2687045 16.8167358,17.1509198 13.2739746,17.1509198 Z" id="Shape" fill="#000000" fill-rule="nonzero"></path>
    </g>
</svg>
                </div> -->
                <div class="app-navbar__basket app-navbar__basket--cart" :class="{'animC':animate}" @click="goLink('/cart')" style="position:relative;margin-left:3rem;">
                    <svg class="SVGInline-svg" width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g id="Icon/Bag" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="bag-(1)" transform="translate(4.000000, 1.000000)" fill="#000000" fill-rule="nonzero">
                                <path d="M11.9375,16.82 C9.07598267,16.8234227 6.61026343,14.8056355 6.0475,12 C6.00347199,11.7099277 6.08905141,11.4151544 6.28157074,11.1937572 C6.47409008,10.9723599 6.75412415,10.8466777 7.0475,10.85 C7.53967974,10.8428441 7.96387262,11.1949242 8.0475,11.68 C8.43567549,13.5327847 10.0694888,14.8597714 11.9625,14.8597714 C13.8555112,14.8597714 15.4893245,13.5327847 15.8775,11.68 C15.9611274,11.1949242 16.3853203,10.8428441 16.8775,10.85 C17.1708759,10.8466777 17.4509099,10.9723599 17.6434293,11.1937572 C17.8359486,11.4151544 17.921528,11.7099277 17.8775,12 C17.3111489,14.8241882 14.8177848,16.847423 11.9375,16.82 L11.9375,16.82 Z" id="Path"></path>
                                <path d="M20.8375,30.0000024 L3.0375,30.0000024 C2.21516623,30.0010286 1.42846092,29.6644514 0.861333743,29.0689679 C0.294206563,28.4734843 -0.00361736386,27.6713058 0.0375,26.85 L0.8475,9.61 C0.917152708,8.00412328 2.24011415,6.73849016 3.8475,6.73999865 L20.0275,6.73999865 C21.6348858,6.73849016 22.9578473,8.00412328 23.0275,9.61 L23.8375,26.85 C23.8786174,27.6713058 23.5807934,28.4734843 23.0136663,29.0689679 C22.4465391,29.6644514 21.6598338,30.0010286 20.8375,30.0000024 L20.8375,30.0000024 Z M3.8475,8.75 C3.29521525,8.75 2.8475,9.19771525 2.8475,9.75 L2.0375,26.95 C2.02379421,27.2237686 2.12306885,27.4911614 2.31211125,27.689656 C2.50115364,27.8881505 2.76338874,28.0003429 3.0375,28.0000008 L20.8375,28.0000008 C21.1116113,28.0003429 21.3738464,27.8881505 21.5628888,27.689656 C21.7519311,27.4911614 21.8512058,27.2237686 21.8375,26.95 L21.0275,9.71 C21.0275,9.15771525 20.5797847,8.71 20.0275,8.71 L3.8475,8.75 Z" id="Shape"></path>
                                <path d="M17.9375,7.75 L15.9375,7.75 L15.9375,6 C15.9375,3.790861 14.146639,2 11.9375,2 C9.728361,2 7.9375,3.790861 7.9375,6 L7.9375,7.75 L5.9375,7.75 L5.9375,6 C5.9375,2.6862915 8.6237915,-8.8817842e-16 11.9375,-8.8817842e-16 C15.2512085,-8.8817842e-16 17.9375,2.6862915 17.9375,6 L17.9375,7.75 Z" id="Path"></path>
                            </g>
                        </g>
                    </svg>
                    <span v-if="$store.state.cartlength&&!$store.state.auth.success">{{$store.state.cartlength}}</span>
                    <span v-if="$store.state.main.cartnr!=0&&$store.state.auth.success">{{$store.state.main.cartnr}}</span>
                </div>
                <div class="app-navbar__wishlist" @click="goLink('/wishlist')">
                    <svg class="SVGInline-svg" width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" >
                        <g id="Icon/Heart" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="heart-(4)" transform="translate(0.066310, 1.000000)" fill="#000000" fill-rule="nonzero">
                                <path d="M31.2140453,5.24515556 C30.093672,2.32678406 27.4043915,0.305062353 24.2896435,0.0395946667 C23.9948453,0.0138808889 23.6969751,0 23.3947813,0 C20.5295304,0.00705388871 17.8079701,1.25557007 15.9336897,3.42277689 C14.0594093,1.25557007 11.3378491,0.00705388871 8.47259818,0 C8.1704044,0 7.87253418,0.0138808889 7.57705329,0.0400497778 C4.4626598,0.305662475 1.77371694,2.32715292 0.653334175,5.24515556 C-0.598419741,8.29960037 -0.0237225419,11.7986086 2.13927195,14.2921956 L15.0747813,29.1905991 C15.2908799,29.4394146 15.6042466,29.5823078 15.9338035,29.5823078 C16.2633604,29.5823078 16.5767271,29.4394146 16.7928257,29.1905991 L29.7281075,14.2921956 C31.891102,11.7986086 32.4657992,8.29960037 31.2140453,5.24515556 Z M28.0107457,12.8 L15.9336897,26.7088782 L3.85697506,12.8 C2.25681204,10.9506584 1.83399678,8.35801626 2.7636844,6.09609956 C3.57222522,3.97645179 5.52042788,2.50482796 7.78037418,2.30661689 C8.00815729,2.28590933 8.23889862,2.27543602 8.47259818,2.27543602 C11.131783,2.26072981 13.5997179,3.65606556 14.9579315,5.942272 C15.1780928,6.26451906 15.5431877,6.45725143 15.9334622,6.45725143 C16.3237367,6.45725143 16.6888315,6.26451906 16.9089928,5.942272 C18.2672887,3.65592491 20.7354344,2.26056795 23.3947813,2.27543602 C23.6281395,2.27543602 23.8592222,2.28613689 24.0858675,2.30661689 C26.3461433,2.50452076 28.2947622,3.97619197 29.1034675,6.09609956 C30.0332165,8.35797428 29.61049,10.9506118 28.0104044,12.8 L28.0107457,12.8 Z" id="Shape"></path>
                            </g>
                        </g>
                    </svg>
                    <span v-if="$store.state.w_length&&!$store.state.auth.success">{{$store.state.w_length}}</span>
                    <span v-if="$store.state.main.wishlistnr!=0&&$store.state.auth.success">{{$store.state.main.wishlistnr}}</span>
                </div>
                <div v-if="!$store.state.auth.success" class="app-navbar__basket" @click="goLink('/contul-meu/login')" style="margin-left:3rem;" > 
                    <svg class="SVGInline-svg" width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g id="Icon/User" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="user-(7)" transform="translate(5.000000, 1.000000)" fill="#000000" fill-rule="nonzero">
                                <path d="M11.1111111,11.1111111 C8.0428625,11.1111111 5.55555556,8.62380417 5.55555556,5.55555556 C5.55555556,2.48730695 8.0428625,0 11.1111111,0 C14.1793597,0 16.6666667,2.48730695 16.6666667,5.55555556 C16.6630543,8.62230662 14.1778622,11.1074987 11.1111111,11.1111111 Z M11.1111111,2.22222222 C9.27016195,2.22222222 7.77777778,3.71460639 7.77777778,5.55555556 C7.77777778,7.39650472 9.27016195,8.88888889 11.1111111,8.88888889 C12.9520603,8.88888889 14.4444444,7.39650472 14.4444444,5.55555556 C14.4424237,3.71544408 12.9512226,2.22424301 11.1111111,2.22222222 L11.1111111,2.22222222 Z" id="Shape"></path>
                                <path d="M11.1111111,30 C8.80486495,29.9835472 6.50592621,29.7385723 4.248,29.2686667 C3.85624225,29.1833089 3.5408577,28.8935083 3.42274074,28.5103524 C3.30462378,28.1271965 3.40209738,27.7101226 3.67779761,27.4190049 C3.95349783,27.1278873 4.36465648,27.0078859 4.75366667,27.105 C6.84617066,27.5336844 8.97522947,27.7589925 11.1111111,27.7777778 C13.7511111,27.7766667 18.2834444,27.3274444 20,26.1474444 L20,24.4444444 C20,19.5352467 16.0203089,15.5555556 11.1111111,15.5555556 C6.20191333,15.5555556 2.22222222,19.5352467 2.22222222,24.4444444 L2.22222222,26.6666667 C2.22222222,27.2803164 1.72476083,27.7777778 1.11111111,27.7777778 C0.497461389,27.7777778 0,27.2803164 0,26.6666667 L0,24.4444444 C0,18.3079472 4.97461389,13.3333333 11.1111111,13.3333333 C17.2476083,13.3333333 22.2222222,18.3079472 22.2222222,24.4444444 L22.2222222,26.6666667 C22.2222222,26.9613276 22.1050549,27.243897 21.8966667,27.4522222 C19.4205556,29.9283333 11.9542222,30 11.1111111,30 L11.1111111,30 Z" id="Path"></path>
                            </g>
                        </g>
                    </svg>
                </div>
                <div v-else class="app-navbar__basket" @click="goLink('/contul-meu')" style="margin-left:3rem;"> <!-- style="margin-left:3rem;"-->
                    <svg class="SVGInline-svg" width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g id="Icon/User-logged-2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="user-(7)" transform="translate(5.000000, 1.000000)">
                                <circle id="Oval" fill="#FFCD00" cx="11" cy="6" r="4"></circle>
                                <path d="M11.1111111,11.1111111 C8.0428625,11.1111111 5.55555556,8.62380417 5.55555556,5.55555556 C5.55555556,2.48730695 8.0428625,0 11.1111111,0 C14.1793597,0 16.6666667,2.48730695 16.6666667,5.55555556 C16.6630543,8.62230662 14.1778622,11.1074987 11.1111111,11.1111111 Z M11.1111111,2.22222222 C9.27016195,2.22222222 7.77777778,3.71460639 7.77777778,5.55555556 C7.77777778,7.39650472 9.27016195,8.88888889 11.1111111,8.88888889 C12.9520603,8.88888889 14.4444444,7.39650472 14.4444444,5.55555556 C14.4424237,3.71544408 12.9512226,2.22424301 11.1111111,2.22222222 L11.1111111,2.22222222 Z" id="Shape" fill="#000000" fill-rule="nonzero"></path>
                                <path d="M4.248,29.2686667 C3.85624225,29.1833089 3.5408577,28.8935083 3.42274074,28.5103524 C3.30462378,28.1271965 3.40209738,27.7101226 3.67779761,27.4190049 C3.95349783,27.1278873 4.36465648,27.0078859 4.75366667,27.105 C6.84617066,27.5336844 8.97522947,27.7589925 11.1111111,27.7777778 C13.7511111,27.7766667 18.2834444,27.3274444 20,26.1474444 L20,24.4444444 C20,19.5352467 2.22222222,19.5352467 2.22222222,24.4444444 L2.22222222,26.6666667 C2.22222222,27.2803164 1.72476083,27.7777778 1.11111111,27.7777778 C0.497461389,27.7777778 0,27.2803164 0,26.6666667 L0,24.4444444 C0,18.3079472 4.97461389,13.3333333 11.1111111,13.3333333 C17.2476083,13.3333333 22.2222222,18.3079472 22.2222222,24.4444444 L22.2222222,26.6666667 C22.2222222,26.9613276 22.1050549,27.243897 21.8966667,27.4522222 C19.4205556,29.9283333 11.9542222,30 11.1111111,30 C8.80486495,29.9835472 6.50592621,29.7385723 4.248,29.2686667 Z" id="Path" fill="#FFCD00" fill-rule="nonzero"></path>
                                <path d="M11.1111111,30 C8.80486495,29.9835472 6.50592621,29.7385723 4.248,29.2686667 C3.85624225,29.1833089 3.5408577,28.8935083 3.42274074,28.5103524 C3.30462378,28.1271965 3.40209738,27.7101226 3.67779761,27.4190049 C3.95349783,27.1278873 4.36465648,27.0078859 4.75366667,27.105 C6.84617066,27.5336844 8.97522947,27.7589925 11.1111111,27.7777778 C13.7511111,27.7766667 18.2834444,27.3274444 20,26.1474444 L20,24.4444444 C20,19.5352467 16.0203089,15.5555556 11.1111111,15.5555556 C6.20191333,15.5555556 2.22222222,19.5352467 2.22222222,24.4444444 L2.22222222,26.6666667 C2.22222222,27.2803164 1.72476083,27.7777778 1.11111111,27.7777778 C0.497461389,27.7777778 0,27.2803164 0,26.6666667 L0,24.4444444 C0,18.3079472 4.97461389,13.3333333 11.1111111,13.3333333 C17.2476083,13.3333333 22.2222222,18.3079472 22.2222222,24.4444444 L22.2222222,26.6666667 C22.2222222,26.9613276 22.1050549,27.243897 21.8966667,27.4522222 C19.4205556,29.9283333 11.9542222,30 11.1111111,30 L11.1111111,30 Z" id="Path-Copy" fill="#000000" fill-rule="nonzero"></path>
                            </g>
                        </g>
                    </svg>
                </div>
            </div>
        </div>
         <AppNavbarTest  version="d" />  
        <!-- <AppTypes v-if="$cookies.get('gender')" version="d"/> -->
    </div>
</template>

<script>
import $ from "jquery";
import AppNavbarTest from "../components/AppNavbarTest.vue";
export default {
    components:{
        AppNavbarTest
    },
    data(){
        return {
            navbar_m:false,
            cart:0,
            animate:false,
            typeClose:false,
            stickyHeader:false,
            navShow:"",
            lastScroll:0
        }
    },
    methods:{
        goLink(link){
            window.location = link;
        },
        selectGender(gender){
            const searchTerm = 'c1=';
            const indexOfFirst = gender.indexOf(searchTerm);
            gender=gender.substring(indexOfFirst+3,indexOfFirst+4)
            this.$cookies.set('gender', gender);
        },
        openNavbar(){
            this.navbar_m=true;
            $("html").css("overflow", "hidden");
        },
        closeNavbar(){
            this.navbar_m=false
            this.typeClose=!this.typeClose
            $("html").css("overflow", "auto");
        },
        handleScroll(){
            // if(window.scrollY>=80) this.stickyHeader = true;
            // if(window.scrollY < 80) this.stickyHeader = false;
            $(".vue-notification").css("display", "none");

            setTimeout(()=>{
                $(".vue-notification").css("display", "block");
            },35000)

            if(this.lastScroll < window.scrollY ) {
                this.navShow = 'bottom';
                this.$store.commit("SET_NAVSHOW",true);
            }
            else {
                if(window.scrollY>=200){
                    this.stickyHeader = true;
                    this.navShow = "";
                    this.$store.commit("SET_NAVSHOW",false);
                }
                else {
                    this.stickyHeader = false;
                    this.$store.commit("SET_NAVSHOW",true);
                } 
                 
            }
            this.lastScroll = window.scrollY;
            
            // $(window).bind('mousewheel', (event)=> {
            //     if (event.originalEvent.wheelDelta >= 0) {
            //         if(window.scrollY>=80){
            //             this.stickyHeader = true;
            //             this.navShow = "";
            //         }
            //         else {
            //             this.stickyHeader = false;
            //         } 
            //     }
            //     else {
            //         // this.stickyHeader = false;
            //         this.navShow = 'bottom';
            //         // console.log(this.navShow)
            //         // console.log('Scroll down');
            //     }
            // });
        }
    },
    computed:{
        cartL(){
            return this.$store.state.cartlength;
        },
        navbar(){
            if(this.$store.state.main)
                return this.$store.state.main.mainmenu;
            else return ""
        },
        navbarLogo(){
            if(this.$store.state.main)
            return this.$store.state.main.logoimage;
            else return ""
        }
    },
    mounted(){
        this.cart = JSON.parse(this.$cookies.get('cart'));
        if(this.cart) this.$store.commit('SET_CARTL', this.cart.length);
        if(this.$cookies.get('wishlist')) this.$store.commit("SET_WLENGTH",JSON.parse(this.$cookies.get('wishlist')).length)
        else this.$store.commit("SET_WLENGTH",0)
    },
    watch:{
        cartL(){
            this.animate = true;
            setTimeout(
                () => {
                    this.animate = false;
                }, 1500);
        },
        '$route.fullPath':function(){
            this.$store.dispatch('loadMain',this.$route);
            this.$store.dispatch('reloadNavMobileHome',this.$route.query.c1);
        }
    },
    created () {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed () {
        window.removeEventListener('scroll', this.handleScroll);
    },
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/_variables.scss";
@import "../assets/styles/main.scss";
.app-navbar{

    display: flex;
    flex-direction: column;
    transition: all .5s;

    &-cnt {
        display: flex;
        flex-direction: row;
        padding: 2rem 2rem;
        // -webkit-box-shadow: 0 1px 3px 0 rgb(0 0 0 / 30%);
        // box-shadow: 0 1px 3px 0 rgb(0 0 0 / 30%);
        justify-content: space-between;
        background: white;
    } 

    &__burger {
        width: 4rem;
        display: flex;
        flex-direction: column;
        margin: auto 0;
        display: none!important;

        span {
            background: $color-black;
            height: 2px;
            width: 100%;
            margin: 2px 0;

            &:nth-child(1) {
                width: 50%!important;
            }

            &:nth-child(3) {
                width: 70%!important;
            }
        }


    }

    &__opened-m {
        height: 0;
        position: fixed;
        top: 0;
        right: 0;
        background: $color-white;
        z-index: 500;
        opacity: 0;
        width: 0;
        -webkit-transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        box-sizing: border-box;
        display: none!important;
        width: 100vw!important;
        overflow: auto;

        &--t {
            opacity: 1;
            width: 100%;
            height: 100vh;
        }

        ul {
            list-style:none;
            padding-top: 8rem;
            display: flex;
            justify-content: space-around;
            background-color: $color-gray-light;

            li{
                margin: 2.5rem 0 ;
            }
        }

        &-link {
            cursor: pointer;
            font-family: $semibold-main;
            font-size: 1.7rem;
            color: $color-black;
            text-decoration: none;
            padding-bottom: 1rem;

            &--active{
                color: red;
                border-bottom: 2px solid $color-black;
            }
        }

        &-close {
            display: flex;
            position: absolute;
            top: 3rem;
            right: 3rem;
            width: 30px;
            height: 30px;
            cursor: pointer;

            
            span{
                height: 2px!important;
                width: 100%;
                background-color: transparent;
                margin: auto 0;

                &::before,
                &::after {
                    width: inherit;
                    height: inherit;
                    background-color: $color-black;
                }

                &::before {
                    content: "";
                    position: absolute;
                    transform: rotate(-135deg);
                }
                &::after {
                    content: "";
                    position: absolute;
                    transform: rotate(135deg);  
                }

                // &:nth-child(1) {
                //     transform: rotate(-35deg);
                // }
                // &:nth-child(2) {
                //      transform: rotate(135deg);
                // }
            }
        }
    }

    &__genders{
        display: flex;
        flex-direction: row;
        margin-top: auto;
        margin-bottom: auto;
        width: 33%;
    }
    &__link{
        display: block;
        color:$color-gray;
        font-family: $semibold-main;
        font-size: 1.8rem;
        margin: 0 2rem;
        cursor: pointer;
        transition: all .5s;
        text-decoration: none;

        &:hover{
            color: $color-black;
            // border-bottom: 1px solid black;
            margin-top: -2px;
        }
    }

    &__logo{
        
        img{
            width: 16rem;
            cursor: pointer;
        }
    }

    &__icons {
        width: 33%;
        display: flex;
        justify-content: flex-end;
    }

    &__wishlist {
        display: block;
        margin: auto 0 auto 0;
        cursor: pointer;
        position: relative;

        span {
                display: block;
                position: absolute;
                right: -.4rem;
                bottom: -.1rem;
                background: $color-yellow;
                padding: .2rem;
                border-radius: 50%;
                font-family: $semibold-main;
                min-width: 15px;
                text-align: center;
            }

        svg{
            width: 2.5rem
        }
    }
    
    &__basket {
        display: block;
        margin: auto 0 auto 0;
        cursor: pointer;

        &--cart {
            margin-right: 3rem;
            

            span {
                display: block;
                position: absolute;
                right: -.4rem;
                bottom: -.1rem;
                background: $color-yellow;
                padding: .2rem;
                border-radius: 50%;
                font-family: $semibold-main;
                min-width: 15px;
                text-align: center;
            }
        }
        
        svg{
            width: 2.5rem
        }
    }
}

.active-gender {
    color: $color-black;
    border-bottom: 1px solid black;
    margin-top: -2px;
}
.search-mobile {
    display: none;
}
@media only screen and (max-width: 850px) {
    .search-mobile {
        display: block;
    }
    .app-navbar__genders{
        display: none;
    }
    .app-navbar__logo{
        display: flex;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);

        img {
            width: 15rem;
            display: block;
        }
    }
    .app-navbar__opened-m,
    .app-navbar__burger {
        display: flex!important;
        flex-direction: column;
        z-index: 0;
    }

    .app-navbar__opened-m {
        z-index: 90999999999999999;
    }
}

@media only screen and (max-width: 600px) {
    .app-navbar__logo {
        left: 120px;
        margin-top: 8px;
        
        img {
            width: 10rem; 
        }
    }
}

@media only screen and (max-width: 500px) {
    .app-navbar-cnt {
        padding-left: 1rem;
        padding-right: 0rem;
    }
    .app-navbar__logo {
        left: 120px;
        margin-top: 8px;
        
        img {
            width: 10rem; 
        }
    }
    .app-navbar__basket--cart {
        margin-right: 1rem;
    }
    .app-navbar__basket {
        margin-left: 1rem!important;
    }
    .app-navbar__icons{
        margin-left: auto;
        width:30%;
        font-size: 1rem
    };

    .app-navbar__basket,
    .app-navbar__wishlist {
        svg {
            width: 2.3rem;
        }
    }
}

@keyframes animateCart{
    40% {
        transform: rotate(15deg)
    }
    80%{
        transform: rotate(-15deg)
    }
    100%{
    transform: rotate(0)
    }
}

.animC{
    -webkit-animation: animateCart linear .5s 2 forwards;
    animation: animateCart linear .5s 2 forwards;
}

.sticky-el {
    position:fixed!important;
    top: 0;
    width:100vw;

    &__dissapear {
        top:-500px!important;
        display: none;
    }
}

.hide-nav{
    top:-500px!important;
}
</style>