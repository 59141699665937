<template>
    <div class="app-purchase">
        <div class="app-purchase__top">
            <span class="app-purchase__top-title">
                Comanda {{order.ordername}}
            </span>
            <button @click="goOrder">Vizualizeaza</button>
        </div>
        <div class="app-purchase__middle">
            <div class="app-purchase__middle-date">
                <span>
                    <b>Data:</b> {{order.data}}
                </span>
            </div>
            <div class="app-purchase__middle-total">
                <span>
                    <b>Total:</b> <span>{{order.total}}</span>
                </span>
            </div>
        </div>
        <div class="app-purchase__bottom">

        </div>
    </div>
</template>

<script>
export default {
    props : {
        order:{
            type:Object
        }
    },
    methods:{
        goOrder(){
            window.location = "/contul-meu/comenzile-mele/"+this.order.idcomanda
        }
    }
}
</script>

<style lang="scss" scoped>
$padd-left-right:1rem;
$padd-top-bottom:1.5rem;
$bd-color:rgb(185, 185, 185);
.app-purchase {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border: 1px solid $bd-color;
        padding:$padd-top-bottom $padd-left-right;
        box-sizing: border-box;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        background: rgb(231, 231, 231);

        span {
            display: block;
            font-family: $semibold-main;
            font-size: 1.5rem;
            margin: auto 0;
        }
        button {
            display: block;
            color: $color-white;
            background: $color-black;
            outline: none;
            border: none;
            font-family: $semibold-main;
            font-size: 1.3rem;
            padding: 1.3rem 2rem;
        }
    }

    &__middle {
        display: flex;
        padding:$padd-top-bottom $padd-left-right;
        border: 1px solid $bd-color;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        border-top: none;

        &-date {
          

            span {
                font-family: $light-main;
                font-size: 1.5rem;
            }
        }

        &-total {

            span {
                font-family: $light-main;
                font-size: 1.5rem;

                span {
                    color: red;
                }
            }
        }
    }
}
</style>