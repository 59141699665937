<template>
    <div class="app-cart-summary" v-if="summary">
        <div style="display:flex;">
            <h6 class="app-cart-summary__title">{{summary.title}}</h6> 
            <div class="edit-btn" @click="goBack"><ion-icon name="create-outline"></ion-icon></div>
        </div>

        <div class="app-cart-summary-line"></div>
        <div class="app-cart-summary__stats" v-for="(t,index) in summary.content" :key="index">
            <span class="app-cart-summary__stats-left limited-width">{{t}}</span>
        </div>
    </div>
</template>


<script>
export default {
    props:{
        summary:{
            type:Object
        }
    },
    methods:{
        goBack(){
            window.location = "/checkout/step=" + this.summary.backlink;
        },
        buyProd() {
            if(!this.$store.state.auth.success) this.$router.push("/contul-meu/login-checkout");
            else this.$router.push('/checkout/step=1')
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/_variables.scss";
@import "../assets/styles/main.scss";
.app-cart-summary {
    width: 100%;
    background: #f4f4f4;
    padding: 3rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    position: relative;

    &__title {
        font-family: $bold-main;
        font-size: 2rem;

    }

    &-line{
        width: 100%;
        height: 2px;
        background: $color-gray-light;
        margin-top: 1.5rem;
    }

    &__stats{
        display: flex; 
        margin-top: 2rem;

        &--bold {
            margin-top: 5rem;
        }

        span {
            display: block;
            font-family: $bold-main;
            font-size: 1.3rem;
        }

        &-right {
            margin-left: auto;  

            &--bold {
                font-family: $bold-main!important;
            }
        }

        &-left {

            &--bold{
                font-family: $bold-main!important;
            }
        }
    }

    &__btn {
        margin-top: 2rem;
        padding: .8rem 0;
        background: $color-black;
        outline: none;
        border: 1px solid $color-black;
        color: $color-white;
        font-family: $medium-main;
        transition: all .5s;

        &:hover {
            cursor: pointer;
            background-color: rgb(36, 36, 36);
        }

    }
}

.edit-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    
    font-size: 2rem;
    

    ion-icon {
        background: $color-white;
        padding: 1rem;
        border-radius: 50%;
        cursor: pointer;
    }
}
</style>
