<template>
    <div class="app-viewer-m" v-if="viewer_m.length">
        <div class="app-viewer-m__close" @click="$emit('closeViewer')">
            <ion-icon name="close-outline"></ion-icon>
        </div>
        <div class="app-viewer-m__pages">
                {{page+1}} / {{viewer_m.length}}
        </div>
            <v-zoomer-gallery
            style="width: 100%; height: 100%;background-color:white;"
            :list="viewer_m"
            v-model="page"
            ></v-zoomer-gallery>
        <!-- <carousel 
            :paginationEnabled="false"
            :perPage="perPage" 
            :loop="true"
            :mouse-drag="false"
            :navigate-to="page"
            @pageChange="pageChange"
            :speed="550"
            :scrollPerPage="false">
                <slide v-for="(i, index) in viewer_m" :key="index" >
                    <pinch-zoom @toggleZoom="toggleZoom" :autoZoomOut="true">  
                        <img  :src="i" alt="header-image" class="app-viewer-m__image" >
                    </pinch-zoom>
                </slide>
        </carousel> -->
    </div>
</template>

<script>
export default {
    props:{
        viewer_m:{
            type:Array
        },
        newPage:{
            type:Number
        }
    },
    data() {
        return {
            perPage:1,
            page:0
        }
    },
    methods:{
        pageChange(){

        },
        toggleZoom(){
            console.log(1)
        }
    },
    watch:{
        newPage(){
            this.page = this.newPage;
        }
    }
}
</script>

<style lang="scss" scoped>
.app-viewer-m {
    width: 100vw;
    height: 100vh;
    background: $color-white;
    position: fixed;
    z-index: 99999991000000;
    top: 0;
    left: 0;

    &__pages {
        display: flex;
        width: 38px;
        height: 38px;
        font-size: 1.5rem;
        flex-direction: row;
        border-radius: 50%;
        // box-shadow: rgb(116, 116, 116) 0px 2px 4px 0px;
        position: absolute;
        z-index: 1000000000000000000000000000000000000000;
        top: 2.4rem;
        left: 1rem; 
        font-family: $bold-main;
    }

    &__close {
        // color: gray;
        display: flex;
        background: white;
        width: 38px;
        height: 38px;
        font-size: 1.5rem;
        flex-direction: column;
        justify-content: center;
        border-radius: 50%;
        // box-shadow: rgb(116, 116, 116) 0px 2px 4px 0px;
        position: absolute;
        z-index: 1000000000000000000000000000000000000000;
        top: 1.5rem;
        right: 1rem;

        ion-icon {
            font-size: 3rem;
            display: block;
            margin: 0 auto;
        }
    }
}
</style>