<template>
    <div class="app-home-section" v-if="$store.state.homepage"> 
        <div class="app-home-section__img">
            <img :src="$store.state.homepage.sportsection.image.source" alt="section-image">
            <div class="app-home-section__text">
                <span class="app-home-section__text-s" :class="{'app-home-section__text-s-white':$store.state.homepage.sportsection.color=='white'}">{{$store.state.homepage.sportsection.smalltext.text}}</span>
                <span class="app-home-section__text-b" :class="{'app-home-section__text-b-white':$store.state.homepage.sportsection.color=='white'}">{{$store.state.homepage.sportsection.title.text}}</span>
                <span class="app-home-section__text-m" :class="{'app-home-section__text-m-white':$store.state.homepage.sportsection.color=='white'}">{{$store.state.homepage.sportsection.text.text}}</span>
                <button :class="{'app-home-section__btn-white':$store.state.homepage.sportsection.color=='white'}" @click="goLink($store.state.homepage.sportsection.link)">{{$store.state.homepage.sportsection.button.text}}</button>
            </div>
        </div>
        <div class="app-home-section__products" :style="padding">
            <div class="app-home-section__text app-home-section__text--m">
                <span class="app-home-section__text-s app-home-section__text-s--m">{{$store.state.homepage.sportsection.smalltext.text}}</span>
                <span class="app-home-section__text-b app-home-section__text-b--m">{{$store.state.homepage.sportsection.title.text}}</span>
                <span class="app-home-section__text-m app-home-section__text-m--m">{{$store.state.homepage.sportsection.text.text}}</span>
                <button @click="goLink($store.state.homepage.sportsection.link)">{{$store.state.homepage.sportsection.button.text}}</button>
            </div>
                                <!-- :autoplay="true"
                    :autoplayTimeout="5000"  -->
            <div class="app-home-section__carousel">
                    <carousel
                    style="max-width:100vw;"
                    ref="carousel" 
                    :perPage="perpage" 

                    :loop="true"
                    :mouse-drag="true"
                    @pageChange="pageChange"
                    :paginationEnabled="false"
                    >
                        <slide v-for="(s,index) in $store.state.homepage.lastproducts" :key="index" >
                            <div class="slide"><AppProductEl :carousel="true" :product="s"/></div>
                        </slide>
                    </carousel>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            perpage:5,
            time:1000,
            padding:""
        }
    },
    methods:{
        goLink(link){
            window.location = link;
        },
        pageChange(){
            this.time = 5000;
        },
        setBottom(){
            let height = document.getElementsByClassName('slide')[0];
            this.padding = "margin-bottom:" + height.offsetHeight/2 +"px!important;";
            setTimeout(() => {
                this.setBottom()
            }, 1000);
            
        }
    },
    beforeMount(){
        if(window.innerWidth > 1500) this.perpage = 5;
        if(window.innerWidth <= 1000) this.perpage = 3;
        if(window.innerWidth <= 600) this.perpage = 2;
    },
    mounted() {
    setTimeout(() => {
        this.setBottom()
    }, 1000);
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/_variables.scss";
@import "../assets/styles/main.scss";
.app-home-section {
    width: 100vw;


    &__img{
        position: relative;
        width: 100%!important;

        img {
            width: inherit;
        }
    }

    &__text {
        position: absolute;
        top: 50%;
        left: 10%;
        transform: translateY(-50%);
        width: 51rem;
        
        &--m{
            display: block;
            position: relative;
            transform: translate(0);
            padding-left: 5%;
            margin-top: 5rem;
            display: none;
        }

        &-s{
            font-family: $bold-main;
            display: block;
            font-size: 1.2rem;

            &-white {
                color: $color-white!important;
            }
        }

        &-b {
            display: block;
            font-family: $bold-main;
            font-size: 3rem;
            margin-top: 1.5rem;

            &-white {
                color: $color-white!important;
            }
        }

        &-m {
            display: block;
            font-family: $medium-main;
            margin-top: 2rem;
            font-size: 1.3rem;

            &-white {
                color: $color-white!important;
            }
        }

        &__btn-white {
            background-color: $color-white!important;
        }

        button{
            background: $color-black;
            color: $color-white;
            border:none;
            outline: none;
            padding:1.2rem 5rem;
            font-family: $medium-main;
            display: block;
            margin-top: 2rem;
            cursor: pointer;
        }
    }

    &__products {
        background-color: #f2e557;
        height: 30rem;
        position: relative;
        display: flex;
    }

    &__carousel {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10rem;
    }

}

.slide {
    padding: 1rem;
}

@media only screen and (max-width: 1000px) {
    .app-home-section__products{
        margin-bottom: 1rem;
    }
}

@media only screen and (max-width: 850px) {
    .app-home-section__products{
        flex-direction: column;
    }
    .app-home-section__text--m{
        display: block!important;
    }
    .app-home-section__text{
        display: none;
        top: 0;
        max-width: 100vw;
        left: 0;
        box-sizing: border-box;
    }
    .app-home-section__products{
        height: 50rem;
        margin-bottom: 20%;
    }
}

@media only screen and (max-width: 700px) {
    .app-home-section__products{
        margin-bottom: 15%;
    }
}
@media only screen and (max-width: 600px) {
    
    .app-home-section__products{
        margin-bottom: 30%;
    }
    .app-home-section__carousel{
        width: 99%;
        margin-left: auto!important;
        margin-right: auto!important;
        margin-top: 20px;
    }
}
@media only screen and (max-width: 500px) {
    .app-home-section__products{
        margin-bottom: 10rem;
    }
    .slide {
        min-height: 375px;
    }
}
@media only screen and (max-width: 400px) {
    .app-home-section__products{
        margin-bottom: 3rem;
    }
}

.app-home-section__btn-white {
    color: $color-black!important;
    background-color: $color-white!important;
}

</style>