<template>
    <div class="app-similar" v-if="$store.state.similarproducts">
        <h5>Produse similare</h5>
        <div class="app-brands__carousel">
            <carousel 
            :paginationEnabled="false"
            :perPage="perpage" 
            :autoplay="false" 
            :autoplayTimeout="5000" 
            :loop="true"
            :mouse-drag="true"
            :navigate-to="page"
            @pageChange="pageChange">
                <slide v-for="(s, index) in $store.state.similarproducts" :key="index+9999" >
                    <div class="slide"><AppProductEl bg="rgb(244 244 244)" :product="s"/></div>
               </slide>
            </carousel>
        </div>
    </div>
</template>


<script>
export default {
  data(){
    return{
      page:0,
      mobile:false,
      perpage:7
    }
  },
  methods:{
    goProduct(link){
      this.$router.push(link);
    },
    pageChange(i){
      this.page = i;
    },
    goPage(index){
      this.page+=index;
      if(this.page == -1) this.page = 0;
      if(this.page == this.$store.state.images_header.length ) this.page = this.$store.state.images_header.length - 1;
    }
  },
  beforeMount(){
        if(window.innerWidth <= 1200) this.perpage = 5;
        if(window.innerWidth <= 850) this.perpage = 3;
        if(window.innerWidth <= 500) this.perpage = 2;
    }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/_variables.scss";
@import "../assets/styles/main.scss";
.slide{
    margin-left: 1rem;
    margin-right: 1rem;
}

.app-similar {
    padding: 5rem 0;
    background: $color-gray-light;

    h5 {
        font-family: $bold-main;
        font-size: 2.5rem;
        margin-left: 1rem;
        margin-right: 1rem;
        padding-bottom: 3rem;
    }
}


</style>
