<template>
    <div class="reclama"></div>
</template>

<script>
export default {
    beforeMount(){
        localStorage.setItem("campaign", this.$route.params.id)
        const route = this.$route.fullPath.split('/');
        let redirect = "/";
        for(let i = 3;i < route.length;i++){
            redirect = redirect + route[i] + '/';
        }
        if(redirect[redirect.length-1] == '/')
            redirect = redirect.slice(0, -1)
        console.log(redirect)
        if(redirect!='//')
            window.location = redirect;
        else
         window.location = "/"
        console.log(route)
        // if(this.$route.params.redirect) {
        //    window.location = this.$route.params.redirect;
        // }
        // else {
        //     window.location = "/"
        // }
        console.log(this.$route);
    }
}
</script>