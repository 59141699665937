<template>
    <div class="app-newsletter" v-if="$store.state.pdc">
        <div class="wrapper top-space" v-html="$store.state.pdc.content"></div>
    </div>
</template>


<script>
export default {
	beforeMount(){
		this.$store.dispatch('loadPolitica');
	}
}
</script>