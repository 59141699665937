<template>
    <div class="app-purchase" v-if="$store.state.history">
        <AppCartProd style="margin-top:2rem;" v-for="product in $store.state.history.produse" :key="product.product_total" :product="product" :simple="false"></AppCartProd>
        <div class="app-purchase__border"></div>
        <div class="app-purchase__prices">
            <div class="app-purchase__prices-template">
                <span>Subtotal comanda:</span>
                <span>{{$store.state.history.subtotalcomanda}}</span>
            </div>
            <div class="app-purchase__prices-template">
                <span>Cost livrare si procesare:</span>
                <span>{{$store.state.history.costlivrare}}</span>
            </div>
            <div class="app-purchase__prices-template">
                <span>Discount comanda:</span>
                <span>{{$store.state.history.discountcomanda}}</span>
            </div>
            <div class="app-purchase__prices-template">
                <span>Voucher:</span>
                <span>{{$store.state.history.voucher}}</span>
            </div>
            <div class="app-purchase__prices-template">
                <span>TOTAL COMANDA:</span>
                <span style="color:red;">{{$store.state.history.totalcomanda}}</span>
            </div>
        </div>
        <div class="app-purchase__border" style="margin-top:1rem;"></div>
        <div class="app-purchase__boxes">
            <div class="app-purchase__boxes-el" v-for="(box, index) in $store.state.history.boxes" :key="box">
                <div class="app-purchase__boxes-el--top" >
                    <span>
                        <ion-icon name="document-text-outline" v-if="index==1" ></ion-icon> 
                        <ion-icon name="home-outline" v-if="index==0"></ion-icon>
                        <ion-icon name="card-outline" v-if="index==2"></ion-icon>
                        {{box.title}}
                    </span>
                </div>
                <div class="app-purchase__boxes-el--bottom">
                    <span v-for="t in box.content" :class="{'bold-txt':t.bold}" :key="t">{{t.value}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    beforeMount(){
        this.$store.dispatch('loadHistory',this.$route.params.orderid)
    }
}
</script>

<style lang="scss" scoped>
.app-purchase{

    &__border {
        width: 100%;
        height: 1px;
        background: rgb(124, 124, 124);
    }

    &__prices {
        display: flex;
        flex-direction: column;

        &-template {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 1rem;
            span {
                display: block;

                &:first-child {
                    font-family: $semibold-main;
                    font-size: 1.5rem;
                }

                &:nth-child(2) {
                    font-family: $medium-main;
                    font-size: 1.5rem;
                }
            }
        }
    }

    &__boxes {
        margin-top: 2rem;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;


        &-el {
            width: 30%;
            padding: 1rem;
            border: 1px solid rgb(187, 187, 187);
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;

            &--top {
                display: flex;
                text-align: center;
                justify-content: center;
                border-bottom: 1px solid rgb(184, 184, 184);
                padding: 10px 0;
                
                span {
                    font-family: $semibold-main;
                    font-size: 1.4rem;

                    ion-icon {
                        transform: translateY(2px);
                    }
                }
            }

            &--bottom {
                span {
                    display: block;
                    font-size: 1.2rem;
                    font-family: $medium-main;
                    margin-top: 5px;
                }
            }
        }
    }
}

.bold-txt {
    font-weight: bold;
}

@media only screen and (max-width: 800px) {
    .app-purchase__boxes-el {
        width: 100%;
        margin-top: 2rem;
    }
}
</style>