<template>
    <div></div>
</template>

<script>
export default {
    beforeMount() {
        this.$cookies.set("token", this.$route.params.token);
        this.$cookies.set("useridsession", this.$route.params.userid);
        console.log(this.$route.params.route);
        if (this.$route.params.route == "contul-meu-activare")
            window.location = "/contul-meu/activare";
        else window.location = "/" + this.$route.params.route;
        // const response = await $axios.$get(`/checkout?domain=${store.state.domain}&products=${JSON.stringify(products)}&language=${store.state.language}&token=${route.params.token}&useridsession=${route.params.userid}`);
    },
};
</script>
