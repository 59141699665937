<template>
    <div class="app-answear">
        <a :href="$store.state.main ? $store.state.main.footer.footerblackline.link : '' ">
            <ion-icon name="help-circle-outline"></ion-icon>
            <span class="app-answear__text">{{$store.state.main ? $store.state.main.footer.footerblackline.text : ''}}</span>
        </a>
    </div>
</template>

<script>
export default {
    data(){
        return{
            answer:null
        }
    },
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/_variables.scss";
@import "../assets/styles/main.scss";
.app-answear {
    width: 100%;
    display: flex;
    flex-direction: row;
    background: $color-black;
    justify-content: center;
    padding: 2rem 0;

    a{
        display: flex;
        text-decoration: none;
    }

    ion-icon {
        color: $color-white;
        font-size: 2rem;
        cursor: pointer;
    }

    &__text {
        display: block;
        color: $color-white;
        font-family: $medium-main;
        margin: auto 0;
        margin-left: 1rem;
        font-size: 1.6rem;
        cursor: pointer;
    }
}
</style>