<template>
    <div class="app-cart" v-if="$store.state.cart_prods">
        
        <div class="app-cart__empty" v-if="$store.state.cart_prods.checkout.products==null&&$store.state.cart_prods.checkout.productsout==null">
            <span class="app-cart__empty-title">
                <svg class="SVGInline-svg" width="22px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g id="Icon/Bag" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="bag-(1)" transform="translate(4.000000, 1.000000)" fill="#000000" fill-rule="nonzero">
                            <path d="M11.9375,16.82 C9.07598267,16.8234227 6.61026343,14.8056355 6.0475,12 C6.00347199,11.7099277 6.08905141,11.4151544 6.28157074,11.1937572 C6.47409008,10.9723599 6.75412415,10.8466777 7.0475,10.85 C7.53967974,10.8428441 7.96387262,11.1949242 8.0475,11.68 C8.43567549,13.5327847 10.0694888,14.8597714 11.9625,14.8597714 C13.8555112,14.8597714 15.4893245,13.5327847 15.8775,11.68 C15.9611274,11.1949242 16.3853203,10.8428441 16.8775,10.85 C17.1708759,10.8466777 17.4509099,10.9723599 17.6434293,11.1937572 C17.8359486,11.4151544 17.921528,11.7099277 17.8775,12 C17.3111489,14.8241882 14.8177848,16.847423 11.9375,16.82 L11.9375,16.82 Z" id="Path"></path>
                            <path d="M20.8375,30.0000024 L3.0375,30.0000024 C2.21516623,30.0010286 1.42846092,29.6644514 0.861333743,29.0689679 C0.294206563,28.4734843 -0.00361736386,27.6713058 0.0375,26.85 L0.8475,9.61 C0.917152708,8.00412328 2.24011415,6.73849016 3.8475,6.73999865 L20.0275,6.73999865 C21.6348858,6.73849016 22.9578473,8.00412328 23.0275,9.61 L23.8375,26.85 C23.8786174,27.6713058 23.5807934,28.4734843 23.0136663,29.0689679 C22.4465391,29.6644514 21.6598338,30.0010286 20.8375,30.0000024 L20.8375,30.0000024 Z M3.8475,8.75 C3.29521525,8.75 2.8475,9.19771525 2.8475,9.75 L2.0375,26.95 C2.02379421,27.2237686 2.12306885,27.4911614 2.31211125,27.689656 C2.50115364,27.8881505 2.76338874,28.0003429 3.0375,28.0000008 L20.8375,28.0000008 C21.1116113,28.0003429 21.3738464,27.8881505 21.5628888,27.689656 C21.7519311,27.4911614 21.8512058,27.2237686 21.8375,26.95 L21.0275,9.71 C21.0275,9.15771525 20.5797847,8.71 20.0275,8.71 L3.8475,8.75 Z" id="Shape"></path>
                            <path d="M17.9375,7.75 L15.9375,7.75 L15.9375,6 C15.9375,3.790861 14.146639,2 11.9375,2 C9.728361,2 7.9375,3.790861 7.9375,6 L7.9375,7.75 L5.9375,7.75 L5.9375,6 C5.9375,2.6862915 8.6237915,-8.8817842e-16 11.9375,-8.8817842e-16 C15.2512085,-8.8817842e-16 17.9375,2.6862915 17.9375,6 L17.9375,7.75 Z" id="Path"></path>
                        </g>
                    </g>
                </svg>
                Coșul tău este gol
            </span>
            <span class="app-cart__empty-description">Vezi oferta curentă, cu siguranță vei găsi ceva interesant.</span>
            <button style="color:black;" @click="goLink($store.state.checkout.linkexploreaza)">Exploreaza</button>
            <div class="app-cart__empty-bottom wrapper">
                <div class="app-cart__empty-bottom--left">
                    <span>N-ar trebui să ai ceva în coș?</span>
                    <p v-if="!$store.state.auth.token">Asigură-te că ești conectat la cont.</p>
                    <p>Conectarea va sincroniza coșul de cumpărături cu celelalte device-uri. Pentru clienții neconectați, produsele vor rămâne în coș zece zile.</p>
                    <button style="color:black;" @click="goLink('/contul-meu/login')" v-if="!$store.state.auth.token">Conecteaza-te</button>
                </div>
                <div class="app-cart__empty-bottom--right">
                    <p>
                        Te-ai conectat și nu-ți poți vedea produsele în coș?
                    </p>
                    <p>Ți-am transferat produsele ieșite din stoc către wishlist. Acolo te poți abona la notificări legate de disponibilitatea lor în viitor.</p>
                    <p>Dacă nici unul dintre aceste cazuri nu ți se aplică, te rugăm să raportezi bug-ul către noi <a href="/contact">AICI</a>.</p>
                </div>
            </div>
        </div>
        <div class="app-cart__prod" v-else>
            <div class="wrapper app-cart__prod-cnt ">
                <div class="app-cart__prod-products">
                    <h5 class="app-cart__prod-title" v-if="$store.state.cart_prods.checkout.products!=null">
                        Coș de cumpărături 
                         <span class="app-cart__prod-title-nr" v-if="$store.state.cart_prods.checkout.products.length>1">{{$store.state.cart_prods.checkout.products.length}} produse</span>
                <span class="app-cart__prod-title-nr" v-if="$store.state.cart_prods.checkout.products.length==1">{{$store.state.cart_prods.checkout.products.length}} produs</span>
                    </h5>
                    <div class="app-cart__prod-line" v-if="$store.state.cart_prods.checkout.products!=null"></div>
                    <div class="app-cart__prod-el"  v-for="(p, index) in onStock" :key="index">
                        <AppCartProd :product="p"/>
                        <div class="app-cart__prod-line"></div>
                    </div>
                    <div class="app-cart__prod-products" style="padding:0;width:100%;margin-top:3rem;" v-if="offStock.length">
                        <h5 class="app-cart__prod-title">
                            Produse care nu mai sunt pe stoc
                        </h5>
                        <div class="app-cart__prod-line"></div>
                        <div class="app-cart__prod-el"  v-for="(p, index) in offStock" :key="index">
                            <AppCartProd :product="p"/>
                            <div class="app-cart__prod-line"></div>
                        </div>
                    </div>
                </div>

                <div class="app-cart__prod-summary">
                    <AppCartSummary :summary="$store.state.cart_prods.checkout"/>
                    <div class="app-cart__promo" @click="promo=true">
                       <div style="display:flex;" v-if="!promo" >
                            <ion-icon name="cash-outline"></ion-icon>
                            <span>Am un cod promo</span>
                       </div>
                       <div v-else style="width:100%;position:relative;">
                           <input type="text"  :class="{'err-promo':errPromo}" @input="errPromo=false" placeholder="Cod Promo" v-model="promoCode">
                           <button @click="sendPromo">Folosește</button>
                           <p class="err-promo-txt" v-if="errPromo">
                               {{errPromo}}
                           </p>
                       </div>
                    </div>
                    <AppCartPriority/>
                </div>
            </div>
        </div>
        <notifications position="top center" width="100%" class="notification-custom"/>
    </div>
</template>


<script>
import cookies from 'js-cookie'
export default {
    data(){
        return {
            products:null,
            promo:false,
            promoCode:"",
            errPromo:false
        }
    },
    computed:{
        onStock(){
            if(this.$store.state.cart_prods.checkout.products)
                return this.$store.state.cart_prods.checkout.products.filter(p => p.instock==true);
            else 
                return []
        },
        offStock(){
            if(this.$store.state.cart_prods.checkout.products)
                return this.$store.state.cart_prods.checkout.products.filter(p => p.instock==false);
            else 
                return []
        }
    },
    methods:{
        goLink(link){
            window.location = link;
        },
       async sendPromo(){
           let promo = this.promoCode;
        //    alert(this.$store.state.cart_prods.checkout.total.subtotalunformated)
           if(this.promoCode == "") promo = "empty";
            let response = await this.axios.get(`/promocode?domain=${this.$store.state.domain}&language=${this.$store.state.language}&token=${this.$store.state.auth.token}&useridsession=${this.$store.state.auth.useridsession}&promocode=${promo}&subtotal=${this.$store.state.cart_prods.checkout.total.subtotalunformated}`);
            response = response.data;
            this.$cookies.set("voucher", response.cachevoucher);
            this.$store.dispatch('loadCart',this.$route);
            if(!response.success) {
                // this.$notify({ type: 'error', text: response.text })
                this.errPromo = response.text;
                this.promoCode = "";
            }
            else {
                this.$notify({ type: 'success', text: '<i class="bx bx-x close-notif"></i>'+response.text })
                this.errPromo = "";
            }
            console.log(response)
        }
    },
    beforeMount(){
        if(this.$cookies.get("voucher")!='null'&&this.$cookies.get("voucher")) {
            this.promoCode = this.$cookies.get("voucher")
            this.promo = true;
        }
        if(cookies.get('cart'))
        this.products = JSON.parse(cookies.get('cart'));
        this.$store.dispatch('loadCart',this.$route);
    }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/_variables.scss";
@import "../assets/styles/main.scss";
.app-cart {

    &__empty {

        text-align: center;

        &-title {
            font-family: $bold-main;
            font-size: 2rem;
            display: block;

            svg {
                transform: translateY(6px);
            }
        }

        &-description {
            display: block;
            font-family: $medium-main;
            margin-top: 2rem;

        }

        &-bottom {
            display: flex;
            margin-top: 4rem;
            justify-content: space-between;
            flex-wrap: wrap;
            &--left {
                text-align: start!important;
                width: 45%;
                display: flex;
                justify-content: start!important;
                flex-direction: column;

                span {
                    font-family: $bold-main;
                    font-size: 1.4rem;
                }

                p {
                    font-family: $medium-main;
                    font-size: 1.1rem;
                    margin-top: .5rem;
                }

                button {
                    background: black!important;
                    color: white!important;
                    font-family: $medium-main!important;
                    font-size: 1.5rem;
                    margin-left: 0!important;
                }
            }

            &--right {
                width: 40%;
                text-align: start;
                margin-top: 2rem;

                p {
                    font-family: $medium-main;
                    font-size: 1.1rem;
                    margin-top: .5rem;

                    a {
                        color: gray;
                        text-decoration: none;
                    }
                }
            }

        }

        button {
            display: block;
            margin: 0 auto;
            margin-top: 2rem;
            background: none;
            outline: none;
            border: 1px solid $color-black;
            font-family: $medium-main;
            padding: 1rem 10rem;
            transition: all .5s;
            font-size: 1.3rem;

            &:hover {
                font-family: $semibold-main;
                cursor: pointer;
                font-size: 1.32rem;
                -moz-box-shadow:    inset 0 0 1px #000000;
                -webkit-box-shadow: inset 0 0 1px #000000;
                box-shadow:         inset 0px 0px 1px 1px #000000;
            }
        }
    }

    &__prod {
        margin-top: 2rem;
        background: $color-gray-light;
        padding-top: 3rem;

        &-cnt {
            display: flex;
            flex-direction: row;
        }

        &-products {
            width: calc(100% - 35rem);
            background: $color-white;
            padding: 3rem;
        }

        &-title {
            font-family: $bold-main;
            font-size:2.4rem;
        }

        &-line{
            width: 100%;
            height: 2px;
            background: $color-gray-light;
            margin-top: 1.5rem;
        }

        &-el {
            margin-top:2rem
        }

        &-summary {
            margin-left: 5rem;
            width: 35rem
            
        }
    }

    &__promo {
        display: flex;
        background: $color-white;
        margin-top: 4rem;
        padding: 2rem .5rem;
        cursor: pointer!important;

        ion-icon {
            font-size:3rem;
        }

        span {
            display: block;
            margin:auto 0 auto 1rem;
            font-family: $semibold-main;
            font-size: 1.1rem;
            cursor: pointer;
        }

        input {
            margin: auto 0;
            width:100%;
            box-sizing: border-box;
            padding: .2rem;
            border-top-style: none;
            border-left-style: none;
            border-right-style: none;
            outline: none;
            font-family: $medium-main;
            border-bottom: 1px solid $color-black;;
            padding-bottom: .8rem;
        }

        button {
            display: block;
            margin-top: .5rem;
            margin-left: auto;
            font-family: $semibold-main;
            outline: none;
            border:1px solid black;
            padding: .8rem;
            background: transparent;
            cursor: pointer;
        }
    } 
}

@media only screen and (max-width: 850px) {
    .app-cart__empty {
        padding: 0 1rem;
    }
    .app-cart__prod-cnt{
        flex-direction: column;
    }
    .app-cart__empty-bottom--right, 
    .app-cart__empty-bottom--left {
        width: 100%;

        button {
            margin: 0 auto!important;
            margin-top: 1.5rem!important;
            // width: 100%!important;
        }
    }
    .app-cart__prod-el,
    .app-cart__prod-products,
    .app-cart__prod-summary{
        width: 100%;
        box-sizing: border-box;
        margin: 0;
    }
    .app-cart__prod-el{
        margin-top: 3rem;
    }

    .app-cart__prod-summary {
        margin-top: 2rem;
    }
}

.err-promo {
    border-color: red!important;
}

.err-promo-txt {
    display: block;
    color: red;
    position: absolute;
    bottom: 1rem;
    font-size: 1.3rem;
}

@media only screen and (max-width: 550px){
    .app-cart-prod__info-title{
        color: red;
    }
}

.app-cart__prod-title{
    display: flex!important; 
}
.app-cart__prod-title-nr {
    font-family: $light-main;
    color: #585858; 
    font-size: 10px;;
    display: block;
    margin:auto 0;
    margin-left: 1rem;;
}
</style>