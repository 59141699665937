<template>
    <div class="app-contact" v-if="$store.state.auth.success">
        <div class="wrapper">
            <div class="app-my-account__lg">
                <div class="app-my-account__sections">
                    <div >
                        <ion-icon name="person-outline"></ion-icon>
                        <a href="/contul-meu" >Contul meu</a>
                    </div>
                    <div>
                        <ion-icon name="mail-outline"></ion-icon>       
                        <a href="/contact">Contact</a>
                    </div>
                </div>
                <div  class="app-my-account__login-form" >
                    <div class="wrapper">
                        <div class="app-contact__form">
                            <span class="app-contact__form-title">Schimba parola</span>
                            <span class="app-contact__form-txt">Va rugam sa introduceti parola noua.</span>
                            <form>
                                <div class="app-contact__form-cnt">
                                    <label for="password">Noua parola*</label>
                                    <input @input="err.new=false" :type="typePass1" class="password-input" :class="{'err-input':err.new!=false}" name="password" v-model="password">
                                    <img v-if="typePass1=='password'" @click="typePass1='text'" class="pass-image" src="../assets/images/visibility.png" alt="visibility">
                                    <img v-else @click="typePass1='password'" class="pass-image" src="../assets/images/visibility2.png" alt="visibility">
                                    <p class="form-error" v-if="err.new">{{err.new}}</p>
                                </div>
                                <div class="app-contact__form-cnt">
                                    <label for="password">Repeta parola*</label>
                                    <input @input="err.repeat=false" :type="typePass2" class="password-input" :class="{'err-input':err.repeat}" name="password" v-model="repeat">
                                    <img v-if="typePass2=='password'" @click="typePass2='text'" class="pass-image" src="../assets/images/visibility.png" alt="visibility">
                                    <img v-else @click="typePass2='password'" class="pass-image" src="../assets/images/visibility2.png" alt="visibility">
                                    <p class="form-error" v-if="err.repeat">{{err.repeat}}</p>
                                </div>
                                <button @click="sendPass" style="color:black!important;" type="button" class="app-contact__form-btn">Schimba parola</button>
                            </form>
                        </div>
                    </div>                         
                </div>
            </div>
        </div>
        <notifications position="top center" width="100%" class="notification-custom"/>   
    </div>           
</template>


<script>
export default {
    data() {
        return {
            name:"",
            email:"",
            number:"",
            question:"",
            password:"",
            repeat:"",
            err:{
                new:false,
                repeat:false
            },
            typePass1:"password",
            typePass2:"password"
        }
    },
    methods:{
        async sendPass(){
            let repeat = "empty";
            let password = "empty";
            if(this.password!="") password = this.password;
            if(this.repeat!="") repeat = this.repeat;
            let response = await this.axios.get(`/updatepassword?domain=${this.$store.state.domain}&newpassword1=${password}&newpassword2=${repeat}&useridsession=${this.$store.state.auth.useridsession}&token=${this.$store.state.auth.token}`);
            response = response.data;
            if(!response.success) {
                // this.$notify({ type: "error", text: response.text })
                this.err.new = response.newpassword1;
                this.err.repeat = response.newpassword2;
            }
            else {
                this.$notify({ type: "success", text:'<i class="bx bx-x close-notif"></i>'+ response.success })
                this.repeat = "";
                this.password = "";
                this.err.new = false;
                this.err.repeat = false;
                setTimeout(()=>{
                    window.location = "/contul-meu"
                }, 1000)
            }
        }
    },
    beforeMount(){
        this.$store.dispatch('loadContact');
    }
}
</script>

<style lang="scss" scoped>
.app-contact{

    &__form {
        width: 40rem;
        margin: 0 auto;
        margin-top: 4rem;

        &-title{
            font-family: $bold-main;
            font-size: 1.8rem;

        }

        &-cnt{
            margin-top: 3rem;
            display: flex;
            flex-direction: column;
            position: relative;

            label {
                font-family: $semibold-main;
                font-size: 1.3rem;
                padding-bottom: .7rem;
            }

            input{
                font-size: 1.6rem;
                outline: none;
                padding: .7rem;
                font-family: $light-main;
                -webkit-appearance: none;
                border: 1px solid $color-black;
            }

            textarea{
                resize: none;
                height: 10rem;
                outline: none;
                font-size: 1.6rem;
                font-family: $light-main;
                -webkit-appearance: none;
                border: 1px solid $color-black;
            }
        }

        &-txt {
            font-family: $medium-main;
            display: block;
            margin-top: 2rem;
            color: $color-gray;

            a {
                color: inherit;
            }

            &-link {
                text-decoration: none;
                color: inherit;
            }
        }

        &-btn {
            font-family: $medium-main;
            width: 50%;
            background: none;
            outline: none;
            border: 1px solid black;
            padding: 1rem 0;
            display: block;
            margin-top: 2rem;
            transition: all .1s;
            box-sizing: border-box;
            color: $color-black;

            &:hover {
                cursor: pointer;
                font-family: $semibold-main;
            }
        }
    }
}

.registertextclass {
    color: $color-gray-light;

    a {
        text-decoration: none;
        color: $color-gray-light!important;
    }
}
@media only screen and (max-width: 600px) {
    .app-contact__form {
        width: 100%;
    }
}


.app-my-account {

    &__lg,
    &__page {
        display: grid;
        grid-template-columns: 1fr 1.5fr 1fr;
        column-gap: 2rem;
    }

    &__choose {
        display: flex;
        flex-direction: row;
        display: none;

        span {
            display: block;
            width: 50%;
            text-align: center;
            font-family: $bold-main;
            margin-top: 3rem;
            font-size: 1.5rem;
            padding: 2rem;
        }

        &-active-el {
            background: $color-gray-light;
        }
    }

    &__comenzi {
       
        padding: 3rem;

        h3 {
            font-family: $bold-main;
            font-size: 1.8rem;
        }

        span {
            display: block;
            margin-top: 2rem;
            font-family: $light-main;
            font-size: 1.3rem;
        }
    }

    &__retururi{

        &-types {
            display: flex;

            span {
                display: block;
                padding: 3rem;
                
                font-family: $bold-main;
                font-size: 1.6rem;
                cursor: pointer;
            }
        }

        &-prod {
            display: flex;
          
            height: 78%;

            span {
                font-family: $medium-main;
                display: block;
                margin: 0 auto;
                margin-top: 3rem;
                font-size: 1.3rem;
            }
        }
    }

    &__friend {
        
        h4 {
            font-family: $bold-main;
            font-size: 2rem;
        }

        h5 {
            font-family: $bold-main;
            font-size: 3.5rem;
        }

        &-description {
            display: block;
            margin-top: 1.5rem;
            font-family: $medium-main;
            font-size: 1.4rem;
        }

        &-copy {
            display: flex;
            margin-top: 3rem;

            &-how-works{
                display: flex;
                flex-direction: row;
                margin-top: 5rem;

                &-left{
                    width: 50%;
                    border-right: 2px solid $color-gray-light;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;

                    span {
                        font-size: 2rem;
                        display: block;
                        font-family: $semibold-main;
                    }

                    div {
                        padding: 2rem 0;
                    
                        ion-icon {
                            background-color: rgb(233, 233, 233);
                            padding: 1.5rem;
                            font-size: 4.5rem;
                            border-radius: 50%;
                            transform: rotate(-45deg);
                        }
                    }
                }
                &-right{
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                    padding-left: 2rem;

                    span {
                        font-family: $bold-main;
                        font-size: 2rem;

                        &:nth-child(2),
                        &:nth-child(4) {
                            display: block;
                            margin-top: 2.5rem;
                            font-family: $light-main;
                        }
                    }

                    div {
                        display: flex;
                        margin-top: 1rem;

                        span {
                            font-family: $light-main;
                            display: block;
                            font-size: 1.4rem;
                            margin: auto 0;
                            margin-left: 2rem;
                        }
                    }
                }
            }

            &-icon{
                background: $color-yellow;
                display: inline-block;
                padding: .7rem;
                border-radius: 50%;
                height: 3.5rem;
                width: 3.5rem;
                margin: auto 0;
                
                ion-icon {
                    font-size: 3.5rem;
                    display: block;
                    transform: rotate(-45deg);
                }
            }

            &-link {
                display: flex;
                flex-direction: column;
                margin-left: 2rem;
                // box-shadow: 0 8px 24px -5px rgb(0 0 0 / 10%);
                width: 100%;
                padding:3rem 2rem;

                span {
                    display: block;
                    font-family: $semibold-main;
                    font-size: 1.5rem;
                }

                &-cnt {
                    margin-top: 2rem;
                    display: flex;

                    button {
                        width: calc(100% - 57rem);
                        margin-left: auto;
                        font-family: $medium-main;
                        color: $color-white;
                        background-color: $color-black;
                        border:none;
                        outline: none;
                        cursor: pointer;
                    }

                    &-txt {
                        display: flex;
                        font-family: $medium-main;
                        width: 55rem;
                        border: 2px solid $color-gray-light;
                        padding: 1rem;
                        cursor: pointer;
                        position: relative;
                        
                        padding-right: 5rem;
                        box-sizing: border-box;

                        span {
                            display: block;
                            overflow: hidden;
                            white-space: nowrap;

                            &:nth-child(2) {
                                display: block;
                                position: absolute;
                                right: 0;
                                top: 50%;
                                transform: translate(-4rem,-50%);
                            }

                        }
                    }
                }
            }
        }
    }

    &__sections {
        display: flex;
        flex-direction: column;

        div {
            font-family: $bold-main;
            display: flex;
            margin-top: 2rem;
            color: $color-black;
            font-size: 1.4rem;

            ion-icon {
                font-size: 2.4rem;
                color: gray;
                cursor: pointer;
            }

            a {
                text-decoration: none;
                display: block;
                margin: auto 0;
                margin-left: 2rem;
                padding-bottom: .3rem;
                cursor: pointer;
                color: gray;
                // border-bottom: 1px solid black;
                // color: $color-black;
                
            }

            &:hover  {

                a{
                    border-bottom: 1px solid black;
                    color: $color-black;
                }
                ion-icon {
                    color: black;
                }
            }
        }
    }

    &__login {

        &-form {
            padding: 0 6rem;

            &-title {
                font-family: $semibold-main;
                font-size: 2rem;
            }

            &-cnt {
                margin-top: 3rem;
                display: flex;
                flex-direction: column;


                div {
                    display: flex;
                    flex-direction: column;
                    padding-bottom: 3rem;
                    position: relative;
                    
                    label {
                        font-family: $semibold-main;
                        letter-spacing: .1rem;
                    }

                    input {
                        position: relative;
                        border: 1.5px solid rgb(207, 207, 207);
                        background: transparent;
                        outline: none;
                        font-family: $medium-main;
                        padding: 1rem;
                        margin-top: 1rem;
                        -webkit-appearance: none;

                        &:focus {
                            border: 2px solid $color-black;
                        }
                    }



                }
                span {
                    font-family: $medium-main;
                    cursor: pointer;
                    display: block;
                    margin-top: -1.5rem;
                    margin-left: auto;
                    z-index: 2000;
                }

                button {
                    background: $color-black;
                    outline: none;
                    color: $color-white;
                    padding: 1rem 0;
                    font-family: $medium-main;
                    font-size: 1.5rem;
                    border: none;
                    cursor: pointer;
                    margin-top: 2rem;
                    transition: all .5s;

                    &:hover {
                        background-color: rgb(46, 46, 46);
                    }
                }
            }

            &-description {
                margin-top: 2rem;
                
                span {
                    font-family: $bold-main;
                    margin: 0;
                    font-size: 1.5rem;
                }

                ul {
                    font-family: $medium-main;
                    margin-left: 1.8rem;
                    font-size: 1.1rem;
                    margin-top: 1rem;

                    li {
                        margin-top: 1rem;
                    }
                }
            }
        }
    }
}

.wrapper {
    margin: 0 auto!important;
    margin-top: 2.3rem!important;
}

.form-error {
    position: absolute;
    bottom:-1.3rem;
    color: red;
    font-family: $medium-main;
}
.inp-err {
    border: 1px solid red!important;
}

.bullet{
    width: 2.2rem;
    height: 2.2rem;
    border: 2px solid rgb(104, 104, 104);
    box-sizing: border-box;
    padding: 0!important;
    border-radius: 50%;
    display: flex;
    flex-direction: row!important;
    cursor: pointer;

    div {
        width: 1.2rem!important;
        height: 1.2rem!important;
        background: $color-black;
        border-radius: 50%;
        padding: 0!important;
        margin: auto!important;

    }

    &__text {
        display: block;
        margin: auto 0 auto 1rem!important;
        font-family: $semibold-main!important;
        font-size: 1.3rem;
        cursor: pointer;
    }
}


.terms-conditions {
    margin: 0!important;
    display: flex;
    flex-direction: row!important;;
    padding: 0 !important;
    margin-top: 2rem!important;
    
    &:first-child {
        margin-top: 0!important;
    }

    span {
        margin: auto 0 auto 1rem!important;
        display: block;
        width: 90%;
        cursor: pointer;
    }
}

.square {
    height: 1.5rem;
    width: 1.5rem;
    padding: 0!important;
    box-shadow: 0 0 0 1px $color-black;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    justify-content: center;

    div {   display: flex;
            width: 1rem;
            height: 1rem;
            background: $color-black;
            padding:0!important;
            margin:auto!important;
            border: none!important;
    }
}

.register-txt {
    margin: 0!important;
    margin-top: 1.5rem!important;
    cursor: default!important;
}

.registertextclass {
    cursor: default!important;

    a{
        text-decoration: none;
        color: gray!important;
    }
}

input,
input[type="text"],
input[type="button"],
input[type="submit"] {
-webkit-appearance: none!important;
border-radius: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none!important;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  -webkit-appearance: none!important;
}

.grid-2 {
    display: grid;
    column-gap: 2rem;
    grid-template-columns: 1fr 3fr!important;
}

.retur-n-act {
    background: #fff!important;
    color: gray;
    transition: all .5s;

    &:hover {
        background-color: rgb(0, 0, 0)!important;
        color: white;
    }
}

.app-logged-1 {
    display: flex;
    flex-direction: column;

    &__title {
        font-family: $bold-main;
        font-size: 1.8rem;
    }

    &__template {
        font-family: $semibold-main;
        margin-top: 2rem;
        padding: 2.4rem;
        background-color: $color-gray-light;
        font-size: 1.5rem;
        border-radius: 3px;

        &-txt {
            display: block;
            margin-top: 1rem;
            font-family: $light-main;

            &:nth-child(1) {
                margin-top: 0!important;
                font-family: $bold-main;
            }
        }
    }

    &__address {

        &-btn {
            display: block;
            width: 30rem;
            font-family: $medium-main;
            padding: 1.3rem 0;
            margin-top: 2rem;
            background: $color-white;
            outline: none;
            border: 1px solid black;
            cursor: pointer;

        }
    }
}

.app-logged-2 {
    display: flex;
    flex-direction: column;

    &__title {
        font-family: $bold-main;
        font-size: 1.8rem;
    }
}
.active-section {
    a{
        border-bottom: 1px solid black;
        color: $color-black!important;
    }
    ion-icon {
        color: black!important;
    }
}

.adresses-grid {
    grid-column: span 3;  
    display: grid;
    grid-template-columns: 1fr 2.5fr!important;
    column-gap: 2rem;

    &__cnt{
        display: grid;
        grid-template-columns: 1fr 1fr!important;
        column-gap: 2rem;
        row-gap: 2rem;
        margin-top: 2rem;
        position: relative;

        div {
            background: $color-gray-light;
            height: auto;
            padding: 2rem;
            border-radius: 2px;
            padding-bottom: 5rem;
            position: relative;

            .edit-btn {
                position: absolute;
                top: 0;
                right: 0;
                
                font-size: 2rem;
                

                ion-icon {
                    background: $color-white;
                    padding: 1rem;
                    border-radius: 50%;
                    cursor: pointer;
                }
            }

            .delete-btn {
                position: absolute;
                bottom: -5rem;
                right: 0;
                font-size: 1.4rem;
                background: transparent;
                
                ion-icon {
                    background: $color-white;
                    padding: 1rem;
                    border-radius: 50%;
                    cursor: pointer;
                }

            }

            span {
                display: block;
                font-size: 1.3rem;
                font-family: $medium-main;
                margin-top: .5rem;
            }
        }
    }
}

.add-edit-address {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
    column-gap: 2rem;
    margin-top: 5rem;

    &__template {
        display: flex;
        flex-direction: column;
        padding-bottom: 3rem;
        position: relative;

        label {
            display: block;
            font-family: $semibold-main;
            letter-spacing: .1rem;
        }

        input {
            display: block;
            border: 1.5px solid rgb(207, 207, 207);
            background: transparent;
            outline: none;
            font-family: $medium-main;
            padding: 1rem;
            margin-top: 1rem;

            &:focus {
                border: 2px solid $color-black;
            }
        }
    }

    &__btns {
        display: flex;
        justify-content: space-between;

        button {
            background: none;
            border: 1px solid $color-black;
            width: 15rem;
            font-family: $medium-main;
            padding: 1rem 0;
            cursor: pointer;

            &:nth-child(2) {
                background: $color-black;
                color: $color-white;
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .app-my-account__page {
        grid-template-columns: 50% 50%;
    }
    .app-my-account__sections {
        grid-column: 1;
        grid-row: 1;
    }
    .app-logged-1 {
        grid-column: 1 / 2;
        margin-top: 4rem;
    }
    .app-logged-2 {
        grid-column: 2 / 2;
        box-sizing: border-box;
        padding-right: 2rem;
        margin-top: 4rem;
    }
    .adresses-grid {
        grid-template-columns:100%!important; 
        margin-top: 4rem;
    }
    .grid-2 {
        grid-template-columns: 100%!important;
    }
    .app-my-account__comenzi,
    .app-my-account__retururi,
    .app-my-account__friend {
        margin-top: 4rem;
        margin-bottom: 4rem;
    }
}

@media only screen and (max-width: 800px) {
    .app-my-account__lg{
        grid-template-columns:1fr;
    }
    .app-my-account__login-form-title {
        display: none;
    }
    .app-my-account__sections {
        margin-left: 2rem;
    }
    .app-my-account__login-form,
    .app-my-account__login-form-cnt {
        padding: 0;
        background: $color-gray-light;
        padding: 2rem;
    }
    .app-my-account__choose{
        display: flex;
    }
    .show-el {
        display: flex;
    }
    .unshow-el {
        display: none;
    }
    .app-my-account__login-form-cnt {
        margin-top: 0;
    }
    input,
    input[type="text"],
    input[type="button"],
    input[type="submit"] {
    -webkit-appearance: none!important;
    border-radius: 0;
    font-size: 16px!important;
    }
    .app-my-account__friend-copy-how-works {
        flex-direction: column;
    }
    .app-my-account__friend-copy-how-works-right {
        margin-top: 2rem;
    }
    .app-my-account__friend-copy-how-works-left  {
        

        span,
        div{
            margin-left: 2rem;
        }

        &-r-text {
            margin-top: 4rem;
        }
    } 
    
    .app-my-account__friend-copy-link-cnt-txt {
        width: 50%;
    }
    .app-my-account__friend-copy-link-cnt>button {
        display: block;
        width: 40%;
        box-sizing: border-box!important;
        margin-right: auto;
        margin-left: 0;
    }
}

@media only screen and (max-width: 600px) {
    .app-my-account__friend-copy-how-works-left,
    .app-my-account__friend-copy-how-works-right{
        width: 100%;
    }
    .app-my-account__page,
    .adresses-grid__cnt,
    .add-edit-address{
        grid-template-columns: 100%!important;
    }
    .adresses-grid__cnt,
    .add-edit-address {
        box-sizing: border-box;
        padding-right: 6rem;
    }
    .app-logged-1 {
        grid-column: 1;
    }
    .app-logged-2 {
        grid-column: 1;
    }
    .app-logged-1 {
        padding-right: 2rem;
    }
    .app-my-account__friend-copy-link-cnt{
        flex-direction: column;
    }
    .app-my-account__friend-copy-link-cnt-txt {
        width: 75%;
    }
    .app-my-account__friend-copy-link-cnt {

        button {
            padding-top:1rem;
            padding-bottom: 1rem;
            margin-top: 1rem;
            width: 75%;
        }
    }
}

.forgot-txt {
    font-family: $medium-main;
    font-size: 1.2rem;
    padding-bottom: 2rem;
    color: rgba(112, 112, 112, 0.933);
}

.app-contact__phone {
    display: flex;
    margin-top: 2rem;
    
    ion-icon {
        font-size: 3rem;
    }

    div {
        display: flex;
        flex-direction: column;
        margin-left: 1rem;

        span {

            &:first-child {
                font-family: $bold-main;
                font-size: 1.4rem;
            }

            &:nth-child(2) {
                font-size: 1.2rem;
                font-family: $light-main;
            }
        }
    }
}

.app-my-account__login-form {
    background: transparent;
}

.err-input {
    border-color: red!important;
}

.password-input {
    padding-right: 4rem!important;
    box-sizing: border-box;
    max-width: 100%;
    position: relative;
}

.pass-image {
    width: 20px;;
    position: absolute;
    right: 10px;
    top:54%;
    cursor: pointer;
}
</style>