<template>
  <div >
    <AppGenderBigSection/>
    <div class="container-brands">
      <AppBrands/>
    </div>
    <div class="container-brands mobile-genders" style="padding:0 20px;">
      <AppGendersMobile/>
    </div>

    <div style="padding:10rem 0;" class="remove-padding">
      <AppSectionHome/>
    </div>
    <div  style="margin-top:4rem;">
      <AppPriority/>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import AppGendersMobile from "../components/AppGendersMobile.vue";

export default {
  name: 'Home',
  components:{
    AppGendersMobile
  },
  beforeMount(){
    if(this.$route.query.token) this.$cookies.set('invitedFrom',this.$route.query.token);
    console.log(this.$route.params)
    this.$store.dispatch('loadHomepage','');
  },
  mounted(){
      // window.fbq('track', 'PageView');
      console.log(this.$store.state)
  }
}
</script>

<style lang="scss" scoped>
div {
  margin: 0;
  padding: 0;
}
.container{
	
	&-gender{
		width: 80%;
		margin: 2rem auto 0 auto;
	}

	&-brands{
		margin-top: 10rem;
	}
}

.mobile-genders{
  display: none;
}

@media only screen and (max-width: 850px) {
  .mobile-genders{
    display: block;
  }
}

@media only screen and (max-width: 450px) {
  .remove-padding {
    padding: 1rem 0!important;
    padding-top: 5rem!important;
  }

  .container-brands {
    margin-top: 5rem;
  }
}
</style>