<template>
    <div class="app-priority" v-if="$store.state.homepage">
        <div class="app-priority__cnt" v-for="(p, index) in $store.state.homepage.columns3" :key="index">
            <img :src="p.icon" alt="icon" @click="goLink(p.link)">
            <span class="app-priority__title" @click="goLink(p.link)">
                {{p.title}}
            </span>
            <span class="app-priority__description" >
                {{p.text}}
            </span>
        </div>
    </div>
</template>

<script>
export default {
    methods:{
        goLink(link) {
            window.location = link; 
        },
    }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/_variables.scss";
@import "../assets/styles/main.scss";
.app-priority {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &__cnt {
        display: flex;
        flex-direction: column;
        width: 32.8%;

        img {
            width: 20%;
            display: block;
            margin: 0 auto;
            cursor: pointer;
        }
    }

    &__title{
        display: block;
        font-family: $bold-main;
        font-size: 2.5rem;
        margin: 2rem auto 0 auto;
        cursor: pointer;
    }

    &__description {
        width: 70%;
        margin: 0 auto;
        display: block;
        font-size: 1.3rem;
        text-align: center;
        font-family: $light-main;
        margin-top: 2rem;
    }
}

@media only screen and (max-width: 850px) {
    .app-priority__cnt{
        width: 100%;
        margin-top: 2rem;

        &:first-child{
            margin-top: 0;
        }

        img {
            width: 10%;
        }
    }
}
</style>