<template>
    <div class="app-newsletter" v-if="$store.state.main">
        <div class="app-newsletter__texts">
            <h4 class="app-newsletter__title" @click="goLink(newsletter.title1_link)"><span>{{newsletter.title1}}</span> {{newsletter.title2}}</h4>
            <span class="app-newsletter__description">{{newsletter.text}}</span>
        </div>
        <div class="app-newsletter__email">
            <div class="app-newsletter__input">
                <input type="text" name="email2" :placeholder="newsletter.textemail" v-model="email">
            </div>
            <button class="app-newsletter__button" @click="sendEmail">{{newsletter.textbutton}}</button>
        </div>
        <notifications position="top center" width="100%" :class="{'notif-position':$store.state.hiddenNav==false}" class="notification-custom"  ></notifications>
    </div>
</template>


<script>
export default {
    data(){
        return {
            email:""
        }
    },
    methods:{
        goLink(link) {
            window.location = link; 
        },
        async sendEmail(){
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (re.test(String(this.email).toLowerCase())){
                gtag('event', 'conversion', {'send_to': 'AW-10800167573/dsqnCKfA15EDEJX19Z0o'});
                this.$notify({ type: 'success', text: 'Îți mulțumim că te-ai abonat la newsletter.' })
                let response = await this.axios.get(`/subscribe?domain=${this.$store.state.domain}&email=${this.email}&useridsession=${this.$store.state.auth.useridsession}&token=${this.$store.state.auth.token}`);
                console.log(response)
                this.email = "";
            }
            else {
                if(this.email == '') this.$notify({ type: 'error',  text: '<i class="bx bx-x close-notif"></i>Te rugam sa introduci o adresa de email'})
                else
                    this.$notify({ type: 'error', text: '<i class="bx bx-x close-notif"></i>Adresa de email este invalida. Te rugam sa incerci din nou' })
            }
        }
    },
    computed:{
        newsletter(){
            if(this.$store.state.main) return this.$store.state.main.footernewsletter;
            else return ""
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/_variables.scss";
@import "../assets/styles/main.scss";
.app-newsletter{
    width: 100%;
    background:$color-gray-light;
    display: flex;
    padding: 8rem 10rem; 
    box-sizing: border-box;

    &__texts {
        display: flex;
        flex-direction: column;
        width: 40%;
    }

    &__title {
        font-family: $bold-main;
        font-size: 3rem;
        cursor: pointer; 

        span {
            background: #ffcd00;
            padding: .3rem;
        }
    }

    &__description {
      font-family: $medium-main;  
      font-size: 1.5rem;
      margin-top: 4rem;
    }

    &__email {
        display: flex;
        flex-direction: column;
        width: 50%;
        margin-left: auto;
        
    }

    &__input {
         box-sizing: border-box;
         padding-bottom: 3rem;

        input {
            width: 80%;
            outline: none;
            border: none;
            border-bottom: 1px solid black;
            background: url("../assets/images/message.png") no-repeat scroll;
            padding-left:50px;
            padding-bottom: 1rem;
            font-size: 1.6rem;
            background-position-y: -6px ;
            font-family: $light-main;
            font-size: 16px;;
        }
    }

    &__button {
        box-sizing: border-box;
        display: block;
        font-family: $light-main;
        color: $color-white;
        background: $color-black;
        border: none;
        outline: none;
        font-size: 1.4rem;
        padding: 1rem 4rem;
        width: 30%;
        cursor: pointer;
    }
}

@media only screen and (max-width: 850px) {
    .app-newsletter{
        flex-direction: column;
        justify-content: center;
    }
    .app-newsletter__texts,
    .app-newsletter__email{
        margin: 0 auto;
        width: 100%;
    }
    .app-newsletter__email{
        margin-top: 5rem;
    }
}

@media only screen and (max-width: 550px) {
.app-newsletter{
    padding: 8rem 2rem;
}
.app-newsletter__button{
    width: 50%;
}
}

.notification-content {
    font-size: 2rem!important;
}
</style>