<template>
  <div class="mobile-slider">
    <VueSlickCarousel v-bind="settings">
      <div v-for="(i, index) in images" :key="index">
        <img :src="i" alt="">
      </div>
</VueSlickCarousel>
  </div>
</template>

  

<script>
  import VueSlickCarousel from 'vue-slick-carousel'
  import 'vue-slick-carousel/dist/vue-slick-carousel.css'
 import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
  components: { VueSlickCarousel },
    props:{
        images:{
            type:Array,
            default:()=>{return []}
        }
    },
    data(){
      return {
        settings:{
          "dots": true,
          "edgeFriction": 0.35,
          "infinite": true,
          "speed": 500,
          "slidesToShow": 1,
          "slidesToScroll": 1,
          arrows:false
        }
      }
    },
  methods:{
    //   openBig(index) {
    //       this.$emit("openBig", index);
    //   }
  }
};
</script>

<style lang="scss" scoped>
img {
  max-width: 100vw;
}
.mobile-slider {
  display: none;
}
@media only screen and (max-width: 500px) {
    .mobile-slider {
        display: block;
        height: 100%;  
    }
}
</style>