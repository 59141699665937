<template>
    <div class="app-newsletter" v-if="$store.state.reclamatii">
        <div class="wrapper top-space" v-html="$store.state.reclamatii.content"></div>
    </div>
</template>


<script>
export default {
    beforeMount(){
        this.$store.dispatch("loadReclamatii")
    }
}
</script>