import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'

Vue.config.productionTip = false


import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

import vueHeadful from 'vue-headful';
Vue.component('vue-headful', vueHeadful);

import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)
axios.defaults.baseURL = 'https://api.dmgroup.ro';


import  AppSaleTop from "./components/AppSaleTop.vue"
Vue.component("AppSaleTop", AppSaleTop);

import  AppInfoBar from "./components/AppInfoBar.vue"
Vue.component("AppInfoBar", AppInfoBar);

import  AppNavbar from "./components/AppNavbar.vue"
Vue.component("AppNavbar", AppNavbar);

import  AppTypes from "./components/AppTypes.vue"
Vue.component("AppTypes", AppTypes);

import  AppNewsLetter from "./components/AppNewsLetter.vue"
Vue.component("AppNewsLetter", AppNewsLetter);

import  AppAnswearBar from "./components/AppAnswearBar.vue"
Vue.component("AppAnswearBar", AppAnswearBar);

import  AppFooter from "./components/AppFooter.vue"
Vue.component("AppFooter", AppFooter);

import  AppBrands from "./components/AppBrands.vue"
Vue.component("AppBrands", AppBrands);

import  AppPriority from "./components/AppPriority.vue"
Vue.component("AppPriority", AppPriority);

import  AppSectionHome from "./components/AppSectionHome.vue"
Vue.component("AppSectionHome", AppSectionHome);

import  AppProductEl from "./components/AppProductEl.vue"
Vue.component("AppProductEl", AppProductEl);

import  AppGenderBigSection from "./components/AppGenderBigSection.vue"
Vue.component("AppGenderBigSection", AppGenderBigSection);

import  AppProduct from "./components/AppProduct.vue"
Vue.component("AppProduct", AppProduct);

import  AppSimilarProducts from "./components/AppSimilarProducts.vue"
Vue.component("AppSimilarProducts", AppSimilarProducts);

import  AppCartProd from "./components/AppCartProd.vue"
Vue.component("AppCartProd", AppCartProd);

import  AppCartSummary from "./components/AppCartSummary.vue"
Vue.component("AppCartSummary", AppCartSummary);

import  AppCartPriority from "./components/AppCartPriority.vue"
Vue.component("AppCartPriority", AppCartPriority);

import  AppCartSummaryv2 from "./components/AppCartSummaryv2.vue"
Vue.component("AppCartSummaryv2", AppCartSummaryv2);

import  AppCheckoutSteps from "./components/AppCheckoutSteps.vue"
Vue.component("AppCheckoutSteps", AppCheckoutSteps);

import AppPagination from "./components/AppPagination.vue";
Vue.component( "app-pagination",AppPagination)

import AppSizesW from "./components/AppSizesW.vue";
Vue.component( "AppSizesW",AppSizesW)

import Notifications from 'vue-notification'
Vue.use(Notifications)

import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

import PinchZoom from 'vue-pinch-zoom';
Vue.component('pinch-zoom', PinchZoom);

import AppLoader from "./components/AppLoader.vue"
Vue.component("AppLoader",AppLoader)

import AppModal from "./components/AppModal.vue"
Vue.component("AppModal",AppModal)

import AppFilterD from "./components/AppFilterD.vue"
Vue.component("AppFilterD", AppFilterD);

import Cleave from 'vue-cleave-component';
Vue.use(Cleave)

// import Sticky from 'vue-sticky-directive'
// Vue.use(Sticky)

import 'boxicons/css/boxicons.min.css'

import cookies from 'js-cookie'
if(window.innerWidth  <= 800 && !cookies.get('gender')) {
  cookies.set('gender', 1)
}



import VueZoomer from 'vue-zoomer'
Vue.use(VueZoomer)

import ZoomOnHover from "vue-zoom-on-hover";
Vue.use(ZoomOnHover);


import Sticky from 'vue-sticky-directive'
Vue.use(Sticky)

// Setting up the favicon 
import $ from 'jquery';
async function setFavicon(){
  const response = await axios.get(`/main?domain=designmall.ro&token=qm2dhinis87le5lh2j9vqyshidqb8z&useridsession=104&route=/&c1=4`);
  $("link[rel*='icon']").attr("href", response.data.faviconimage);
  $("#favicon").attr("href",response.data.faviconimage);
}
setFavicon();

// import VueGtag from "vue-gtag";
// Vue.use(VueGtag, {
//   config: { id: "G-DECQ48TNBM" }
// });

import VueMeta from 'vue-meta'

import './registerServiceWorker'
Vue.use(VueMeta)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
