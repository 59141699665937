<template>
    <VueRecaptcha 
        id="g-recaptcha"
        @verify="onVerify"
        ref="recaptcha" 
        sitekey="6Ld6x-kdAAAAAAyuhl62pqCfdb67R1y3q0BOdpb2" 
    /> 
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';
export default {
    components: { VueRecaptcha }, 
    methods:{
        onVerify(response){
            this.$emit("onVerify", response);
        },
    }
}
</script>