<template>
    <div class="app-cart-prod" v-if="product">
        <div class="app-cart-prod__img figure" @click="goLink(product.product_link)">
            <img oncontextmenu="return false" :src="product.product_image" alt="product_image">
            <img class="image-hover" oncontextmenu="return false" :src="product.picture_hover" alt="hover-picture">
        </div>
        <div class="app-cart-prod__info">
            <div class="app-cart-prod__info-title" @click="goLink(product.product_link)">
                <span class="noselect" :class="{'line-th':product.instock==false}">{{product.product_name}}</span>
                <span v-if="product.instock==false"> &nbsp;Stoc epuizat</span>
            </div>
            <div class="app-cart-prod__stats">
                <div class="app-cart-prod__stats-el">
                    <span class="app-cart-prod__stats-el-title">
                        Marime:
                    </span>
                    <span class="app-cart-prod__stats-el-description">
                        {{product.product_size}}
                    </span>
                </div>
                <div class="app-cart-prod__stats-el">
                    <span class="app-cart-prod__stats-el-title">
                        Cantitate:
                    </span>
                    <span class="app-cart-prod__stats-el-description">
                        <div v-if="simple" :class="{'hide-el-vis':product.product_quantity==1}" @click="addremoveElement(-1)"><ion-icon name="remove-outline"></ion-icon></div>
                            <p :class="{centered:simple==false}">{{product.product_quantity}}</p>
                        <div v-if="simple" :class="{'hide-el-vis':product.product_quantity==9||product.product_quantity==product.max_quantity}" @click="addremoveElement(1)"><ion-icon name="add-outline"></ion-icon></div>
                    </span>
                </div>
                <div class="app-cart-prod__stats-el">
                    <span class="app-cart-prod__stats-el-title app-cart-prod__stats-el-title--price">
                        Preț:
                    </span>
                    <span class="app-cart-prod__stats-el-description">
                        {{product.product_discount}} 
                    </span>
                    <span class="app-cart-prod__stats-el-description--discount" v-if="product.showdiscount">
                        {{product.product_price}}  
                    </span>
                </div>
                <div class="app-cart-prod__stats-el">
                    <span class="app-cart-prod__stats-el-title">
                        Total:
                    </span>
                    <span class="app-cart-prod__stats-el-description">
                        {{product.product_total}} 
                    </span>
                </div>
            </div>
            <div class="app-cart-prod__actions" v-if="simple&&retur==false">
                <span :class="{'hideW':hideW}" @click="addWishlist">
                    <ion-icon name="heart-outline"></ion-icon>
                        <span>Adaugă la wishlist</span>
                </span>
                <span @click="deleteCartProd">
                    <ion-icon name="trash-outline"></ion-icon>
                        <span>Șterge</span>
                </span>
            </div>
            <div class="retur-motiv" v-if="retur">
                <!-- <textarea v-model="reason" :class="{'textarea-err':reason_err!='trimis'&&reason_err!=false}" placeholder="Te rog sa ne comunici de ce vrei sa returnezi acest produs"></textarea> -->
                <select v-model="reason" :class="{'no-reason':reason_err==true}">
                    <option value="" disabled selected>Selecteaza motivul returului</option>
                    <option :value="m" v-for="(m,index) in product.motive_retur" :key="index">
                        {{m}} 
                    </option>
                </select> 
                <button @click="addRetur" :class="{'added-ss':true}">{{text_btn}}</button>
            </div>
            <!-- <span style="margin-top:5rem;margin-left:auto;" v-if="product.buy_time">{{product.buy_time}}</span>
            <span style="margin-top:5rem;margin-left:auto;" >{{product.comanda_product}}</span> -->
        </div>
        <notifications position="top center" width="100%" class="notification-custom"/>
    </div>
</template>

<script>
// import func from 'vue-editor-bridge';
export default {
    data(){
        return {
            hideW:false,
            reason:"",
            reason_err:false,
            text_btn:"Adauga la retur"
        }
    },
    props:{
        product: {
            type:Object
        },
        simple:{
            type:Boolean,
            default:true
        },
        retur :{
            type:Boolean,
            default:false
        }
    }, 
    methods:{
        goLink(link) {
            this.$router.push(link)
        },
        addRetur(){
            if(this.text_btn == "Adauga la retur"){
                if(this.reason=='') {
                    this.reason_err = true;
                    this.$notify({ type: "error", text: '<i class="bx bx-x close-notif"></i>Te rugam sa ne spui de ce vrei sa returnezi acest produs' })
                }
                else
                {   this.reason_err = 'trimis';
                    this.text_btn = "Sterge"
                    this.$emit('addRetur','add',this.product.product_ID,this.product.product_quantity,this.reason, this.product.product_size, this.product.comanda_product)
                    
                }
            }
            else {
               
                this.$emit('addRetur','remove',this.product.product_ID,this.product.product_quantity,this.reason, this.product.product_size, this.product.comanda_product)
                this.reason = ""
                this.text_btn = "Adauga la retur";
                this.reason_err = false     
                
            }
        },
        async addremoveElement(i) {
            const voucher = this.$cookies.get('voucher');
            let quantity = Number(this.product.product_quantity);
            quantity += i;
            if(this.$cookies.get("token")&&this.$cookies.get("useridsession")&&quantity > 0 && quantity < 10){
                const productsGet = await this.axios.get(`/checkout?domain=${this.$store.state.domain}&token=${this.$store.state.auth.token}&useridsession=${this.$store.state.auth.useridsession}&updateproduct_id=${this.product.product_ID}&updatenumber=${i}&updatesize=${this.product.product_size}&cachevoucher=${voucher}`);
                this.$store.commit('SET_CART_PRODS', productsGet.data);
            }
            else if(quantity > 0 && quantity < 10) {
                let products = this.$cookies.get('cart');
                if(products)
                products = JSON.parse(products)
                const index = products.findIndex(product => product.product_id === this.product.product_ID && this.product.product_size == product.size);
                products[index].number = quantity;
                this.$cookies.set('cart', JSON.stringify(products));
                const productsGet = await this.axios.get(`/checkout?domain=${this.$store.state.domain}&products=${JSON.stringify(products)}&token=${this.$store.state.auth.token}&useridsession=${this.$store.state.auth.useridsession}&cachevoucher=${voucher}`);
             this.$store.commit('SET_CART_PRODS', productsGet.data);
            }

            if(this.retur == true) {
                let index=null;
                if(quantity <= this.product.max_quantity&&quantity>=1) 
                    index = this.$store.state.retururi.cereriretur.findIndex(product => product.product_ID === this.product.product_ID && this.product.product_size == product.product_size);
                if(index!=null)
                this.$store.state.retururi.cereriretur[index].product_quantity = quantity; 
                if(quantity<1) this.$notify({ type: 'error', text: "Trebuie sa returnezi cel putin un produs"})
                if(quantity > this.product.max_quantity) this.$notify({ type: 'error', text: '<i class="bx bx-x close-notif"></i>Nu poti returna mai multe produse decat ai cumparat'})
            }
        },
        async deleteCartProd(){ 
            // if(!this.$store.state.auth.success) {
                if(this.$cookies.get("token")&&this.$cookies.get("useridsession")){
                    this.$cookies.remove("cart");
                    const productsGet = await this.axios.get(`/checkout?domain=${this.$store.state.domain}&token=${this.$store.state.auth.token}&useridsession=${this.$store.state.auth.useridsession}&detelefromcart=${this.product.product_ID}&deletefromcartthissize=${this.product.product_size}`);
                    console.log(productsGet)
                    this.$store.commit('SET_CART_PRODS', productsGet.data);
                    this.$notify({ type: 'success', text: '<i class="bx bx-x close-notif"></i>Produsul a fost scos din coș' })
                    if(this.$cookies.get("token")&&this.$cookies.get("useridsession")){
                        this.$store.dispatch("loadCart", this.$route);
                    } 
               }
                else {
                    let products = this.$cookies.get('cart');
                    console.log(JSON.parse(products));
                    if(products.length)
                        products = JSON.parse(products)
                    const index = products.findIndex(product => product.product_id === this.product.product_ID && this.product.product_size == product.size);
                    products.splice(index, 1);
                    if(products.length == 0) this.$cookies.remove('cart')
                    else this.$cookies.set('cart', JSON.stringify(products));
                    if(products.length != 0) products = JSON.stringify(products)
                    const productsGet = await this.axios.get(`/checkout?domain=${this.$store.state.domain}&products=${products}&token=${this.$store.state.auth.token}&useridsession=${this.$store.state.auth.useridsession}&detelefromcart=${this.product.product_ID}&deletefromcartthissize=${this.product.product_size}`);
                    this.$store.commit('SET_CART_PRODS', productsGet.data);
                    console.log(productsGet)
                    if(products.length==0)
                    this.$store.commit('SET_CARTL', products.length);
                    else this.$store.commit('SET_CARTL', JSON.parse(products).length);
                    this.$notify({ type: 'success', text: '<i class="bx bx-x close-notif"></i>Produsul a fost scos din coș' })
                }
                if(this.$cookies.get("token")&&this.$cookies.get("useridsession")) {
                    this.$store.dispatch("loadWishlist",this.$route);
                }
        },
        addWishlist(){
            this.$store.dispatch("updateLive",this.product.product_ID);
            console.log(this.$cookies.get('wishlist'))
            const product_id = this.product.product_ID;
            let wishlist = this.$cookies.get('wishlist');
            wishlist = JSON.parse(wishlist);
            if(!wishlist) { wishlist = []; }
            const found = wishlist.find(product => product === product_id);
            if(found === undefined) {
                wishlist.push(product_id);
                this.$cookies.set('wishlist', JSON.stringify(wishlist));
                wishlist = this.$cookies.get('wishlist');
                this.hideW = true;
                this.$notify({ type: 'success', text: '<i class="bx bx-x close-notif"></i>Produsul a fost adaugat cu succes la favorite.' })
                if(this.$cookies.get("token")&&this.$cookies.get("useridsession")) {
                    this.$store.dispatch("loadWishlist",this.$route);
                }
                else {
                    if(this.$cookies.get('wishlist')) this.$store.commit("SET_WLENGTH",JSON.parse(this.$cookies.get('wishlist')).length)
                    else this.$store.commit("SET_WLENGTH",0)
                }
            }
            console.log(this.$cookies.get('wishlist'))
        }
    },
    watch:{
        "reason":function () {
            this.reason_err = false;
        },
        '$store.state.retururi':function(){ 
            this.reason = ""
            this.text_btn = "Adauga la retur";
            this.reason_err = false    
        },
        "$store.state.cart_prods.checkout.products":function (){
            if(this.$cookies.get("token")&&this.$cookies.get("useridsession")){
                this.hideW = this.product.inwishlist;
            }
            else {
                this.hideW = this.product.inwishlist;
                let wishlist = this.$cookies.get('wishlist');
                wishlist = JSON.parse(wishlist)
                let found;
                if(wishlist) found = wishlist.find(element => element == this.product.product_ID);
                if(found) this.hideW = true;
            }
        }
    },
    beforeMount(){
        console.log(this.product)
        if(this.$cookies.get("token")&&this.$cookies.get("useridsession")){
            this.hideW = this.product.inwishlist;
        }
        else {
            let wishlist = this.$cookies.get('wishlist');
            wishlist = JSON.parse(wishlist)
            let found;
            if(wishlist) found = wishlist.find(element => element == this.product.product_ID);
            if(found) this.hideW = true;
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/_variables.scss";
@import "../assets/styles/main.scss";
.app-cart-prod {
    display: flex;
    flex-direction: row;
    width: 100%;

    &__img {
        cursor: pointer;
        width:20rem;

        img {
            width: 100%;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        margin-left: 3rem;
        width: 100%;

        &-title {
            font-family: $semibold-main;
            font-size: 1.6rem;
            cursor: pointer;
        }
    }

    &__stats {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;

        &-el{
            margin-top: 5rem;
            display: flex;
            flex-direction: column;

            &-title {
                font-family: $medium-main;
                display: block;
                font-size: 1.5rem;
            }

            &-description {
                font-family: $bold-main;
                font-size: 1.5rem;
                display: flex;
                margin-top: 2rem;

                &--discount{
                    font-family: $semibold-main;
                    font-size: 1.5rem;
                    color: rgb(122, 122, 122);
                    text-decoration: line-through;
                }

                div {
                    border: 1px solid $color-black;
                    border-radius: 50%;
                    width: 2rem;
                    height: 2rem;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    cursor: pointer;
      
                    
                    ion-icon{
                        transform: translateY(2px);
                    }
                }
                
                p{
                    padding: 0 1.62rem;
                }
            }
        }
    }

    &__actions {
        display: flex;
        margin-top: 5rem;
        margin-left: auto;
        

        span {
            cursor: pointer;
            font-size: 1.4rem;
            font-family: $light-main;
            display: flex;

            &:first-child {
                margin-left: 0;
                padding-left: 1rem;
            }

            span {
                display: block;
                margin-top: auto;
                transform: translateY(15%);
                

                &:last-child{
                    margin-right: 30px;
                }
            }

            ion-icon {
                transform: translateY(5px);
                border: 1px solid $color-black;
                padding: .3rem;
                border-radius: 50%;
                margin-right: 5px;
            }
        }
    }

}

.app-cart-prod__info-title {

    span {

        &:first-child {

        }

        &:nth-child(2) {
            color: red;
        }
    }
}

@media only screen and (max-width: 700px) {
    .app-cart-prod__stats{
        flex-direction: column;
    }
    .app-cart-prod__stats-el{
        margin-top: 2rem;
        flex-direction: row;

        &:nth-child(3) {
            flex-wrap: wrap!important;
        }
    }
    .app-cart-prod__stats-el-description {
        margin: 0;
        margin-left: 1.8rem;
    }
    .app-cart-prod__stats-el-description--discount {
        margin-left: 1rem;
        display: block;
        margin-left: 43px;
    }

    .app-cart-prod__actions {
        margin-top: 2rem;
    }
}

@media only screen and (max-width: 360px) {
    .app-cart-prod__img {
        img {
            width:100%;
        }
    }
}
.centered {
    margin: auto!important;
}

@media only screen and (max-width: 550px){
    .app-cart-prod__info-title{
        font-size: 1.4rem!important;
        
    }
    .app-cart-prod__stats-el>span {
        font-size: 1.3rem!important;
    }
    .app-cart-prod__stats-el-title{
        width: 60px
    }
    .app-cart-prod__actions>span>span {
        font-size: 1rem;
    }
    .app-cart-prod__actions {
        width: 200%;
        transform: translateX(-10px);
    }
    .app-cart-prod__stats-el-description--discount{
        margin-left: 70px;
    }

    .centered {
        padding: 0!important;
    }
    .app-cart-prod__stats-el-description {
        margin-left: 10px;
    }
    .app-cart-prod__info{
        margin-left: 1rem;
    }
}


@media only screen and (max-width: 340px){
    .app-cart-prod__stats-el-title--price {
        width: 25px;;
    }
    .app-cart-prod__stats-el-description--discount {
        margin-left: 35px;
    }
}


.hideW {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
}

.retur-motiv {
    width: 100%;
    margin-top: 2rem;
    display: flex;
    flex-direction: row;

    select {
        border: 1.5px solid rgb(207, 207, 207);
        background: transparent;
        outline: none;
        font-family: $medium-main;
        padding: 1rem;
        margin-top: 1rem;
        -webkit-appearance: none;
        padding-top:15px;
        padding-bottom:15px; 
        cursor: pointer;

        &:focus {
            border: 2px solid $color-black;
        }
    }

    textarea {
        width: 100%;
        height: 80px;
        resize: none;
        outline: none;
        box-sizing: border-box;
        padding: 1rem;
        font-family: $light-main;
        font-size: 13px!important;   
        box-sizing: border-box;  
    } 

    button {
        outline: none;
        border: 1px solid $color-black;
        font-family: $semibold-main;
        padding: 1rem 3rem;
        margin-left: auto;
        margin-top:1rem;
    }
}

.textarea-err {
    border: 1px solid red;
}

.added-ss {
    background: black;
    color: $color-white;
}
.line-th {
    text-decoration: line-through;
    color: rgb(167, 167, 167);
}

.figure {
    position:relative;
}
.image-hover {
     position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      object-fit: contain;
      opacity: 0;
      transition: opacity .2s;
      max-height: 100%;
}

 .figure:hover img.image-hover {
      opacity: 1;
    }
    .hide-el-vis {
        visibility: hidden;
        opacity: 0;
    }

    .no-reason {
        border-color: red!important;
    }

@media only screen and (max-width: 800px) {
    .retur-motiv {
        flex-direction: column;
    }
}
</style>