<template>
    <div class="app-newsletter" v-if="$store.state.tc">
        <div class="wrapper top-space" v-html="$store.state.tc.content"></div>


        <!-- <input type="text" id="testx" >
        <div  class="actual-month">testttt</div> -->
        <!-- <div id="testar">dfdf</div> -->
    </div>
</template>


<script>
import $ from 'jquery'
export default {
	beforeMount(){
        this.$store.dispatch("loadTermeni")
    },
    watch:{
        '$store.state.tc':function() {
            setTimeout(()=>{
                if(this.$route.params.type=='plata'){
                    let targetOffset = $('#plata').offset().top;
                    $('html, body').animate({scrollTop: targetOffset}, 100);
                }
            }, 200)
        }
    }
}
</script>