<template>
    <div v-if="$store.state.browse" >
        <vue-ads-pagination
            :total-items="maxPages*10"
            :max-visible-pages="maxV"
            :page="page"
            :loading="loading"
            @page-change="pageChange"
            @range-change="rangeChange"
        >
            <template slot-scope="props">
                <div class="vue-ads-pr-2 vue-ads-leading-loose">
                    Items {{ props.start }} tot {{ props.end }} van de {{ props.total }}
                </div>
            </template>
            <template
                slot="buttons"
                slot-scope="props"
            >
                <vue-ads-page-button
                    v-for="(button, key) in props.buttons"
                    :key="key"
                    v-bind="button"
                    @page-change="pageChange(button.page)"
                />
            </template>
        </vue-ads-pagination>
    </div>
</template>

<script>
import '../../node_modules/@fortawesome/fontawesome-free/css/all.css';
import '../../node_modules/vue-ads-pagination/dist/vue-ads-pagination.css';

import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';

export default {
    name: 'App',
    
    components: {
        VueAdsPagination,
        VueAdsPageButton,
    },

    data () {
        return {
            loading: false,
            page: this.$store.state.browse.pagination.currentpage-1,
            maxV:5,
            maxPages:this.$store.state.browse.pagination.numarmaxim
        };
    },

    methods: {
        pageChange (page) {
            this.page = page;
            let route = '';
            route = this.$route.fullPath;
            if(this.$route.fullPath.indexOf('&pagina=') >= 0){
                route = route.replace('&pagina='+this.$store.state.browse.pagination.currentpage, "");
            }
            // console.log(this.$route.fullPath.indexOf('&pagina='))
            let p = this.page+1;
            window.location = route + '&pagina=' + p
        },
        
        rangeChange (start, end) {
            console.log(start, end);
        },
    },
    watch:{
        page(){
            if(this.page > 100) this.maxV = 1
        },
        "$store.state.browse.pagination.numarmaxim":function() {
            this.maxPages = this.$store.state.browse.pagination.numarmaxim;
        },
        // "$store.state.browse.pagination.currentpage":function() {
        //     this.page = this.$store.state.browse.pagination.currentpage+1;
        // },
        "$route.params.type": function() {
            let index = this.$route.params.type.indexOf("&pagina=");
            if(index==-1) {
                this.page = 0;
            }
            else {
                let str = this.$route.params.type.split('&');
                str.forEach(item => {
                        let cpy = item.split('=');
                        if(cpy[0]=='pagina') {
                            this.page = cpy[1]-1
                        }
                    });
            }
        //     if(index!=-1){
        //         let str = this.$route.params.type.split('&');
        //         str.forEach(item => {
        //             let cpy = item.split('=');
        //             if(cpy[0]=='pagina') {
        //                 item = "";
        //             }
        //             if(item!="")
        //             r = r + item + '&';
        //         });
        //         this.$store.commit("SET_CURR_PAGE", 0);
        //         r = r.slice(0, -1)
        //  }
        }
    },
    beforeMount(){
        if(this.$route.fullPath.split('pagina=')[1] > 99) this.maxV = 3
    }
};
</script>


<style lang="scss">
.vue-ads-leading-loose {
    display: none;
}


.vue-ads-flex {
    display: flex;
    // justify-content: center;
    max-width: 100vw;
}

.vue-ads-w-6 {
    font-size: 1.2rem;
    margin: 1rem;
    width: auto!important;
    font-family: $medium-main;
}

.vue-ads-bg-teal-500 {
    background: none;
    color: black;
    font-weight: bold;
}

@media only screen and (max-width: 650px){
    .vue-ads-justify-end {
        justify-content: center;
    }
}
</style>