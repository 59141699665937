<template>
    <div class="app-sizesw" v-if="$store.state.w_opened&&$store.state.w_opened.sizes[0].value.size!='One Size'">
        <div class="app-sizesw__main">
            <div class="app-sizesw__main-top">
                <div class="app-sizesw__main-title">
                    Selecteaza marimea  
                </div>
                <ion-icon name="close-outline" @click="closeSizes"></ion-icon>
            </div>
            <div class="app-sizesw__main-templates">
                <div class="app-sizesw__main-template" v-for="(x,index) in $store.state.w_opened.sizes" :key="index" @click="x.selected=!x.selected">
                    <span class="filter-icon" :class="{'filter-icon--active':x.selected}">&nbsp;</span>
                    <span class="app-sizesw__main-template-size">{{x.value.size}}</span>
                    <span class="app-sizesw__main-template-price">{{$store.state.w_opened.price}}</span>
                </div>
            </div>
            <button @click="addManyCart">{{btnText}}</button>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
export default {
    data(){
        return {
            sizes:null
        }
    },
    computed:{
        btnText() {
            let find = this.$store.state.w_opened.sizes.filter(el=>el.selected==true);
            if(!find.length) return 'Inchide';
            else return "Adauga in cos";
        }
    },
    methods:{
        closeSizes(){
            this.$store.commit("SET_WSIZES",null);
            $('html, body').css({
                overflow: 'auto',
                height: 'auto'
            });
        },
        addSizes(size){
            this.sizes.push(size);
        },
        addManyCart(){
            // window.fbq('track', 'AddToCart', {
            //     content_ids: [this.$store.state.w_opened.id],
            //     content_type: 'product'
            // });
            if(this.btnText==='Inchide') {this.closeSizes();return;}
            console.log(this.$cookies.get("cart")) 
            let cart = this.$cookies.get("cart");
            if(!cart) {
                cart = [];
                for(let i = 0; i < this.$store.state.w_opened.sizes.length;i++){
                    if(this.$store.state.w_opened.sizes[i].selected){
                        cart.push({
                            product_id:this.$store.state.w_opened.id,
                            size:this.$store.state.w_opened.sizes[i].value.size,
                            number:1
                        })
                    }
                }
                this.$cookies.set('cart', JSON.stringify(cart));
                console.log(this.$cookies.get("cart"))
                this.$store.commit("SET_WSIZES",null);
                $('html, body').css({
                    overflow: 'auto',
                    height: 'auto'
                });
                gtag('event', 'conversion', {'send_to': 'AW-10800167573/_1KkCO6sn5EDEJX19Z0o'});
                this.$notify({ type: 'success', text: '<i class="bx bx-x close-notif"></i>Produsul a fost adaugat cu succes in cos' })
                this.$store.commit('SET_CARTL', JSON.parse(this.$cookies.get("cart")).length);
                if(this.$cookies.get("token")&&this.$cookies.get("useridsession")){
                    this.$store.dispatch("loadCart", this.$route);
                }    
            }
            else {
                let cart = JSON.parse(this.$cookies.get("cart"));
                for(let i = 0; i < this.$store.state.w_opened.sizes.length;i++){
                    if(this.$store.state.w_opened.sizes[i].selected){
                        let found = cart.find(product => product.product_id === this.$store.state.w_opened.id && product.size == this.$store.state.w_opened.sizes[i].value.size);
                        if(!found) {
                            cart.push({
                                product_id:this.$store.state.w_opened.id,
                                size:this.$store.state.w_opened.sizes[i].value.size,
                                number:1
                            })
                        }
                    }
                }
                this.$cookies.set('cart', JSON.stringify(cart));
                console.log(this.$cookies.get("cart"))
                this.$store.commit("SET_WSIZES",null);
                $('html, body').css({
                    overflow: 'auto',
                    height: 'auto'
                });
                this.$store.commit('SET_CARTL', JSON.parse(this.$cookies.get("cart")).length);
                this.$notify({ type: 'success', text: '<i class="bx bx-x close-notif"></i>Produsul a fost adaugat cu succes in cos' })
                if(this.$cookies.get("token")&&this.$cookies.get("useridsession")){
                    this.$store.dispatch("loadCart", this.$route);
                }    
            }
        }
    },
    watch:{
        '$store.state.w_opened':function(){
          if(this.$store.state.w_opened.sizes[0].value.size=='One Size') {
              this.$store.state.w_opened.sizes[0].selected = true;
              this.addManyCart()
          }
        }
    }
}
</script>

<style lang="scss" scoped>
.app-sizesw{
    z-index: 9999999999999999999999999999999;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.459);
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;

    &__main {
        margin: auto;
        width: 600px;
        max-width: 600px;
        background: $color-white;
        padding-bottom: 2rem;
        height: 80vh;
        max-height: 80vh;
        position: relative;
        // overflow: auto;
        
        &-top {
            // -webkit-box-shadow: 0px 11px 16px -11px rgba(0,0,0,0.75);
            // -moz-box-shadow: 0px 11px 16px -11px rgba(0,0,0,0.75);
            // box-shadow: 0px 11px 16px -11px rgba(0,0,0,0.75);
            display: flex;
            padding: 2rem;
            border-bottom: 1px solid rgb(141, 141, 141);

            ion-icon {
                display: block;
                margin-left: auto;
                margin-top: auto;
                margin-bottom: auto;
                font-size: 3rem;
                font-weight: bold;
                cursor: pointer;
            }
        }

        &-title {
            font-family: $semibold-main;
            font-size: 2rem;
            
        }

        &-templates {
            height:calc(100% - 220px);
            overflow: auto;
            box-sizing: border-box;
            padding-bottom: 100px;
        }

        &-template {
            display: flex;
            padding: 1.5rem 2rem;
            border-bottom: 1px solid rgb(141, 141, 141);
            cursor: pointer;

            span {
                display: block;
                font-size: 1.7rem;
                margin-top: auto;
                margin-bottom: auto;
            }

            &-check {
                display: block;
                width: 15px;
                height: 15px;
                border: 1px solid black;
                box-sizing: border-box;
                margin-top: auto;
                margin-bottom: auto;
                position: relative;

                div {
                    position: absolute;
                    background: black;
                    width: 9px;
                    height: 9px;
                    margin: auto;
                    top:6px;
                    left: 7px;;
                    transform:translateY(-50%) translateX(-50%);
                }
            }

            &-price {
                margin-left: auto;
            }

            &-size {
                margin-left: 1rem;
            }
        }

        button {
            
            display: block;
            width: calc(100% - 4rem);
            margin-left: auto;
            margin-right: auto;
            margin-top: 2rem;
            background: #000000;
            color: $color-white;
            padding: 1.5rem;
            font-family: $semibold-main;
            font-size: 1.5rem;
            // bottom: 10px;
            // left: 50%;
            // transform: translateX(-50%);


        }
    }
}

@media only screen and (max-width: 650px){
    .app-sizesw__main {
        max-width: 99vw;
        max-height: 99vh;
        width: 100vw;
        height: 100vh;
    }

    button {
        position: fixed;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
    }
}

.filter-icon {
    width: 9px;
    height: 9px;
    overflow: hidden;
    float: left;
    display: block;
    margin-top: 4px;
    white-space: nowrap;
    outline: 1px solid #000;
    outline-offset: 1px;

    &--active {
        background: black;
    }
}
</style>