<template>
    <div class="app-newsletter" v-if="$store.state.retur">
        <div class="wrapper top-space" v-html="$store.state.retur.content"></div>
    </div>
</template>


<script>
export default {
    beforeMount(){
        this.$store.dispatch("loadRetur")
    }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/_variables.scss";
@import "../assets/styles/main.scss";
</style>