<template>
    <div class="app-footer" v-if="$store.state.main&&$store.state.main.footer">
        <div class="app-footer__sections">
            <div class="app-footer__container">
                <span class="app-footer__container-title" >
                    {{footer.footerup[0].title}} 
                </span>
                <ul>
                    <li v-for="(el, index) in footer.footerup[0].submenu" :key="index">
                        <a :href="el.link" class="app-footer__link">{{el.text}}</a>
                    </li>
                </ul>
            </div>
            <div class="app-footer__container">
                <span class="app-footer__container-title">
                    {{footer.footerup[1].title}}
                </span>
                <ul>
                    <li v-for="(el, index) in footer.footerup[1].submenu" :key="index"> 
                        <a :href="el.link" class="app-footer__link">{{el.text}}</a>
                    </li>
                </ul>
                <div class="app-footer__images">
                    <img v-for="(im, index) in footer.footerup[1].images" :key="index" @click="goLink(im.link)" :src="im.image" alt="curier">
                </div>
            </div>
            <div class="app-footer__container">
                <span class="app-footer__container-title">
                    {{footer.footerup[2].title}}
                </span>
                <ul>
                    <li v-for="(el, index) in footer.footerup[2].submenu" :key="index">
                        <a :href="el.link" class="app-footer__link">{{el.text}}</a>
                    </li>
                </ul>
                <div class="app-footer__images">
                    <img v-for="(im, index) in footer.footerup[2].images" :key="index" @click="goLink(im.link)"  :src="im.image" alt="payment">
                </div>
            </div>
            <div class="app-footer__container">
                <span class="app-footer__container-title">
                   {{footer.footerup[3].title}} 
                </span>
                <ul>
                   <li v-for="(el, index) in footer.footerup[3].submenu" @click="trackApp(el.name)" :key="index"><a :href="el.link" class="inactive-link"><img :src="el.image" alt="mobile-app"></a></li>
                </ul>
                <span class="app-footer__container-title" style="margin-top:4rem;" v-if="footer.footerup[3].socials.show">
                    {{footer.footerup[3].socials.title}}
                </span>
                <div class="app-footer__images ">
                    <a v-for="(el, index) in socials"  target="_blank"  :key="index"  :href="el.link"><img :src="el.icon" alt="curier"></a>
                </div>
            </div>
        </div>
        <div class="app-footer__bottom">
            <div class="app-footer__bottom-left">
                <ul>
                    <li v-for="(el, index) in footer.left" :key="index" >
                        <a :href="el.link" class="app-footer__link app-footer__link--bottom">{{el.text}}</a>
                    </li>
                </ul>
                <span class="app-footer__copyright">
                    {{footer.copyrighttext}}
                </span>
            </div>
            <!-- <div class="app-footer__bottom-right">
                <ul>
                    <li v-for="(el, index) in footer.right.language" :key="index" @click="changeLanguage(el.value)" :class="{'language-active':el.active}">{{el.value}}</li>
                </ul>
            </div> -->
        </div>
    </div>
</template>


<script>
export default {
    methods:{
        // changeLanguage(gender){
        //     this.$cookies.set('gender', gender);
        //     location.reload();
        // },
        goLink(link){
           window.open(link, '_blank').focus();
        },
        async trackApp(t) {
            await this.axios.get(`/trackappsclicks?domain=${this.$store.state.domain}&apptype=${t}`);
        }
    },
    computed:{
        footer() {
            return this.$store.state.main.footer;
        },
        socials(){
            if(this.$store.state.main.footer.footerup[3].socials.submenu) {
                return this.$store.state.main.footer.footerup[3].socials.submenu.filter(el => el.show)
            }
            else return []
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/_variables.scss";
@import "../assets/styles/main.scss";
.app-footer {
    display: flex;
    flex-direction: column;

    &__sections {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    &__container {
        display: flex;
        flex-direction: column;
        width: 25%;
        border-right: 1px solid rgba(0, 0, 0, 0.103);
        border-bottom: 1px solid rgba(0, 0, 0, 0.103);
        box-sizing: border-box;
        padding: 3rem;

        &:last-child {
            border-right: none;
        }

        &-title {
            font-family: $bold-main;
            font-size: 1.5rem;
        }

        ul{
            list-style: none; 
            margin-top: 2rem;
             
            li {
                padding: .5rem 0;
            }
        }
    }

    &__link {
        text-decoration: none;
        font-family: $medium-main;
        color: $color-black;
        font-size: 1.3rem;
        transition: all .5s;

        &--bottom {
            font-size: 1.1rem;
        }

        &:hover {
            color: $color-gray;
        }
    }
    &__images {
        margin-top: 1rem;

        img {
            cursor: pointer;
        }
    }
    &__bottom {
        display: flex;
        flex-direction: row;
        padding: 3rem 0;

        div {
            width: 100%;
        }

        &-left {
            display: flex;
            flex-direction: column;

            ul {
                list-style: none;
                display: flex;

                li{
                    font-family: $light-main;
                    margin-left: 1rem;
                    margin-right: 1rem;
                }
            }
        }

        &-right {
            display: flex;
            flex-direction: row;
            


            ul {
                list-style: none;
                display: flex;
                margin-left: auto;


                li{
                    display: block;
                    font-family: $medium-main;
                    margin-left: 1rem;
                    margin-right: 1rem;
                    font-size: 1.3rem;
                    cursor: pointer;
                }
            }
        }
    }

    &__copyright {
        font-family: $medium-main;
        margin-left: 1rem;
        margin-top: 2rem;
    }
}

.language-active {
    font-family: $bold-main!important;
}

@media only screen and (max-width: 850px) {
    .app-footer__container{
        width: 33.33%;
    }
    .app-footer__container:last-child{
        width: 100%;
    }
}
@media only screen and (max-width: 550px) {
    .app-footer__container{
        width: 100%;
    }
    .app-footer__bottom-left{
        ul{
            flex-direction: column;

            li{
                margin-top: 2rem;
            }
        }
    }
}

.inactive-link {
   pointer-events: none!important;
   cursor: default;
}
</style>