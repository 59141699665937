<template>
    <div class="app-prod-header">
        <div class="app-prod-header__links">
            <a v-for="(l, index) in $store.state.browse.sections" :key="index+102" :href="l.link" >
                <span v-if="l.show">{{l.text}} </span>
            </a>
        </div>
        <div style="display:flex;margin-top:1rem;">
            <h2 v-if="$store.state.browse.browsetitles[0].show">{{$store.state.browse.browsetitles[0].text}} &nbsp;</h2>
            <span style="margin-left:0;" v-if="$store.state.browse.browsetitles[1].show">{{$store.state.browse.browsetitles[1].text}}</span>
        </div>
        <div>
            <div class="custom-select" >
                <span>Sorteaza dupa</span>
                <div v-click-outside="closeSort">
                    <button @click="openedSort=true" style="display:flex;">{{textSort.name}}  
                        <i class='bx bx-sort-alt-2' style="display:block;margin-left:auto;transform:translateY(3px);"></i>
                    </button>
                    <div class="custom-option" v-if="openedSort">
                        <span v-for="(p,index) in $store.state.sort" @click="sortBy(p.ID)" :key="index+18928">{{p.name}} </span>
                    </div>
                </div>

            </div>
        </div>
        <!-- <AppSortBy :opened="openedSort" :categories="$store.state.sort" @closeSort="closeSort"/> -->
            <!-- <div class="custom-select">
  <select>
    <option value="0">Sorteaza dupa:</option>
    <option value="1">Audi</option>
    <option value="2">BMW</option>
    <option value="3">Citroen</option>
    <option value="4">Ford</option>
    <option value="5">Honda</option>
    <option value="6">Jaguar</option>
    <option value="7">Land Rover</option>
    <option value="8">Mercedes</option>
    <option value="9">Mini</option>
    <option value="10">Nissan</option>
    <option value="11">Toyota</option>
    <option value="12">Volvo</option>
  </select>
</div> -->
    </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
export default {
  directives: {
    ClickOutside
  },
    data() {
        return {
            openedSort:false,
            activeID:false
        }
    },
    methods:{
        closeSort() {
            this.openedSort = false;
        },
        sortBy(v){
            this.activeID = v;
            let r = '';
            let index = this.$route.params.type.indexOf("&sortby");
            if(index!=-1){
                let str = this.$route.params.type.split('&');
                str.forEach(item => {
                    let cpy = item.split('=');
                    console.log(cpy[0])
                    if(cpy[0]=='sortby') {
                        item = "sortby=" + v; 
                    }
                    if(cpy[0]=='pagina') {
                        item = "";
                        // alert(1)
                    }
                    if(item!="")
                    r = r + item + '&';
                });
                this.$store.commit("SET_CURR_PAGE", 0);
                r = r.slice(0, -1)
                this.$router.push(r);
            }
            else {
                this.$router.push(this.$route.params.type+"&sortby="+this.activeID);
            }
            this.closeSort()
        }
    },
    computed:{
        textSort(){
            if(this.$store.state.sort.length==0) return "";
            else return this.$store.state.sort .find(element => element.active ==true);
        }
    }
}
</script>


<style lang="scss" scoped>
.app-prod-header {
    width: 100%;
    display: flex;
    padding: 3rem 0;
    padding-bottom: 0rem;
    flex-direction: column;
    position: relative;

    &__links {
        display: flex;
        
        
        a {
            text-decoration: none;
            color: $color-black;
            font-size: 1.1rem;
            display: block;
            font-family: $medium-main;
            margin-left: .4rem;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                span {
                    color: rgb(134, 134, 134);
                }
            }



            span {
                margin: 0;
                
                &:hover {
                    color:rgb(134, 134, 134);
                    cursor: pointer;
                }
            }
        }
    }

    h2 {
        font-family: $bold-main;
        font-size: 2.5rem;
    }
    span{
        display: block;
        margin: auto 0;
        font-family: $medium-main;
        margin-left: 2rem;
        color: $color-gray;
    }
}

// @media only screen and (max-width: 550px) {
// .app-prod-header{
//     flex-direction: column;
//     justify-content: center;

//     h2{
//         margin: 0 auto;
//     }
//     span{
//         margin: 0 auto;
//         margin-top: 2.2rem;
//     }
// }
// }


.custom-select {
    position: absolute;
    right: 0;
    bottom: 15px;
    display: flex;
    
    select {
        padding: 1rem;
    }

    span {
        padding-right: 20px;;
    }

    button {
        font-family: $light-main;
        border: 1px solid rgb(149, 149, 149);
        padding: .5rem 2rem;
        font-size: 1.2rem;
        color: rgb(90, 90, 90);
        outline: none;
        position: relative;
        width:166px;
        text-align: start;

        i {
            transform: translateY(2px);
        }
    }
}
.custom-option {
    position:absolute;
    width:54%;
    background: rgb(255, 255, 255);
    z-index: 1000000;
    padding: 0;

    span {
        font-size: 1.1rem;
        padding: .5rem;
        cursor: pointer;
        margin: 0;
        padding-left: 1.8rem;
        // text-align: center;
        color: black;

        &:hover {
            background: rgb(233, 233, 233);
        }
    }
}

@media only screen and (max-width: 800px) {
    .custom-select {
        display: none;
    }
}
</style>