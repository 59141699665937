<template>
    <div class="app-big-section" v-if="$store.state.homepage">
        <div class="big-slider">
            <carousel 
            ref="carousel1"
            style="position:relative;height:70vh;"
            :perPage="1" 
            :autoplay="true" 
            :autoplayTimeout="5000" 
            :mouse-drag="true"
            :loop="true"
            @pageChange="pageChange"
            :navigate-to="page"
            :paginationEnabled="false"
            >         
            <slide  v-for="(s) in $store.state.homepage.homeslider" class="slider-el big-screen" :style="backgroundI(s.image.imagesource)" :key="s.image.imagesource">
                <div style="width:100%;height:100%;" @click="goLink(s.link)"></div>
                <div class="slider-content" :class="{'slider-content-right':s.image.position=='right'}" @click="goLink(s.link)">
                    <span class="slider-content-tt1" :class="{'slider-content-tt1-black':s.image.color=='black'}">{{s.smalltext.text}}</span>
                    <span class="slider-content-tt2" :class="{'slider-content-tt2-black':s.image.color=='black'}">{{s.bigtext.text}}</span>
                    <div class="slider-content-btn" style="color:black;">
                        <span>{{s.button1.text}}</span>
                    </div>
                </div>
            </slide>
            <slide v-for="(s,index) in $store.state.homepage.homeslider" class="slider-el small-screen" :style="backgroundI(s.image.imagesmall)" :key="index">
                    <div style="width:100%;height:100%;" @click="goLink(s.link)"></div>
                    <div class="slider-content"  @click="goLink(s.link)">
                        <span class="slider-content-tt1">{{s.smalltext.text}}</span>
                        <span class="slider-content-tt2">{{s.bigtext.text}}</span>
                        <div class="slider-content-btn">
                            <span>{{s.button1.text}}</span>
                        </div>
                    </div>
                </slide>
            </carousel> 
            <div class="btn-prev" @click="prevSlide">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </div>
            <div class="btn-next"  @click="nextSlide">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </div>
            <div class="bullets-big">
                <div @click="goPage(n-1,'bullet')" :class="{'active-bullet':n-1==page}" v-for="n in $store.state.homepage.homeslider.length" :key="n"></div>
            </div>
        </div>
        <div class="second-slider">
        <VueSlickCarousel v-bind="settings" style="max-width:100vw;">
                <div v-for="(s,index) in $store.state.homepage.homeslider" style="max-width:100vw;" class="slider-el small-screen" :key="index">
                    <!-- <img :src="s.image.imagesmall" alt="" style="height:100%;">    -->
                    <div :style="backgroundI(s.image.imagesmall)" class="background-mobile-image"  style="width:100%!important;height:100%!important;" @click="goLink(s.link)"></div>
                    <div class="slider-content"  @click="goLink(s.link)">
                        <span class="slider-content-tt1">{{s.smalltext.text}}</span>
                        <span class="slider-content-tt2">{{s.bigtext.text}}</span>
                        <div class="slider-content-btn">
                            <span>{{s.button1.text}}</span>
                        </div>
                    </div>
                </div>
        </VueSlickCarousel>
        </div>
		<!-- <div class="wrapper" style="margin-top:5rem;">
			<AppPriority/>
		</div> -->
    </div>
</template>

<script>
  import VueSlickCarousel from 'vue-slick-carousel'
//   import 'vue-slick-carousel/dist/vue-slick-carousel.css'
export default {
      components: { VueSlickCarousel },
    data() {
        return {
            page:0,
            settings:{
                "lazyLoad": "ondemand",
                "dots": false,
                "dotsClass": "slick-dots custom-dot-class",
                "infinite": true,
                "speed": 500,
                "slidesToShow": 1,
                "slidesToScroll": 1,
                arrows:false
            }
        }
    },
    methods:{
        pageChange(i){
            this.page = i;
            console.log(this.page)
            if(this.page>4) this.page=0;
            if(this.page<0) this.page=4;
        },
        goLink(link){
            window.location = link.link
        },
        nextSlide() {
            this.$refs.carousel1.goToPage(this.$refs.carousel1.getNextPage());
            if(this.page>4) this.page=0;
        },
        prevSlide() {
        this.$refs.carousel1.goToPage(this.$refs.carousel1.getPreviousPage());
        if(this.page==0) this.page=4;
        },
        goPage(index,type){
            if(type=='bullet') this.page = index;
            else this.page+=index;
        },
        backgroundI(img){
			return "background-image: linear-gradient(to top, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0) 85%), url(" + img + ");";
		},
    }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/_variables.scss";
@import "../assets/styles/main.scss";
.slider-el {
    cursor: pointer;
    height: 70vh;
    // background: url('../assets/images/slider.webp');
    background-position: top;
    background-size: cover;
    position: relative;
}


.container-brands {
    margin-top: 5rem;
}

.second-slider {
    display: none;
}

.big-slider {
    height: 70vh;
    position: relative;
}
.btn-prev {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.493);
    z-index: 10000;
    font-size: 2rem;
    top: 50%;
    transform: translateY(-50%);
    padding: 3rem .5rem;
    cursor: pointer;
    color: rgb(83, 83, 83);
    transition: all .2s;

    &:hover {
        padding: 3rem .8rem;
        background-color: rgba(255, 255, 255, 0.719);
    }

    ion-icon {
        transform: translateY(2px);
    }
   
}
.btn-next {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.493);
    z-index: 10000;
    font-size: 2rem;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    padding: 3rem .5rem;
    cursor: pointer;
    color: rgb(83, 83, 83);
    transition: all .2s;

    &:hover {
        padding: 3rem .8rem;
        background-color: rgba(255, 255, 255, 0.719);
    }

    ion-icon {
        transform: translateY(2px) rotate(-180deg);
    }
   
}

.bullets-big {
    position: absolute;
    top: 0;
    display: flex;
    left: 50%;
    top: 95%;
    transform: translateX(-50%);

    div {
        width:18px;
        height:18px;
        background: rgba(255, 255, 255, 0.513);
        border-radius: 50%;
        cursor: pointer;

        margin:0 .6rem;

        &:first-child {
            margin-left: 0;
        }
    }
}

.active-bullet {
    background: $color-white!important;
}

.slider-content {
    position: absolute;
     display: flex;
     flex-direction: column;
     bottom: 10%;
     left: 10%;
     max-width: 50rem;
     cursor: pointer;

    &-right {
        left:auto;
        right: 5vw!important;

        text-align: left!important;
    }

     &-tt1 {
         font-size: 2rem;
         color: $color-white;
         font-family: $light-main;

         &-black {
             color:$color-black;
         }
     }

     &-tt2{
        font-size: 4rem;
         color: $color-white;
         font-family: $medium-main; 
         margin-top: 2rem;
        
        &-black {
             color:$color-black;
         } 
     }

     &-btn {
         display: block;
         font-size: 2rem;
         font-family: $medium-main;
         display: inline;
         outline: none;
         border: none;
        background: transparent;
         padding: 1rem 0;
         color: rgba(0, 0, 0, 0.603);
         margin-top: 3rem;
         cursor: pointer;

         span {
             background-color: $color-white;
             padding:1.5rem 3.5rem;
         }
     }
}
.small-screen {
    display: none!important;
}
.big-screen {
    display: block!important;
}

@media only screen and (max-width: 750px) {
    .big-screen,.big-slider {
        display: none!important;
    }
    .small-screen,
    .second-slider {
        display: block!important;
    }
    .btn-next,
    .btn-prev,
    .bullets-big {
        display: none;
    }
    .slider-content {
        max-width: 90vw;;
        width: 90vw;
        left: 5vw;
        bottom: 6%!important;
        top: auto;

        &-tt1 {
            font-size: 2.3;
        }

        &-tt2 {
            font-size: 2.5rem;
            font-weight: bold;
            margin-top: 1rem;
        }

        &-btn {
            width: 100%;
            margin-top:.6rem;

            span {
                display: block;
                text-align: center;
                color: $color-black;
                border-radius: 5px;
            }
        }
    }
    .slider-el {
       
    }
}

.slick-slide img {
    height: 100%!important;
    width: auto;
}

.slick-slide .background-mobile-image {
    width: 100%!important;
    height: 100%!important;
    background-position: top;
    background-size: cover;
    position: relative;
}
</style>