<template>
    <div class="app-checkout" v-if="$store.state.step">
        <div style="display:flex;justify-content:center;margin-top:3rem;">
            <AppCheckoutSteps/>
        </div>
        <div v-if="step==1" class="wrapper">
            <span class="app-checkout__title">
                Selecteaza metoda de livrare
            </span>
            <div class="app-checkout__select" v-for="(del,index) in $store.state.step.deliverytypes" :key="index+9999" @click="selectDelivery(del.selectpossible, del.couriertrackvalue)">
                <div class="app-checkout__select-bullet" :class="{'app-checkout__select-bullet--off':del.selectpossible==false}"><div></div></div>
                <span class="app-checkout__select-company">
                    {{del.type}}
                    <span class="app-checkout__select-company--x-m"  v-if="index==1">Indisponibil momentan</span> 
                </span>
                <div class="app-checkout__select-description">
                    <span>{{del.text1}}</span>
                    <span :class="{'indisponibil':index==1}"><ion-icon name="alert-circle-outline"></ion-icon>{{del.text2}}</span>
                </div>
                <span class="app-checkout__select-price">{{del.amount}}</span>
            </div>
            <div class="app-checkout__select-btns">
                <button @click="goLink('/cart')" class="black-color"> <ion-icon name="return-down-back-outline"></ion-icon> Continuă cumpărăturile</button>
                <button @click="goNext(2)" class="app-checkout__select-btns--2">Următorul pas</button>
            </div>
        </div>
        <div v-if="step==2">
             <!-- {{$store.state.step.listaadrese}} -->
            <div class="wrapper step-2-header" style="display:flex;">
                <span class="app-checkout__title" style="margin-top:auto;margin-bottom:auto;">
                    Selecteaza adresa de livrare
                </span> 
                <button class="app-step-2__btn black-color" @click="addAddress" v-if="openedAddress==false">Adauga adresa noua</button>
            </div>
            <div class="adresses-grid__cnt wrapper" v-if="openedAddress==false">
                
                <div v-for="(address,index) in $store.state.step.listaadrese" :key="index+55" @click="selectAddress(index)" :class="{activeAddress:activeAddress==index}" >
                    <div  >
                        
                        <span style="padding-bottom:1rem;font-weight:bold;">{{address.title}}</span>
                        <span style="font-weight:bold;">{{address.fullname}}</span>
                        <span class="limited-width" style="width:80%;">{{address.email}}</span>
                        <span>{{address.telefon}}</span>
                        <span style="margin-top:1.5rem;">{{address.strada}} {{address.nrcasa}}</span>
                        <!-- <span>{{address.codpostal}}</span> -->
                        <span>{{address.judet}}</span>
                        <span>{{address.oras}}</span>
                        <div class="edit-btn" @click="editAdress(address.addressid)"><ion-icon name="create-outline"></ion-icon></div>
                        <!-- <div class="delete-btn" @click="deleteAddress(address.addressid)" v-if="address.deletepossible"><ion-icon name="trash-outline"></ion-icon></div> -->
                    </div>
                </div>
            </div>
            <div v-if="openedAddress=='edit'" style="padding-bottom:10rem;width:90vw;margin:auto;">
                    <div class="app-contact__form">
                        <span class="app-contact__form-title">Datele de livrare</span>
                        <form>
                            <div class="app-contact__form-cnt"  v-for="(a,index) in forEdit" :key="index+111">
                                <label :for="a.label">{{a.text}}</label>
                                <input type="text" v-if="a.dropdown==false" :name="a.label" v-model="a.predefinied" >
                                <select name="xxx" v-else v-model="a.predefinied">
                                    <option :value="i" v-for="(i, index) in a.valuesdropdown" :key="index+1223">
                                        {{i}}
                                    </option>
                                </select>
                                <p style="color:red" v-if="errors[index]">{{errors[index]}}</p>
                            </div>
                            <span class="app-contact__form-txt" ></span>
                            <div class="add-edit-address__btns">
                                <button type="button" class="black-color" @click="closeEdit" :class="{'hidden-btn':$store.state.forceupdate}" >Anulează</button>
                                <button type="button" @click="saveEdit">Salvează</button>
                            </div>
                        </form>
                    </div>   
            </div>
            <div v-if="openedAddress=='add'" style="padding-bottom:10rem;width:90vw;margin:auto;">
                    <div class="app-contact__form">
                        <span class="app-contact__form-title">Datele de livrare</span>
                        <form>
                            <div class="app-contact__form-cnt"  v-for="(a,index) in forEdit" :key="index+222">
                                <label :for="a.label">{{a.text}}</label>
                                <input type="text" :name="a.label" v-if="a.dropdown==false" v-model="a.predefinied" >
                                <select name="xxx" v-else v-model="a.predefinied">
                                    <option :value="i" v-for="(i, index) in a.valuesdropdown" :key="index+1223">
                                        {{i}}
                                    </option>
                                </select>
                                <p style="color:red" v-if="errors[index]">{{errors[index]}}</p>
                            </div>
                            <span class="app-contact__form-txt" ></span>
                            <div class="add-edit-address__btns">
                                <button type="button" @click="closeAdd" class="black-color">Anulează</button>
                                <button type="button" @click="addDelivery">Adauga</button>
                            </div>
                        </form>
                    </div>
            </div>
            <div class="app-checkout__select-btns wrapper" v-if="openedAddress==false">
                <button @click="goLink('/cart')" class="black-color"> <ion-icon name="return-down-back-outline"></ion-icon> Continuă cumpărăturile</button>
                <button @click="goNext(3)" class="app-checkout__select-btns--2">Următorul pas</button>
            </div>
        </div>
        <div v-if="step==3" class="wrapper">
            <span class="app-checkout__title">
                Alege metoda de plată
            </span>
            <div class="app-checkout__select app-checkout__select--2" @click="selectPayment(del.value)" v-for="(del, index) in $store.state.step.paymenttype" :key="del">
                <div class="app-checkout__select-bullet" :class="{'app-checkout__select-bullet--off':del.selectpossible==false}"><div v-if="del.value==payment"></div></div>
                <span class="app-checkout__select-company">{{del.text1}}</span>
                <div class="app-checkout__select-description app-checkout__select-description--2" style="display:block!important;">
                    <span v-if="index==1">Poți efectua plata online prin cardul bancar personal.</span>
                    <span v-else>Platiţi produsele la livrare prin agenţii companiei de curierat.</span>
                    <!-- <span><ion-icon name="alert-circle-outline"></ion-icon>del.text2</span> -->
                </div>
                <!-- <span class="app-checkout__select-price">del.amount</span> -->
            </div>
            <!-- <div class="app-checkout__select app-checkout__select--payment" @click="selectPayment(del.value)" v-for="(del) in $store.state.step.paymenttype" :key="del">
                <div class="app-checkout__select-bullet" :class="{'app-checkout__select-bullet--off':del.selectpossible==false}"><div v-if="del.value==payment"></div></div>
                <span class="app-checkout__select-company">{{del.type}}</span>
                <div class="app-checkout__select-description app-checkout__select-description--payment">
                    <span>{{del.text1}}</span>
                    <span><ion-icon name="alert-circle-outline"></ion-icon>{{del.text2}}</span>
                </div>
                <span class="app-checkout__select-price">{{del.amount}}</span>
            </div> -->
            <div class="app-checkout__select-btns">
                <button @click="goLink('/cart')" class="black-color"> <ion-icon name="return-down-back-outline"></ion-icon> Continuă cumpărăturile</button>
                <button @click="goNext(4)" class="app-checkout__select-btns--2">Următorul pas</button>
            </div>
        </div>
        <div v-if="step==4">
        
        <div class="wrapper app-cart__prod-cnt">
            <div class="left-side">
                <div class="app-cart__prod-products">
                    <h5 class="app-cart__prod-title">
                        Produse 
                    </h5>
                    <div class="app-cart__prod-line"></div>
                    
                    <div class="app-cart__prod-el"  v-for="(p) in $store.state.step.summary.produse" :key="p">
                        
                        <AppCartProd :product="p" :simple='false'/>
                        <div class="app-cart__prod-line"></div>
                    </div>
                </div>
               <div class="app-cart__prod-products app-cart__comment" style="margin-top:4rem;">
                    <h5 class="app-cart__prod-title app-cart__prod-title--2">
                        Comentarii suplimentare 
                    </h5>
                    <div class="app-cart__prod-line"></div>
                    <label class="checkout-comment-label" for="comment">Comentariul tău</label>
                    <textarea class="checkout-comment-area" v-model="comment" name="comment" cols="100%" rows="10"></textarea>
                </div>
            </div>
                <div class="app-cart__prod-summary">  
                    <button class="app-cart-summary__btn" @click="goNext(5)">Finalizare comanda</button>
                    <div v-for="(s,index) in $store.state.step.info" :key="index+8475" class="app-cart__prod-summary-el">
                        <AppCartSummaryv2 :summary="s" />
                    </div>
                    <AppCartSummary @click="goNext(5)" :summary="$store.state.step.summary" :chng="true"/>
                </div>
            </div>
        </div>
        <div v-if="step==5" class="wrapper">
            <div class="app-step-5">
                <h5 class="app-step-5__title">Multumim pentru comanda!</h5>
                <div class="app-step-5__status">
                    <span>
                      Starea comenzii: &nbsp;<p>{{$store.state.step.stareacomenzi}}</p> 
                    </span>
                </div>
                <div class="app-step-5__template" v-for="n in $store.state.step.info" :key="n">
                    <div class="app-step-5__template-line"></div>
                    <div class="app-step-5__template-content">
                        <span>
                            {{n.text1}}
                        </span>
                        <span>
                            {{n.text2}}
                        </span>
                    </div>
                </div>
                <div class="app-checkout__select-btns">
                    <button style="transform: translateX(-40px);" @click="goLink('/')" class="black-color"> <ion-icon name="return-down-back-outline"></ion-icon> Continuă cumpărăturile</button>               
                </div>
                <!-- {{$store.state.step}} -->
            </div>
        </div>
        <notifications position="top center" width="100%" class="notification-custom"/>
    </div>
</template>
<script>
import $ from 'jquery';
export default {
    data() {
        return {
            step:1,
            form2:[],
            payment:2,
            comment:"",
            openedAddress:false,
            addressEdit:null,
            forEdit:[],
            activeAddress:0,
            addTemplate:[],
            errors:[]
        }
    },
    methods:{
        goLink(link) {
            window.location = link;
        },
        selectPayment(p){
            this.payment = p;
        },
        selectDelivery(t, index){
            if(!t) this.$notify({ type: 'error', text: '<i class="bx bx-x close-notif"></i>Aceasta metoda de livrare este indisponibila momentant in zona ta.' })
            this.axios.get(`/trackcurier?&useridsession=${this.$store.state.auth.useridsession}&token=${this.$store.state.auth.token}&courier=${index}`);
        },
        selectAddress(index){
            this.activeAddress = index;
            console.log(this.$store.state.step.listaadrese[index])
            this.$cookies.set('address', this.$store.state.step.listaadrese[index].addressid);
            this.$cookies.set('addressindex', index);
        },
       async editAdress(id){
            this.openedAddress = 'edit';
            this.addressEdit = id;
            const response = await this.axios.get(`/updateadresa?domain=${this.$store.state.domain}&useridsession=${this.$store.state.auth.useridsession}&token=${this.$store.state.auth.token}&afisareadresa=${id}`);
            this.forEdit = response.data.afisareadresa.detaliiadresa;
            console.log("xxxxxxxxxx",this.forEdit) 
        },
        addAddress(){
            this.openedAddress = 'add';
            this.forEdit =  JSON.parse(JSON.stringify(this.$store.state.step.formtemplate));
        },
        closeAdd(){
            this.openedAddress = false;
            this.forEdit = []
            window.scrollTo(0, 0);
        },
        async closeEdit(){
            this.openedAddress = false;
            console.log(this.$store.state.step.listaadrese)
            window.scrollTo(0, 0);
        },
        async addDelivery() {
            let data = "";
            let validate = "";
            const address = this.forEdit;
            for(let i = 0; i < address.length;i++){
                validate = validate + '&'+ address[i].validatelabel + '=' + address[i].predefinied;
                data = data + '&'+ address[i].paramtere + '=' + address[i].predefinied;
            }
            const validare = await this.axios.get(`/validatefields?domain=${this.$store.state.domain}&useridsession=${this.$store.state.auth.useridsession}&token=${this.$store.state.auth.token}&updateaddressid=${this.addressEdit}${validate}`)
            console.log(validare)
            this.errors = validare.data.errors;
            if(validare.data.success) {
                await this.axios.get(`/edit-address?domain=${this.$store.state.domain}&useridsession=${this.$store.state.auth.useridsession}&token=${this.$store.state.auth.token}${data}&addnew=1`)
                const response = await this.axios.get(`/checkoutstep2?domain=${this.$store.state.domain}&language=${this.$store.state.language}&token=${this.$store.state.auth.token}&useridsession=${this.$store.state.auth.useridsession}`);
                this.$store.commit('SET_CHECKOUT_STEPS', response.data);
                this.openedAddress = false;
                console.log(this.$store.state.step)
                this.selectAddress(0)
                window.scrollTo(0, 0);
                this.$notify({ type: 'success', text: '<i class="bx bx-x close-notif"></i>Adresa a fost adaugata cu succes' })
            }
            else {
                if(document.body.clientWidth< 600) {
                    let ind = this.errors.findIndex((el) => el!=false);
                    let targetOffset = $('.app-contact__form-cnt').eq(ind).offset().top - 200;
                    $('html, body').animate({scrollTop: targetOffset}, 500);
                        // $('html,body').animate({ scrollTop: $(".app-contact__form-cnt")[1].offset().top}, 'slow');
                }
            }
        },
        async saveEdit(){
            let data = "";
            let validate = "";
            const address = this.forEdit;
            for(let i = 0; i < address.length;i++){
                if(address[i].predefinied){
                    data = data + '&'+ address[i].paramtere + '=' + address[i].predefinied;
                    validate = validate + '&'+ address[i].validatelabel + '=' + address[i].predefinied;
                }
                else {
                    data = data + '&'+ address[i].paramtere + '=' + '';
                    validate = validate + '&'+ address[i].validatelabel + '=' + '';
                }
            }
            const validare = await this.axios.get(`/validatefields?domain=${this.$store.state.domain}&useridsession=${this.$store.state.auth.useridsession}&token=${this.$store.state.auth.token}&updateaddressid=${this.addressEdit}${validate}`)
            console.log(validare)
            this.errors = validare.data.errors;
            // alert(validare.data.success)
            if(validare.data.success){
            this.selectAddress(0);
            await this.axios.get(`/updateadresa?domain=${this.$store.state.domain}&useridsession=${this.$store.state.auth.useridsession}&token=${this.$store.state.auth.token}&updateaddressid=${this.addressEdit}${data}`)
            this.addressEdit = null;
            this.openedAddress = false;
            this.$store.dispatch('loadStep', this.$route.fullPath.slice(-1) )
            window.scrollTo(0, 0);
            this.$notify({ type: 'success', text: '<i class="bx bx-x close-notif"></i>Adresa a fost actualizata cu succes' })
            }
            else {
                if(document.body.clientWidth< 600) {
                    let ind = this.errors.findIndex((el) => el!=false);
                    let targetOffset = $('.app-contact__form-cnt').eq(ind).offset().top - 200;
                    $('html, body').animate({scrollTop: targetOffset}, 500);
                        // $('html,body').animate({ scrollTop: $(".app-contact__form-cnt")[1].offset().top}, 'slow');
                }
            }
            // this.addressEdit = null;
            // this.openedAddress = false;
            // const response = await this.axios.get(`/checkoutstep2?domain=${this.$store.state.domain}&language=${this.$store.state.language}&token=${this.$store.state.auth.token}&useridsession=${this.$store.state.auth.useridsession}`);
            // this.$store.commit('SET_CHECKOUT_STEPS', response);
            // this.$store.dispatch('loadStep', this.$route.fullPath.slice(-1) )
            // this.openedAddress = false;
       },
        async goNext(step) {
            if(step==2){
                window.location = '/checkout/step=' + step
            }
            if(step==3) { 
                
                // let form2 = this.form2;
                // console.log(form2[0].predefined, form2[0].parameter)
                // console.log(form2[1].predefined,form2[1].parameter)
                // console.log(form2[2].predefined,form2[2].parameter)
                // console.log(form2[3].predefined,form2[3].parameter)
                // console.log(form2[4].predefined,form2[4].parameter)
                // console.log(form2[5].predefined,form2[5].parameter)
                // console.log(form2[6].predefined,form2[6].parameter)
                // console.log(form2[7].predefined,form2[7].parameter)
                // console.log(form2[8].predefined,form2[8].parameter)
                // console.log(form2[9].predefined,form2[9].parameter)
                // const data = await this.$axios.$get(
                //     `/checkoutstep2?
                //     domain=${this.$store.state.domain}
                //     &useridsession=${this.$store.state.auth.useridsession}
                //     &token=${this.$store.state.auth.token}
                //     &${form2[0].parameter}=${form2[0].predefined}
                //     &${form2[1].parameter}=${form2[1].predefined}
                //     &${form2[2].parameter}=${form2[2].predefined}
                //     &${form2[3].parameter}=${form2[3].predefined}
                //     &${form2[4].parameter}=${form2[4].predefined}
                //     &${form2[5].parameter}=${form2[5].predefined}
                //     &${form2[6].parameter}=${form2[6].predefined}
                //     &${form2[7].parameter}=${form2[7].predefined}
                //     &${form2[8].parameter}=${form2[8].predefined}
                //     &${form2[9].parameter}=${form2[9].predefined}
                // `);
                console.log(this.$store.state.step.listaadrese[this.activeAddress])
                 const data = await this.axios.get(`/validareadresa?domain=${this.$store.state.domain}&useridsession=${this.$store.state.auth.useridsession}&token=${this.$store.state.auth.token}&idadresa=${this.$store.state.step.listaadrese[this.activeAddress].addressid}`);
                    if(data.forceupdate) 
                    this.editAdress(this.$store.state.step.listaadrese[this.activeAddress].addressid)
                    else {
                        this.$cookies.set('address',this.$store.state.step.listaadrese[this.activeAddress].addressid)
                        window.location = '/checkout/step=' + step
                   }
                    console.log(data)
            }
            if(step==4){
               

                this.$cookies.set('payment', this.payment);
                window.location = '/checkout/step=' + step
            }

            if(step == 5) {
                this.$cookies.set("user_comments", this.comment);
                let address;
                if(this.$cookies.get('address')) address = this.$cookies.get('address')
                console.log(address)
                // let payment = this.$cookies.get('payment');
                // let comm;
                // if(this.comment == "" ) comm = "empty";else comm = this.comment;
                // let data = await this.axios.get(`/checkoutstep5?domain=${this.$store.state.domain}&useridsession=${this.$store.state.auth.useridsession}&token=${this.$store.state.auth.token}&deliverymethod=1&paymenttype=${payment}&usercomments=${comm}`);
                // data = data.data;
                // alert(data.paymentredirect.link)
                // if(data.paymentredirect.redirect) this.$router.push(data.paymentredirect.link)
                // else
                if(this.$cookies.get('payment')==2)   
                    window.location.href = '/pay';
                else 
                    window.location.href = '/checkout/step=5';
            }
            // const data = await this.$axios.$get(`/checkoutstep${step}?domain=${this.$store.state.domain}&useridsession=${this.$store.state.auth.useridsession}&token=${this.$store.state.auth.token}`);

        }
    },
    
    beforeMount(){
        this.$cookies.remove('cart');
        if(!this.$cookies.get('token')||!this.$cookies.get('useridsession')) window.location = "/contul-meu"
            //   this.$gtag('event', 'conversion', { 
            //     'send_to': 'AW-302540489/94KWCIiV-vUCEMnNoZAB',
            //     'transaction_id': ''
            // })
            // this.$fb.enable()
            // fbq('track', "AddToCart");
            this.step = this.$route.fullPath.slice(-1)
        this.$store.dispatch('loadStep', this.$route.fullPath.slice(-1) )
        if( this.$route.fullPath.slice(-1) == 2) {
            if(this.$cookies.get('addressindex'))
                this.activeAddress = this.$cookies.get('addressindex');
            else this.activeAddress  = 0;
            // if(this.$store.state.step.forceupdate) this.editAdress(this.$store.state.step.forceupdate)
        }
    },
    mounted() {
        if(this.$route.params.step == "step=1") gtag('event', 'conversion', {'send_to': 'AW-10800167573/mgT_CJyizpEDEJX19Z0o'});

            // console.log("sdfdfgfdgfgvgfggggggggggggggggggd", this.$route.params.step);
            // this.$gtag('event', 'conversion', { 
            //     'send_to': 'AW-302540489/94KWCIiV-vUCEMnNoZAB',
            //     'transaction_id': ''
            // })
        
    },
    watch:{
        '$store.state.forceupdate': function() {
            if(this.$store.state.forceupdate)
            this.editAdress(this.$store.state.forceupdate)
            console.log(this.$store.state.forceupdate)
        },
        "$store.state.step":function(){
            let googlevalue = null;
            if(this.$store.state.step.googlevalue) googlevalue = this.$store.state.step.googlevalue;
            if(this.$store.state.step.googlevalue){
                gtag('event', 'conversion', {
                    'send_to': 'AW-10800167573/pDa4COjTzpEDEJX19Z0o',
                    'value': googlevalue,
                    'currency': 'RON',
                    'transaction_id': ''
                });
            }
            // if(this.$store.state.step.fbpurchases)
            //     window.fbq('track', 'Purchase', {
            //         content_ids: this.$store.state.step.fbpurchases,
            //         content_type: 'product'
            //     });
        }
    }
}
</script>


<style lang="scss" scoped>
@import "../assets/styles/_variables.scss";
@import "../assets/styles/main.scss";
.app-checkout{

    &__title {
        display: block;
        font-family: $bold-main;
        font-size: 2rem;

    }

    &__select {
        display: flex;
        flex-direction: row;
        padding: 4rem 2rem 4rem 2rem;
        background: $color-gray-light;
        cursor: pointer;
        margin-top: 3rem;

        &--payment {
            padding: 2rem;
        }

        &-bullet {
            width: 2rem;
            height: 2rem;
            border: 2px solid $color-black;
            border-radius: 50%;
            display: flex;
            cursor: pointer;
            box-sizing: border-box;
            margin: auto 0;

            &--off {
                border: 2px solid rgb(102, 102, 102);
                div {
                    display: none;
                }
            }
             
             div {
                 width: 60%;
                 height: 60%;
                 background-color: $color-black;
                 margin: auto;
                 border-radius: 50%;
             }
        }

        &-company {
            font-family: $bold-main;
            display: block;
            margin: auto 0 auto 1.3rem;
            font-size: 1.3rem;
            width:200px;;
        }

        &-description {
            display: block;
            margin: 0 auto;
            margin-left: 20%;;

            span {
                display: block;
                padding: .5rem;
                font-family: $light-main;
                font-size: 1.2rem;

                ion-icon {
                    transform: translateY(4px);
                    font-size: 1.7rem;
                    padding-right: 1rem;
                }
            }
        }

        &-price {
            font-family: $bold-main;
            font-size: 1.4rem;
            text-transform: uppercase;
            display: block;
            margin: auto 0;
        }

        &-btns {
            display: flex;
            margin-top: 3rem;

            button{

                font-family: $light-main;
                padding: .8rem 4rem;
                outline: none;
                border: none;
                cursor: pointer;
                transition: all .5s;

                &:first-child {
                    background: transparent;

                    ion-icon {
                        font-size: 2rem;
                        transform: translateY(6px);
                        padding-right: 1.5rem;
                    }

                    &:hover {
                        font-weight: bold;
                    }
                }

                &:nth-child(2) {
                    margin-left: auto;
                    background-color: $color-black;
                    color: $color-white;

                    &:hover {
                        background-color: rgb(59, 59, 59);
                    }
                }
            }
        }
    }
}

// Form

.app-contact{

    &__form {
        width: 50rem;
        margin: 0 auto;

        &-title{
            font-family: $bold-main;
            font-size: 1.8rem;

        }

        &-cnt{
            margin-top: 3rem;
            display: flex;
            flex-direction: column;

            label {
                font-family: $semibold-main;
                font-size: 1.3rem;
                padding-bottom: .7rem;
            }

            input{
                font-size: 1.6rem;
                outline: none;
                padding: .7rem;
                font-family: $light-main;
                -webkit-appearance: none;
                border: 1px solid $color-black;
            }

            textarea{
                resize: none;
                height: 10rem;
                outline: none;
                font-size: 1.6rem;
                font-family: $light-main;
                -webkit-appearance: none;
                border: 1px solid $color-black;
            }
        }

        &-txt {
            font-family: $medium-main;
            display: block;
            margin-top: 2rem;
            color: $color-gray;

            a {
                color: inherit;
            }

            &-link {
                text-decoration: none;
                color: inherit;
            }
        }

        &-btn {
            font-family: $medium-main;
            width: 50%;
            background: none;
            outline: none;
            border: 1px solid black;
            padding: 1rem 0;
            display: block;
            margin-top: 2rem;
            transition: all .1s;
            box-sizing: border-box;

            &:hover {
                cursor: pointer;
                font-family: $semibold-main;
            }
        }
    }
}

.registertextclass {
    color: $color-gray-light;

    a {
        text-decoration: none;
        color: $color-gray-light!important;
    }
}
@media only screen and (max-width: 600px) {
    .app-contact__form {
        width: 100%;
    }
    .app-step-5 {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}


// Product style
.app-cart {

    &__empty {

        text-align: center;

        &-title {
            font-family: $bold-main;
            font-size: 2rem;
            display: block;

            svg {
                transform: translateY(6px);
            }
        }

        &-description {
            display: block;
            font-family: $medium-main;
            margin-top: 2rem;

        }

        button {
            display: block;
            margin: 0 auto;
            margin-top: 2rem;
            background: none;
            outline: none;
            border: 1px solid $color-black;
            font-family: $medium-main;
            padding: 1rem 12rem;
            transition: all .5s;

            &:hover {
                font-family: $semibold-main;
                cursor: pointer;
                font-size: 1.32rem;
                -moz-box-shadow:    inset 0 0 1px #000000;
                -webkit-box-shadow: inset 0 0 1px #000000;
                box-shadow:         inset 0px 0px 1px 1px #000000;
            }
        }
    }

    &__prod {
        margin-top: 2rem;
        background: $color-gray-light;
        padding-top: 3rem;

        &-cnt {
            display: flex;
            flex-direction: row;
        }

        &-products {
            width: 100%;
            background: $color-gray-light;
            padding: 3rem;
        }

        &-title {
            font-family: $bold-main;
            font-size:2.4rem;
        }

        &-line{
            width: 100%;
            height: 2px;
            background: rgb(204, 204, 204);
            margin-top: 1.5rem;
        }

        &-el {
            margin-top:2rem
        }

        &-summary {
            margin-left: 8rem;
            width: 35rem
        }
    }
}

@media only screen and (max-width: 850px) {
    .left-side {
        width: 100%!important;
    }
    .app-cart__prod-cnt{
        flex-direction: column;
    }
    .app-cart__prod-el,
    .app-cart__prod-products,
    .app-cart__prod-summary{
        width: 100%;
        box-sizing: border-box;
        margin: 0;
    }
    .app-cart__prod-el{
        margin-top: 3rem;
    }

    .app-cart__prod-summary {
        margin-top: 2rem;
    }
}
.left-side {
    display:flex;
    flex-direction:column;
    width: calc(100% - 35rem);
}
.checkout-comment-label {
    font-size: 1.3rem;
    margin-top: 1.4rem;
    display: block;
    font-family: $light-main;
    padding-bottom: .5rem;
}
.checkout-comment-area {
    width: 100%;
    box-sizing: border-box;
    border: 1.5px solid #cfcfcf;
    background: transparent;
    outline: none;
    font-family: "Montserrat-Medium",Arial,Helvetica,sans-serif;
    padding: 1rem;
    margin-top: 1rem;
    resize: none;

    &:focus {
        border: 2px solid $color-black;
    }
}

.app-cart__prod-summary-el {
    margin-top: 2rem;
    
    &:first-child {
        margin-top: 0!important;
    }
}

.app-step-5 {
    display: flex;
    flex-direction: column;
    max-width: 120rem;
    margin: 0 auto;
    margin-top: -80px;

    &__title {
        font-family: $bold-main;
        text-align: center;
        font-size: 2.4rem;
    }

    &__status {
        
        font-family: $semibold-main;
        font-size: 1.6em;
         margin:1rem auto;

        span {
            display: flex;
            color: $color-gray;
           

            p {
                color: $color-black!important;
            }
        }
    }

    &__template {

        &-line{
            width:100%;
            height: 1px;
            background: rgb(158, 158, 158);
            margin-top: 4rem;
        }

        &-content {
            display: flex;
            margin-top: 3rem;

            span {
                font-family: $bold-main;
                display: block;
                width: 50%;
                font-size: 1.2rem;
                word-wrap: break-word;
                
                &:nth-child(2) {
                    text-align: start;
                    font-family: $medium-main;
                    font-size: 1.2rem;
                }
            }
        }
    }
}

.app-checkout__select-company--x-m  {
    display: none;
}

@media only screen and (max-width: 800px) {
    .app-checkout__select-description {
        display: none!important;

        &--payment {
            display: flex!important;
            margin: auto;
            
            span {
                &:first-child {
                    font-family: $bold-main;
                    font-size: 1.3rem;
                    margin: auto;
                }
                &:nth-child(2) {
                    display: none;
                }
            }
        }
    }
    .app-checkout__select-price {
        margin-left: auto!important;
    }

    .app-checkout__select-company{
        position: relative;
    }
    .app-checkout__select-company--x-m {
        display: block!important;
        color: red;
        position: absolute;
        bottom: -16px;
        left: 0;
        font-family: $medium-main;
        font-size: 1rem;
    }
}
@media only screen and (max-width: 600px) {
    .app-step-5 {
        max-width: 95vw;
    }
}
@media only screen and (max-width: 500px) {
    .app-checkout__select-btns {
        flex-direction: column-reverse;
        justify-content: center;

        button {
            width: 70%;;
            margin: 0 auto;
            padding: 0;
            transform: translateX(0%)!important;
            &:nth-child(1) {
                margin-top: 1.5rem;
            }
        }
    }
    // .app-checkout__select-company {
    //     display: none;
    // }
}

@media only screen and (max-width: 380px) {
.app-checkout__select-company{
    width:50vw;
}
}






















.app-my-account {

    &__lg,
    &__page {
        display: grid;
        grid-template-columns: 1fr 1.5fr 1fr;
        column-gap: 2rem;
    }

    &__choose {
        display: flex;
        flex-direction: row;
        display: none;

        span {
            display: block;
            width: 50%;
            text-align: center;
            font-family: $bold-main;
            margin-top: 3rem;
            font-size: 1.5rem;
            padding: 2rem;
        }

        &-active-el {
            background: $color-gray-light;
        }
    }

    &__comenzi {
        background: $color-gray-light;
        padding: 3rem;

        h3 {
            font-family: $bold-main;
            font-size: 1.8rem;
        }

        span {
            display: block;
            margin-top: 2rem;
            font-family: $light-main;
            font-size: 1.3rem;
        }
    }

    &__retururi{

        &-types {
            display: flex;

            span {
                display: block;
                padding: 3rem;
                background: $color-gray-light;
                font-family: $bold-main;
                font-size: 1.6rem;
                cursor: pointer;
            }
        }

        &-prod {
            display: flex;
            background: $color-gray-light;
            height: 78%;

            span {
                font-family: $medium-main;
                display: block;
                margin: 0 auto;
                margin-top: 3rem;
                font-size: 1.3rem;
            }
        }
    }

    &__friend {
        
        h4 {
            font-family: $bold-main;
            font-size: 2rem;
        }

        h5 {
            font-family: $bold-main;
            font-size: 3.5rem;
        }

        &-description {
            display: block;
            margin-top: 1.5rem;
            font-family: $medium-main;
            font-size: 1.4rem;
        }

        &-copy {
            display: flex;
            margin-top: 3rem;

            &-how-works{
                display: flex;
                flex-direction: row;
                margin-top: 5rem;

                &-left{
                    width: 50%;
                    border-right: 2px solid $color-gray-light;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;

                    span {
                        font-size: 2rem;
                        display: block;
                        font-family: $semibold-main;
                    }

                    div {
                        padding: 2rem 0;
                    
                        ion-icon {
                            background-color: rgb(233, 233, 233);
                            padding: 1.5rem;
                            font-size: 4.5rem;
                            border-radius: 50%;
                            transform: rotate(-45deg);
                        }
                    }
                }
                &-right{
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                    padding-left: 2rem;

                    span {
                        font-family: $bold-main;
                        font-size: 2rem;

                        &:nth-child(2),
                        &:nth-child(4) {
                            display: block;
                            margin-top: 2.5rem;
                            font-family: $light-main;
                        }
                    }

                    div {
                        display: flex;
                        margin-top: 1rem;

                        span {
                            font-family: $light-main;
                            display: block;
                            font-size: 1.4rem;
                            margin: auto 0;
                            margin-left: 2rem;
                        }
                    }
                }
            }

            &-icon{
                background: $color-yellow;
                display: inline-block;
                padding: .7rem;
                border-radius: 50%;
                height: 3.5rem;
                width: 3.5rem;
                margin: auto 0;
                
                ion-icon {
                    font-size: 3.5rem;
                    display: block;
                    transform: rotate(-45deg);
                }
            }

            &-link {
                display: flex;
                flex-direction: column;
                margin-left: 2rem;
                // box-shadow: 0 8px 24px -5px rgb(0 0 0 / 10%);
                width: 100%;
                padding:3rem 2rem;

                span {
                    display: block;
                    font-family: $semibold-main;
                    font-size: 1.5rem;
                }

                &-cnt {
                    margin-top: 2rem;
                    display: flex;

                    button {
                        width: calc(100% - 57rem);
                        margin-left: auto;
                        font-family: $medium-main;
                        color: $color-white;
                        background-color: $color-black;
                        border:none;
                        outline: none;
                        cursor: pointer;
                    }

                    &-txt {
                        display: flex;
                        font-family: $medium-main;
                        width: 55rem;
                        border: 2px solid $color-gray-light;
                        padding: 1rem;
                        cursor: pointer;
                        position: relative;
                        
                        padding-right: 5rem;
                        box-sizing: border-box;

                        span {
                            display: block;
                            overflow: hidden;
                            white-space: nowrap;

                            &:nth-child(2) {
                                display: block;
                                position: absolute;
                                right: 0;
                                top: 50%;
                                transform: translate(-4rem,-50%);
                            }

                        }
                    }
                }
            }
        }
    }

    &__sections {
        display: flex;
        flex-direction: column;

        div {
            font-family: $bold-main;
            display: flex;
            margin-top: 2rem;
            color: $color-black;
            font-size: 1.4rem;

            ion-icon {
                font-size: 2.4rem;
                color: gray;
                cursor: pointer;
            }

            a {
                text-decoration: none;
                display: block;
                margin: auto 0;
                margin-left: 2rem;
                padding-bottom: .3rem;
                cursor: pointer;
                color: gray;
                // border-bottom: 1px solid black;
                // color: $color-black;
                
            }

            &:hover  {

                a{
                    border-bottom: 1px solid black;
                    color: $color-black;
                }
                ion-icon {
                    color: black;
                }
            }
        }
    }

    &__login {

        &-form {
            padding: 0 6rem;

            &-title {
                font-family: $semibold-main;
                font-size: 2rem;
            }

            &-cnt {
                margin-top: 3rem;
                display: flex;
                flex-direction: column;


                div {
                    display: flex;
                    flex-direction: column;
                    padding-bottom: 3rem;
                    position: relative;
                    
                    label {
                        font-family: $semibold-main;
                        letter-spacing: .1rem;
                    }

                    input {
                        border: 1.5px solid rgb(207, 207, 207);
                        background: transparent;
                        outline: none;
                        font-family: $medium-main;
                        padding: 1rem;
                        margin-top: 1rem;
                        -webkit-appearance: none;

                        &:focus {
                            border: 2px solid $color-black;
                        }
                    }



                }
                span {
                    font-family: $medium-main;
                    cursor: pointer;
                    display: block;
                    margin-top: -1.5rem;
                    margin-left: auto;
                    z-index: 2000;
                }

                button {
                    background: $color-black;
                    outline: none;
                    color: $color-white;
                    padding: 1rem 0;
                    font-family: $medium-main;
                    font-size: 1.5rem;
                    border: none;
                    cursor: pointer;
                    margin-top: 2rem;
                    transition: all .5s;

                    &:hover {
                        background-color: rgb(46, 46, 46);
                    }
                }
            }

            &-description {
                margin-top: 2rem;
                
                span {
                    font-family: $bold-main;
                    margin: 0;
                    font-size: 1.5rem;
                }

                ul {
                    font-family: $medium-main;
                    margin-left: 1.8rem;
                    font-size: 1.1rem;
                    margin-top: 1rem;

                    li {
                        margin-top: 1rem;
                    }
                }
            }
        }
    }
}

.wrapper {
    margin: 0 auto!important;
    margin-top: 2.3rem!important;
}

.form-error {
    position: absolute;
    bottom: 1.5rem;
    color: red;
    font-family: $medium-main;
}
.inp-err {
    border: 1px solid red!important;
}

.bullet{
    width: 2.2rem;
    height: 2.2rem;
    border: 2px solid rgb(104, 104, 104);
    box-sizing: border-box;
    padding: 0!important;
    border-radius: 50%;
    display: flex;
    flex-direction: row!important;
    cursor: pointer;

    div {
        width: 1.2rem!important;
        height: 1.2rem!important;
        background: $color-black;
        border-radius: 50%;
        padding: 0!important;
        margin: auto!important;

    }

    &__text {
        display: block;
        margin: auto 0 auto 1rem!important;
        font-family: $semibold-main!important;
        font-size: 1.3rem;
        cursor: pointer;
    }
}


.terms-conditions {
    margin: 0!important;
    display: flex;
    flex-direction: row!important;;
    padding: 0 !important;
    margin-top: 2rem!important;
    
    &:first-child {
        margin-top: 0!important;
    }

    span {
        margin: auto 0 auto 1rem!important;
        display: block;
        width: 90%;
        cursor: pointer;
    }
}

.square {
    height: 1.5rem;
    width: 1.5rem;
    padding: 0!important;
    box-shadow: 0 0 0 1px $color-black;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    justify-content: center;

    div {   display: flex;
            width: 1rem;
            height: 1rem;
            background: $color-black;
            padding:0!important;
            margin:auto!important;
            border: none!important;
    }
}

.register-txt {
    margin: 0!important;
    margin-top: 1.5rem!important;
    cursor: default!important;
}

.registertextclass {
    cursor: default!important;

    a{
        text-decoration: none;
        color: gray!important;
    }
}

input,
input[type="text"],
input[type="button"],
input[type="submit"] {
-webkit-appearance: none!important;
border-radius: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none!important;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  -webkit-appearance: none!important;
}

.grid-2 {
    display: grid;
    column-gap: 2rem;
    grid-template-columns: 1fr 3fr!important;
}

.retur-n-act {
    background: #fff!important;
    color: gray;
    transition: all .5s;

    &:hover {
        background-color: rgb(0, 0, 0)!important;
        color: white;
    }
}

.app-logged-1 {
    display: flex;
    flex-direction: column;

    &__title {
        font-family: $bold-main;
        font-size: 1.8rem;
    }

    &__template {
        font-family: $semibold-main;
        margin-top: 2rem;
        padding: 2.4rem;
        background-color: $color-gray-light;
        font-size: 1.5rem;
        border-radius: 3px;

        &-txt {
            display: block;
            margin-top: 1rem;
            font-family: $light-main;

            &:nth-child(1) {
                margin-top: 0!important;
                font-family: $bold-main;
            }
        }
    }

    &__address {

        &-btn {
            display: block;
            width: 30rem;
            font-family: $medium-main;
            padding: 1.3rem 0;
            margin-top: 2rem;
            background: $color-white;
            outline: none;
            border: 1px solid black;
            cursor: pointer;

        }
    }
}

.app-logged-2 {
    display: flex;
    flex-direction: column;

    &__title {
        font-family: $bold-main;
        font-size: 1.8rem;
    }
}
.active-section {
    a{
        border-bottom: 1px solid black;
        color: $color-black!important;
    }
    ion-icon {
        color: black!important;
    }
}

.adresses-grid {
    grid-column: span 3;  
    display: grid;
    grid-template-columns: 1fr 2.5fr!important;
    column-gap: 2rem;

    &__cnt{
        display: grid;
        grid-template-columns: 1fr 1fr!important;
        column-gap: 2rem;
        row-gap: 2rem;
        margin-top: 2rem;

        div {
            background: $color-gray-light;
            height: auto;
            padding: 2rem;
            border-radius: 2px;
            padding-bottom: 5rem;
            position: relative;

            .edit-btn {
                position: absolute;
                top: 0;
                right: 0;
                
                font-size: 2rem;
                

                ion-icon {
                    background: $color-white;
                    padding: 1rem;
                    border-radius: 50%;
                    cursor: pointer;
                }
            }

            .delete-btn {
                position: absolute;
                bottom: -5rem;
                right: 0;
                font-size: 1.4rem;
                background: transparent;
                
                ion-icon {
                    background: $color-white;
                    padding: 1rem;
                    border-radius: 50%;
                    cursor: pointer;
                }

            }

            span {
                display: block;
                font-size: 1.3rem;
                font-family: $medium-main;
                margin-top: .5rem;
            }
        }
    }
}

.add-edit-address {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
    column-gap: 2rem;
    margin-top: 5rem;

    &__template {
        display: flex;
        flex-direction: column;
        padding-bottom: 3rem;
        position: relative;

        label {
            display: block;
            font-family: $semibold-main;
            letter-spacing: .1rem;
        }

        input {
            display: block;
            border: 1.5px solid rgb(207, 207, 207);
            background: transparent;
            outline: none;
            font-family: $medium-main;
            padding: 1rem;
            margin-top: 1rem;

            &:focus {
                border: 2px solid $color-black;
            }
        }
    }

    &__btns {
        display: flex;
        justify-content: space-between;

        button {
            background: none;
            border: 1px solid $color-black;
            width: 15rem;
            font-family: $medium-main;
            padding: 1rem 0;
            cursor: pointer;

            &:nth-child(2) {
                background: $color-black;
                color: $color-white;
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .app-my-account__page {
        grid-template-columns: 50% 50%;
    }
    .app-my-account__sections {
        grid-column: 1;
        grid-row: 1;
    }
    .app-logged-1 {
        grid-column: 1 / 2;
        margin-top: 4rem;
    }
    .app-logged-2 {
        grid-column: 2 / 2;
        box-sizing: border-box;
        padding-right: 2rem;
        margin-top: 4rem;
    }
    .adresses-grid {
        grid-template-columns:100%!important; 
        margin-top: 4rem;
    }
    .grid-2 {
        grid-template-columns: 100%!important;
    }
    .app-my-account__comenzi,
    .app-my-account__retururi,
    .app-my-account__friend {
        margin-top: 4rem;
        margin-bottom: 4rem;
    }
}

@media only screen and (max-width: 800px) {
    .app-my-account__lg{
        grid-template-columns:1fr;
    }
    .app-my-account__login-form-title {
        display: none;
    }
    .app-my-account__sections {
        margin-left: 2rem;
    }
    .app-my-account__login-form,
    .app-my-account__login-form-cnt {
        padding: 0;
        background: $color-gray-light;
        padding: 2rem;
    }
    .app-my-account__choose{
        display: flex;
    }
    .show-el {
        display: flex;
    }
    .unshow-el {
        display: none;
    }
    .app-my-account__login-form-cnt {
        margin-top: 0;
    }
    input,
    input[type="text"],
    input[type="button"],
    input[type="submit"] {
    -webkit-appearance: none!important;
    border-radius: 0;
    font-size: 16px!important;
    }
    .app-my-account__friend-copy-how-works {
        flex-direction: column;
    }
    .app-my-account__friend-copy-how-works-right {
        margin-top: 2rem;
    }
    .app-my-account__friend-copy-how-works-left  {
        

        span,
        div{
            margin-left: 2rem;
        }

        &-r-text {
            margin-top: 4rem;
        }
    } 
    
    .app-my-account__friend-copy-link-cnt-txt {
        width: 50%;
    }
    .app-my-account__friend-copy-link-cnt>button {
        display: block;
        width: 40%;
        box-sizing: border-box!important;
        margin-right: auto;
        margin-left: 0;
    }
}

@media only screen and (max-width: 650px) { 
    .step-2-header {
        flex-direction: column;
    }
    .app-step-2__btn {
        width: 100%!important; 
    }
}
@media only screen and (max-width: 600px) {
    .app-my-account__friend-copy-how-works-left,
    .app-my-account__friend-copy-how-works-right{
        width: 100%;
    }
    .app-my-account__page,
    .adresses-grid__cnt,
    .add-edit-address{
        grid-template-columns: 100%!important;
    }
    .adresses-grid__cnt,
    .add-edit-address {
        box-sizing: border-box;
        // padding-right: 6rem;
    }
    .app-logged-1 {
        grid-column: 1;
    }
    .app-logged-2 {
        grid-column: 1;
    }
    .app-logged-1 {
        padding-right: 2rem;
    }
    .app-my-account__friend-copy-link-cnt{
        flex-direction: column;
    }
    .app-my-account__friend-copy-link-cnt-txt {
        width: 75%;
    }
    .app-my-account__friend-copy-link-cnt {

        button {
            padding-top:1rem;
            padding-bottom: 1rem;
            margin-top: 1rem;
            width: 75%;
        }
    }
    .app-cart__prod-title--2 {
        font-size: 1.5rem;
    }
    // .app-cart-prod__stats-el-description>p {
    //     padding: 0!important;
    // }

    .app-step-2__btn {
        margin: 0 auto;
        width: 100%!important;
    }
}

.app-checkout__select--2 {
    display: flex;
    flex-wrap: wrap;
}

.app-checkout__select-description--2 {
    margin-left: 0;
}
.forgot-txt {
    font-family: $medium-main;
    font-size: 1.2rem;
    padding-bottom: 2rem;
    color: rgba(112, 112, 112, 0.933);
}

.add-edit-address--open {
    display: block;
    padding: 0;
    margin-top: 0;

    .add-edit-address__template {
        padding-bottom: 0!important;
        padding-top: 1rem;
    }

    div {
        padding: 1rem;
        padding-top: 0;
    }
}

.app-step-2__btn {
    display: block;
    margin-left: auto;
    display: block;
    width: 30rem;
    font-family: $medium-main;
    padding: 1.3rem 0;
    margin-top: 2rem;
    background: $color-white;
    outline: none;
    border: 1px solid black;
    cursor: pointer;
}

.activeAddress {
    border: 2px solid black;
}

.hidden-btn {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
}

@media only screen and (max-width: 350px) {
.app-cart__prod-products {
    padding-right: 5px;
    padding-left: 5px;
}
}

.indisponibil {
    color:red;
}

.app-contact__form-cnt {
    select {
        font-size: 1.6rem;
        outline: none;
        padding: .7rem;
        font-family: $light-main;
        -webkit-appearance: none;
        border: 1px solid $color-black;
        background: $color-white;

        // &:focus {
        //     border: 2px solid $color-black;
        // }
    }
}

.app-checkout__select-btns--2 {
    padding:1rem 2rem!important; 
    box-sizing: border-box;
}

.app-cart-summary__btn { 
        padding: .8rem 0;
        background: $color-black;
        outline: none;
        border: 1px solid $color-black;
        color: $color-white;
        font-family: $medium-main;
        transition: all .5s;
        width: 100%;

        &:hover {
            cursor: pointer;
            background-color: rgb(36, 36, 36);
        }
}
</style>