<template>
    <div class="app-cookies" v-if="active!=null&&$store.state.main&&$store.state.main.cookiespopup&&!$cookies.get('cookiesShow')">
        <div class="app-cookies__main" >
            <div v-if="active==1">
                <h5>{{$store.state.main.mainpopup.title}}</h5>
                <span class="app-cookies__main-txt">{{$store.state.main.mainpopup.subtitle}}</span>
                <div class="app-cookies__btns">
                    <button class="app-cookies__change" @click="active=2">Setari</button>
                    <button class="app-cookies__accept" @click="acceptCookies">Accepta toate</button>
                </div>
            </div>
            <div v-if="active==2">
              <div class="app-cookies__settings" >
                  <div class="app-cookies__settings-title">
                      {{$store.state.main.mainpopup.title}}
                  </div>
                  <div class="app-cookies__settings-subtitle">
                      Schimbă setările magazinului în funcție de preferințele tale
                  </div>
              </div>
              <div class="app-cookies__settings-content" id="scroll">
                  <div class="app-cookies__settings-template" v-for="n in $store.state.main.mainpopup.setari.objects" :key="n">
                      <div class="app-cookies__settings-template-top">
                          <span>{{n.title}}</span>
                          <div class="check__container">
                              <input  type="checkbox" class="switch" v-model="n.defaultvalue">
                          </div> 
                      </div>
                      <div class="app-cookies__settings-template-bottom">
                          {{n.text}} 
                      </div>
                  </div>
              </div>
              <div class="app-cookies__settings-btns">
                  <button @click="acceptCookies">Accepta toate</button>
                  <button @click="acceptCookies">Confirma selectia</button>
              </div>
          </div>
      </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            active:1
        } 
    },
    methods:{
      acceptCookies(){
        this.$cookies.set("cookiesShow","accepted", "3y")
        this.active = null;
      }
    }
}
</script>

<style lang="scss" scoped>
.app-cookies {
    // display: none!important;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.514);
    z-index: 1000000;
    display: flex;
    justify-content: center;

    &__main {
        margin: auto;
        background: $color-white;
        padding: 4rem;
        max-width: 600px;
        box-sizing: border-box;
                    max-height: 100vh;
            overflow: auto;

        h5 {
            font-family: $semibold-main;
            font-size: 2rem;
        }

        &-txt {
            font-family: $medium-main;
            font-size: 1.3rem;
            display: block;
            margin-top: 1.5rem;
        }
    }

    &__btns {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 2rem;
    }

    &__change {
        font-family: $medium-main;
        font-size: 1.5rem;
        color: rgb(105, 105, 105);
        outline: none;

        &:hover {
            color: black;
        }
    }

    &__accept {
        background: black;
        color: $color-white;
        padding: 1rem 3rem;
        font-family: $medium-main;
        font-size: 1.5rem;
        outline: none;

        &:hover {
            background: rgb(42, 42, 42);
        }
    }

    &__settings {
        display: flex;
        flex-direction: column;


        &-title {
            font-family: $semibold-main;
            text-align: center;
            font-size: 2rem;
        }

        &-subtitle {
            font-family: $light-main;
            font-size: 1.3rem;
            text-align: center;
        }

        &-content {
            width: 100%;
            background: rgb(250, 250, 250);
            max-height: 500px;
            overflow: auto;
            margin-top: 3rem;
            padding-right: 2rem;

        }

        &-template {
            display: flex;
            flex-direction: column;
            border-bottom: 1px solid rgb(121, 121, 121);
            padding-bottom: 2rem;
            padding-top: 1rem;

            &-top {
                display: flex;

                span {
                    display: block;
                    font-family: $semibold-main;
                    font-size: 1.45rem;
                    margin-top: auto;
                }
            }

            &-bottom {
                font-family: $light-main;
                margin-top: 1rem;
                width: 90%;
                font-size: 1.17rem;
            }
        }

        &-btns {
            display: flex;
            flex-direction: column;
            margin-top: 2rem;

            button {
              outline: none;
                &:first-child {
                    background: $color-black;
                    color: $color-white;
                    font-family: $semibold-main;
                    padding: 1rem 5rem;
                    box-sizing: border-box;
                    font-size: 1.4rem;
                    max-width: 300px;
                    margin: 0 auto;
                }

                &:nth-child(2) {
                    font-family: $medium-main;
                    font-size: 1.4rem;
                    margin-top: 2rem;
                    color: rgb(83, 83, 83);
                }
            }
        }
    }
}

// Checkboxes
.check__container {
    margin-left: auto;
    margin-top: auto;
    margin-bottom:auto;
}


@supports(-webkit-appearance: none) or (-moz-appearance: none) {
  input[type='checkbox'],
  input[type='radio'] {
    --active: rgb(0, 0, 0);
    --active-inner: #fff;
    --focus: 2px rgba(0, 0, 0, 0.027);
    --border: rgb(133, 133, 133);
    --border-hover: rgb(0, 0, 0);
    --background: #fff;
    --disabled: #F6F8FF;
    --disabled-inner: #E1E6F9;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 21px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background .3s, border-color .3s, box-shadow .2s;
    &:after {
      content: '';
      display: block;
      left: 0;
      top: 0;
      position: absolute;
      transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
    }
    &:checked {
      --b: var(--active);
      --bc: var(--active);
      --d-o: .3s;
      --d-t: .6s;
      --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
    }
    &:disabled {
      --b: var(--disabled);
      cursor: not-allowed;
      opacity: .9;
      &:checked {
        --b: var(--disabled-inner);
        --bc: var(--border);
      }
      & + label {
        cursor: not-allowed;
      }
    }
    &:hover {
      &:not(:checked) {
        &:not(:disabled) {
          --bc: var(--border-hover);
        }
      }
    }
    &:focus {
      box-shadow: 0 0 0 var(--focus);
    }
    &:not(.switch) {
      width: 21px;
      &:after {
        opacity: var(--o, 0);
      }
      &:checked {
        --o: 1;
      }
    }
    & + label {
      font-size: 14px;
      line-height: 21px;
      display: inline-block;
      vertical-align: top;
      cursor: pointer;
      margin-left: 4px;
    }
  }
  input[type='checkbox'] {
    &:not(.switch) {
      border-radius: 7px;
      &:after {
        width: 5px;
        height: 9px;
        border: 2px solid var(--active-inner);
        border-top: 0;
        border-left: 0;
        left: 7px;
        top: 4px;
        transform: rotate(var(--r, 20deg));
      }
      &:checked {
        --r: 43deg;
      }
    }
    &.switch {
      width: 38px;
      border-radius: 11px;
      &:after {
        left: 2px;
        top: 2px;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        background: var(--ab, var(--border));
        transform: translateX(var(--x, 0));
      }
      &:checked {
        --ab: var(--active-inner);
        --x: 17px;
      }
      &:disabled {
        &:not(:checked) {
          &:after {
            opacity: .6;
          }
        }
      }
    }
  }
  input[type='radio'] {
    border-radius: 50%;
    &:after {
      width: 19px;
      height: 19px;
      border-radius: 50%;
      background: var(--active-inner);
      opacity: 0;
      transform: scale(var(--s, .7));
    }
    &:checked {
      --s: .5;
    }
  }
}

.personalizeaza-txt {
    width:80%;

    p{
        &:first-child {
            font-family: $bold-main;
            font-size: 1.5rem;
        }

        &:nth-child(2) {
            font-family: $light-main;
            font-size: 1.3rem!important;    
        } 

    }
}

.app-my-account__friend-copy-icon {
    margin: 0;
    padding: 0;
}

.app-history-retur-btn {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5rem;
    background: black;
    color: $color-white;
    font-family: $medium-main;
    padding: 1rem 5rem;
    font-size: 1.5rem;
    max-width: 100%;
    box-sizing: border-box;
    outline: none;
}

.app-modal-retur {
    display: flex;
    flex-direction: column;

    &__title {
        font-family: $bold-main!important; 
        font-size: 2rem!important; 
        text-transform: uppercase; 
    }

    &__top-inputs {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 2rem;
    }

    &__input {
        width: 48%; 
 
        input {
            border: 1px solid rgb(141, 140, 140);
        }
    }
}

#scroll::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

#scroll::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

#scroll::-webkit-scrollbar-thumb
{
	background-color: #000000;
	border: 2px solid #555555;
}

@media screen and (max-width: 500px) {

  .app-cookies__main {
    position: fixed;
    padding:4rem 2rem;
    bottom: 0;
  }

  .app-cookies__main-txt {

  }
}
</style>