<template>
  <div class="app-vertical-slider">
    <div class="app-image-slider__vertical">
        <VueSlickCarousel v-bind="settings" :style="{height: '95vh'}" @goTo="page" ref="carouselvertical"> 
        <div v-for="(n, index) in images" :key="n" @click="$emit('changeImage', index)">
            <img  :src="n" alt="" style="height:30px!important;width:30px;" :class="{'inactive-img':index!=page}">
            <div class="inactive-el"></div> 
        </div>
        </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
  import VueSlickCarousel from 'vue-slick-carousel'
export default {
components: { VueSlickCarousel },
props:{
    page:{
        type:Number,
        default:0
    },
    images:{
        type:Array,
        default:()=>{[]}
    }
},
  data(){
    return {
      settings:{
        "dots": false,
        "infinite": false,
        "slidesToShow": 7,
        "slidesToScroll": 1,
        "vertical": true,
        "verticalSwiping": true,
        "swipeToSlide": false, 
        "arrows":false
      },
    }
  },
  watch:{
      page(){
        if(this.page>5)  this.$refs.carouselvertical.next();
        if(this.page<5)  this.$refs.carouselvertical.prev();
    }
  }

}
</script>


<style lang="scss" scoped>

.slick-arrow {
    display: none!important; 


}
.slick-slide img, .slick-slide { 
  height: auto!important;
  width:55px!important;
  position: relative;
}

.slick-slide {
    .inactive-img {
        opacity: 0.7!important;
        cursor: pointer;

        &:hover {
            opacity: 1!important;
        }
    }
}

// .inactive-el {
//     position: absolute; 
//     background: rgba(228, 228, 228, 0.356);
//     width: 100px;
//     height: 100px;
//     z-index: 9999999; 
//     top: 0;
// }

</style>