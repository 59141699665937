<template>
    <div class="app-newsletter" v-if="$store.state.newsletter" :style="{'background-image':'url('+$store.state.newsletter.image +')'}">
        <div class="app-newsletter__content">
            <span class="app-newsletter__title">
                {{$store.state.newsletter.title_1}} <br>
                {{$store.state.newsletter.title_2}}
            </span>
            <span class="app-newsletter__subtitle">
              {{$store.state.newsletter.subtitle}}
            </span>
            <div style="max-width:800px;margin:0 auto;">
                <div class="app-newsletter__genders">
                    <span>
                        {{$store.state.newsletter.choose.title}}
                    </span>
                    <div class="app-newsletter__gender" @click="male=!male">
                        <span class="filter-icon" :class="{'filter-icon--active':male==true}">&nbsp;</span>
                        <span class="app-newsletter__gender-type">{{$store.state.newsletter.choose.genders[0].name}}</span>
                    </div>
                    <div class="app-newsletter__gender" @click="female=!female">
                        <span class="filter-icon" :class="{'filter-icon--active':female==true}">&nbsp;</span>
                        <span class="app-newsletter__gender-type">{{$store.state.newsletter.choose.genders[1].name}}</span>
                    </div>
                </div>
                <div class="app-newsletter__gender" style="margin-top:1rem;" @click="kids=!kids">
                    <span class="filter-icon" style="margin-left:0;" :class="{'filter-icon--active':kids==true}">&nbsp;</span>
                    <span class="app-newsletter__gender-type" style="margin-left:30px;" >{{$store.state.newsletter.choose.genders[2].name}}</span>
                </div>
                <div class="app-newsletter__inp">
                    <span>E-mail</span>
                    <input type="email" placeholder="Adresa de e-mail" v-model="email">
                </div>
                <button class="app-newsletter__btn" @click="sendEmail">
                    Abonati-va
                </button>
            </div>
        </div>
        <!-- <div class="wrapper top-space" v-html="$store.state.newsletter.content"></div> -->
    </div>
</template>


<script>
export default {
    data(){
        return {
            male:false,
            female:false,
            kids:false,
            email:""
        }
    },
    methods:{
     async sendEmail(){
            let male = 0;
            let female = 0;
            let kids = 0;
            if(this.male) male = 1;
            if(this.female) female = 1;
            if(this.kids) kids = 1;
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (re.test(String(this.email).toLowerCase())){
                gtag('event', 'conversion', {'send_to': 'AW-10800167573/dsqnCKfA15EDEJX19Z0o'});
                this.$notify({ type: 'success', text: 'Îți mulțumim că te-ai abonat la newsletter.' })
                let response = await this.axios.get(`/subscribe?domain=${this.$store.state.domain}&email=${this.email}&useridsession=${this.$store.state.auth.useridsession}&token=${this.$store.state.auth.token}&moda_barbati=${male}&moda_femei=${female}&moda_copii=${kids}`);
                console.log(response)
                this.email = "";
                console.log(response)
            }
            else {
                if(this.email == '') this.$notify({ type: 'error',  text: '<i class="bx bx-x close-notif"></i>Te rugam sa introduci o adresa de email'})
                else
                    this.$notify({ type: 'error', text: '<i class="bx bx-x close-notif"></i>Adresa de email este invalida. Te rugam sa incerci din nou' })
            }
        }
    },
	beforeMount(){
		this.$store.dispatch("loadNewsletter")
    }
}
</script>


<style lang="scss" scoped>
.app-newsletter {
    width: 100%;
    background-position: center;
    background-size: cover;
    padding: 10rem 0 3rem 0;

    &__content {
        max-width: 1180px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;   
    }

    &__title {
        font-family: $bold-main;
        text-transform: uppercase;
        color: white;
        display: block;
        text-align: center;
        font-size: 3rem;
        line-height: 30px;
    }

    &__subtitle {
        font-family: $bold-main;
        text-transform: uppercase;
        color: white;
        display: block;
        text-align: center;
        font-size: 2rem;
        line-height: 30px;
        margin-top: 3rem;
    }

    &__genders {
        margin: 0 auto;
        margin-top: 3rem;
        display: flex;

        span{
            &:first-child {
                color: white;
                font-family: $bold-main;
                font-size: 1.3rem;
            }
        }
    }

    &__gender {
        cursor: pointer;

        &-type {
            display: block;
            margin-left: 4.2rem;
            color: white; 
            font-family: $medium-main;
            font-size: 1.4rem;
        }
    }

    &__inp {
        margin-top: 4rem;
        display: flex;
        flex-direction: column;

        input {
            font-family: $light-main;
            padding: .8rem .6rem;
            outline: none;
            border: 1.5px solid #cfcfcf;
        }


        span {
            color: white;
            font-family: $bold-main;
            font-size: 1.3rem;
        }
    }

    &__btn {
        background:black;
        color: white;
        font-family: $bold-main;
        padding: 1rem 4rem;
        display: block;
        margin: 0 auto;
        margin-top: 2rem;
        outline: none;

        &:hover {
            background-color: rgb(44, 44, 44);
        }
    }
}


.filter-icon {
    width: 15px;
    height: 15px;
    overflow: hidden;
    float: left;
    display: block;
    margin-top: 2px;
    white-space: nowrap;
    outline: 1px solid rgb(255, 255, 255);
    outline-offset: 1px;
    margin-left: 15px;

    &--active {
        background: rgb(255, 255, 255);
    }
}

@media only screen and (max-width: 500px){
.app-newsletter__genders{
    flex-direction: column;
}
.app-newsletter__title {
    font-size: 2rem;
}

.app-newsletter__subtitle {
    font-size: 1.3rem;
    line-height: 20px;
}

.app-newsletter__content {
    padding-left: 1rem;
    padding-right: 1rem;
}
.app-newsletter {
    padding-top: 30px;
}
 .app-newsletter__inp {
     margin-top:2rem;
 }
.app-newsletter__gender{ 
    margin: 1rem 0; 
}
.app-newsletter__gender-type {
    margin-left: 30px;
}
.filter-icon {
    margin-left: 0!important;
}
}
</style>