<template>
    <div class="app-retur-box">
        <div class="app-retur-box__left">
            <span class="app-retur-box__title">
               Numar retur {{retur.returnnumber}} 
               
            </span>
            <div class="app-retur-box__left-stats">
                <span>
                    <b>Data retur</b> {{retur.dataretur}} 
                </span>
                &nbsp;
                <span>
                    <b>Status</b> {{retur.statusretur}} 
                </span>
                &nbsp;
                <span>
                    <b>Produs returnat din comanda</b> {{retur.comanda}} 
                </span>
                &nbsp;
                <span>
                    <b>Suma</b> {{retur.suma}} 
                </span>
                &nbsp;
                <span>
                    <b>IBAN</b> {{retur.iban}}
                </span>
                <AppCartProd 
                    style="margin-top:2rem;" 
                    :product="retur.produs"  
                    :simple="false"
                >
                </AppCartProd>
            </div>
        </div>
        <!-- <div class="app-retur-box__right">
            <button>Vezi detalii</button>
        </div> -->
    </div>
</template>

<script>
export default {
    props:{
        retur:{
            type:Object
        }
    }
}
</script>

<style lang="scss" scoped>
.app-retur-box {
    display: flex;
    flex-direction: row;
    border: 1px solid black;
    width: 100%;
    padding: 2rem;
    background: $color-white;

    &__title {
        display: block;
        font-family: $bold-main;
        font-size: 1.3rem;
    }

    &__left {
        display: flex;
        flex-direction: column;
        max-width: 100%; 

        &-stats {
            display: flex;
            margin-top: 1rem;
            flex-wrap: wrap;
            flex-direction: column;

            span {
                display: block;
                font-family: $medium-main;
                font-size: 1.1rem;
            }
        }
    }
    &__right {
        margin-left: auto;
        margin-top: auto;
        margin-bottom: auto;

        button {
            background: transparent;
            border: 1px solid black;
            font-family: $semibold-main;
            font-size: 1.2rem;
            padding: .5rem 4rem;
            outline: none;
        }
    }
}
</style>