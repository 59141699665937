<template>
    <div class="app-wishlist">
        <!-- <div class="wrapper" style="display:flex;"><AppHeaderProd /></div> -->
        <div class="wrapper app-wishlist__main--empty" v-if="!$cookies.get('wishlist')&&$store.state.w_text&&!$store.state.favorites">
            <div class="app-wishlist__how">
                <!-- <h6 class="app-wishlist__how-title">Cum se folosește wishlist-ul?</h6> -->
            <div class="app-w-title" v-if="$store.state.w_text">
                <h5 style="font-size:2.5rem;">Wishlist</h5>
                <h5 class="app-cart__prod-title" style="font-size:2rem!important;">
                    {{$store.state.w_text.title}} 
                </h5>
            </div> 
                <ul class="app-wishlist__how-list" v-if="$store.state.w_text"> 
                    <li v-for="(l, index) in $store.state.w_text.list" :key="index+500">&nbsp;&nbsp;{{l}}</li>
                </ul>
            </div>
            <div class="app-wishlist__empty" v-if="$store.state.w_text">
                <div class="app-wishlist__empty-el">
                    <span class="app-wishlist__empty-el-title">Wishlist-ul tău este gol</span>
                    <span class="app-wishlist__empty-el-description">Alege-ți produsele preferate printr-o inimioară!</span>
                    <span class="app-wishlist__empty-el-icon"><ion-icon name="heart-outline"></ion-icon></span>
                </div>
                <div class="app-wishlist__empty-el" v-if="!$store.state.auth.success">
                    <span class="app-wishlist__empty-el-title" >Am deja cont</span>
                    <span class="app-wishlist__empty-el-description">Conectează-te pentru a sincroniza conținutul coșului între device-uri</span>
                    <!-- <span class="app-wishlist__empty-el-icon"><ion-icon name="heart-outline"></ion-icon></span> -->
                    <button class="app-wishlist__empty-el-btn" @click="goLink('/contul-meu/login')">Conecteaza-te</button>
                </div>
                <div class="app-wishlist__empty-el" style="margin-top:2rem;">
                    <span class="app-wishlist__empty-el-title">Exploreaza produse</span>
                    <span class="app-wishlist__empty-el-description">Exploreaza produsele noastre in diferite sectiuni</span>
                    <!-- <span class="app-wishlist__empty-el-icon"><ion-icon name="heart-outline"></ion-icon></span> -->
                    <button class="app-wishlist__empty-el-btn app-wishlist__empty-el-btn-white" @click="goLink($store.state.w_link)">Exploreaza</button>
                </div>
            </div> 
        </div>
        
        <div class="wrapper app-w-title" style="margin-bottom:0!important;" v-if="$store.state.favorites">
            <h5 class="app-cart__prod-title" style="font-size:2.5rem;">
                Wishlist 
                <span class="app-cart__prod-title-nr" v-if="$store.state.favorites.length>1">{{$store.state.favorites.length}} produse</span>
                <span class="app-cart__prod-title-nr" v-if="$store.state.favorites.length==1">{{$store.state.favorites.length}} produs</span>
            </h5>
        </div>
        
        <div class="wrapper grid-cnt" style="margin-top:2rem;margin-bottom:10vw;" v-if="$store.state.favorites&&$store.state.auth.token">
            <div v-for="(p, index) in $store.state.favorites" :class="{'w-product':index>max}" :key="p.product_id">
                <AppProductEl  :wishlist="true" :product="p"></AppProductEl>
            </div>
            <AppSizesW @selectValue="selectValue"></AppSizesW>
        </div>
        <div class="wrapper grid-cnt" style="margin-top:2rem;margin-bottom:10vw;" v-if="$store.state.favorites&&!$store.state.auth.token">
            <div v-for="(p, index) in reverseFavorites" :class="{'w-product':index>max}" :key="p.productid">
                <AppProductEl  :wishlist="true" :product="p"></AppProductEl> 
            </div>
            
            <AppSizesW @selectValue="selectValue"></AppSizesW>
        </div>
    </div>
</template>


<script>
export default {
    data(){
        return {
            wishlist:[],
            max:1
        }
    },
    computed:{
        reverseFavorites(){
            let x = this.$store.state.favorites;
            x = x.reverse()
            return x;
        }
    },
    methods:{
        goLink(link) {
            window.location = link;
        },
        selectValue(index){
            this.$store.state.w_opened.selected[index] = !this.$store.state.w_opened.selected[index]
            console.log(this.$store.state.w_opened)
        }
    },
    beforeMount(){
        this.$store.dispatch("loadWishlist")
        if(!this.$store.state.auth.success) {
            this.wishlist = this.$cookies.get('wishlist');
        }
        if(window.innerWidth > 1000) {
            this.max=4;
        }
    }
}
</script>


<style lang="scss" scoped>
.app-wishlist {

    &__main {
        
        &--empty {
            display: flex;
            margin-top: 2.5rem;
        }
    }

    &__how {
        padding-right: 5rem;

        &-title {
            font-family: $bold-main;
            font-size: 1.7rem;
        }

        &-list {
            list-style: none;
            font-family: $semibold-main;
            font-size: 1.1rem;
            list-style-position: inside;
            

            li{
                margin:2rem 0;
                text-indent: -9px;

                &::before {
                    content: "-";
                }
            }
        }
    }

    &__empty {
        display: flex;
        flex-direction: column;
        background: $color-gray-light;
        padding: 5rem 20rem;
        text-align: center;
        margin-left:auto;

        &-el{

            span{
                display: block;
            }

            &-title{
                font-family: $bold-main;
                font-size: 2rem;
            }

            &-description {
                font-family: $medium-main;
                font-size: 1.2rem;
                margin-top: 1rem;
            }

            &-icon {
                font-size: 4rem;
                color: rgb(88, 88, 88);
                margin-top: 2rem;
            }

            &-btn {
                width: 100%;
                background: $color-black;
                color: $color-white;
                border: none;
                outline: none;
                padding:1rem 0;
                font-family: $light-main;
                margin-top: 2rem;
                cursor: pointer;

                &-white {
                    background-color: $color-white;
                    color: $color-black;
                    font-family: $medium-main;
                    box-shadow: 0 0 0 1px $color-black;
                    transition: all .5s;

                    &:hover {
                        box-shadow: 0 0 0 2px $color-black;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1050px) {
    .wrapper {
        margin-bottom: 27vw!important;
    }
    .app-wishlist__main--empty{
        flex-direction: column;
        justify-content: center;
    }
    .app-wishlist__how,
    .app-wishlist__empty,
    .app-wishlist-header{
        display: block;
        margin: 0 auto!important;;
    }

    .app-wishlist__how{
        margin-right: 0!important;
        margin-left: 10px!important;
    }
    .app-wishlist__empty {
        margin-top: 2rem!important;
        width:97vw;
        padding: 5rem 10rem;
        box-sizing: border-box;
    }
}

@media only screen and (max-width: 550px) {
    .app-wishlist__empty{
        padding:3rem 0;
        width: 100%;

        button {
            width: 80%;
        }
    }
}

.grid-cnt {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    row-gap: 8rem;
    column-gap: 3rem;
}

@media only screen and (max-width: 1000px) {
    .grid-cnt {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        row-gap: 3rem;
        column-gap: 2rem;
    }
}
@media only screen and (max-width: 1000px) {
    .grid-cnt {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 3rem;
        column-gap: 2rem;
    }
    .w-product {
        margin-top: 23vw!important;
    }
}

@media only screen and (max-width: 500px) {
    .w-product {
        margin-top: 3vw!important;
    }
}

.w-product {
    margin-top: 5rem;
}

.app-w-title {
    margin-top: 2rem;
    padding-bottom: 1rem;
    h5 {
        font-family: $bold-main;
        font-size: 2.4rem;
        display: block;
    }
}

.app-cart__prod-title{
    display: flex!important; 
      font-family: $bold-main;
    font-size:2.4rem!important; 
}
.app-cart__prod-title-nr {
    font-family: $light-main;
    color: #585858; 
    font-size: 10px;;
    display: block;
    margin:auto 0;
    margin-left: 1rem;;
}
</style>