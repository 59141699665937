<template>
    <div class="app-types" v-if="$store.state.main"  @mouseleave="opened=false">
        <div class="app-types-d">
            <div class="app-types__cnt">
                <div class="app-types__cnt-left">
                    <ul>
                        <li @click="goLink(c.link)" v-for="(c, index) in $store.state.main.menucategories[genderIndex].content" :key="index"  @mouseover="openCategories(c)">
                            <a :href="c.link" :style="{color:c.menucolor}">{{c.text}}</a>
                        </li>
                    </ul>
                </div> 
                <div class="app-types__cnt-right" >
                    <!-- <input type="text" class="app-types__cnt-right-input" placeholder="Cauta..." @input="searchProds" v-model="searchW">
                    <i class='bx bx-search-alt'></i>
                    <div class="app-types__cnt-right__opened-search" v-if="searchresponses.length>0">  
                        <span v-for="(s, index) in searchresponses" :key="index">
                            <a @click="searchLink(s)" v-html="boldString(s, searchW)"></a>
                        </span>
                    </div> -->
                </div>
            </div>
            <div class="app-types__opened" @mouseleave="opened=false" v-if="opened&&subCategories!=false">
                <div class="app-types__section" v-for="(s, index) in subCategories" :key="index">
                    <div class="app-types__section-title">
                        {{s.title}} 
                    </div>
                    <ul :class="{'grid-brands':s.title=='Branduri'}"> 
                        <li :class="{'last-grid-el':m.text=='Vezi toate'}" v-for="(m, index) in s.menu" :key="index">
                            <a :href="m.link" :class="{'link-bold':m.bold==true}">
                                {{m.text}} 
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="app-types-m" v-if="version=='m'">
            <div v-if="backText" class="app-types-m-el-template app-types-m-el-template-gray" @click="counter=0,backText=false">
                <span>
                <ion-icon name="return-down-back-outline"></ion-icon>   
                {{backText}}</span>
            </div>
            <div class="app-types-m-el-template"  v-for="(c, index) in mobileNav[counter]" :key="index"> 
                <span v-if="c.show" @click="changeLvlMobile(index, c.text)" >{{c.text}} <img  src="../assets/images/right-arrow.png" alt="right-arrow"></span>
                <a v-else :href="c.link">{{c.text}} </a>
            </div>
        </div>
    </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
export default {
    directives: {
        ClickOutside
    },
    props:{
        version:{
            type:String,
            default:'m'
        },
        closeMobile:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return {
            opened:false,
            subCategories:[],
            level:1,
            m_cat:[],
            mobileNav:[],
            counter:0,
            backText:"",
            openedSearch:false,
            searchW:"",
            searchresponses:[]
        }
    },
    computed:{
        genderIndex(){
            // return 1;
            let gender = this.$cookies.get('gender');
            // console.log(gender)
            // let gender = this.$cookies.get('gender');
            if(gender) return gender-1
            return 0;
            
        }
    },
    methods:{
        boldString(str, substr) {
            let strRegExp = new RegExp(substr, 'g');
            console.log(substr)
            return str.replace(strRegExp, '<b>'+substr+'</b>');
        },
        searchLink(search) {
            let r = '';
            let index = this.$route.params.type.indexOf("&search");
            if(index!=-1){
                let str = this.$route.params.type.split('&');
                str.forEach(item => {
                    let cpy = item.split('=');
                    if(cpy[0]=='search') {
                        item = "search=" + search; 
                    }
                    if(cpy[0]=='pagina') {
                        item = "";
                        // alert(1)
                    }
                    if(item!="")
                    r = r + item + '&';
                });
                this.$store.commit("SET_CURR_PAGE", 0);
                r = r.slice(0, -1)
                window.location = r;
                // this.$router.push(r);
            }
            else {
                window.location = this.$route.params.type+"&search="+search;
                // this.$router.push(this.$route.params.type+"&search="+search);
            }
        },
        async searchProds() {
            this.openedSearch = true;
            let response = await this.axios.get(`/searchhelper?&domain=${this.$store.state.domain}&presearch=${this.searchW}`);
            this.searchresponses = [...response.data.presearch]
            console.log(this.searchresponses);
        },
        goLink(link){
            this.$router.push(link);
        },
        changeLvlMobile(index, text) {
            this.backText = text;
            let gender = this.$cookies.get('gender');
            if(!gender) gender = 1;
            this.counter++;
            let arr = [];
            arr.push(...this.$store.state.main.menucategories[gender-1].content[index].content[0].menu);
            this.mobileNav[this.counter] = arr;
            console.log(this.mobileNav)
        },
        openCategories(el) {
            this.opened = el.show;
            if(el.show!=false) this.subCategories = [...el.content];
            else this.subCategories = [];
            // console.log(el)
        }
    },
    created(){
        console.log(1)
        if(this.$cookies.get('gender')){
        let gender = this.$cookies.get('gender');
        console.log(this.$store.state.main.menucategories[gender-1].content)
        if(!gender) gender = 1;
        let arr = [];
        arr.push(...this.$store.state.main.menucategories[gender-1].content);
        this.mobileNav[0] = arr;
        }
    },
    watch:{
        searchW(){
            if(this.searchW.length<3) this.openedSearch = false;
        },
        '$store.state.main':function(){
            console.log(1)
            if(this.$cookies.get('gender')){
            let gender = this.$cookies.get('gender');
            console.log(this.$store.state.main.menucategories[gender-1].content)
            if(!gender) gender = 1;
            let arr = [];
            arr.push(...this.$store.state.main.menucategories[gender-1].content);
            this.mobileNav[0] = arr;
            }
        },
        closeMobile(){
            setTimeout(
                ()=>{
                    if(this.mobileNav.length > 1) this.mobileNav.pop();
                    this.backText = "";
                    this.counter = 0;
                },1000
            )
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/_variables.scss";
@import "../assets/styles/main.scss";
.app-types {

    &-m {

        display: none;

        &-el-template {
            
            border-bottom: 1px solid #e5e5e5;
            width: 100vw;
            margin-top: 2rem;

            &:last-child {
                margin-bottom: 100px;
            }

            span,a {
                text-decoration: none;
                font-family: $semibold-main;
                font-size: 1.3rem;
                padding: 1rem 0 2rem 0;
                padding-left: 3rem;
                display: flex;
                color:$color-black;

                img {
                    height: 1.7rem;
                    margin-left: auto;
                    padding-right: 2rem;
                    transform: translateY(-2px);
                }
            }

            &-gray {
                margin-top: 0;
                border-top: 1px solid rgb(175, 175, 175);
                background-color: #f4f4f4;
                padding-top: 25px;

                span {
                    display: flex;
                    margin-bottom: -1rem;
                    color: rgb(56, 56, 56);
                    ion-icon {
                        font-size: 2.5rem;
                        padding-right: 2rem;
                        padding-bottom: 0-.4rem;
                    }
                }
            }
        }
    }

    &__cnt {
        background: $color-gray-light;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        // -webkit-box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%);
        // box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%);

        &-right {
            margin-right: 2rem;
            display: flex;
            position: relative;

            &-input {
                display: block;
                margin-top: auto;
                margin-bottom: auto;
                border-bottom: 1px solid black!important;
                background: transparent;
                font-family: $light-main;
                outline: none;
                position: relative;
                padding-right: 3.3rem;
                padding-left: .5rem;
            }

            i {
                position: absolute;
                right: 5px;
                top: 20px;
                font-size: 2rem;
                cursor: pointer;
            }

            &__opened-search {
                position: absolute;
                top: 100%; 
                background: #f4f4f4;
                padding: .1rem .5rem 1rem .5rem;
                box-sizing: border-box;
                width: 262px; 
                z-index:10000000;

                span {
                    display: block;
                    font-family: $medium-main;
                    margin: .5rem 0;
                    cursor: pointer;
                    font-size: 1.3rem;

                    a {
                        text-decoration: none;
                        color: black;
                    }
                }
            }
        }

        &-left {
            margin-left: 2rem;
            box-sizing: border-box;

            ul {
                list-style: none;
                display: flex;
                box-sizing: border-box;

                li {
                    padding: 2rem;
                    box-sizing: border-box;
                    cursor: pointer;

                    a {
                        font-family: $bold-main;
                        text-decoration: none;
                        color: $color-black;
                        font-size: 1.3rem;
                        box-sizing: border-box;
                    }
                    &:hover {
                        background: #fff;
                         box-shadow: inset 0 -2px 0 0 $color-black;
                    }
                }
            }
        }
    }

    &__opened{
        position: absolute;
        border-top: 1px solid rgb(230, 230, 230);
        padding-left: 3rem;
        background: $color-gray-light;
        padding-top: 1rem;
        width: 100vw;
        padding-bottom: 2rem;
        z-index: 122200;
        display: flex;
    }

    &__section {
        margin-left: 10%;
        text-align: start;

        &:first-child {
            margin-left: 0;
        }

        &-title {
            font-family: $bold-main;
            font-size: 1.3rem;
            text-transform: uppercase;
        }

        ul{
            margin-top: 1rem;
            list-style: none;

            li {
                margin-top: .5rem;

                a {
                    color: $color-black;
                    text-decoration: none;
                    font-family: $semibold-main;
                    font-size: 1.1rem;
                }
            }
        }
    }
}

@media only screen and (max-width: 850px) {
    .app-types-d {
        display: none;
    }

    .app-types-m {
        display: block;
        z-index: 1000000;
    }
}

.link-bold {
    font-family: $bold-main!important;;
}
.grid-brands {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 15rem;
}
.last-grid-el {
grid-column: last-col / span 1;
//   grid-row: last-line / span 1;
}
</style>