<template>
    <div class="app-sizesw" v-if="opened">
        <div class="app-sizesw__main">
            <div class="app-sizesw__main-top">
                <div class="app-sizesw__main-title">
                    {{title}}
                </div>
                <span @click="goLink($store.state.deleteF.returnlink)" v-if="simple==0&&$store.state.deleteF.possible">sterge tot</span>
                <span @click="deleteAllFromCategory" v-if="simple==1&&canDelete==true">sterge</span>
                <!-- <ion-icon name="close-outline" @click="closeSizes"></ion-icon> -->
            </div>
            <div class="search_brands" v-if="title == 'Brand'">
                <input type="text" placeholder="Cauta..." :value="brand" @input='evt=>brand=evt.target.value'>
                <i class='bx bx-search'></i>
            </div>
            
            <div class="app-sizesw__main-templates" v-if="simple==0" >
                <div style="border-bottom: 1px solid rgb(141, 141, 141)"
                v-for="(x,index) in filter.filter(el => el!=null&&el.show)" :key="index" @click="selectCategory(x, index, 1)">
                <div class="app-sizesw__main-template" >
                    <span class="filter-icon" v-if="simple!=0" :class="{'filter-icon--active':x.selected}">&nbsp;</span>
                    <!-- <div class="app-sizesw__main-template-check" v-if="simple!=0">
                        <div v-if="x.selected"></div>
                    </div> -->
                    <span class="app-sizesw__main-template-size" v-if="x">{{x.name}}</span>
                    <span class="app-sizesw__main-template-price" v-if="simple==0"><i class='bx bx-chevron-right' ></i></span>
                </div>
                <div class="app-filter-d__category-content" style="overflow:hidden!important;padding-bottom:20px;" v-if="x.type=='pricebar'">
                    <div class="prices-section">
                        <span>{{x.selectrange[0]}}</span >
                        <span>{{x.selectrange[x.selectrange.length-1]}}</span >
                    </div>
                        <vue-slider
                        style="margin-top:10px;"
                            @drag-end="setPrice(x.selectrange)"
                            v-model="x.selectrange"
                            :min="x.range[0]"
                            :max="x.range[x.range.length-1]"
                            :min-range="0"
                            :height="10"
                            :process-dragable="true"
                            v-bind="options"
                        ></vue-slider>
                        <!-- <div class="price-inputs">
                            <input type="text" v-model="f.selectrange[0]">  
                            <i class='bx bx-move-horizontal' ></i>
                            <input type="text" v-model="f.selectrange[f.selectrange.length-1]">
                            <button @click="setPrice(f.selectrange)"><i class='bx bx-chevron-right'></i></button>
                        </div> -->
                    <!-- <div class="app-filter-d__category-content--sizes-el" 
                         v-for="(m, ind) in f.values" :key="m" @click="selectC(f.ID, ind)"
                         :class="{'app-filter-d__category-content--sizes-el--active':m.selected,
                         'one-size':m.name=='One Size'}">
                        {{m.name}} 
                    </div> -->
                </div>
                </div>
            </div>
            <div class="app-sizesw__main-templates" v-if="simple==1" :class="{'brand-margin ':title=='Brand'}">
                <div class="app-sizesw__main-template" v-for="(x,index) in section2" :class="{'hide-el':x==null||x.show!=true}" :key="index" @click="selectCategory(x, index, 2)">
                    <span class="filter-icon"  :class="{'filter-icon--active':x.selected}">&nbsp;</span>
                    <span class="app-sizesw__main-template-size" v-if="x">{{x.name}}</span>
                    <span class="app-sizesw__main-template-price" v-if="simple==0"><i class='bx bx-chevron-right' ></i></span>
                </div>
            </div>
            <button @click="seeProducts" v-if="simple==0">Vezi {{$store.state.browse.browsetitles[1].text}}</button>
            <button @click="saveComplex" v-else>{{textBtn}}</button>
        </div>
    </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import $ from 'jquery'
export default {
    components: {
        VueSlider
    },
    data(){
        return {
            se:[10,122],
            sizes:null,
            localCategories:[],
            copyCat:[],
            activeID:false,
            simple:0,
            title:"Filtreaza",
            activeSection:"",
            updated:false,
            filter:[],
            section2:[],
            copyParams:"",
            options:{
                tooltip:"none"
            },
            saved:true,
            brand:"",
            textBtn:"Inapoi"
        }
    },
    props:{
        opened:{
            type:Boolean,
            default:false
        },
        categories:{
            type:Array,
            default:()=>{[]}
        }
    },
    watch:{
        brand(){
            this.searchBrand()
        },
        '$route.params.type':function(){
            if(this.copyParams == this.$route.params.type) this.textBtn = "Inapoi";else this.textBtn = "Salveaza"
        },
        opened(){
            if(this.opened) 
                $('html, body').css({
                    overflow: 'hidden',
                    height: 'auto'
                });
                this.localCategories = JSON.parse(JSON.stringify(this.categories));
        },
        "$store.state.filter":function() {
            this.filter = [...this.$store.state.filter];
        }
    },
    computed:{
        canDelete(){
            for(let i = 0; i < this.section2.length;i++) {
                if(this.section2[i].selected) return true;
            }
            return false;
        }
    },
    methods:{
        updatePage(routeX) {
            let route = '';
            route = routeX
            if(routeX.indexOf('&pagina=') >= 0){
                route = route.replace('&pagina='+this.$store.state.browse.pagination.currentpage, "");
            }
            return route + '&pagina=1';
        },
        goLink(link){
            this.$router.push(link)
        },
        deleteAllFromCategory(){
            for(let i = 0; i < this.section2.length;i++) {
                this.section2[i].selected = false;
            }
            this.deleteAll(this.activeSection)
        },
        deleteAll(id){ 
            let ind = this.$route.params.type.indexOf(id)
            if(ind!=-1) {
                let str = this.$route.params.type.split('');
                str.splice(ind, 1);
                while(str[ind] != '&'||str.length==ind-1) {
                str.splice(ind, 1);
                console.log(str.length)
                if(str.length==ind) {
                    str.splice(ind-1, 1);
                    break;
                }
                }
                str.splice(ind, 1);
                str = str.join('');
                console.log(str)
                this.$router.push(this.updatePage(str)) 
            }
        },
        searchBrand(){
            for(let i = 0; i < this.section2.length;i++) {
                let name = this.section2[i].name.toLowerCase()
                if(name.startsWith(this.brand.toLowerCase())) this.section2[i].show = true;else this.section2[i].show = false;
            }
        },
        closeSizes(){
            this.brand = ""
            this.title = "Filtreaza";
            this.updated = false;
            if(this.simple==0){
                this.$emit("closeFilter");
                $('html, body').css({
                    overflow: 'auto',
                    height: 'auto'
                });
            }
            else {
                this.$router.push(this.updatePage(this.copyParams))
                this.saved = true;
                this.simple = 0;
                this.$store.dispatch('loadProduse',this.$route.params.type,this.$route.params.type)
                
            }
        },
        saveComplex() {
            this.title = "Filtreaza";
            this.textBtn = "Inapoi"
            this.brand = ""
            this.saved = true;
            this.simple = 0;
            this.$emit("updateComplex");
        },
        seeProducts(){
            this.updated = false;
            this.simple = 0;
            this.localCategories = [];
            this.$emit('closeFilter');
        },
        setPrice(period) {
            let index1 = this.$route.params.type.indexOf("&maxprice");
            let index2 = this.$route.params.type.indexOf("&minprice");
            if(index1==-1&&index2==-1) {
                let price = "&maxprice="+period[1] + "&minprice=" + period[0];
                this.$router.push(this.updatePage(this.$route.params.type + price))
            }
            else {
                let str = this.$route.params.type.split('');
                str.splice(index1, 1);
                let count = 0;
                while(str[index1]!=undefined) {
                    if(str[index1] == '&') count++;
                    if(count == 2) {break; }
                str.splice(index1, 1);
                console.log(str.length)
                if(str.length==index1) {
                    // str.splice(index1-1, 1);
                    break;
                }
                }
                console.log(str)
                str = str.join('');
                let price = "&maxprice="+period[1] + "&minprice=" + period[0];
                this.$router.push(this.updatePage(str+price)) 
            }
        },
        selectCategory(c, index, l){
            console.log(c)
            if(l==1&&c.name!='Pret') {
                this.title = c.name;
                this.simple = l;
                this.section2 = JSON.parse(JSON.stringify(c.values));
                this.activeSection = c.ID;
                this.copyParams = this.$route.params.type;

            }
            if(l==2) {
                // this.simple = l;
                this.section2[index].selected = !this.section2[index].selected
                // if(this.saved==true){
                //     this.copyParams = this.$route.params.type;
                //     // alert(this.copyParams)
                //     }
                    // this.saved = false;


                    // Delete from route
                        let r = this.$route.params.type;
                        console.log(r)
                        let inddx = r.indexOf(this.activeSection)
                        if(inddx!=-1) {
                            let textx = "" 
                            r = this.$route.params.type.split('');
                            for(let i = inddx ; i < r.length;i++) {
                                if(r[i]=='&'||r[i]==null) {console.log('opriteeeeeeeeeeeeeeeeeeee',r[i]);break;}
                                textx = textx + r[i];
                            }
                            r = r.join('');
                            r = r.replace(textx,'');
                            console.log(r)
                            let valuesSelected = ""
                            // r = r.join('')
                            // r = r+ this.activeSection + '=';
                            let ok = false;
                            for(let i = 0;i < this.section2.length;i++) {
                                if(this.section2[i].selected) {
                                    valuesSelected=valuesSelected+this.section2[i].ID + '+';
                                    ok = true;
                                    }
                            }
                            if(ok == false) this.deleteAll(this.activeSection);
                            else {
                            valuesSelected = valuesSelected.slice(0, -1);
                            if(r.charAt(r.length - 1)) r = r.slice(0, -1);
                            r = r + '&'+this.activeSection + '=' + valuesSelected;
                            this.$router.push(this.updatePage(r));}
                        }
                        else {
                            let ok = false;
                            let valuesSelected = ""
                            // r = r.join('')
                            // r = r+ this.activeSection + '=';
                            for(let i = 0;i < this.section2.length;i++) {
                                if(this.section2[i].selected) {
                                    valuesSelected=valuesSelected+this.section2[i].ID + '+';
                                    ok = true;
                                }
                            }
                            if(ok == false) this.deleteAll(this.activeSection);
                            else {
                                valuesSelected = valuesSelected.slice(0, -1);
                                r = r + '&'+this.activeSection + '=' + valuesSelected;
                                this.$router.push(this.updatePage(r)) 
                            } 
                        }
          
  
                        //     r = r.join('')
                        //     r = r+ this.activeSection + '=';
                        //     for(let i = 0;i < this.section2.length;i++) {
                        //         if(this.section2[i].selected) r = r + this.section2[i].ID + "+";
                        //     }
                        //     r.slice(0, -1);
                        //     this.$router.push(r)
                        // }
                        // else {
                        //      r = r+ this.activeSection + '=';
                        //     for(let i = 0;i < this.section2.length;i++) {
                        //         if(this.section2[i].selected) r = r + this.section2[i].ID + "+";
                        //     }
                        //     this.$router.push(r)
                        // }
                    
                // this.selectC(this.activeSection, index)
            }
        },
        setActive(id) {
            console.log(this.categories)
            if(this.copyCat.length==0) this.copyCat = [...this.categories]
            let cp = JSON.parse(JSON.stringify(this.localCategories));
            for(let i =0;i < cp.length;i++) {
                if(cp[i].ID == id) cp[i].active = true;
                else cp[i].active = false;
            }
            this.localCategories = JSON.parse(JSON.stringify(cp));
            this.activeID = id;
        }
    },
    beforeMount(){
        this.filter = [...this.$store.state.filter];
    }
}
</script>

<style lang="scss" scoped>
.app-sizesw{
    z-index: 99999999999999;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.459);
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;

    &__main {
        margin: auto;
        width: 600px;
        max-width: 600px;
        background: $color-white;
        padding-bottom: 2rem;
        height: 80vh;
        max-height: 80vh;
        position: relative;
        // overflow: auto;
        
        &-top {
            // -webkit-box-shadow: 0px 11px 16px -11px rgba(0,0,0,0.75);
            // -moz-box-shadow: 0px 11px 16px -11px rgba(0,0,0,0.75);
            // box-shadow: 0px 11px 16px -11px rgba(0,0,0,0.75);
            display: flex;
            padding: 2rem;
            border-bottom: 1px solid rgb(141, 141, 141);
            position: relative;


            span {
                display: block;
                margin-left: auto;
                margin-top: auto;
                margin-bottom: auto;
                font-size: 1.15rem;
                font-weight: bold;
                cursor: pointer;
                color: rgba(31, 31, 31, 0.8);
                font-family: $light-main!important;
            }
        }

        &-title {
            font-family: $semibold-main;
            font-size: 2rem;
            
        }

        &-templates {
            height:calc(100% - 120px);
            overflow: auto;
            box-sizing: border-box;
            padding-bottom: 100px;
        }

        &-template {
            display: flex;
            padding: 1.5rem 2rem;
            cursor: pointer;

            span {
                display: block;
                font-size: 1.7rem;
                margin-top: auto;
                margin-bottom: auto;
            }

            &-check {
                display: block;
                width: 15px;
                height: 15px;
                border: 1px solid black;
                box-sizing: border-box;
                margin-top: auto;
                margin-bottom: auto;
                position: relative;

                div {
                    position: absolute;
                    background: black;
                    width: 9px;
                    height: 9px;
                    margin: auto;
                    top:6px;
                    left: 7px;;
                    transform:translateY(-50%) translateX(-50%);
                }
            }

            &-price {
                margin-left: auto;
            }

            &-size {
                margin-left: 1rem;
            }
        }

        button {
            
            display: block;
            width: calc(100% - 4rem);
            margin-left: auto;
            margin-right: auto;
            margin-top: 2rem;
            background: #000000;
            color: $color-white;
            padding: 1.5rem;
            font-family: $semibold-main;
            font-size: 1.5rem;
            // bottom: 10px;
            // left: 50%;
            // transform: translateX(-50%);


        }
    }
}

@media only screen and (max-width: 650px){
    .app-sizesw__main {
        max-width: 99vw;
        max-height: 99vh;
        width: 100vw;
        height: 100vh;
    }

    button {
        position: fixed;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
    }
}

.app-filter-d__category-content {
    display: flex;
    flex-direction: column;
    width:90%!important;
    margin: 0 auto;

    span {

    }
}

.prices-section {
    display: flex;
    justify-content: space-between;

    span {
        font-size: 1.3rem;
        
        &:first-child {
            margin-left: 10px;
        }

        &:nth-child(2) {
            margin-right: 5px;
        }
    }
}

.search_brands {
    border: 1px solid rgb(209, 209, 209);
    position: absolute;
    width: 100%;
    z-index: 123443;
    
    input {
        width: 100%!important;
        outline: none;
        font-family: $light-main;
        padding: 1rem 0;
        padding-left: .5rem;
        position: relative; 
        font-size: 16px;
        padding-right: 4rem;
    }

    i {
        position: absolute;
        font-size: 2rem;
        top: 50%;
        z-index: 1223232323;
        right: 10px;
        transform: translateY(-50%);
        
    }
}

.hide-el {
    display: none!important;;
}

.brand-margin {
    margin-top: 50px!important;
}

.filter-icon {
    width: 9px;
    height: 9px;
    overflow: hidden;
    float: left;
    display: block;
    margin-top: 4px;
    white-space: nowrap;
    outline: 1px solid #000;
    outline-offset: 1px;

    &--active {
        background: black;
    }
}
</style>