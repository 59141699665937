<template>
  <div class="app-image-slider" v-if="show" >
    <div class="app-image-slider__close"  @click="$emit('closeBigSlider')">
      <i class='bx bx-x' ></i>
    </div>
    <div class="app-image-slider__vertical">
      <AppVerticalSlider :page="page" :images="images" @changeImage="pageChange"/> 
<!-- <VueSlickCarousel v-bind="settings" :style="{height: '100vh'}">
  <div v-for="n in images" :key="n">
    <img :src="n" alt="" style="height:30px!important;width:30px;">
  </div>
</VueSlickCarousel> -->
    </div>
    <div class="app-image-slider__big">
      <div 
        class="arrow-big arrow-big__left" 
        :class="{'invisible-arrow':page<=0}" @click="goBack">
        <i class='bx bx-chevron-left'></i>
      </div>
     <carousel
                    style="max-width:95%;"
                    ref="carousel" 
                    :perPage="1" 
                    :navigate-to="page"
                    @pageChange="pageChange"
                    :loop="true"
                    :mouse-drag="true"
                    :paginationEnabled="false"
                    >
                        <slide v-for="(s,index) in images" :key="index" style="width:95vh">
                              <img :src="s" alt="" oncontextmenu="return false">
                        </slide>
                    </carousel>
    <div 
      class="arrow-big arrow-big__right" 
      :class="{'invisible-arrow':page>=images.length-1}"  @click="goNext">
      <i class='bx bx-chevron-right'></i>
    </div> 

    </div>
  </div>
</template>

<script>
import AppVerticalSlider from "./AppVerticalSlider.vue";
  // import VueSlickCarousel from 'vue-slick-carousel'
export default {
// components: { VueSlickCarousel },
components:{
  AppVerticalSlider
},
props:{
    activePage:{
        type:Number,
        default:null
    },
    images:{
        type:Array,
        default:()=>[]
    },
    show:{
      type:Boolean,
      default:false
    }
},
computed:{
  activeP(){
    this.page = this.activePage
    return this.activePage;
  }
},
methods:{
  pageChange(i){
    this.page = i;
  },
  goNext(){
    this.pageChange(this.page+1)
  },
  goBack(){
    this.pageChange(this.page-1)
  }
},
created(){
   document.addEventListener('keyup',  (evt)=> {
        if (evt.keyCode === 27) {
            this.$emit('closeBigSlider')                            
        }
    });
},
watch:{
  activeP(){
    alert(this.activePage)
  }
},
  data(){
    return {
      page:0,
    }
  }

}
</script>


<style lang="scss" scoped>


// .slick-slide img, .slick-slide { 
//   height: 100px!important;
//   width:100px!important;
// }
.app-image-slider {
  width: 100vw!important;;
  height: 100vh!important;
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  z-index: 99999999999;

  &__big {
    width: 95vw;
    box-sizing: border-box;
    padding: 0 3rem;
    height: 100vh;
    margin-left: auto;
    display: flex;
    justify-content: center;

    img {
      display: block;
      height:100vh!important;
      width:auto;
      margin: 0 auto;
      // height: 100vh!important;
      // width: auto; 
    }
  }

  &__close {
    margin-left: auto;
    position: fixed;
    right: 20px;
    top: 20px;
    font-size: 40px;
    cursor: pointer;
  }
}
.arrow-big {
  margin-top: auto;
  margin-bottom: auto; 
  font-size: 30px;
  padding: 15px;
  transition: all .5s; 
  display: flex;
  flex-direction: column;
  justify-content: center;

  i{
    display: block;
    margin: auto;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    cursor: pointer;
  }
}

.invisible-arrow {
  visibility: hidden;
  opacity: 0;
}
</style>