<template>
    <div class="app-types" v-if="$store.state.main"  @mouseleave="opened=false">
        <!-- <div class="search-opened-background"></div> -->
        <div class="app-types-d">
            <div class="app-types__cnt">
                <div class="app-types__cnt-left">
                    <ul>
                        <li @click="openCategories(c)"  v-for="(c, index) in $store.state.main.menucategories[genderIndex].content" :key="index">
                            <a v-if="index==0" :href="c.link" :style="{color:c.menucolor}">{{c.text}}</a>
                            <a v-else href="#" :style="{color:c.menucolor}">{{c.text}}</a>
                        </li>
                    </ul>
                </div> 
                <div class="app-types__cnt-right" style="display:none;">
                    <input type="text" class="app-types__cnt-right-input" placeholder="Cauta..." @input="searchProds" v-model="searchW">
                    <i class='bx bx-search-alt search-input-icon'></i>
                    <i class='bx bx-x delete-input' v-if="searchW" @click="searchW=''"></i>
                    <div class="app-types__cnt-right__opened-search" >  
                        <div class="response-all">
                            <div class="response-all__icon1"><i class='bx bx-search-alt '></i></div>
                            <span class="response-all__search">{{searchW}}</span>
                            <span class="response-all__see">
                                Toate rezultatele
                                <i class='bx bx-chevron-right' ></i>
                            </span>
                        </div>
                        <div class="response-section">
                            <div class="response-section__header">
                                <span class="response-section__header-title">Branduri</span>
                                <span class="response-section__header-more">Arată mai mult</span>
                            </div>

                            <div class="response-section__template" v-for="n in 3" :key="n">
                                <div class="response-section__template-img">
                                    <i class='bx bx-category' ></i>
                                    <!-- <img src="../assets/images/b596ce37a98d18c02a1e10773f3abbb3.webp" alt=""> -->
                                </div>
                                <div class="response-section__template-name">
                                    <!-- <span class="response-section__template-name--big">
                                        ADIDAS SPORTSsddddddddddfdf
                                    </span> -->
                                    <div class="response-section__template-name--small">
                                        <span class="response-section__template-name--small-text1">
                                            Sport top ' adidas x Karlie Kloss'
                                        </span>
                                        <span class="response-section__template-name--small-text2">
                                            ADIDAS PERFORMANCE
                                        </span>
                                    </div>
                                </div>
                                <div class="response-section__template-number">
                                    <div>
                                        1359
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <span v-for="(s, index) in searchresponses" :key="index" >
                            <a @click="searchLink(s)" v-html="boldString(s, searchW)"></a>
                        </span> -->
                    </div>
                </div>
            </div>
            <div class="app-types__opened" @mouseleave="opened=false" v-if="opened&&subCategories!=false">
                <div class="app-types__section" v-for="(s, index) in subCategories" :key="index">
                    <div class="app-types__section-title">
                        {{s.title}} 
                    </div>
                    <ul :class="{'grid-brands':s.title=='Branduri'}"> 
                        <li :class="{'last-grid-el':m.text=='Vezi toate'}" v-for="(m, index) in s.menu" :key="index">
                            <a :href="m.link" :class="{'link-bold':m.bold==true}">
                                {{m.text}} 
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
export default {
    directives: {
        ClickOutside
    },
    props:{
        version:{
            type:String,
            default:'m'
        },
        closeMobile:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return {
            opened:false,
            subCategories:[],
            level:1,
            m_cat:[],
            mobileNav:[],
            counter:0,
            backText:"",
            openedSearch:false,
            searchW:"",
            searchresponses:[]
        }
    },
    computed:{
        genderIndex(){
            // return 1;
            let gender = this.$cookies.get('gender');
            // console.log(gender)
            // let gender = this.$cookies.get('gender');
            if(gender) return gender-1
            return 0;
            
        }
    },
    methods:{
        boldString(str, substr) {
            let strRegExp = new RegExp(substr, 'g');
            console.log(substr)
            return str.replace(strRegExp, '<b>'+substr+'</b>');
        },
        searchLink(search) {
            let r = '';
            let index = this.$route.params.type.indexOf("&search");
            if(index!=-1){
                let str = this.$route.params.type.split('&');
                str.forEach(item => {
                    let cpy = item.split('=');
                    if(cpy[0]=='search') {
                        item = "search=" + search; 
                    }
                    if(cpy[0]=='pagina') {
                        item = "";
                        // alert(1)
                    }
                    if(item!="")
                    r = r + item + '&';
                });
                this.$store.commit("SET_CURR_PAGE", 0);
                r = r.slice(0, -1)
                window.location = r;
                // this.$router.push(r);
            }
            else {
                window.location = this.$route.params.type+"&search="+search;
                // this.$router.push(this.$route.params.type+"&search="+search);
            }
        },
        async searchProds() {
            this.openedSearch = true;
            let response = await this.axios.get(`/searchhelper?&domain=${this.$store.state.domain}&presearch=${this.searchW}`);
            this.searchresponses = [...response.data.presearch]
            console.log(this.searchresponses);
        },
        goLink(link){
            this.$router.push(link);
        },
        changeLvlMobile(index, text) {
            this.backText = text;
            let gender = this.$cookies.get('gender');
            if(!gender) gender = 1;
            this.counter++;
            let arr = [];
            arr.push(...this.$store.state.main.menucategories[gender-1].content[index].content[0].menu);
            this.mobileNav[this.counter] = arr;
            console.log(this.mobileNav)
        },
        openCategories(el) {
            this.opened = el.show;
            if(el.show!=false) this.subCategories = [...el.content];
            else this.subCategories = [];
            // console.log(el)
        }
    },
    created(){
        console.log(1)
        if(this.$cookies.get('gender')){
        let gender = this.$cookies.get('gender');
        console.log(this.$store.state.main.menucategories[gender-1].content)
        if(!gender) gender = 1;
        let arr = [];
        arr.push(...this.$store.state.main.menucategories[gender-1].content);
        this.mobileNav[0] = arr;
        }
    },
    watch:{
        searchW(){
            if(this.searchW.length<3) this.openedSearch = false;
        },
        '$store.state.main':function(){
            console.log(1)
            if(this.$cookies.get('gender')){
            let gender = this.$cookies.get('gender');
            console.log(this.$store.state.main.menucategories[gender-1].content)
            if(!gender) gender = 1;
            let arr = [];
            arr.push(...this.$store.state.main.menucategories[gender-1].content);
            this.mobileNav[0] = arr;
            }
        },
        closeMobile(){
            setTimeout(
                ()=>{
                    if(this.mobileNav.length > 1) this.mobileNav.pop();
                    this.backText = "";
                    this.counter = 0;
                },1000
            )
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/_variables.scss";
@import "../assets/styles/main.scss";
.app-types {

    &-m {

        display: none;

        &-el-template {
            
            border-bottom: 1px solid #e5e5e5;
            width: 100vw;
            margin-top: 2rem;

            &:last-child {
                margin-bottom: 100px;
            }

            span,a {
                text-decoration: none;
                font-family: $semibold-main;
                font-size: 1.3rem;
                padding: 1rem 0 2rem 0;
                padding-left: 3rem;
                display: flex;
                color:$color-black;

                img {
                    height: 1.7rem;
                    margin-left: auto;
                    padding-right: 2rem;
                    transform: translateY(-2px);
                }
            }

            &-gray {
                margin-top: 0;
                border-top: 1px solid rgb(175, 175, 175);
                background-color: #f4f4f4;
                padding-top: 25px;

                span {
                    display: flex;
                    margin-bottom: -1rem;
                    color: rgb(56, 56, 56);
                    ion-icon {
                        font-size: 2.5rem;
                        padding-right: 2rem;
                        padding-bottom: 0-.4rem;
                    }
                }
            }
        }
    }

    &__cnt {
        background: $color-gray-light;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        // -webkit-box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%);
        // box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%);

        &-right {
            margin-right: 2rem;
            display: flex;
            position: relative;

            &-input {
                display: block;
                margin-top: auto;
                margin-bottom: auto;
                border-bottom: 1px solid black!important;
                background: transparent;
                font-family: $light-main;
                outline: none;
                position: relative;
                padding-right: 4.3rem;
                padding-left: 3.5rem;
                font-family: $medium-main;
            }

            .search-input-icon {
                position: absolute;
                left: 5px;
                top: 20px;
                font-size: 2rem;
                cursor: pointer;
            }

            .delete-input {
                position: absolute;
                top: 20px;
                font-size: 2rem;
                left: auto!important;
                cursor: pointer;
                right: 3px!important; 
            }

            .search-responses {
                position: absolute;
                width: 100%;
            }

            &__opened-search {
                position: absolute;
                top: 80%; 
                background-color: white;
                box-shadow: rgba(0, 0, 0, 0.137) 0px 2px 8px 0px;
                padding: .2rem 1rem 3rem 1rem;
                box-sizing: border-box;
                width: 100%; 
                z-index:10000000;

                span {
                    display: block;
                    font-family: $medium-main;
                    margin: .5rem 0;
                    cursor: pointer;
                    font-size: 1.3rem;

                    a {
                        text-decoration: none;
                        color: black;
                    }
                }
            }
        }

        &-left {
            margin-left: 2rem;
            box-sizing: border-box;

            ul {
                list-style: none;
                display: flex;
                box-sizing: border-box;

                li {
                    padding: 2rem;
                    box-sizing: border-box;
                    cursor: pointer;

                    a {
                        font-family: $bold-main;
                        text-decoration: none;
                        color: $color-black;
                        font-size: 1.3rem;
                        box-sizing: border-box;
                    }
                    &:hover {
                        background: #fff;
                         box-shadow: inset 0 -2px 0 0 $color-black;
                    }
                }
            }
        }
    }

    &__opened{
        position: absolute;
        border-top: 1px solid rgb(230, 230, 230);
        padding-left: 3rem;
        background: $color-gray-light;
        padding-top: 1rem;
        width: 100vw;
        padding-bottom: 2rem;
        z-index: 122200;
        display: flex;
    }

    &__section {
        margin-left: 10%;
        text-align: start;

        &:first-child {
            margin-left: 0;
        }

        &-title {
            font-family: $bold-main;
            font-size: 1.3rem;
            text-transform: uppercase;
        }

        ul{
            margin-top: 1rem;
            list-style: none;

            li {
                margin-top: .5rem;

                a {
                    color: $color-black;
                    text-decoration: none;
                    font-family: $semibold-main;
                    font-size: 1.1rem;
                }
            }
        }
    }
}

@media only screen and (max-width: 850px) {
    .app-types-d {
        display: none;
    }

    .app-types-m {
        display: block;
        z-index: 1000000;
    }
}

.link-bold {
    font-family: $bold-main!important;;
}
.grid-brands {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 15rem;
}
.last-grid-el {
grid-column: last-col / span 1;
//   grid-row: last-line / span 1;
}

.search-opened-background {
    width: 100vw;
    position: fixed;
    height: 100%;
    background: rgba(0, 0, 0, 0.63);
    top: 0;
    z-index: 1000;
}

.response-all {
    width: 100%;
    display: flex;
    padding: 1rem 0;
    border-bottom: 1px solid rgb(155, 155, 155);;;

    &__icon1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 2rem;
        color: rgb(155, 155, 155);;
        width: 12%;
    }
    &__see {
        display: block;
        margin-left: auto!important;
        width: 44%;
        color: rgb(155, 155, 155);;
        font-family: $medium-main;

        i{ 
            transform: translateY(1.5px);
        }
    }
    &__search {
        display: block;
        width: 41%;
        font-family: $semibold-main!important;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}

.response-section {
    display: flex;
    flex-direction: column;
    padding:1.5rem 0;

    &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: rgb(155, 155, 155);;

        &-title {
            font-family: $bold-main!important; 
        }
    }

    &__template {
        margin-top: 1.5rem;
        width: 100%;
        display: flex;
        cursor: pointer;

        &-name {
            display: flex;
            flex-direction: column;
            width: 70%;
            box-sizing: border-box;
            padding: 0 1rem;

            &--big {
                display: block;
                margin:auto 0!important; 
                font-family: $semibold-main!important;
                width: 100%;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }

            &--small {
                display: block;
                width: 100%;
                
                span {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    font-size: 1.1rem!important;
                }

                &-text2 {
                    color: rgb(155, 155, 155);;
                }
            }
        }

        &-img {
            width: 15%;

            i {
                color:rgb(155, 155, 155);; ;
                font-size: 2rem;
                transform: translateY(8px);
            }

            img {
                max-width: 100%;
            }
        }

        &-number {
            display: flex; width: 15%;

            div {
                margin: auto 0 auto auto; 
                color:rgb(155, 155, 155);; ;
                box-sizing: border-box;
                padding: .1rem .6rem;
                border-radius: 10px;
                background-color: rgb(245, 245, 245);
            }
        }
    }
}
</style>