<template>
    <div class="app-gender-mobile">
        <div class="app-gender-mobile__templates">
            <div class="app-gender-mobile__template" @click="changeSection(n.gender)" v-for="(n, index) in $store.state.main.mobile_section.filter((el, index)=>index<4)" :key="index">
                <div class="app-gender-mobile__template-title" :class="{'app-gender-mobile__template-title--active':n.active}">
                    <span>{{n.name}}</span>
                </div>
            </div>
        </div>
            <div 
                class="app-gender-mobile__templates" 
                style="flex-wrap:wrap;" v-for="(n, index) in $store.state.main.mobile_section" :key="index">
                <div 
                    class="app-gender-mobile__template-section" 
                    :class="{'hide-el':!n.active}"   
                    v-for="(c, index) in n.content" :key="index+99"
                    @click="goLink(c.link)">
                        <img :src="c.image" alt="">
                        <span>{{c.name}}</span> 
                </div>
            </div>
    </div>
</template>

<script>
export default {
    methods:{
        goLink(link){
            this.$router.push(link);
        },
        changeSection(g){
            this.$cookies.set("gender", g);
            this.$store.dispatch('loadHomepage','');
            this.$store.dispatch('loadMain',this.$route);
        }
    }
}
</script>

<style lang="scss" scoped>
    .app-gender-mobile {
        display: flex;
        width: 100%;
        flex-direction: column;

        &__templates {
            display: flex;
            width: 100%;
            column-gap: 1rem;
            justify-content: space-around;
        }

        &__template {
            display: flex;
            flex-direction: column;
            width: 100%;
            justify-content: center;

            &-title {
                color: black;
                font-family: $bold-main;
                font-size: 1.5rem; 
                padding-bottom: .5rem;
                text-align: center;
                display: block;
                width:60px;
                margin: 0 auto;

                &--active {
                    border-bottom: 2px solid black;
                }
            }

            &-section {
                display: block;
                width: 30%; 
                margin: 10px auto;
                border: 1px solid rgb(226, 226, 226);
                padding: 20px 0;
                margin-top: 30px;

               img {
                   display: block;
                    width: 50%;
                    margin: 0 auto;
               }

               span {
                   display: block;
                   margin: 0 auto;
                   text-align: center;
                   margin-top: 15px;
                   font-family: $bold-main;
               }
            }
        }
    }

    .hide-el {
        display: none;
    }
</style>