<template>  
	<div class="app-home">
		<div class="app-loader-c" v-if="loader">
            <AppLoader/>
        </div>
        <div style="box-sizing: border-box;" class="product-cnt"  :class="{'hidden-ell':loader==true}">
		<div class="app-prod-header__links">
            <a v-for="(l, index) in $store.state.product.sections" :key="index+102" :href="l.link" >
                <span v-if="l.show">{{l.text}}</span>
            </a>
        </div>
            <AppProduct :hidden="!loader" :class="{'hidden-ell':loader==true}"/>
        </div>
        <div style="margin-top:50px" class="similar-cnt" :class="{'hidden-ell':loader==true}">
            <AppSimilarProducts/>
        </div>
	</div>
</template>

<script>
import $ from 'jquery'
export default {
	data(){
		return {
			loader:true
		}
	},
	mounted(){
		// window.fbq('track', 'ViewContent', {
		// 	content_ids: [this.$route.params.id],
		// 	content_type: 'product'
		// });

        setTimeout(()=>{
            this.loader = false;
        },700)
    },
	beforeMount(){
		this.$store.dispatch('loadProduct', this.$route.params.id);
        gtag('event', 'conversion', { 
            'send_to': 'AW-10800167573/YHmTCJuazZEDEJX19Z0o'
        })
	},
    watch:{
        "$store.state.product":function(){
            window.scrollBy(0, 0); 
            if(window.innerWidth > 799){
                // setTimeout(()=>{
                //         let targetOffset = $('.app-prod-header__links').offset().top;
                //         $('html, body').animate({scrollTop: 180}, 200);
                //         setTimeout(()=>{
                //             this.$store.commit("SET_NAVSHOW",true);  
                //         },2200)
                // }, 400)
            }
            else {    
                window.scrollBy(0, 0); 
            }
            
        }
    }
}
</script>

<style lang="scss" scoped>

.container{
	
	&-gender{
		width: 80%;
		margin: 2rem auto 0 auto;
	}

	&-brands{
		margin-top: 10rem;
	}
}

.product-cnt {
	padding: 0 5rem;
	width: 100%;
	margin-top: 2rem;
}

@media only screen and (max-width: 850px) {
.product-cnt {
	padding: 0 1rem;
	width: 100%;
	margin-top: 1rem;
}
}
@media only screen and (max-width: 500px) {
	.similar-cnt {
		margin-top: 10px!important;;
	}
}

.app-loader-c {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    margin-top: 5rem;
}

.hidden-ell {
    visibility: hidden!important;
}

.app-prod-header {

    &__links {
        display: flex;
		margin-left:10px; 
		padding-bottom: 10px;
        
        a {
            text-decoration: none;
            color: $color-black;
            font-size: 1.1rem;
            display: block;
            font-family: $medium-main;
            margin-left: .4rem;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                span {
                    color: rgb(134, 134, 134);
                }
            }



            span {
                margin: 0;
                
                &:hover {
                    color:rgb(134, 134, 134);
                    cursor: pointer;
                }
            }
        }
    }
}
</style>
