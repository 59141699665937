<template>
    <div class="app-filter-d">
        <div class="app-filter-d__category" v-if="$store.state.deleteF.showfiltreaza">
            <div class="app-filter-d__category-title" style="border-bottom:1px solid gray;padding-bottom:2rem;">
                <div style="display:flex;font-size:1.4rem;"> 
                    <!-- <i class='bx bx-caret-right' ></i> -->
                    <span>Filtreaza dupa</span>
                </div>
                <span @click="goLink($store.state.deleteF.returnlink)" v-if="$store.state.deleteF.possible">sterge tot </span>
            </div>
        </div>
        <div class="app-filter-d__category" v-for="(f, index) in filter.filter(e => e.show ==true)" :key="index">
            <div class="app-filter-d__category-title">
                <div style="display:flex;font-size:1.4rem;postion:relative;" @click="hideEl(f.ID)">  
                    <i class='bx bx-caret-right' ></i>
                    <span>{{f.name}}</span>
                </div>
                <span @click="deleteAll(f.ID)" v-if="f.deletepossible&&f.type!='pricebar'">sterge</span>
                <span @click="deletePrice" v-if="f.deletepossible&&f.type=='pricebar'">sterge</span>
            </div>
            <div class="search-filter" v-if="f.ID=='b'&&hideBrand">
                <input type="text" placeholder="Cauta..." v-model="brandName" @input="searchBrand"> 
            </div>
            <div class='app-filter-d__category-content' v-if="f.type=='singleline'" 
                :class="{
                    'hide-el1':f.ID=='c3'&&!hideProduse,
                    'hide-el':f.ID=='b'&&!hideBrand,
                    'brandX':f.ID=='b',
                    'c3':f.ID=='c3',
                    'sizesX':f.ID=='sizes',
                    'unusedX':f.ID=='unused'
                    }">
                <div v-for="(c, ind) in f.values" :class="{'hide-el':c==null||c.show!=true}" :key="c.ID"  class="app-filter-d__category-content--simple" @click="selectC(f.ID, ind)">
                    <span class="filter-icon" :class="{'filter-icon--active':c.selected}">&nbsp;</span>
                    <span class="app-filter-d__category-content--simple-d">{{c.name}}</span>
                </div>
            </div>
            <div class="app-filter-d__category-content" v-if="f.type=='multipleline'&&hideSize" 
                :class="{
                'brandX':f.ID=='b',
                'c3':f.ID=='c3',
                'sizesX':f.ID=='sizes',
                'unusedX':f.ID=='unused',
                }">
                <div class="app-filter-d__category-content--sizes">
                    <div class="app-filter-d__category-content--sizes-el" 
                         v-for="(m, ind) in f.values"  :key="ind+6666
                         " @click="selectC(f.ID, ind)"
                         :class="{'app-filter-d__category-content--sizes-el--active':m.selected,
                         'one-size':m.name=='One Size'}">
                        {{m.name}}
                    </div>
                </div>
            </div>
            <div class="app-filter-d__category-content" v-if="f.type=='pricebar'&&hidePrice" style="overflow:hidden!important;">
                <div class="app-filter-d__category-content" style="overflow:hidden!important;">
                    <div class="prices-section">
                        <span>{{f.selectrange[0]}}</span >
                        <span>{{f.selectrange[f.selectrange.length-1]}}</span >
                    </div>
                        <vue-slider
                            @drag-end="setPrice(f.selectrange)"
                            v-model="f.selectrange"
                            :min="f.range[0]"
                            :max="f.range[f.range.length-1]"
                            :min-range="0"
                            :height="10"
                            :process-dragable="true"
                            v-bind="options"
                        ></vue-slider>
                        <div class="price-inputs">
                            <input type="text" v-model="f.selectrange[0]">  
                            <i class='bx bx-move-horizontal' ></i>
                            <input type="text" v-model="f.selectrange[f.selectrange.length-1]">
                            <button @click="setPrice(f.selectrange)"><i class='bx bx-chevron-right'></i></button>
                        </div>
                    <!-- <div class="app-filter-d__category-content--sizes-el" 
                         v-for="(m, ind) in f.values" :key="m" @click="selectC(f.ID, ind)"
                         :class="{'app-filter-d__category-content--sizes-el--active':m.selected,
                         'one-size':m.name=='One Size'}">
                        {{m.name}} 
                    </div> -->
                </div>
            </div>
        </div>
        <!-- <div class="app-filter-d__category">
            <div class="app-filter-d__category-title">
                <div style="display:flex;font-size:1.4rem;"> 
                    <i class='bx bx-caret-right' ></i>
                    <span>Branduri</span>
                </div>
                <span>sterge</span>
            </div>
            <div class="app-filter-d__category-content">
                <div v-for="n in 34" :key="n"  class="app-filter-d__category-content--simple">
                    <span class="filter-icon" :class="{'filter-icon--active':n==2}">&nbsp;</span>
                    <span class="app-filter-d__category-content--simple-d">Louis Vuitton</span>
                </div>
            </div>
        </div>
        <div class="app-filter-d__category">
            <div class="app-filter-d__category-title">
                <div style="display:flex;font-size:1.4rem;"> 
                    <i class='bx bx-caret-right' ></i>
                    <span>Masuri</span>
                </div>
                <span>sterge</span>
            </div>
            <div class="app-filter-d__category-content">
                <div class="app-filter-d__category-content--sizes">
                    <div class="app-filter-d__category-content--sizes-el" v-for="m in 20" :key="m">
                        2XS
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import $ from 'jquery';
export default {
    components: {
        VueSlider
    },
    data(){
        return {
            filter:[],
            period: [0, 99999],
            options:{
                tooltip:"none"
            },
            brandName:"",
            hideProduse:true,
            hideBrand:true,
            hideSize:true,
            hidePrice:true
        }
    },
    methods:{
        updatePage(routeX) {
            let route = '';
            route = routeX
            if(routeX.indexOf('&pagina=') >= 0){
                route = route.replace('&pagina='+this.$store.state.browse.pagination.currentpage, "");
            }
            return route + '&pagina=1';
        },
        hideEl(id) {
            if(id=="c3") this.hideProduse = !this.hideProduse;
            if(id=="b") this.hideBrand = !this.hideBrand;
            if(id=="sizes") this.hideSize = !this.hideSize;
            if(id=="unused") this.hidePrice = !this.hidePrice;
        },
        deletePrice(){
                let index1 = this.$route.params.type.indexOf("&maxprice");
                let str = this.$route.params.type.split('');
                str.splice(index1, 1);
                let count = 0;
                while(str[index1]!=undefined) {
                    if(str[index1] == '&') count++;
                    if(count == 2) {break; }
                str.splice(index1, 1);
                console.log(str.length)
                if(str.length==index1) {
                    // str.splice(index1-1, 1);
                    break;
                }
                }
                console.log(str)
                str = str.join('');
                this.$router.push(this.updatePage(str)) 
        },
        setPrice(period) {
            $(".brandX").animate({ scrollTop: 0 }, "fast");
            $(".c3").animate({ scrollTop: 0 }, "fast");
            $(".sizesX").animate({ scrollTop: 0 }, "fast");
            let index1 = this.$route.params.type.indexOf("&maxprice");
            let index2 = this.$route.params.type.indexOf("&minprice");
            if(index1==-1&&index2==-1) {
                let price = "&maxprice="+period[1] + "&minprice=" + period[0];
                this.$router.push(this.updatePage(this.$route.params.type + price));
            }
            else {
                let str = this.$route.params.type.split('');
                str.splice(index1, 1);
                let count = 0;
                while(str[index1]!=undefined) {
                    if(str[index1] == '&') count++;
                    if(count == 2) {break; }
                str.splice(index1, 1);
                console.log(str.length)
                if(str.length==index1) {
                    // str.splice(index1-1, 1);
                    break;
                }
                }
                console.log(str)
                str = str.join('');
                let price = "&maxprice="+period[1] + "&minprice=" + period[0];
                this.$router.push(this.updatePage(str+price)) 
            }
        },
        deleteAll(id){ 
            let ind = this.$route.params.type.indexOf(id)
            if(ind!=-1) {
                let str = this.$route.params.type.split('');
                str.splice(ind, 1);
                while(str[ind] != '&'||str.length==ind-1) {
                str.splice(ind, 1);
                console.log(str.length)
                if(str.length==ind) {
                    str.splice(ind-1, 1);
                    break;
                }
                }
                str.splice(ind, 1);
                str = str.join('');
                console.log(str)
                this.$router.push(this.updatePage(str)) 
            }
        },
        searchBrand(){
            for(let i = 0; i < this.filter.length;i++) {
                if(this.filter[i].ID=="b") {
                    for(let j = 0; j < this.filter[i].values.length;j++) {
                        let name = this.filter[i].values[j].name.toLowerCase()
                        if(name.startsWith(this.brandName.toLowerCase())) {
                            this.filter[i].values[j].show = true;
                            }
                            else {
                                this.filter[i].values[j].show = false;
                            }
                    }
                }
            }
        },
        selectC(m_id, indx){
            if(m_id=='b') {
                $(".c3").animate({ scrollTop: 0 }, "fast");
                $(".sizesX").animate({ scrollTop: 0 }, "fast");
            }

            if(m_id=='c3'){
                $(".brandX").animate({ scrollTop: 0 }, "fast");
                $(".sizesX").animate({ scrollTop: 0 }, "fast");
            }

            if(m_id=='sizes'){
                $(".brandX").animate({ scrollTop: 0 }, "fast");
                $(".c3").animate({ scrollTop: 0 }, "fast");
            }
            if(m_id=='unused'){
                $(".brandX").animate({ scrollTop: 0 }, "fast");
                $(".c3").animate({ scrollTop: 0 }, "fast");
                $(".sizesX").animate({ scrollTop: 0 }, "fast");
            }

            let pos = [];
            console.log(this.filter[0].values[indx])
            for(let i = 0; i < this.filter.length;i++) {
                if(this.filter[i].ID==m_id&&indx!==false&&m_id&& this.filter[i].values[indx]) {
                    pos = [...this.filter[i].values]
                    console.log( this.filter[i].values)
                    if(indx||indx==0)
                    this.filter[i].values[indx].selected = !this.filter[i].values[indx].selected;
                }
            }
            let index = this.$route.params.type.indexOf(m_id)
            // let char = "";
            if(index!=-1){
                let options = "";
                for(let o of pos){
                    if(o.selected) options = options + o.ID + "+";
                }
                console.log(pos)
                console.log("oppppppp",options)
                let str = this.$route.params.type.split('');
                console.log(str, index)
                console.log(typeof index)  
                str.splice(index, 1);
                while(str[index] != '&'||str.length==index-1) {
                str.splice(index, 1);
                console.log(str.length)
                if(str.length==index) {
                    str.splice(index-1, 1);
                    break;
                }
                }
                str.splice(index, 1);
                str = str.join('');
                options = options.slice(0, -1)
                str = str + "&" + m_id + "=" + options;
                console.log("hehe", str)  
                this.$router.push(this.updatePage(str)) 
                this.$store.dispatch("updatePage", this.$route.fullPath);
            }
            else {
                let options = "";
                for(let o of pos){
                    if(o.selected) options = options + o.ID + "+";
                }
                options = options.slice(0, -1)
                this.$router.push(this.updatePage(this.$route.params.type + "&" + m_id + "=" + options)) 
                this.$store.dispatch("updatePage", this.$route.fullPath);
            }
        },
        goLink(link){
            this.$router.push(link)
        }
    },
    watch:{
        "$store.state.filter":function() {
            this.filter = [...this.$store.state.filter];
        }
    },
    beforeMount(){
        this.filter = [...this.$store.state.filter];
    }
}
</script>


<style lang="scss" scoped>
.app-filter-d {
    width: 220px;
    box-sizing: border-box;
    

    &__category {
        margin-top: 2rem;
        width: 220px!important;

        &-title {
            font-size: 1.2rem;
            display: flex;
            justify-content: space-between;

            i {
                transform: translateY(2.8px);
            }

            span {
                display: block;
                font-family: $semibold-main;
                cursor: pointer;
            }
        }

        &-content {
            padding-left: 1.5rem;
            max-height: 250px;
            overflow: auto;
            overflow-x: hidden;

            &--simple {
                cursor: pointer;
                margin-top: .5rem;

                &-d {
                    font-size: 1.2rem;
                    font-family: $medium-main;
                    margin-left: 5px;
                }
            }

            &--sizes {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                &-el {
                    cursor: pointer;
                    border: 1px solid black;
                    padding: .2rem 0;
                    font-family: $medium-main;
                    border-radius: 5px;
                    font-size: 1.1rem;
                    width:auto;
                    text-align: center;
                    margin: 3px 3px;
                    padding-left: .5rem;
                    padding-right: .5rem;

                    &--active {
                        background: black;
                        color: white;
                    }
                }
            }
        }
    }
}

.filter-icon {
    width: 9px;
    height: 9px;
    overflow: hidden;
    float: left;
    display: block;
    margin-top: 4px;
    white-space: nowrap;
    outline: 1px solid #000;
    outline-offset: 1px;

    &--active {
        background: black;
    }
}

.hide-element {
    display: block;
}
@media only screen and (max-width: 800px) {
    .hide-element {
        display: block;
    }
    .app-filter-d {
        display: none;
    }
}

::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	width: 5px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	background-color: #000000;
	border: 1px solid #555555;
}
.one-size {
    width:calc(50% + 6px)
}

.prices-section {
    display: flex;
    justify-content: space-between;
    margin-left:-10px;
    span {
        display: block;
        font-family: $medium-main;
        font-size: 1.2rem;
    }
}

.price-inputs {
    display: flex;
    margin-top: 10px;

    input {
        width: 30%;
        border: 1px solid black;
        font-family: $light-main;
        font-size: 14px!important;
        text-align: center;
        border-radius: 3px;
    }

    i{
        font-size: 1.2rem;
        margin-left: 6px;
        margin-right: 6px;;
        transform: translateY(5px);
    }

    button {
        background: black;
        width:20px;
        text-align: center;
        margin-left:15px;
        border-radius: 5px;

        i {
            color: white;
            transform: translateY(1.7px);
            font-size: 2rem;
            transform: translateX(-6px);
        }
    }
}

.search-filter {
   margin-top:10px;

    input {
        border: 1px solid rgb(187, 185, 185);
        padding: .4rem 1rem;
        border-radius: 5px; 
        width: 90%;
        margin-left: auto;
        transform: translateX(10px);
        font-family: $light-main;
        outline: none;
        font-size: 14px!important;

        &:focus {
            border-color: black;
        }
    }
}
.hide-el {
    display: none!important;;
}
.hide-el1 {
    display: none!important;;
}

.c3 {
    text-decoration: none;
}

.brandX,.sizesX, .unusedX {
    text-decoration: none;
}
</style>