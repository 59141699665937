<template>
    <div class="app-newsletter" v-if="$store.state.pl">
        <div class="wrapper top-space" v-html="$store.state.pl.content"></div>
    </div>
</template>


<script>
export default {
	beforeMount(){
        this.$store.dispatch("loadPl")
    }
}
</script>