<template>
    <div class="app-checkout-steps">
        <div class="app-checkout-steps__el" @click="goStep(step.step)" v-for="(step, index) in $store.state.step.steps" :key="index">
                
                <!-- Active Step -->
                <span class="app-checkout-steps__el-number app-checkout-steps__el-number--active" v-if="step.status=='active'">{{step.step}}</span>
                <div class="app-checkout-steps__el-line" v-if="step.status=='active'&&index!=3"></div>
                <span class="app-checkout-steps__el-text" v-if="step.status=='active'">{{step.text}}</span>
                
                <!-- Finished step -->
                <span class="app-checkout-steps__el-number app-checkout-steps__el-number--passed" v-if="step.status=='finished'">
                    <ion-icon name="checkmark-sharp"></ion-icon>
                </span>
                <div class="app-checkout-steps__el-line" v-if="step.status=='finished'&&index!=3"></div>
                <span v-if="step.status=='finished'" class="app-checkout-steps__el-text app-checkout-steps__el-text--passed">{{step.text}}</span>
            
                <!-- Default step -->
                <span v-if="step.status=='inactive'" class="app-checkout-steps__el-number">{{step.step}}</span>
                <div  v-if="step.status=='inactive'&&index!=3" class="app-checkout-steps__el-line"></div>
                <span v-if="step.status=='inactive'" class="app-checkout-steps__el-text">{{step.text}}</span>
            </div>
    </div>
</template>


<script>
export default {
    data() {
        return {
            active:1
        }
    },
    methods:{
        goStep(step){
            if(step!=4) window.location = "/checkout/step=" + step;
        },
    }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/_variables.scss";
@import "../assets/styles/main.scss";
.app-checkout-steps {
    display: flex;
    flex-direction: row;
    padding-bottom: 10rem;

    &__el {
        display: flex;
        margin-left: 1rem;
        position: relative;

        &:first-child {
            margin-left: 0;
        }

        &-number {
            display: block;
            cursor: pointer;
            font-size: 1.4rem;
            font-family: $bold-main;
            padding: .3rem;
            border: 2px solid $color-black;
            border-radius: 50%;
            width: 2.8rem;
            height: 2.8rem;
            text-align: center;

            &--passed {
                background: $color-yellow;
                border: 2px solid $color-yellow!important;

                ion-icon{
                    font-size: 1.7rem;
                }
            }

            &--active {
                border: 2px solid $color-yellow!important;
            }
        }

        &-line {
            width: 12rem;
            height: 2px;
            background-color: rgb(161, 161, 161);
            margin:auto 0 auto 1rem;
        }

        &-text {
            display: block;
            font-family: $light-main;
            font-size: 1.4rem;
            position: absolute;
            bottom:-45px;
            transform: translate(-15%,0);
            margin: 0 auto;
            padding-bottom: .8rem;
            cursor: pointer;

            &--passed {
                font-family: $bold-main;
            }

            &--active {
                border-bottom: 2px solid $color-black;
                font-family: $bold-main;
            }
           
            
        }
    }
}

@media only screen and (max-width: 650px) {
    .app-checkout-steps__el-line {
        width:16vw;
    }
}

@media only screen and (max-width: 500px) {
    .app-checkout-steps__el-line {
        width:13vw;
    }
}

@media only screen and (max-width: 340px) {
    .app-checkout-steps__el-line {
        width:11vw;
    }
}
</style>
