<template>
    <div class="app-404">
        <div
            class="app-404__content"
            :style="{ 'background-image': 'url(' + image + ')' }"
        >
            <span class="app-404__content-ask">Ups, cum am ajuns aici?</span>
            <span class="app-404__content-big">404</span>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            image: "",
        };
    },
    beforeMount() {
        this.axios
            .get(
                `/404?domain=${this.$store.state.domain}&token=${this.$store.state.auth.token}&useridsession=${this.$store.state.auth.useridsession}`
            )
            .then((response) => {
                // handle success
                console.log(response);
                if (document.body.clientWidth < 1000) {
                    this.image = response.data.image404small;
                } else {
                    this.image = response.data.image404;
                }
            });
    },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/_variables.scss";
@import "../assets/styles/main.scss";
.app-404 {
    &__content {
        width: 100vw;
        height: 80vh;
        // background-image: url('../assets/images/big-404.jpg');
        display: flex;
        flex-direction: column;
        background-repeat: no-repeat;

        span {
            display: block;
            margin-left: auto;
            margin-right: 20rem;

            &:first-child {
                margin-top: auto;
            }
            &:last-child {
                margin-bottom: auto;
            }
        }

        &-ask {
            font-family: $bold-main;
            color: $color-white;
            font-size: 3.5rem;
        }

        &-big {
            font-family: $bold-main;
            color: $color-white;
            font-size: 20rem;
        }
    }
}

@media only screen and (max-width: 1500px) {
    .app-404__content {
        span {
            margin-right: 5rem;
        }
    }
}

@media only screen and (max-width: 1500px) {
    .app-404__content {
        // background-image: url('../assets/images/small-404.jpg');
        background-position: top;
        background-size: cover;

        span {
            margin-left: auto;
            margin-right: auto;
        }
    }
}
@media only screen and (max-width: 1500px) {
    .app-404__content-ask {
        font-size: 2.7rem;
    }

    .app-404__content-big {
        font-size: 14rem;
    }
}
</style>
