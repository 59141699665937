<template>
    <div class="app-product noselect" v-if="$store.state.product">
        <div class="app-product__images">
            <div class="app-product__vertical-c">
            </div>
                <div class="app-product__horizontal-c"  > 
                    <!-- @click="changeScale" -->
                        <carousel 
                        class="carousel-desk"
                        v-if="!viewer_m.length"
                        :paginationEnabled="false"
                        :perPage="perPage" 
                        :autoplay="true" 
                        :autoplayTimeout="5234560"  
                        :loop="true"
                        :mouse-drag="true"
                        :navigate-to="page"
                        @pageChange="pageChange"
                        :scrollPerPage="false">
                            <slide   v-for="(i, index) in $store.state.product.product_images_big_desktop" :key="index" class="slide" style="padding-left:1rem">
                            <img oncontextmenu="return false" :class="{'no-show':!hidden}" @click="openBig(index)" :src="i" alt="header-image" class="show-m app-brands__image" style="width:100%!important;"> 
                                <div @click="openBigSlider(index)">
                                    <zoom-on-hover v-if="scale==1.7" @click="changeScale" oncontextmenu="return false" style="cursor:zoom-in!important;" class="hide-m" :img-normal="i" :scale="1.7" ></zoom-on-hover>
                                </div>
                                <div>
                                    <zoom-on-hover v-if="scale==5" @click="changeScale" oncontextmenu="return false" style="cursor:zoom-in!important;" class="hide-m" :img-normal="i" :scale="3" ></zoom-on-hover>
                                </div>
                            </slide>
                        </carousel>
                        
                <span v-if="$store.state.product.product_images_big.length>2"  @click="goPage(1)" class="app-product__horizontal-c-btn app-product__horizontal-c-btn--next"><ion-icon name="chevron-forward-outline"></ion-icon></span>
                <span v-if="$store.state.product.product_images_big.length>2"  @click="goPage(-1)" class="app-product__horizontal-c-btn app-product__horizontal-c-btn--prev"><ion-icon name="chevron-back-outline"></ion-icon></span>
                <div class="app-product__bullets">
                    <div @click="setPage(n-1)" :class="{'app-product__bullets__el-active':n==page+1}" v-for="(n, index) in $store.state.product.product_images.length" :key="index"></div>
                </div>
                <AppMobileSlider :images="$store.state.product.product_images_big" @openBig="openBig"/>
            </div>
            <span class="app-product__model-stats" v-if="$store.state.product.model_info.show">{{$store.state.product.model_info.text}}</span>
        </div>
        <div class="app-product__stats">
            <div class="app-product__stats-brand noselect">
                <span>{{$store.state.product.product_brand}} - {{$store.state.product.product_name}}</span>
                <div style="padding-left:2rem;display:block;margin-left:auto;" v-if="$store.state.product.brand.show"><img style="cursor:pointer;" @click="goLink($store.state.product.brand.link)" :src="$store.state.product.brand.image" alt=""></div>
            </div>
            <div>
                <span class="app-product__stats-price" :class="{'app-product__stats-price--line':$store.state.product.showdiscount}">
                    {{$store.state.product.original_price}} 
                </span>
                <span class="app-product__stats-price--discount" v-if="$store.state.product.discount_price!=$store.state.product.original_price">{{$store.state.product.discount_price}}</span>
            </div>
            <div style="position:relative;" id="sizes" v-if="$store.state.product.product_size.multiplesize">
                <div class="app-product__stats-size" @click="$store.state.product.stock ? sizes=!sizes :sizes=false" v-if="$store.state.product.stock">
                    <span>{{size}}</span>
                    <ion-icon name="chevron-down-outline"></ion-icon>
                </div>
                <div class="app-product__stats-size-opened" v-if="sizes" >
                    <div  v-for="(s, index) in $store.state.product.product_size.sizes" :class="{'off-stock':s.stock==false}" :key="index" @click="setSize(s)">
                        <span >{{s.size}}</span>
                        <div class="h-line-light" v-if="index!=$store.state.product.product_size.length-1"></div>
                    </div>
                </div>
            </div>
            <div style="position:relative;" v-else>
                <div class="app-product__stats-size" @click="sizes=!sizes">
                    <span class="one-size">{{$store.state.product.product_size.sizes[0].size}}</span>
                </div>
            </div>
            <!-- <span class="app-product__stats-sizes">Tabel cu marimi</span> -->
            <div class="app-product__stats-actions" >
                <button class="app-product__stats-buy"  @click="addCart" v-if="$store.state.product.stock">
                    Adauga in cos <ion-icon name="bag-handle-outline"></ion-icon>
                </button>
                <button class="app-product__stats-buy app-product__stats-buy--out"  v-else>
                    Stoc epuizat <ion-icon name="bag-handle-outline"></ion-icon>
                </button>
                <button class="app-product__stats-favorite" :class="{'added-w':addedW}" @click="addWishlist">
                  <div >
                    <ion-icon name="heart-outline" style="color:black" v-if="!addedW"></ion-icon>
                    <ion-icon name="heart-outline" style="color:white;" v-else></ion-icon>
                  </div>
                </button>
            </div>
            <div class="app-product__stats-priority">
                <div>
                    <svg class="SVGInline-svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g id="Icon/Time" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g transform="translate(1.000000, 1.000000)" fill-rule="nonzero" id="Path">
                                <path d="M16.5007734,18.4379336 L17.5245039,19.8143945 C18.0204063,19.4455508 18.4886797,19.0317187 18.9162617,18.5843711 L17.6761836,17.3991211 C17.3150313,17.7769453 16.9195469,18.1264961 16.5007734,18.4379336 Z" fill="#000000"></path>
                                <path d="M13.7167852,19.8520352 L14.2231289,21.4909922 C14.8138203,21.3085039 15.3933398,21.0737656 15.9454453,20.7932227 L15.1683984,19.2639219 C14.7029609,19.5003789 14.2145781,19.69825 13.7167852,19.8520352 Z" fill="#000000"></path>
                                <path d="M18.6606406,16.1779492 L20.0820898,17.1381289 C20.4289336,16.6246523 20.7337969,16.0786484 20.9883437,15.5152852 L19.4250547,14.8090938 C19.2104258,15.2841563 18.9532578,15.7446953 18.6606406,16.1779492 Z" fill="#000000"></path>
                                <polygon fill="#FFCD00" points="10.1637422 10.1423008 5.36060938 10.1423008 5.36060938 11.8576992 11.8791406 11.8576992 11.8791406 3.28070703 10.1637422 3.28070703"></polygon>
                                <path d="M20.2846016,1.99413672 L20.2846016,5.17085938 C19.4780352,3.88347266 18.409875,2.77380469 17.1351641,1.90897266 C15.3150508,0.674136719 13.1861641,0.0214414062 10.9785586,0.0214414062 C8.04607031,0.0214414062 5.28915234,1.16342187 3.21552344,3.23696484 C1.14198047,5.31059375 0,8.06751172 0,11 C0,13.9324883 1.14198047,16.6894062 3.21552344,18.7630352 C5.28915234,20.8365781 8.04607031,21.9785586 10.9785586,21.9785586 C11.4559844,21.9785586 11.9365469,21.9475352 12.4068828,21.8863906 L12.1857656,20.1853008 C11.7884766,20.2369492 11.3823789,20.2631602 10.9786016,20.2631602 C5.87086328,20.2631602 1.71544141,16.1077383 1.71544141,11 C1.71544141,5.89226172 5.87086328,1.73683984 10.9786016,1.73683984 C14.2939414,1.73683984 17.3025273,3.48549609 18.9537734,6.28263281 L15.9961055,6.28263281 L15.9961055,7.99803125 L22,7.99803125 L22,1.99413672 L20.2846016,1.99413672 Z" fill="#000000"></path>
                                <path d="M20.2091055,11.7719336 C20.201457,11.8634141 20.1923477,11.9544648 20.1821641,12.0452148 L20.1829805,12.0452578 C20.134168,12.4793281 20.0551055,12.9107773 19.9461797,13.3310977 L21.60675,13.7613438 C21.7616523,13.1636055 21.8664961,12.5472187 21.9183164,11.9294141 L21.9170703,11.9293281 C21.921625,11.8769062 21.9261797,11.8245273 21.929832,11.7719336 L20.2091055,11.7719336 L20.2091055,11.7719336 Z" fill="#000000"></path>
                            </g>
                        </g>
                    </svg>
                    <span>Livrare rapida</span>
                </div>
                <div>
                    <svg class="SVGInline-svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g id="Icon/Delivery" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g>
                                <rect id="Rectangle" x="0" y="0" width="25" height="24"></rect>
                                <g id="Group" transform="translate(0.500000, 2.000200)">
                                    <polygon id="Fill-1" fill="#FFCD00" points="0 1.58 9.637 1.58 9.637 0.005 0 0.005"></polygon>
                                    <polygon id="Fill-2" fill="#FFCD00" points="0 5.33 7.762 5.33 7.762 3.756 0 3.756"></polygon>
                                    <polygon id="Fill-3" fill="#FFCD00" points="0 9.079 5.887 9.079 5.887 7.505 0 7.505"></polygon>
                                    <path d="M6.1782,15.783 C6.7782,15.783 7.2662,16.271 7.2662,16.871 C7.2662,17.47 6.7782,17.959 6.1782,17.959 C5.5782,17.959 5.0902,17.47 5.0902,16.871 C5.0902,16.271 5.5782,15.783 6.1782,15.783 L6.1782,15.783 Z M17.2872,15.783 C17.8872,15.783 18.3752,16.271 18.3752,16.871 C18.3752,17.47 17.8872,17.959 17.2872,17.959 C16.6882,17.959 16.1992,17.47 16.1992,16.871 C16.1992,16.271 16.6882,15.783 17.2872,15.783 L17.2872,15.783 Z M16.1992,11.658 L22.1252,11.658 L22.1252,16.084 L19.8322,16.084 L19.7972,15.983 C19.4212,14.922 18.4122,14.209 17.2872,14.209 C16.9832,14.209 16.6842,14.26 16.4012,14.361 L16.1992,14.432 L16.1992,11.658 Z M16.1992,6.267 L18.2282,6.267 C18.5672,6.267 18.8922,6.429 19.0962,6.699 L21.6502,10.084 L16.1992,10.084 L16.1992,6.267 Z M2.6622,17.658 L3.6332,17.658 L3.6682,17.759 C4.0442,18.82 5.0532,19.533 6.1782,19.533 C7.3032,19.533 8.3122,18.82 8.6882,17.759 L8.7232,17.658 L14.7422,17.658 L14.7782,17.759 C15.1542,18.82 16.1622,19.533 17.2872,19.533 C18.4122,19.533 19.4212,18.82 19.7972,17.759 L19.8322,17.658 L23.6992,17.658 L23.6992,11.39 C23.6992,10.612 23.4422,9.843 22.9732,9.222 L20.3532,5.752 C19.8532,5.089 19.0592,4.693 18.2282,4.693 L16.1992,4.693 L16.1992,2.663 C16.1992,1.194 15.0052,7.10542736e-15 13.5372,7.10542736e-15 L11.8132,7.10542736e-15 L11.8132,1.575 L13.5372,1.575 C14.1372,1.575 14.6252,2.063 14.6252,2.663 L14.6252,16.084 L8.7232,16.084 L8.6882,15.983 C8.3122,14.922 7.3032,14.209 6.1782,14.209 C5.0532,14.209 4.0442,14.922 3.6682,15.983 L3.6332,16.084 L2.6622,16.084 C2.0632,16.084 1.5752,15.596 1.5752,14.996 L1.5752,11.256 L0.0002,11.256 L0.0002,14.996 C0.0002,16.464 1.1942,17.658 2.6622,17.658 L2.6622,17.658 Z" id="Fill-4" fill="#000000"></path>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <span>Livrare la adresă </span>
                </div>
                <div>
                    <svg class="SVGInline-svg" width="25px" height="25px" viewBox="0 0 25 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g id="Icon/Return" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g>
                                <rect id="Rectangle" x="0" y="0.5" width="25" height="24"></rect>
                                <path d="M23.5896423,14.1963791 C23.5896423,18.3709542 20.2054805,21.755116 16.0309054,21.755116 L3.18105264,21.755116 L3.18105264,20.2433686 L16.0309054,20.2433686 C19.3705655,20.2433686 22.0778949,17.5360392 22.0778949,14.1963791 C22.0778949,10.856719 19.3705655,8.14938953 16.0309054,8.14938953 L5.26726403,8.14938953 L8.20005396,10.5908616 L7.23253563,11.754907 L2.00188968,7.39351584 L7.23253563,3.03212463 L8.20005396,4.19617012 L5.26726403,6.63764214 L16.0309054,6.63764214 C20.2054805,6.63764214 23.5896423,10.0218039 23.5896423,14.1963791 Z" id="Path" fill="#000000" fill-rule="nonzero"></path>
                            </g>
                        </g>
                    </svg>
                    <span>Retur garantat</span>
                </div>
            </div>
            <div class="app-product__stats-sections">
                <div class="h-line"></div>
                <!-- <input type="text" class="xxxx"> -->
                <div class="app-product__stats-sections-el" v-for="(i, index) in $store.state.product.infoproduct" :key="index">
                    <span class="not-focusable"  tabindex="100" @click="expandBlock(index)">{{i.title}}<ion-icon name="chevron-down-outline"></ion-icon></span>
                    <div class='grow'>
                        <div class='measuringWrapper'>
                            <div  v-if="i.type=='html' && i.text!=null && index!=1 && index!=2" class="text" v-html="i.text.replace(/\n/g, '<br>')" style="font-family:$light-main;"></div>
                            <div  v-if="i.type=='html'&& i.text!=null && index == 1" class="text" v-html="i.text.replace(/\n/g, '')" style="font-family:$light-main;"></div>
                            <div  v-if="i.type=='html' && i.text!=null && index == 2" class="text" v-html="i.text.replace(/\n/g, '')" style="font-family:$light-main;"></div>
                            <div   v-if="i.type=='form'" class="app-product__form">
                                <div>
                                    <label for="name">Prenume și nume*</label>
                                    <input  type="text" name="name" @input="question.nameErr=false" :class="{'err-inp':question.nameErr}" v-model="question.name">
                                </div>
                                <div>
                                    <label for="email">E-mail*</label>
                                    <input  type="text" @input="question.emailErr=false" name="email" :class="{'err-inp':question.emailErr}" v-model="question.email">
                                </div>
                                <div>
                                    <label for="phone">Număr de telefon*</label>
                                    <input  type="text" @input="question.phoneErr=false" name="phone" :class="{'err-inp':question.phoneErr}" v-model="question.phone">
                                </div>
                                <div>
                                    <label for="description">Cerere*</label>
                                    <textarea name="description" :class="{'err-inp':question.descriptionErr}" v-model="question.description" id="" cols="30" rows="10"></textarea>
                                </div>
                                <AppRecaptcha 
                                v-if="!$store.state.auth.token&&!$store.state.auth.useridsession"
                                style="margin-top:20px;display:flex;justify-content:center;"
                                    @onVerify="onVerify"
                                /> 
                                <span>Mai multe informații despre procesarea datelor dvs. personale de către administrator și despre drepturile dvs. pot fi găsite <a href="/politica-de-confidentialitate">AICI</a> .</span>
                                <button style="color:black!important;" @click="sendQuestion">Trimite</button>
                            </div>
                            <div v-if="i.type=='categories'" class="categories-popular">
                                <a   v-for="(t,index) in i.text" :href="t.link" :key="index">{{t.categoriepopulara}} </a>
                            </div>
                        </div>
                    </div>
                    <div class="h-line"></div>
                </div>
            </div>
        </div>
        <div class="app-product__mobile-temporary" :class="{'hidden_el':hidden_el}" >
            <div class="app-product__stats app-product__stats--t">
                <div class="app-product__stats-brand app-product__stats-brand--t">
                    <span>{{$store.state.product.product_brand}} - {{$store.state.product.product_name}}</span>
                    <div style="padding-left:2rem;display:block;margin-left:auto;" v-if="$store.state.product.brand.show"><img style="cursor:pointer;" @click="goLink($store.state.product.brand.link)" :src="$store.state.product.brand.image" alt=""></div>
                </div>
                <div>
                    <span class="app-product__stats-price app-product__stats-price--t" :class="{'app-product__stats-price--line':$store.state.product.showdiscount}">
                        {{$store.state.product.original_price}} 
                    </span>
                    <span class="app-product__stats-price--discount app-product__stats-price--discount--t"  v-if="$store.state.product.discount_price!=$store.state.product.original_price">{{$store.state.product.discount_price}}</span>
                </div>
                <button class="app-product__stats-buy app-product__stats-buy--t" v-if="$store.state.product.stock"  @click="addCart2">
                    Adauga in cos  <ion-icon name="bag-handle-outline"></ion-icon>
                </button>
                <button class="app-product__stats-buy app-product__stats-buy--t app-product__stats-buy--t--out" v-else>
                    Stoc epuizat <ion-icon name="bag-handle-outline"></ion-icon>
                </button>
            </div>
        </div>
        <AppViewerM  :newPage="newPage" :viewer_m="viewer_m" @closeViewer="closeViewer"></AppViewerM>
        <notifications position="top center" width="100%" :class="{'notif-position':$store.state.hiddenNav==false}" class="notification-custom"/>
        <AppBigSlider 
        @closeBigSlider="closeBigSlider"
        :activePage="bigSliderPage" 
        :images="$store.state.product.product_images_big" 
        :show="bigSlider"
        v-if="bigSlider===true"> 

        </AppBigSlider>
    </div>
</template>

<script>
import 'viewerjs/dist/viewer.css'
import AppViewerM from "./AppViewerM.vue";
import VueViewer from 'v-viewer'
import Vue from 'vue'
Vue.use(VueViewer)
import $ from 'jquery';
import cookies from 'js-cookie'
import AppMobileSlider from "../components/AppMobileSlider.vue";
import AppBigSlider from "../components/AppBigSlider.vue"; 
import AppRecaptcha from '../components/AppRecaptcha.vue';
export default {
    components:{
        AppViewerM,
        AppMobileSlider,
        AppBigSlider,
        AppRecaptcha
    },
  data(){
    return{
      page:0,
      mobile:false,
      brands:null,
      sizes:false,
      expand:false,
      size:"Selectează o mărime",
        perPage:2,
        viewer:false,
        question : {
            name:"",
            email:"",
            phone:"",
            description:"",
            emailErr:false,
            nameErr:false,
            phoneErr:false,
            descriptionErr:false,

        },
        addedW:false,
        hidden_el:false,
        viewer_m:[],
        newPage:1,
        scale:1.7,
        bigSlider:false,
        bigSliderPage:0,
        recaptcha:false,

    }
  },
  props:{
      hidden:{
          type:Boolean, 
          default:true
      }
  },
  methods:{
    onVerify(response){
        this.recaptcha = true;
    },
    openBigSlider(i) {        
        this.bigSliderPage = i;
        this.bigSlider = true;
        $("html").css("overflow", "hidden");
    },
    closeBigSlider(){
        this.bigSlider = false;
        $("html").css("overflow", "auto");
    },
    goLink(link){
        this.$router.push(link)
    },
    changeScale(){ 
        if(this.scale == 1.7) this.scale = 5;else this.scale = 1.7;
    },
    pageChange(i){
      this.page = i;
    },
    validateEmail(email){
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    },
    async sendQuestion(){
        // this.$refs.recaptchaProd.reset()
        if(
            !this.question.email ||
            !this.question.name ||
            !this.question.description ||
            !this.question.phone || 
            !this.validateEmail(this.question.email)
        )
        {
            if(!this.question.email) this.question.emailErr = true;else this.question.emailErr = false;
            if(!this.validateEmail(this.question.email)) this.question.emailErr = true;else this.question.emailErr = false;
            
            if(!this.question.name) this.question.nameErr = true;else this.question.nameErr = false;
            if(!this.question.phone) this.question.phoneErr = true;else this.question.phoneErr = false;
            if(!this.question.description) this.question.descriptionErr = true;else this.question.descriptionErr = false;
            this.$notify({ type: 'error', text: '<i class="bx bx-x close-notif"></i>Completeaza corect toate campurile.' })
        }
        else {
            this.question.emailErr = false;
            this.question.nameErr = false;
            this.question.phoneErr = false;
            this.question.descriptionErr = false;
            if(!this.recaptcha&&!this.$store.state.auth.token&&!this.$store.state.auth.useridsession) {
                this.$notify({ type: 'error', text: '<i class="bx bx-x close-notif"></i>Te rugam sa completezi recaptcha pentru a continua procesul.' })
                return ;
            }
            this.$notify({ type: 'success', duration:5000, text: '<i class="bx bx-x close-notif"></i>Cererea dvs. a fost trimisa cu succes.' })
            await this.axios.get(`/intrebareprodus?domain=${this.$store.state.domain}&numeprenume=${this.question.name}&email=${this.question.email}&telefon=${this.question.phone}&cerere=${this.question.description.replace(/\n/g, "<br>")}&productid=${this.$route.params.id}&token=${this.$store.state.auth.token}&useridsession=${this.$store.state.auth.useridsession}`);
            this.question.email = "";
            this.question.name = "";
            this.question.description = "";
            this.question.phone = "";
            if(!this.$store.state.auth.token&&!this.$store.state.auth.useridsession) 
                grecaptcha.reset();
            let el = document.getElementsByClassName('grow')[3];
            el.style.height = 0;
            // this.$refs.recaptcha.reset()
        }
        
    },
    closeViewer(){
        this.viewer_m = [];
        $("html").css("overflow", "auto");
    },
    openBig(i) {
        if(window.innerWidth > 700) {
            this.hidden_el = true;
            this.$viewerApi({
                options: {
                    button:true,
                    navbar:true,
                    title:false,
                    toolbar:false,
                    movable:false,
                    scalable:true,
                    fullscreen:true,
                    initialViewIndex: i
            },
            images: this.$store.state.product.product_images_big 
            })
        }
        else {
            $("html").css("overflow", "hidden");
            this.viewer_m = this.$store.state.product.product_images_big
            this.newPage = i;
        }
    },
    setPage(page){
        this.page=page;
    },
    goPage(index){
        this.page+=index;
        if(this.page == -1) this.page = this.$store.state.product.product_images.length-2
        if(this.page == this.$store.state.product.product_images.length-1) this.page = 0;
        // if(this.page > this.$store.state.product.product_images.length/2+1 && index == 1) this.page = 0
    //   if(this.page == 0 && index==-1) this.page = this.$store.state.product.product_images.length/2;
    //   if(this.page == this.$store.state.product.product_images.length/2 && index == 1) this.page = 0
    //   if(this.page == 0 && index==1) this.page++;
    //   if(this.page == this.$store.state.product.product_images.length/2 && index == -11) this.page--;
    //   if(this.page != 0 && this.page != this.$store.state.product.product_images.length/2) this.page+=index;
    //   if(this.page == -1) this.page = this.$store.state.product.product_images.length-2;
    },
    expandBlock(index){
        for(let i = 0; i < this.$store.state.product.infoproduct.length; i++) {
            if(i != index) {
                let el = document.getElementsByClassName('grow')[i];
                 el.style.height = 0;
            }
        }
         let growDiv = document.getElementsByClassName('grow')[index];
        if (growDiv.clientHeight) {
            growDiv.style.height = 0;
        } else {
            let wrapper = document.getElementsByClassName('measuringWrapper')[index];
            let focus = document.getElementsByClassName('app-product__stats-sections-el')[index];
            growDiv.style.height = wrapper.clientHeight + 50 + "px";
            $(focus).focus();
        }
    },
    setSize(size) {
        this.size = size.size;
        this.sizes = false;

        // let wishlist = this.$cookies.get('wishlist');
        // wishlist = JSON.parse(wishlist)
        // let found;
        // if(wishlist) found = wishlist.find(element => element.product_id == this.$store.state.product.product_id && this.size == element.size);
        // if(found) this.addedW = true;else this.addedW = false;

    },
    addCart(){
        this.$store.dispatch("updateLive",this.$store.state.product.product_id);
        if(this.size === "Selectează o mărime"&&
            this.$store.state.product.product_size.multiplesize==true) this.sizes = true;
        else {
            const product_id = this.$store.state.product.product_id;
            if(!this.$store.state.product.product_size.multiplesize) this.size = this.$store.state.product.product_size.sizes[0].size;
            let size = this.size;
            const prodInfo = {
                product_id,
                size,
                number:1
            }

            let cart;
            if(!cookies.get('cart')) 
                {  cart = [] }
            else 
                { cart = cookies.get('cart'); }
                console.log(cart)
                if(cart.length)
                cart = JSON.parse(cart)
            const found = cart.find(product => product.product_id === prodInfo.product_id && product.size == prodInfo.size);
            if(found === undefined) {
                
                cart.push(prodInfo);
                this.$cookies.set('cart', JSON.stringify(cart));
               
                cart = this.$cookies.get('cart');
                 
                if(cart&&!this.$cookies.get('token')&&!this.$cookies.get("useridsession")) this.$store.commit('SET_CARTL', JSON.parse(cart).length);
                if(this.$cookies.get("token")&&this.$cookies.get("useridsession")){
                    this.$store.dispatch("loadCart", this.$route);
                } 
                gtag('event', 'conversion', {'send_to': 'AW-10800167573/_1KkCO6sn5EDEJX19Z0o'});
                // let fbId = this.$route.params.id;
                // window.fbq('track', 'AddToCart', {
                //     content_ids: [fbId],
                //     content_type: 'product'
                // });

                this.$notify({ type: 'success', text: '<i class="bx bx-x close-notif"></i>Produsul a fost adaugat cu succes in cos.' })
            }
            else {
                this.$notify({ type: 'error', text: '<i class="bx bx-x close-notif"></i>Nu poti adauga un produs care se afla deja in cos.' })
            }
        }    
    },
    addCart2(){
        if(this.$store.state.product.product_size.multiplesize==true)
            document.getElementById("sizes").scrollIntoView({behavior: "smooth"});
        this.addCart()
    },
    handleScroll(){
        if(window.scrollY>100) {
            this.hidden_el = true
        }
        if(window.scrollY<100) {
            this.hidden_el = false
        }
        // console.log(window.scrollY)
    },
    async addWishlist(){
        this.$store.dispatch("updateLive",this.$store.state.product.product_id);
            if(!this.addedW){
                const product_id = this.$store.state.product.product_id;
                let wishlist = this.$cookies.get('wishlist');
                wishlist = JSON.parse(wishlist);
                if(!wishlist) { wishlist = []; }
                const found = wishlist.find(product => product === product_id);
                if(found === undefined) {
                    wishlist.push(product_id);
                    this.$cookies.set('wishlist', JSON.stringify(wishlist));
                    wishlist = this.$cookies.get('wishlist');
                    this.addedW = true;
                    this.$notify({ type: 'success', text: '<i class="bx bx-x close-notif"></i>Produsul a fost adaugat cu succes la favorite.' })
                    if(this.$cookies.get("token")&&this.$cookies.get("useridsession")) {
                        this.$store.dispatch("loadWishlist",this.$route);
                    }
                }
                else {
                    this.$notify({ type: 'error', text: '<i class="bx bx-x close-notif"></i>Acest produs a fost deja adaugat la favorite.' })
                }
            }

            else {
                if(this.$cookies.get("token")&&this.$cookies.get("useridsession")){
                    let response = await this.axios.get(`/wishlist?domain=${this.$store.state.domain}&language=${this.$store.state.language}&token=${this.$store.state.auth.token}&useridsession=${this.$store.state.auth.useridsession}&deletewishlistid=${this.$store.state.product.product_id}`);
                    this.$store.commit("SET_WISHLIST", response.data.favorites);
                    this.$store.dispatch("loadMain",this.$route)
                    this.addedW = false;
                    this.$notify({ type: 'success', text: '<i class="bx bx-x close-notif"></i>Produsul a fost scos cu succes de la favorite' })
                }
                
                else {
                    const product_id = this.$store.state.product.product_id;
                    let wishlist = this.$cookies.get('wishlist');
                    wishlist = JSON.parse(wishlist)
                    const index = wishlist.findIndex(product => product === product_id);
                    wishlist.splice(index, 1);
                    if(wishlist.length == 0) this.$cookies.remove('wishlist')
                    else this.$cookies.set('wishlist', JSON.stringify(wishlist));
                    // const productsGet = await this.$axios.$get(`/checkout?domain=${this.$store.state.domain}&products=${JSON.stringify(products)}&token=${this.$store.state.auth.token}&useridsession=${this.$store.state.auth.useridsession}&detelefromcart=${this.product.product_ID}&deletefromcartthissize=${this.product.product_size}`);
                    // this.$store.commit('SET_CART_PRODS', productsGet);
                    // this.$store.commit('SET_CARTL', products.length);
                    this.$notify({ type: 'success', text: '<i class="bx bx-x close-notif"></i>Produsul a fost scos cu succes de la favorite' })
                    this.addedW = false;
                }
            } 
            if(this.$cookies.get('wishlist')) this.$store.commit("SET_WLENGTH",JSON.parse(this.$cookies.get('wishlist')).length)
            else this.$store.commit("SET_WLENGTH",0)
    }
  },
    beforeMount() {
        if(this.$cookies.get("token")&&this.$cookies.get("useridsession"))
            this.addedW = this.$store.state.product.wishlist_added
        if(window.innerWidth < 1000) this.perPage = 1;
        if(this.$store.state.product){
        if(this.$store.state.product.contactform[0].predifinied)
            this.question.name = this.$store.state.product.contactform[0].predifinied
        if(this.$store.state.product.contactform[1].predifinied)
            this.question.email = this.$store.state.product.contactform[1].predifinied
        if(this.$store.state.product.contactform[2].predifinied)
            this.question.phone = this.$store.state.product.contactform[2].predifinied
        if(this.$store.state.product.contactform[3].predifinied)
            this.question.description = this.$store.state.product.contactform[3].predifinied
        }


    if(this.$cookies.get("token")&&this.$cookies.get("useridsession")) {
        console.log(this.$store.state.product)
    }
    else {
        let wishlist = this.$cookies.get('wishlist');
        wishlist = JSON.parse(wishlist)
        let found;
        if(wishlist) found = wishlist.find(element => element == this.$route.params.id);
        if(found) this.addedW = true;
    }
    },
    computed:{
        addedWC(){
            return  this.$store.state.product.wishlist_added;
        }
    },
    watch:{
        "$store.state.product":function(){
            if(this.$store.state.product.contactform[0].predifinied)
                this.question.name = this.$store.state.product.contactform[0].predifinied
            if(this.$store.state.product.contactform[1].predifinied)
                this.question.email = this.$store.state.product.contactform[1].predifinied
            if(this.$store.state.product.contactform[2].predifinied)
                this.question.phone = this.$store.state.product.contactform[2].predifinied
            if(this.$store.state.product.contactform[3].predifinied)
                this.question.description = this.$store.state.product.contactform[3].predifinied
                if(this.$cookies.get("token")&&this.$cookies.get("useridsession")) {
                    this.addedW = this.$store.state.product.wishlist_added;
                }
        },
        "addedWC":function(){
            // alert(1)
        }
    },
    created () {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed () {
        window.removeEventListener('scroll', this.handleScroll);
    },
    
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/_variables.scss";
@import "../assets/styles/main.scss";
.app-product {
    display: flex;
    width: 100%;

    &__images {
        display:block;
        width: 60%;
    }

    &__vertical-c {
        transform: rotate(90deg);
        &-image {
            width: 10rem;
        }
    }

    &__form {
        padding-bottom: 2rem;
        
        div {
            display: flex;
            flex-direction: column;
            margin-top: 1.5rem;

            &:first-child {
                margin-top: 1.4rem;
            }

            label{
                font-family: $medium-main;
                font-size: 1.2rem;
            }

            input,
            textarea {
                padding: 1rem;
                border: 1.5px solid #cfcfcf;
                outline: none;
                margin-top: 1rem;
                resize: none;
                font-family: $light-main;;
                &:focus {
                    border: 2px solid $color-black;
                }
            }
        }
        
        span {
            display: block!important;
            font-family: $medium-main;
            font-weight: normal;
            margin-top: 1.5rem;

            a {
                font-weight: 600;
                text-decoration: none;
                color: $color-gray;

                &:hover {
                    color: $color-black;
                }
            }
        }

        button {
            font-family: $medium-main;
            background:none;
            outline: none;
            border:1px solid $color-black;
            padding: 1rem 6rem;
            margin-top: 1.5rem;    
            cursor: pointer;  
            transition: all .5s; 
            margin-left: .5px;
            
            &:hover {
                box-shadow: 0 0 0 1px $color-black;
                font-family: $semibold-main;
            }
        }
    }

    &__horizontal-c {
        width: 100%;
        margin:0 auto;
        position: relative;

        &-btn{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background: white;
            padding: 1.3rem 1rem;
            cursor: pointer;
            font-size: 2rem;

            ion-icon {
                transform: translateY(2px);
                color: $color-gray;
            }

            &--next {
                right: 0;
                transform: translate(0rem, -50%);
            }
        }
    }

    &__stats {
        display: flex;
        flex-direction: column;
        width: 40%;
        padding: 0 4rem;

        &-brand {
            display: flex;

            span {
                font-family: $medium-main;
                font-size: 2rem;
                display: block;
                margin-top: auto;
                margin-bottom: auto;
                line-height: 1.30;
            }

            img {
                width: 10rem;
                margin-left: auto;
            }
        }

        &-price {
            font-family: $bold-main;
            font-size: 2rem;
            text-transform: uppercase;
            margin-top: 1.5rem;

            &--discount {
                font-family: $bold-main;
                font-size: 2rem;
                text-transform: uppercase;
                margin-top: 1.5rem;
                color: red;
                margin-left: 1rem;
            }

            &--line {
                text-decoration: line-through;
            }
        }

        &-size {
            width: 100%;
            border: 1px solid rgb(187, 187, 187);
            font-family: $medium-main;
            padding: 1.5rem 1rem;
            cursor: pointer;
            display: flex;
            position: relative;
            font-size: 1.1rem;
            margin-top: 2rem;
            box-sizing: border-box;
            position: relative;

            ion-icon {
                display: block;
                margin-left: auto;
               position: absolute;
               right: 1rem;
               font-size: 2rem;
               color: $color-gray;
               transform: translateY(-4px);
            }

            &-opened {
                position: absolute;
                width: 100%;
                padding: 2rem;
                box-sizing: border-box;
                background: $color-white;
                z-index: 100;
                border: 1px solid rgb(187, 187, 187);
                border-top: none;
                // -webkit-box-shadow: 0 1px 3px 0 rgb(0 0 0 / 30%);
                // box-shadow: 0 1px 3px 0 rgb(0 0 0 / 30%);
                max-height: 25rem;
                overflow-y: auto;

                &::-webkit-scrollbar-track{
                    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                    background-color: #F5F5F5;
                    cursor: pointer;
                }

                &::-webkit-scrollbar{
                    width: 9px;
                    background-color: #F5F5F5;
                    cursor: pointer;
                }

                &::-webkit-scrollbar-thumb{
                    background-color: #000000;
                    border: 2px solid #555555;
                    cursor: pointer;
                }

                div {
                    padding-top: 1rem;

                    &:hover {
                        cursor: pointer;
                        background-color: rgb(243, 243, 243);;
                    }
                }
                
                span {
                    font-family: $bold-main;
                    font-size: 1.2rem;
                    margin-left: 1rem;
                }
            }
        }

        &-sizes {
            display: block;
            font-family: $medium-main;
            text-decoration: underline;
            margin-left: auto;
            margin-top: 1rem;
        }

        &-actions {
            display: flex;
            flex-direction: row;
            margin-top: 2.5rem;

            button {
                font-family: $medium-main;
                outline: none;
                padding: 1rem 0;
            }
        }

        &-buy {
            width: 90%;
            background: $color-black!important;
            color: $color-white;
            padding-bottom: 1.5rem!important;
            border: none;
            cursor: pointer;
            transition: all .5s;
            // margin-right: 2rem;

            

            ion-icon {
                font-size: 2rem;
                transform: translate(5px, 3px);
            }

            &:hover {
                background-color: rgba(0, 0, 0, 0.904)!important;
            }
            &--out {
                background: rgb(134, 134, 134)!important;

                &:hover {
                    background: rgb(134, 134, 134)!important;
                }
            }
            
        }

        &-favorite{
            padding: 0 1rem!important;
            background: none;
            outline: none;
            border: 1px solid $color-black;
            font-size: 2.5rem;
            cursor: pointer;
            transition: all .5s;
            margin-left: auto;
            margin-left: 2rem;
            
            ion-icon {
                transform: translateY(2px);
                // color: $color-black!important;
            }

            &:hover {
                // background:$color-black!important;
                // color: $color-white!important;
            }
        }

        &-priority {
            margin-top: 2rem;

            div {
                display: flex;
                margin-top: 1.3rem;

                span {
                    display: block;
                    margin: auto 0;
                    margin-left: 1rem;
                    font-family: $medium-main;
                    font-size: 1.2rem;
                }
            }
        }

        &-sections {
            margin-top: 5rem;

            &-el{
                font-family: $bold-main;
                font-size: 1.2rem;
                cursor: pointer;

                span {
                    display: flex;

                    ion-icon {
                        margin-left: auto;
                        margin-right: 2rem;
                        font-size: 2rem;
                        transform: translateY(-2px);
                    }
                }

                &-expanded {
                    height: 0;
                    overflow: hidden;
                    transition: all 5s;
                }
            }
        }
    }

    &__model-stats{
        display: block;
        font-family: $medium-main;
        font-size: 1.2rem;
        margin-top: 2rem;
        margin-left: .5rem;
    }
}

.h-line {
    width: 100%;
    height: 2px;
    background: $color-gray;
    margin: 1.6rem 0;

    &-light {
        width: 100%;
        height: 1px;
        background: rgb(206, 206, 206);
        margin: 1rem 0;   
        padding-top: 0!important;
    }
}

.expand {
    height: auto;
    overflow: auto;
}

.grow {
  -moz-transition: height .5s;
  -ms-transition: height .5s;
  -o-transition: height .5s;
  -webkit-transition: height .5s;
  transition: height .5s;
  height: 0;
  overflow: hidden;
}

    .hide-m {
        display: block;
    }
    .show-m {
        display: none;
    }

@media only screen and (max-width: 850px) {
    .hide-m {
        display: none;
    }
    .show-m {
        display: block;
    }
    .app-product__mobile-temporary {
        display: flex!important;
    }
.app-product{
    flex-direction: column;
     box-sizing: border-box;
}
.app-product__images,
.app-product__stats{
    width: 100%;
    box-sizing: border-box;
}
.app-product__stats{
    margin-top: 4rem;
}
.slide{
    padding:0!important;
}
.app-product__stats{
    padding: 0;
}
}

.text {
    font-family: $medium-main;
    line-height: 2.30;
}

.categories-popular {
    a{
        text-decoration:none;
        color:$color-black;
        line-height: 1.34;
        font-family:$medium-main;
        transition: all .5s;

        &:hover {
            color: rgb(82, 82, 82);
        }
    }
}

.off-stock{
    pointer-events: none;
    color: rgb(161, 161, 161);
}

.one-size {
    font-family: $bold-main;
    font-size: 1.2rem;
    text-transform: uppercase;
}

@media only screen and (max-width: 500px) {
    .app-product__horizontal-c-btn {
        display: none;
    }
    .carousel-desk {
        display: none;
    }

    .app-product__bullets {
        display: flex;
        position: absolute;
        bottom: 10px;
        left: 50%;;
        transform: translateX(-50%);

        div {
            margin: 5px;
            width: 10px;
            height: 10px;
            background: rgba(255, 255, 255, 0.376);
            border-radius: 50%;

            &:first-child {
                margin-left: 0;;
            }
        }

        &__el-active {
            background: rgb(255, 255, 255)!important;
        }
    }
    .app-product__stats-sections {
        margin-top: 1rem;
    }
}

.added-w {
    color:$color-white;
    background-color: $color-black;;
}

.err-inp {  
    border-color: red!important;
}

.not-focusable:active,
.not-focusable:focus {
    outline:0!important;
    border: none;
}

.app-product__mobile-temporary {
    position: fixed;
    bottom: 0;
    background: white;
    width: 100%;
    z-index: 999999;
    display: none;
    left: 0;
}

.app-product__stats--t {
    padding: 1.5rem;
}

.app-product__stats--t {
    margin-top: 0;
}

.app-product__stats-brand--t{
    padding-bottom: 10px;
    span {
        font-size: 1.4rem;
    }

    div {
        display: block;
        
        img{
            display: block;
            margin-left: auto;
            margin-right: 10px;
            width: 50%;
        }
    }
}

.app-product__stats-price--t,
.app-product__stats-price--discount--t {
    font-size: 1.2rem;
}

.app-product__stats-buy--t {
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
    margin-top: 1rem;
    box-sizing: border-box;
}

.hidden_el {
    transition: all .5s;
    opacity: 0;
    display: none!important;
}

.w-added {
    color:#fff!important;
}
.w-added-no {
    color: black!important;
}

.app-product__stats-buy--t--out {
    outline: none;
    background: rgb(134, 134, 134)!important;

    &:hover {
        background: rgb(134, 134, 134)!important;
    }
}

.no-show{
    visibility: hidden!important;
}

.vue-slider-dot-tooltip-inner,
.vue-slider-dot-tooltip-inner-top {
    display: none!important;
}


</style>