<template>
    <div class="app-cart-summary" v-if="summary">
        <h6 class="app-cart-summary__title">Sumar</h6>
        <div class="app-cart-summary-line"></div>
        <div class="app-cart-summary__stats">
            <span class="app-cart-summary__stats-left">{{summary.total.prettext}}:</span>
            <span class="app-cart-summary__stats-right">{{summary.total.total}}  </span>
        </div>
        <div class="app-cart-summary__stats">
            <span class="app-cart-summary__stats-left">Reducere:</span>
            <span class="app-cart-summary__stats-right">{{summary.total.reducere}} </span>
        </div>
        <div class="app-cart-summary-line"></div> 
        <div class="app-cart-summary__stats">
            <span class="app-cart-summary__stats-left">Subtotal:</span>
            <span class="app-cart-summary__stats-right">{{summary.total.subtotal}} </span>
        </div>
        <div class="app-cart-summary__stats" v-if="summary.total.voucher.show">
            <span class="app-cart-summary__stats-left">Voucher:</span>
            <span class="app-cart-summary__stats-right">{{summary.total.voucher.voucheter_discount}} RON </span>
        </div>
        <div class="app-cart-summary__stats">
            <span class="app-cart-summary__stats-left">Livrare:</span>
            <span class="app-cart-summary__stats-right">{{summary.total.livrare}} </span>
        </div>
        <div class="app-cart-summary__stats app-cart-summary__stats--bold">
            <span class="app-cart-summary__stats-left app-cart-summary__stats-left--bold">Total:</span>
            <span class="app-cart-summary__stats-right app-cart-summary__stats-right--bold">{{summary.total.final}}</span>
        </div>
        <button v-if="chng==false" class="app-cart-summary__btn" @click="buyProd">Finalizare comanda</button>
        <button v-else class="app-cart-summary__btn" @click="$emit('click')">Finalizare comanda</button>
    </div>
</template>


<script>
export default {
    props:{
        summary:{
            type:Object
        },
        chng:{
            type:Boolean,
            default:false
        }
    },
    methods:{
        buyProd() {
            if(!this.$store.state.auth.success) window.location = '/contul-meu/login-checkout'
            else {
                if(this.$store.state.cart_prods.checkout.products.filter(p => p.instock==true).length==0&&this.$route.fullPath=="/cart"){
                    this.$notify({ type: 'error', text: '<i class="bx bx-x close-notif"></i>Produsele tale nu mai sunt in stoc. Adauga alte produse disponibile ' })
                }
                else
                    window.location = '/checkout/step=1';
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/_variables.scss";
@import "../assets/styles/main.scss";
.app-cart-summary {
    width: 100%;
    background: $color-white;
    padding: 3rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    &__title {
        font-family: $bold-main;
        font-size: 2.4rem;

    }

    &-line{
        width: 100%;
        height: 2px;
        background: $color-gray-light;
        margin-top: 1.5rem;
    }

    &__stats{
        display: flex; 
        margin-top: 2rem;

        &--bold {
            margin-top: 5rem;
        }

        span {
            display: block;
            font-family: $medium-main;
            font-size: 1.3rem;
        }

        &-right {
            margin-left: auto;  

            &--bold {
                font-family: $bold-main!important;
            }
        }

        &-left {

            &--bold{
                font-family: $bold-main!important;
            }
        }
    }

    &__btn {
        margin-top: 2rem;
        padding: .8rem 0;
        background: $color-black;
        outline: none;
        border: 1px solid $color-black;
        color: $color-white;
        font-family: $medium-main;
        transition: all .5s;

        &:hover {
            cursor: pointer;
            background-color: rgb(36, 36, 36);
        }

    }
}
</style>
