<template>
    <div class="app-brands" v-if="$store.state.homepage">
        <div class="app-brands__carousel">
            <carousel 
            :loop="true"
            :perPage="numberB" 
            ref="carousel"
            :autoplay="true" 
            :autoplayTimeout="5000" 
            :mouse-drag="true"
            :paginationEnabled="false"
            @pageChange="pageChange">
                <slide v-for="(brand) in $store.state.homepage.brands" :key="brand.ID" >
                  <img :src="brand.image" @click="goProduct(brand.link)" alt="header-image" class="app-brands__image" >
                </slide>
            </carousel>
        </div>
        <div class="arrow-left" @click="prevSlide">
          <ion-icon name="chevron-back-outline"></ion-icon>
        </div>
        <div class="arrow-right" @click="nextSlide">
          <ion-icon name="chevron-back-outline"></ion-icon>
        </div>
    </div>
</template>

<script>
export default {
  data(){
    return{
      page:1,
      mobile:false,
      brands:null,
      numberB:8,
      timer:1
    }
  },
  methods:{
    goProduct(link){
      this.$router.push(link);
    },
    pageChange(i){
      this.page = i;
      console.log(this.page)
    },
    nextSlide() {
      this.$refs.carousel.goToPage(this.$refs.carousel.getNextPage());
    },
    prevSlide() {
      this.$refs.carousel.goToPage(this.$refs.carousel.getPreviousPage());
    }
  },
  beforeMount(){
    if(window.innerWidth <= 1700) this.numberB = 6;
    if(window.innerWidth <= 1500) this.numberB = 5;
    if(window.innerWidth <= 850) this.numberB = 4;
    if(window.innerWidth <= 550) this.numberB = 3;
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/_variables.scss";
@import "../assets/styles/main.scss";
.app-brands{
position: relative;
    
    &__carousel{
        position: relative;
        max-width: 92%;
        margin: 0 auto;
    }

    &__image {
        width: 50%;
        cursor: pointer;
        height:auto;
        display: block;
        margin: 0 auto;
    }
}

.arrow-left {
  position: absolute;
  top:50%;
  left: 10px;
  font-size: 3rem;
  transform: translateY(-40%);
  color: rgb(139, 139, 139);
  cursor: pointer;
}
.arrow-right {
  position: absolute;
  top:50%;
  right: 10px;
  font-size: 3rem;
  transform: translateY(-40%);
  color: rgb(139, 139, 139);
  cursor: pointer;

  ion-icon {
    transform: rotate(-180deg);
  }
}

@media screen and (max-width: 1500px) {
  .arrow-left,
  .arrow-right{
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .app-brands__image {
    width: 80%;
  }
  .arrow-left {
    display: block;
    left: -5px!important;
  }
  .arrow-right {
    display: block;
    right: -4px!important;
  }
}


</style>
