<template>
    <div class="app-newsletter " v-if="$store.state.club">
        <!-- <div class="wrapper top-space" v-html="$store.state.club.content"></div> -->

        <div class="app-newsletter__content">
            <div class="app-newsletter__content-top wrapper">
                <div class="app-newsletter__content-top-header">
                    <p class="app-newsletter__content-top-header-title">{{$store.state.club.text.title}}</p>
                    <p class="app-newsletter__content-top-header-subtitle">
                        {{$store.state.club.text.subtitle}}
                    </p>
                    <button class="app-newsletter__button" @click="goLink($store.state.club.text.button.link)">{{$store.state.club.text.button.name}}</button>  
                    <div class="app-newsletter__scroll" @click="scrollDown">
                        <img src="../assets/images/scroll.png" alt="">
                        <div>
                            <b>Afla mai mult</b>
                            <span>Scroll</span>
                        </div>
                    </div>
                </div>
                <div class="app-newsletter__content-top-left" :style="{'background-image':'url('+$store.state.club.image +')'}"></div>
                <span class="wrapper app-newsletter__text" style="width:1200px;margin-left:auto;margin-right:0;margin-top:4rem;margin-bottom:4rem;" id="club-scroll" v-html="$store.state.club.text.info"></span>
            </div>
            <span class="app-newsletter__question wrapper" >Cum funcționează?</span>
            <div class="app-newsletter__row" v-for="(n, index) in $store.state.club.text.content" :key="n">
                <div class="app-newsletter__template wrapper">
                    <div class="app-newsletter__template-line"></div>
                    <div class="app-newsletter__template-info">
                        <div class="app-newsletter__template-info-number">
                            {{index+1}}.                        
                        </div>
                       <div>
                            <div class="app-newsletter__template-info-top">
                            <p v-html="n.title"></p>
                        </div>
                        <div class="app-newsletter__template-info-bottom">
                            <div>
                                <i :class='n.icon1.image'></i>
                                <p v-if="n.icon1.text">{{n.icon1.text}}</p>
                                <p v-if="n.icon1.subtext" style="color:gray;">{{n.icon1.subtext}}</p>
                            </div>
                            <span>=</span>
                            <div style="margin-left:10rem;">
                                <i :class='n.icon2.image'></i>
                                <p v-if="n.icon2.text">{{n.icon2.text}}</p> 
                                <p v-if="n.icon2.subtext" style="color:gray;">{{n.icon2.subtext}}</p>
                            </div>
                        </div>
                       </div>
                    </div>
                </div>
            </div>
            <span class="wrapper app-newsletter__text" v-html="$store.state.club.text.downtext">
            </span>
        </div>
    </div>
</template>


<script>
import $ from 'jquery'
export default {
    methods:{
        goLink(link) {
            window.location = link;
        },
        scrollDown(){
               let targetOffset = $('#club-scroll').offset().top;
                $('html, body').animate({scrollTop: targetOffset}, 1000);
        }
    },
	beforeMount(){
		this.$store.dispatch("loadClub");
	}
}
</script>

<style lang="scss" scoped>

$color-gray:#585858;
.app-newsletter {

    &__scroll {
        display: flex;
        flex-direction: row;
        margin-top: 50px;;
        font-family: $medium-main;
        font-size: 1.7rem;
        cursor: pointer;

        img {
            display: block;
            height: 60px; 
        }

        b {
            display: block;
        }

        span {
            color: gray;
        }
    }

    &__button {
        background: black; 
        display: block;
        color: white;
        width:200px;
        font-family: $bold-main;
        padding: 1rem 0;
        font-size: 2rem;
        margin-top: 2rem;
    }

    &__row {
        margin-top: 5rem;
        margin-bottom:10rem;
    }

    &__question {
        display: block;
        margin: 0 auto;
        font-family: $bold-main;
        font-size: 5rem;
    }

    &__text {
        display: block;
        margin:0 auto;
        font-family: $medium-main;
        font-size: 3rem;
    }

    &__content {

        &-top {
            position: relative;
            &-header {
                position: absolute;
                display: flex;
                flex-direction: column;
                transform: translateY(15%);

                &-title {
                    font-family: $bold-main;
                    font-size: 6rem;
                }

                &-subtitle {
                    width:600px;
                    font-family: $bold-main;
                    font-size: 3rem;
                }

            }

            &-left {
                width: 75%;
                margin-left: auto;
                background-image: url('../assets/images/ac-intro2.jpg'); 
                height: 550px;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
            }
        }
    }

    &__template {
        display: flex;
        flex-direction: row;
        padding: 2rem 0;

        &-line {
            width: 130px!important;
            height: 10px;
            background: #ffcd00;
            transform: translateY(20px);
            position: absolute;
            // display: none;
        }

        &-info {
            display: flex;
            flex-direction: row;
            margin-left: 330px; 

            &-number {
                padding-right: 40px;;
                font-family: $medium-main;
                font-size: 8rem;
                transform: translateY(-16px);
                color: $color-gray;
            }

            &-top {
                p {
                    font-size: 3rem;
                    font-family: $medium-main;
                }
            }

            &-bottom {
                display: flex;

                div {
                    text-align: center;
                    i{
                        color: $color-gray;
                        font-size: 15rem;
                    }
                    p {
                        font-family: $bold-main;
                        font-size: 2rem;
                        margin-top:1.5rem;
                    }
                }

                span {
                    color: #ffcd00;
                    font-family: $bold-main;
                    font-size: 8rem;
                    margin-top: 6rem;
                }
            }
        }
    }
}

@media only screen and (max-width: 1250px) {
    .app-newsletter__template-line {
        width: 100px!important;
    }
    .app-newsletter__template-info {
        margin-left: 120px;
    }
    .app-newsletter__question {
        font-size: 3rem;
    }
    .app-newsletter__template-info {
        &-number {
            font-size: 5rem;
        }
        &-top {
            p{
                font-size: 1.8rem;
            }
        }
        &-bottom {
            i {
                font-size: 12rem!important;
            }
            span {
                font-size: 4.5rem;
                margin-left: -1rem;
            }

            p {
                font-size: 1.5rem!important;
            }
        }
    }
    .app-newsletter__content-top-header {
        max-width: 50%;
    }
    .app-newsletter__content-top-left{
        height: 300px;;
        width: 70%;
    }
    .app-newsletter__content-top-header-title {
        font-size: 3rem;;
        max-width: 100%;
    }
    .app-newsletter__content-top-header-subtitle {
        font-size: 2rem;
         max-width: 100%;
    }
    .app-newsletter__button {
        font-size: 1.5rem;
    }
    .app-newsletter__scroll {
        font-size: 1.3rem;

        img {
            height: 40px;
        }
    }
    .app-newsletter__text {
        font-size: 2.2rem;
    }
}


@media only screen and (max-width: 600px) {
    .app-newsletter__question {
        padding: 0 3%; 
    }
    .app-newsletter__row {
        margin-bottom: 0;
        padding-left: 3%;
        padding-right: 3%;
    }
    .app-newsletter__template-line {
        position: relative!important;
        transform: translateY(-30px);
    }
    .app-newsletter__template {
       flex-wrap: wrap;
    }
    .app-newsletter__template-info {
        width: 100%;
        margin-left: 0;
        flex-direction: column;
    }
    .app-newsletter__template-info-bottom {
        margin: 0 auto;
        justify-content: center

        span {
            position: relative;
        }

        div {
            margin-left:0!important;
        }

    }
    .app-newsletter__content-top-header {
        max-width: 100%; 
        padding-left: 3%; 
        margin-top: 20rem;
    }
    .app-newsletter__content-top-left{
        height: 200px;;
        width: 100%;
    }
    .app-newsletter__scroll {
        display: none;
    }
   #club-scroll {
       margin-top: 23rem!important; 
   }
   .app-newsletter__text {
       padding: 0 3%;
       width: 100vw;
       margin-left: 0!important;
       margin-right: 0!important;
   }
}

@media only screen and (max-width: 400px) {
    .app-newsletter__template-info {
        &-number {
            font-size: 5rem;
        }
        &-top {
            p{
                font-size: 1.8rem;
            }
        }
        &-bottom {
            margin-top: 1rem;

            div {
                width:150px;
            }
            i {
                font-size: 7rem!important;
            }
            span {
                font-size: 4.5rem;
                margin-left: 0rem;
                margin-top: 2rem;
            }

            p {
                font-size: 1rem!important;
                height: 30px;
            }
        }
    }
}

@media only screen and (max-width: 320px) {
   #club-scroll {
       margin-top: 25rem!important; 
   }
}

</style>