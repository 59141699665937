<template>
    <div class="app-home__sale" v-if="$route.name!='produs'&&$route.name!='Newsletter'&&$route.name!='Produse'" :style="background" @click="goLink()"></div>
</template>

<script>
export default {
    computed:{ 
		background(){
            if(this.$store.state.main)
			return "background-image: url(" + this.$store.state.main.lineup.image + ");";
            else return "";
		}
    },
    methods:{
        goLink(){
            if(this.$store.state.main.lineup)
                this.$router.push(this.$store.state.main.lineup.imagelink);
        },
    },
    beforeMount(){
        console.log(this.$route)
    }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/_variables.scss";
@import "../assets/styles/main.scss";
.app-home__sale {
    width: 100vw;
    height: 65px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    color: $color-black;
}
</style>