<template>
    <div class="app-card-payment" v-if="$store.state.payment" :style="{'background-image':'url('+$store.state.payment.payimage +')'}">
        <div class="app-card-payment__cnt">
            <div class="app-card-payment__recipient">
                <img :src="$store.state.payment.logo" alt="logo">
                <div class="app-card-payment__recipient-el">
                    <span>Beneficiar</span>
                    <span>{{$store.state.payment.payinfo.beneficiar}}</span>
                </div>
              <div class="app-card-payment__recipient-el">
                    <span>Adresa ta de e-mail</span>
                    <span>{{$store.state.payment.payinfo.email}}</span>
                </div>
                <div class="line"></div>
                <div class="app-card-payment__recipient-total">
                    <span>Suma</span>
                    <span>{{$store.state.payment.payinfo.amount}}</span>
                </div>
            </div>
        </div>
        <div class="app-card-payment__cnt">
            <div class="app-card-payment__form">
                <div class="app-card-payment__form-top">
                    <span>Card</span>
                   <div style="display:flex;margin-left:auto;">
                        <img src="../assets/images/visa.svg" alt="visa-logo">
                        <img src="../assets/images/mastercard.png" alt="mastercard-logo">
                   </div>
                </div>
                <div class="app-card-payment__form-center">
                    <label for="card_number">Numărul de card</label>
                    <cleave name="card_number"  :class="{'inp-error-pay':error_number!=false}" class="app-card-payment__form-center-cn form-control" type="tel" v-model="card_number"  :options="options.creditCard" placeholder="1234 4534 2342 1234"/>
                    <span class="error-txt" v-if="error_number">{{error_number}}</span>
                    <div style="display:flex;flex-direction:row;margin-top:2rem;">
                        <div style="width:49%;">
                            <label for="valabil">Data expirarii </label>
                            <cleave name="valabil" :class="{'inp-error-pay':error_year!=false}"  v-model="expire_period" class="form-control app-card-payment__form-center-cn" :raw="false" :options="options.date" placeholder="LL/AA"/>
                            <span class="error-txt" v-if="error_year">{{error_year}}</span>
                        </div>
                        <div style="width:49%;margin-left:auto;">
                            <label for="valabil">CVV/CVC</label>
                            <input v-model="cvv" :class="{'inp-error-pay':error_cvv!=false}" maxlength="3" class="form-control app-card-payment__form-center-cn" placeholder="CVV/CVC"/>
                            <span class="error-txt" @input="error_cvv=false" v-if="error_cvv">{{error_cvv}}</span>
                        </div>
                    </div>
                    <span v-if="error_main" class="error-txt" style="margin-top:10px;display:block;text-align:center!important;" >{{error_main}}</span>
                    <button @click="pay">Plateste {{$store.state.payment.payinfo.amount}}</button>
                </div>
                <div class="app-card-payment__form-bottom">
                    <img src="../assets/images/safe.png" alt="safe-image">
                </div>
            </div>
            <div class="app-pay__footer">
                <div class="app-pay__footer-top">
                    <span>{{$store.state.payment.payinfo.plataprocesatapentru}} </span> 
                </div>
                <div class="app-pay__footer-middle">
                    <span>{{$store.state.payment.payinfo.extratext}}</span>  
                    <p @click="txtAction">{{textVal}}</p>
                </div>
                <div class="app-pay__footer-bottom">
                    <div class="app-pay__footer-bottom-template">
                        <img :src="el.image" @click="openImage(el.link)" v-for="(el, index) in $store.state.payment.row1" :key="index+2323" alt="card">
                    </div>
                    <div class="app-pay__footer-bottom-template">
                        <img :src="el.image" @click="openImage(el.link)" v-for="(el, index) in $store.state.payment.row2" :key="index+2323" alt="card">
                    </div>
                </div>
            </div>
        </div>
        <div class="app-pay-loading" v-if="waiting">
            <div class="app-pay-loading__center">
                <div class='container-loader'>
                    <div class='bar'></div>
                </div>
                <h5>Plata dumnevoastra se proceseaza</h5>
                <p>Va rugam sa asteptati</p>
            </div>
        </div>
    </div>
</template>

<script>
import Cleave from 'vue-cleave-component';
import $ from 'jquery'
export default {
    components: {
        Cleave
    },
    data(){
        return {
            waiting:false,
            cvv:"",
            expire_period:"",
            expire_year:"",
            expire_date:"",
            card_number:"",
            error_number:false,
            error_year:false,
            error_cvv:false,
            error_main:false,
            options: {
                    creditCard: {
                        creditCard:true,
                        delimiter: ' ', 
                    },
                    date: {
                        date: true,
                        datePattern: ['m', 'd'],
                        delimiter: '/',
                    },
                    number: {
                        prefix: '$ ',
                        numeral: true,
                        numeralPositiveOnly: true,
                        noImmediatePrefix: true,
                        rawValueTrimPrefix: true,
                        numeralIntegerScale: 9,
                        numeralDecimalScale: 2
                    }
            },
            textVal:'Citeste mai mult',
            pi:null
    }},
    methods:{
        openImage(url){window.open(url, '_blank').focus();},
        async pay(){
            this.expire_date = this.expire_period.substring(0, 2); 
            this.expire_year = this.expire_period.substring(3, 5); 
            let data = await this.axios.get(`/validatorformcard?cardnr=${this.card_number}&expyear=${this.expire_year}&expmonth=${this.expire_date}&cvv=${this.cvv}`);
            data = data.data;
            this.error_number = data.cardnrerror;
            this.error_year = data.expiredateerror;
            this.error_cvv = data.cvverror;
            this.error_main = data.notification;
            console.log(data)
            if(data.formsuccess) {
                this.waiting = true;
                let dataStripe = await this.axios.get(`/cardform?domain=${this.$store.state.domain}&useridsession=${this.$store.state.auth.useridsession}&token=${this.$store.state.auth.token}&cardnr=${this.card_number}&expyear=${this.expire_year}&expmonth=${this.expire_date}&payedamount=${this.$store.state.payment.payinfo.amount}&cvv=${this.cvv}&pi=${this.pi}`);
                dataStripe = dataStripe.data; 
                console.log(dataStripe)
                if(dataStripe.secure3d) window.location = dataStripe.secure3d;
                else {
                    if(dataStripe.notification){
                        this.error_main = dataStripe.notification;
                        this.waiting = false
                    }
                    else {
                        window.location = '/checkout/step=5';
                    }
                }
                
            }
        },
        txtAction() {
            if(this.textVal != "Citeste mai putin") {
                this.textVal = "Citeste mai putin";
                $('.app-pay__footer-middle').height('100%');
            }
            else {
                this.textVal = "Citeste mai mult"
                $('.app-pay__footer-middle').height('50px')
            }
        }
    },
    beforeMount(){
        // if(!this.$store.state.auth.success) window.location = "/contul-meu"
        if(this.$route.params.pi)
            this.pi = this.$route.params.pi.split('pi=')[1];
        this.$store.dispatch('loadPayment',this.pi);
    },
    watch:{
        "$store.state.payment":function(){
            this.error_main = this.$store.state.payment.notification
        }
    }

}
</script>


<style lang="scss" scoped>
@import "../assets/styles/_variables.scss";
@import "../assets/styles/main.scss";
.app-card-payment {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-size: cover;
    background-position: top;
    display: flex;
    flex-direction: row;
    justify-content: center;

    &__recipient {
        display: flex;
        flex-direction: column;
        padding:3rem;
        background: #eee;
        width:30rem;
        border-radius: 5px;
        margin-top: 10rem;
        max-width: 100vw; 
        

        img {
            display: block;
            width:13rem;
            margin: 0 auto;
        }

        &-el {
            margin-top: 1rem;
            span {
                display: block;
                font-family: $medium-main;
                font-size: 1.2rem;

                &:nth-child(2) {
                    color: #008558;
                }
            }
        }

        &-total {
            display: flex;
            margin-top: 2rem;

            span {
                font-size: 1.3rem;
                display: block;
                &:first-child {
                    font-family: $medium-main;
                }

                &:nth-child(2) {
                    font-family: $semibold-main;
                    color: #008558;
                    margin-left: auto;
                    font-size: 1.6rem;
                }
            }
        }
    }
    &__form {
        margin-top: 10rem;
        width: 40rem;
        margin-left: 2rem;
        margin-right: 200px;
        max-width: 100vw;   

        &-top {
            width: 100%;;
            padding: 2rem;
            background: #eee;
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
            display: flex;

            img {
                width: 30px;
                display: block;

                &:first-child{
                    margin-right: 10px;;
                }
            }

            span {
                font-size: 2rem;
                font-family: $bold-main;
            }
        }

        &-center {
            padding: 2rem;
            width:100%;
            background-color: #fafafa;

            &-cn {
                outline: none;
                padding: 10px;
                border: 1px solid #d9d9d9;
                color: #000;
                box-shadow: 1px 1px 3px 0 #f2f2f2;
                transition: .2s all;
                font-weight: 400;
                width:100%;
                box-sizing: border-box;
                border-radius: 5px!important;

                &:focus {
                    -webkit-box-shadow: 1px 1px 6px -2px #1AB5FF; 
                    box-shadow: 1px 1px 6px -2px #1AB5FF;
                    border: 1px solid #1AB5FF;
                }
            }

            label {
                font-family: $medium-main;
                font-size: 1.2rem;
                color: $color-gray;
                display: block;
                padding-bottom: 5px;
            }

            button {
                width:100%;
                display: block;
                margin-top: 2rem;
                outline: none;
                background-image: linear-gradient(#44922a,#44922a);
                color: #fff!important;
                padding: 11px 10px;
                border: none;
                transition: .1s all;
                cursor: pointer;
                text-align: center;
                box-shadow: 0 1px 1px #dedede;
                text-decoration: none;
                box-sizing: border-box;
                font-family: $semibold-main;
                border-radius: 5px;
                transition: all .5s;

                &:hover {
                    background-image: linear-gradient(#59b13c,#44922a);
                }
            }
        }
        &-bottom {
            width: 100%;;
            padding: 2rem;
            background: #eee;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;

            img {
                width:60px;
                display: block;
                margin-left: auto;
                cursor: pointer;
            }
        }
    }
}

.line {
    width: 100%;
    height: 1px;
    background-color: rgb(202, 200, 200);
    margin-top: 2rem;
}

.app-pay__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.712);
    width: 100vw;
    padding: 4rem 0; 

    &-top {
        padding-left: 5rem;
        padding-right: 5rem;
        display: flex;
        justify-content: space-between;

        span {
            &:first-child {
                font-family: $medium-main;
                font-size: 1.6rem;
                color: $color-white;
            }
        }

        a {
            display: block;
            font-family: $medium-main;
            font-size: 1.6rem;
            text-decoration: none;
            color: $color-white;
        }
    }

    &-middle {
        padding-left: 5rem;
        padding-right: 5rem;
        margin-top: 1rem;
        height: 50px;
        box-sizing: border-box;
        position: relative;
        display: flex;
        flex-direction: column; 

        span {
            display: block;
            color: rgb(134, 134, 134);
            height:60%;   
            overflow: hidden;
        }

        p {
            display: block;
            font-family: $semibold-main;
            color:rgb(134, 134, 134); 
            margin-left: auto;
            margin-right: 30px;
            cursor: pointer;
            user-select: none;
        }
    }

    &-bottom {
        display: flex;
        justify-content: space-between;
                padding-left: 5rem;
        padding-right: 5rem;

        &-template {
            display: flex;

            img {
                margin: 10px;
                display: block;
                height: 40px;
            }
        }
    }
}

.app-pay-loading {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 5;
    background: rgba(0, 0, 0, 0.383);
    display: flex;
    
    &__center {
        position: relative;
        max-width:50rem;
        
        background: white;
        display: block;
        margin: auto;
        border-radius: 5px;
        padding: 3rem;

        h5 {
            font-size: 2rem;
            color: $color-black;
            font-family: $bold-main;
            text-align: center;
            margin-top: 2.5rem;
        }

        p{
            font-size: 1.7rem;
            color: $color-gray;
            font-family: $bold-main;
            text-align: center;
            margin-top: 2.5rem;
        }
    }
}

@media only screen and (max-width: 1600px) {
.app-pay__footer {
    padding-bottom: 1rem!important;
    padding-top: 1rem!important;
}
}
@media only screen and (max-width: 850px) {
    .app-card-payment {
        flex-direction: column;
        justify-content: center;
        height: 110vh;

        &__cnt {      
            margin: 0 auto;
            box-sizing: border-box;
            max-width: 50rem; 
            height: 80px;
        }
    }
    .app-card-payment__recipient,
    .app-card-payment__form {
        width:50rem; 
    }
    .app-card-payment__form {
        margin: auto;
        margin-top: -100px;
    }
    .app-card-payment__form-top {
        margin-top: 2rem!important;
    }
    .app-card-payment__recipient {
        margin-top: -280px;
    }
    .app-pay__footer {
        display: none;
    }
}

@media only screen and (max-width: 430px) {
    .app-card-payment__recipient,
    .app-card-payment__form {
        width:95vw;
    }
}


@keyframes slide {
  from {
    background-position-x: 0;
  }
  to {
    background-position-x: 113px;
  }
}

.container-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  .bar {
    min-height: 50px;
    border-radius: 10px;
    width: calc(113px * 4);
    @media only screen and (max-width: 450px) {
      width: calc(113px * 3);
    }
    @media only screen and (max-width: 350px) {
      width: calc(113px * 2);
    }
    box-shadow: 0px 10px 13px -6px rgb(61, 61, 61);
    background-color: #dbdbdb;
    background-image: repeating-linear-gradient(
      45deg,
      transparent,
      transparent 40px,
      #44922a 40px,
      #44922a 80px
    );

    animation: slide 4s linear infinite;
    will-change: background-position;
  }
}

.inp-error-pay {
    -webkit-box-shadow: 1px 1px 6px -2px #ff1a1a; 
    box-shadow: 1px 1px 6px -2px #ff1a1a;
    border: 1px solid #ff1a1a;
}

.error-txt {
    color: red;
    font-family: $light-main;
}
</style>