<template>
    <div>
        <div class="app-loader-c" v-if="loader">
            <AppLoader/>
        </div>
        <div class="app-type" v-if="$store.state.browse" :class="{'hidden-ell':loader==true}">
            <div class="wrapper">
                <button v-if="$store.state.browse.debug"  @click="hide=!hide">SHOW</button>
                <div v-if="hide==false">
                    <span  style="font-size:3rem;display:block;padding:2rem 0;" v-for="(i, index) in $store.state.browse.alex" :key="index">{{i}}</span>
                </div>
                <AppHeaderProd/>
                <AppFilter :opened="openedFilter" :categories="filter" @updateComplex="updateComplex" @closeFilter="closeFilter" />
                <AppSortBy :opened="openedSort" :categories="$store.state.sort" @closeSort="closeSort"/>
            </div>
            <div class="wrapper" style="border-top:1px solid gray;height:40px;"  v-if="$store.state.browse.pagination.showpagination" >
                <app-pagination></app-pagination>
            </div>
            <div class="wrapper block-wr clearfix" style="display:flex;">
    <div data-v-sticky-container style="width:250px!important;outline:none!important;">
      <!--globally installed (default namespace) -->
      <div style="width:300px!important;outline:none!important;" v-vue-sticky-directive="{topSpacing:20, bottomSpacing:20}">
        <AppFilterD class="set-h" style="overflow-anchor: none;" :class="{'top-b':scrolledFilter==false}"/>
      </div>
    </div>
                     <!-- <div style="width:300px!important;"> 
                        <vue-sticky-sidebar :resizeSensor="true" style="height:960px!important;width:300px!important;overflow-anchor: none!important;" class="sidebar" containerSelector=".block-wr" innerWrapperSelector='.sidebar__inner'>
                            <AppFilterD class="set-h" style="overflow-anchor: none;height:960px;" :class="{'top-b':scrolledFilter==false}"/>
                        </vue-sticky-sidebar> 
                     </div> -->
                <!-- <div :class="{'filter-bottom':scrolledFilter}">
                    <AppFilterD style="margin-top:-40px;" :class="{'filter-bottom-abs':scrolledFilter}"/>
                </div> -->
                <div>
                    <div class="grid-cnt products-container" style="margin-top:2rem;margin-left:auto;margin-right:auto;"> <!-- Au fost 5rem -->
                            <loading 
                            :active="loading"
                            :can-cancel="false"
                            :is-full-page="false"/>
                        <div class="sort-btn hide-element" @click="openedSort=true">
                            <i class='bx bx-sort-alt-2'></i>
                            <div class="sort-btn__text">
                                <span>Sorteaza</span>
                                <span>{{textSort.name}}</span>
                            </div>
                        </div> 
                        <div class="sort-btn hide-element" @click="openFilter">
                            <i class='bx bx-filter-alt' ></i>
                            <div class="sort-btn__text">
                                <span>Filtreaza</span>
                                <span>{{$store.state.browse.browsetitles[1].text}}</span>
                            </div>
                        </div>    
                        <div v-for="p in $store.state.browse.browse" :key="p.product_id">
                            <AppProductEl :product="p"></AppProductEl>
                        </div>
                        <span  class="not-f-prod" v-if="!$store.state.browse.browse">Nu au fost gasite produse.</span>
                    </div>
                    <div class="wrapper" style="margin-left:auto;margin-right:0;" v-if="$store.state.browse.pagination.showpagination" >
                        <app-pagination ></app-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import AppHeaderProd from  "../components/AppHeaderProd";
import AppFilter from "../components/AppFilter.vue";
import AppSortBy from "../components/AppSortBy.vue"
// import vueStickySidebar from "vue-sticky-sidebar";
// import $ from "jquery";
import VueStickyDirective from "@renatodeleao/vue-sticky-directive";
import $ from 'jquery'

export default {
    components: {
        Loading,
        AppHeaderProd,
        AppFilter,
        AppSortBy,
        
        // "vue-sticky-sidebar": vueStickySidebar
    },
    directives: {
        VueStickyDirective
    },
    data(){
        return {
            hide:true,
            loading:false,
            loader:true,
            openedSort:false,
            openedFilter:false,
            filter:null,
            scrolledFilter:false
        }
    },
    computed:{
        textSort(){
            if(this.$store.state.sort.length==0) return "";
            else return this.$store.state.sort .find(element => element.active ==true);
        }
    },
    methods:{
        closeSort() {
            this.openedSort = false;
        },
        openFilter() {
                $('html, body').css({
                    overflow: 'hidden',
                    height: '100vh'
                });
            this.filter = [...this.$store.state.filter];
            this.openedFilter = true;
            
        },
        closeFilter() {
            $('html, body').css({
                overflow: 'auto',
                height: 'auto'
            });
            this.openedFilter = false;
            this.filter = null;
            this.$store.dispatch('loadProduse',this.$route.params.type,this.$route.params.type)
        },
        updateComplex() {
            this.filter = [...this.$store.state.filter];
        },
        handleScroll(){
            // console.log(window.scrollY)
            // let box = document.querySelector('.app-filter-d');
            // let height = box.offsetHeight;
            // if(window.scrollY % 2==0) height++;
            // else height--;
            // // height = height +"px!important;";
            // $('.set-h').height(height);
            // console.log(height)
            if(window.scrollY>424) {
                console.log(window.scrollY)
                this.scrolledFilter = true;
            }
            else {
                this.scrolledFilter = false;
            }
            // if(window.scrollY<150) {
            //     this.hidden_el = false
            // }
            // console.log(window.scrollY)
        },
    },
    mounted(){
        setTimeout(()=>{
            this.loader = false;
        },500)
    },
    beforeMount(){
        this.$store.dispatch('loadProduse',this.$route.params.type,this.$route.params.type)
    },
    watch:{
        "$route.params.type":function() {
            this.$store.dispatch('loadProduse',this.$route.params.type,this.$route.params.type)
        },
        "$store.state.filter" : {
            handler : function() {
                this.filter = this.$store.state.filter;
                console.log("updateeeeeeeeeeeeeeeee", this.filter)
            },
            deep:true
        },
    },
    created () {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed () {
        window.removeEventListener('scroll', this.handleScroll);
    },
}
</script>  


<style lang="scss" scoped>

.grid-cnt {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 8rem;
    column-gap: 3rem;
}

@media only screen and (max-width: 1600px) {
    .grid-cnt {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        row-gap: 3rem;
        column-gap: 2rem;
    }
}
@media only screen and (max-width: 1000px) {
    .grid-cnt {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 3rem;
        column-gap: 2rem;
    }
}

@media only screen and (max-width: 800px) {
    .app-prod-header {
        margin-left: 3px;
    }
}

.app-loader-c {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    margin-top: 5rem;
}

.hidden-ell {
    visibility: hidden;
}

.sort-btn {
    display: flex;
    border: 1px solid #cbcbcb;
    box-sizing: border-box;
    padding: .6rem .3rem;
    cursor: pointer;

    i {
        font-size: 2.7rem;
        color: #6e6e6e;
        transform: translateY(1px);
        margin-top: auto;
        margin-bottom: auto; 
        font-weight: 100;
    }

    &__text {
        display: flex;
        flex-direction: column;
        margin-left: 1rem;
        display: block;


        span {
            font-family: $light-main;
            display: block;

            &:first-child {
                font-size: 1.4rem;
            }

            &:nth-child(2) {
                margin-top:-3px;
                color: rgb(126, 126, 126);
            }
        }
    }
}

.products-container {
    width: calc(100% - 10px);
}

.hide-element {
    display: none;
}
@media only screen and (max-width: 800px) {
    .hide-element {
        display: flex;
    }
    .block-wr {
        display: block!important;
    }

    .not-f-prod {
        margin-top:100px!important;
        transform: translateX(-50%);
    }
}

.not-f-prod {
    font-family: $light-main;
    text-align: center;
    margin-top:3rem;
    display: block;
    margin: 0 auto;;
    position: absolute;
    left:50%;
    font-size: 1.3rem;
}

.filter-bottom {
    width: 440px!important;
    position: relative;
}

.top-b {
    margin-top:-40px;
}
</style>