import Vue from 'vue'
import axios from 'axios'
import cookies from 'js-cookie'
import VueAxios from 'vue-axios';
import Vuex from 'vuex'
Vue.use(Vuex)
Vue.use(VueAxios, axios)
import $ from 'jquery';

export default new Vuex.Store({
  state: {
    saleImage:null,
    header:null,
    language:null,
    gender:null,
    product:null,
    similarproducts:null,
    newsletter:null,
    homepage:null,
    club:null,
    livrare:null,
    produse:null,
    contact:null,
    faq:null,
    retur:null,
    reclamatii:null,
    tc:null, 
    at:null,
    pdc:null,
    despre:null,
    blog:null,
    lt:null,
    lc:null,
    pl:null,
    po:null,
    detalii:null,
    cookies:null,
    sitemap:null,
    browse:null,
    main:null,
    w_opened:null,
    domain:window.location.hostname=='localhost'|| window.location.hostname=='192.168.1.109' ? 'designmall.ro' : window.location.hostname,
    auth:{
      token:cookies.get('token'),
      useridsession:cookies.get('useridsession')
    },
    campaign:localStorage.getItem("campaign"),
    myacc:null,
    cart_prods:null,
    cartlength:0,
    checkout:null,
    adminData:null,
    brands:null,
    step:null,
    payment:null,
    wishlist_size:null,
    test:null,
    forceupdate:null,
    favicon:null,
    pr_originale:null,
    favorites:[],
    w_length:0,
    history:null,
    retururi:{},
    personalizeaza:"",
    w_text:null,
    sort:[],
    filter:[],
    deleteF:null,
    w_link:"",
    hiddenNav:null
  },
  mutations: {
    SET_TEST(state, test){
      state.test = test;
    },
    SET_NAVSHOW(state, hiddenNav){
        state.hiddenNav = hiddenNav;
      },
    SET_WLENGTH(state, length){
        state.w_length = length;
    },
    SET_SALE(state, saleImage) {
        state.saleImage = saleImage;
    },
    SET_WSIZES(state,data){
        state.w_opened = data;
    },
    SET_HEADER(state, header) {
        state.header = header;
    },
    SET_LANGUAGE(state, language) {
        state.language = language;
    },
    SET_GENDER(state, gender) { 
        state.gender = gender;
    },
    SET_PRODUCT(state, product) {
        state.product = product;
    },
    SET_SIMILAR(state, similar) {
        state.similarproducts = similar;
    },
    SET_NEWSLETTER(state, n) {
        state.newsletter = n;
    },
    SET_HOMEPAGE(state, homepage) {
        state.homepage = homepage;
    },
    SET_CLUB(state, club) {
        state.club = club;
    },
    SET_LIVRARE(state, livrare) {
        state.livrare = livrare;
    },
    SET_PRODUSE(state, produse) {
        state.produse = produse;
    },
    SET_CONTACT(state, contact) {
        state.contact = contact;
    },
    SET_FAQ(state, faq) {
        state.faq = faq;
    },
    SET_RETUR(state, retur) {
        state.retur = retur;
    },
    SET_RECLAMATII(state, reclamatii) {
        state.reclamatii = reclamatii;
    },
    SET_TC(state, tc) {
        state.tc = tc;
    },
    SET_AT(state, at) {
        state.at = at;
    },
    SET_PDC(state, pdc) {
        state.pdc = pdc;
    },
    SET_DESPRE(state, despre) {
        state.despre = despre;
    },
    SET_BLOG(state, blog) {
        state.blog = blog;
    },
    SET_WTEXT(state, wtexts) {
        state.w_text = wtexts;
    },
    SET_LT(state, lt) {
        state.lt = lt;
    },
    SET_LC(state, lc) {
        state.lc = lc;
    },
    SET_PL(state, pl) {
        state.pl = pl;
    },
    SET_PO(state, po) {
        state.po = po;
    },
    SET_DETALII(state, detalii) {
        state.detalii = detalii;
    },
    SET_COOKIES(state, cookies) {
        state.cookies = cookies;
    },
    SET_SITEMAP(state, sitemap) {
        state.sitemap = sitemap;
    },
    SET_BROWSE(state, browse) {
        state.browse = browse;
    },
    SET_MAIN(state, main) {
        state.main = main;
    },
    SET_DOMAIN(state, domain) {
        state.domain = domain;
    },
    SET_AUTH(state, auth) {
        state.auth = auth;
    },
    SET_MYACC(state, myacc) {
        state.myacc = myacc;
    },
    SET_CART_PRODS (state, cart) {
        state.cart_prods = cart;
    },
    SET_CHECKOUT(state, checkout) {
        state.checkout = checkout;
    },
    SET_BRANDS(state, brands) {
        state.brands = brands;
    },
    SET_CARTL(state, length) {
        state.cartlength = length;
    },
    SET_CHECKOUT_STEPS(state, step){
        state.step = step;
    },
    SET_PAYMENT(state, payment) {
        state.payment = payment;
    },
    SET_SIZES(state, data) {
        state.wishlist_size = data;
    },
    SET_WISHLIST(state, favorites){
        // if(!state.token&&!state.useridsession) favorites = favorites.reverse()
        state.favorites = favorites;
    },
    SET_PR_ORIGINALE(state, data) {
        state.pr_originale = data;
    },
    SET_HISTORY(state, history) {
        state.history = history;
    },
    SET_RETURURI(state, retururi) {
        state.retururi = retururi;
    },
    SET_PERSONALIZEAZA(state, data) {
        state.personalizeaza = data
    },
    SET_SORT(state, sort) {
        state.sort = sort;
    },
    SET_FILTER(state, filter) {
        state.filter = filter;
    },
    UPDATE_FILTER(state, data) {
        console.log(data)
        state.filter.forEach((el)=>{
            if(el!=null) {
                if(data.id==el.ID) {
                    console.log(el)
                    el.values[data.index].selected = data.value;
                    console.log(el.values[data.index])
                }
            }
        })
    },
    SET_GENERAL_D(state, deleteF) {
        state.deleteF = deleteF;
    },
    SET_W_LINK(state, w_link) {
        state.w_link = w_link;
    },
    SET_CURR_PAGE(state, page) {
        if(state.browse&&state.browse.pagination.currentpage) {
            state.browse.pagination.currentpage = page;
        }
    }
  },

  actions:{
    async loadMain({commit,state}, route) {
      // const response = await axios.get(`https://api.designmall.ro/links`);
      let removable = null;
      let r;
      if(route){
        if(route&&route.params.id) removable = route.params.id;
        if(route&&route.params.type) removable = route.params.type;
        if(route&&route.params.step) removable = route.params.step;
        if(removable&&route&&route.fullPath) {
            r = route.fullPath.replace(removable,"");
            r = r.slice(0, -1);
        }
        else r = route.fullPath;
    }
      let gender = cookies.get("gender");  
      if(!gender) gender = "empty"
      const response = await axios.get(`/main?domain=${state.domain}&token=${state.auth.token}&useridsession=${state.auth.useridsession}&route=${r}&c1=${gender}`);
      console.log(response)
      commit("SET_MAIN",response.data);
      console.log("zzzzzzzzzzzzzzzzz",response)
      $("#favicon").attr("href", response.data.faviconimage);
      if(cookies.get('token') && cookies.get('useridsession')) {
            let auth = {
                token:cookies.get('token'),
                useridsession:cookies.get('useridsession'),
                success:true
            }
            commit("SET_AUTH", auth);
        }
        else {
            let auth = {
                token:null,
                useridsession:null,
                success:false
            }
            commit("SET_AUTH", auth);
        }
    },
    async loadHomepage({commit,state},track){
        // console.log(track)
        const gender = cookies.get('gender')
        let response = await axios.get(`/homepage?domain=${state.domain}&language=${state.language}&token=${state.auth.token}&useridsession=${state.auth.useridsession}&c1=${gender}&track=${track}&campainid=${state.campaign}`);
        response = response.data;
        console.log(response)
        commit('SET_HEADER', response.header)
		commit('SET_HOMEPAGE', response)
		if(track!='off') commit('SET_SIMILAR', response.lastproducts)
    },
    async reloadNavMobileHome({commit,state},genderParam){
        // console.log(track)
        const gender = genderParam;
        let response = await axios.get(`/homepage?domain=${state.domain}&language=${state.language}&token=${state.auth.token}&useridsession=${state.auth.useridsession}&c1=${gender}&campainid=${state.campaign}`);
        response = response.data;
        console.log(response)
        commit('SET_HEADER', response.header)
		commit('SET_HOMEPAGE', response)
		commit('SET_SIMILAR', response.lastproducts)
    },
    async loadRetur({commit,state}){
        let response = await axios.get(`/retur?domain=${state.domain}&language=${state.language}&token=${state.auth.token}&useridsession=${state.auth.useridsession}`);
        response = response.data;
        commit('SET_RETUR', response)
    },
    async loadReclamatii({commit,state}){
        let response = await axios.get(`/reclamatii?domain=${state.domain}&language=${state.language}&token=${state.auth.token}&useridsession=${state.auth.useridsession}`);
        response = response.data;
        console.log(response)
        commit('SET_RECLAMATII', response)
    },
    async loadTermeni({commit,state}){
        let response = await axios.get(`/termeni-si-conditii?domain=${state.domain}&language=${state.language}&token=${state.auth.token}&useridsession=${state.auth.useridsession}`);
        response = response.data;
        console.log(response)
        commit('SET_TC', response)
    },
    async updateLive({state}, id){
        await axios.get(`/liveupdate?ID=${id}`);
        console.log(state)
    },
    async loadFaq({commit,state}){
        let response = await axios.get(`/faq?domain=${state.domain}&language=${state.language}&token=${state.auth.token}&useridsession=${state.auth.useridsession}`);
        response = response.data;
        console.log(response)
        commit('SET_FAQ', response)
    },
    async loadPl({commit,state}){
        let response = await axios.get(`/plata-la-livrare?domain=${state.domain}&language=${state.language}&token=${state.auth.token}&useridsession=${state.auth.useridsession}`);
        response = response.data;
        console.log(response)
        commit('SET_PL', response)
    },
    async loadPo({commit,state}){
        let response = await axios.get(`/plata-online?domain=${state.domain}&language=${state.language}&token=${state.auth.token}&useridsession=${state.auth.useridsession}`);
        response = response.data;
        console.log(response)
        commit('SET_PO', response)
    },
    async loadPrOriginale({commit,state}){
        let response = await axios.get(`/produse-originale?domain=${state.domain}&language=${state.language}&token=${state.auth.token}&useridsession=${state.auth.useridsession}`);
        response = response.data;
        console.log("lll",response)
        commit('SET_PR_ORIGINALE', response) 
    },
    async loadNewsletter({commit,state}){
        let response = await axios.get(`/newsletter?domain=${state.domain}&language=${state.language}&token=${state.auth.token}&useridsession=${state.auth.useridsession}`);
        response = response.data;
        console.log(response)
        commit('SET_NEWSLETTER', response)
    },
    async loadLivrare({commit,state}){
        let response = await axios.get(`/livrare?domain=${state.domain}&language=${state.language}&token=${state.auth.token}&useridsession=${state.auth.useridsession}`);
        response = response.data;
        console.log(response)
        commit('SET_LIVRARE', response)
    },
    async loadContact({commit,state}){
        let response = await axios.get(`/contact?domain=${state.domain}&language=${state.language}&token=${state.auth.token}&useridsession=${state.auth.useridsession}`);
        response = response.data;
        console.log("xxx",response)
        commit('SET_CONTACT', response)
    },
    async loadRetururi({commit,state}){
        let response = await axios.get(`/retururi?domain=${state.domain}&language=${state.language}&token=${state.auth.token}&useridsession=${state.auth.useridsession}`);
        response = response.data;
        commit('SET_RETURURI', response)
    },
    async loadPersonalizeaza({commit,state}){
        let response = await axios.get(`/personalizeaza?domain=${state.domain}&language=${state.language}&token=${state.auth.token}&useridsession=${state.auth.useridsession}`);
        response = response.data;
        for(let i = 0 ; i < response.personalizeaza.length;i++){
            if(response.personalizeaza[i].value == '0') response.personalizeaza[i].value = false;
            else response.personalizeaza[i].value = true;
        }
        commit('SET_PERSONALIZEAZA', response)
    },
    async loadClub({commit,state}){
        let gender = cookies.get("gender");  
        if(!gender) gender = "empty"
        let response = await axios.get(`/club?domain=${state.domain}&language=${state.language}&token=${state.auth.token}&useridsession=${state.auth.useridsession}&c1=${gender}`);
        response = response.data;
        console.log(response)
        commit('SET_CLUB', response)
    },
    async loadPolitica({commit,state}){
        let response = await axios.get(`/politica-de-confidentialitate?domain=${state.domain}&language=${state.language}&token=${state.auth.token}&useridsession=${state.auth.useridsession}`);
        response = response.data;
        console.log(response)
        commit('SET_PDC', response)
    },
    async loadProduse({commit,state}, route, gender){
        let screen = '';
        if(window.innerWidth <= 1600 && window.innerWidth >999) screen = 'medium';
        let response = await axios.get(`/produse?${route}&domain=${state.domain}&cache=${gender}&token=${state.auth.token}&useridsession=${state.auth.useridsession}&campainid=${state.campaign}&screen=${screen}`);
        response = response.data;
        console.log(response)
        commit('SET_SALE', response.lineup)
        commit('SET_BROWSE', response)
        commit('SET_SORT', response.sortby)
        commit("SET_FILTER", response.filter);
        commit("SET_GENERAL_D", response.generaldelete);
        console.log("uisdfugfdsugdugfudsf", response)
    },
    async loadCont({commit,state}){
        let response = await axios.get(`/contul-meu?domain=${state.domain}&language=${state.language}&token=${state.auth.token}&useridsession=${state.auth.useridsession}`);
        response = response.data
        console.log("contul-meu:",response)
        if(state.auth.token){
            commit("SET_MYACC", response.contulmeu.contulmeu);
        }else {
            commit("SET_MYACC", response);
        }
        console.log("hehehehhe",response)
    },
    async loadBrands({commit,state},route){
        let response = await axios.get(`/branduri?domain=${state.domain}&language=${state.language}&${route}&token=${state.auth.token}&useridsession=${state.auth.useridsession}`);
        response = response.data
        // if(state.auth.token){
            commit("SET_BRANDS", response);
        // }
        console.log("zzzz",response)
    },
    async loadWishlist({commit,dispatch, state},route){
        let gender = "";
        if(cookies.get('gender')) gender = cookies.get("gender");
       if(cookies.get('token') && cookies.get("useridsession")) {
                let response = await axios.get(`/wishlist?domain=${state.domain}&language=${state.language}&token=${state.auth.token}&useridsession=${state.auth.useridsession}&wishlists=${cookies.get('wishlist')}&c1=${gender}`);
                response = response.data
                commit("SET_WISHLIST", response.favorites);
                commit("SET_WTEXT", response.stringtexts);
                commit('SET_W_LINK', response.linkexploreaza)
                console.log("wwwwwwwwwwww",response )
                cookies.remove('wishlist');
                dispatch("loadMain",route);
       }
       else {
            let favString = "";
            if(cookies.get('wishlist')){
                let fav = JSON.parse(cookies.get('wishlist'));
                for(let i = 0; i < fav.length;i++){
                    favString = favString + fav[i] + "*";
                }
                favString = favString.slice(0, -1);
            }
           
            let response = await axios.get(`/wishlist?domain=${state.domain}&language=${state.language}&token=${state.auth.token}&useridsession=${state.auth.useridsession}&favoritesstring=${favString}&c1=${gender}`);
            response = response.data
            commit('SET_W_LINK', response.linkexploreaza)
            commit("SET_WISHLIST", response.favorites);
            commit("SET_WTEXT", response.stringtexts);
       }
    },
    async loadPayment({commit,state}, pi){
        let image = 'big';
         if(window.innerWidth <= 1600) image = 'medium';
        if(window.innerWidth <= 700) image = 'small';
        const voucher = cookies.get('voucher');
        let response = await axios.get(`/cardform?domain=${state.domain}&useridsession=${state.auth.useridsession}&token=${state.auth.token}&pi=${pi}&image=${image}&cachevoucher=${voucher}`);
        response = response.data;
        console.log("xx",response)
        if(!response.payinfo&&!state.auth.useridsession&&!state.auth.token) window.location = "/contul-meu";else
        if(response.link!=false) window.location = response.link;
        commit("SET_PAYMENT", response);
    },
    async loadCart({commit,dispatch, state},route){
        const products = cookies.get('cart');
        const voucher = cookies.get('voucher');
        if(cookies.get('token') && cookies.get("useridsession")) {
            cookies.remove("cart")
        }
        let gender = "";
        if(cookies.get('gender')) gender = cookies.get("gender");
        const productsGet = await axios.get(`/checkout?domain=${state.domain}&products=${products}&token=${state.auth.token}&useridsession=${state.auth.useridsession}&cachevoucher=${voucher}`);
        commit('SET_CART_PRODS', productsGet.data);
        const response = await axios.get(`/checkout?domain=${state.domain}&products=${products}&language=${state.language}&token=${state.auth.token}&useridsession=${state.auth.useridsession}&c1=${gender}&cachevoucher=${voucher}`);
		commit('SET_CHECKOUT', response.data)
        console.log("xxxxxxxxxxxxx",response.data)
        dispatch("loadMain",route);
    },
    async loadProduct({commit,state},route){
        let response = await axios.get(`/product?domain=${state.domain}&productid=${route}&gender=${state.gender}&token=${state.auth.token}&useridsession=${state.auth.useridsession}&campainid=${state.campaign}`);
        response.data.product.infoproduct[0].text = response.data.product.infoproduct[0].text.replace(/&lt;br&gt;/g, '\n');
        response.data.product.titlu = response.data.titlu;
        response.data.product.seo = response.data.seo;
        response.data.product.contactform = response.data.contactform
        // if(response.data.product.product_images.length%2==0) response.data.product.product_images.pop();
        commit('SET_PRODUCT', response.data.product);
        commit('SET_SIMILAR', response.data.similarproducts.similarproducts);
    },
    async loadHistory({commit,state},orderid){
        let response = await axios.get(`/detaliicomanda?domain=${state.domain}&token=${state.auth.token}&useridsession=${state.auth.useridsession}&orderid=${orderid}`);
        response = response.data;
        console.log("zzzzzzzzzzzzzzz",response)
        commit('SET_HISTORY', response.detaliicomanda);
    },
    async loadStep({commit,state},route){
        let address;
        let orderid = null;
        const voucher = cookies.get('voucher');
        if(cookies.get('orderid')) orderid = cookies.get('orderid')
        if(cookies.get('address')) address = cookies.get('address')
        // alert(address)
        let payment = cookies.get('payment');
        const data = await axios.get(`/checkoutstep${route}?domain=${state.domain}&useridsession=${state.auth.useridsession}&token=${state.auth.token}&deliverymethod=1&paymenttype=${payment}&idadresa=${address}&orderid=${orderid}&campainid=${state.campaign}&cachevoucher=${voucher}&usercomments=${cookies.get("user_comments")}`);
        commit('SET_CHECKOUT_STEPS', data.data);
        console.log("🚀 ~ file: index.js ~ line 519 ~ loadStep ~ data", data)
        if(state.step.orderid)  cookies.set('orderid', state.step.orderid);
        if(route==2){
            state.forceupdate = state.step.forceupdate;
            if(!state.forceupdate) {
                       if(cookies.get('addressindex'))
                cookies.set('address', state.step.listaadrese[this.$cookies.get('addressindex')].addressid);
            else  
            cookies.set('address', state.step.listaadrese[0].addressid);
            }
        }
        if(this.step==3) {
            cookies.remove('orderid');
        }
        // if(this.step == 2) {
        //     this.form2 = this.$store.getters.GET_STEP2;
        //     this.form2 = JSON.parse(JSON.stringify(this.form2));
        //     console.log(this.$store.state.step);
        // }
    },
    addWishlist(id) {
        cookies.set("x",id);
    }
  }
});