<template>
    <div class="app-newsletter" v-if="$store.state.po">
        <div class="wrapper top-space" v-html="$store.state.po.content"></div>
    </div>
</template>


<script>
export default {
	beforeMount(){
		this.$store.dispatch("loadPo")
    }
}
</script>